import React, {useState} from 'react';
import {Box, Container, Grid, Link, Typography, List, ListItemText, ListItemSecondaryAction, Hidden,  ListItem} from '@material-ui/core';
import {Lock} from '@material-ui/icons';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import honeylogo from './../assets/honeylogo.png';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {LoginHelper} from '../Helper';
import {createBrowserHistory} from 'history';
import {Loader} from '../Components/LoaderButton';
import {useSnackbar} from 'notistack';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
            backgroundColor: '#ffffff',
        },

        description: {

            marginTop: -12,
            // [theme.breakpoints.down('sm')]: {
            //     fontSize: 12,
            //     marginTop: -17,
            //     marginLeft: -8,
            // },
        },

        description2: {
            // [theme.breakpoints.down('sm')]: {
            //     fontSize: 12,
            // },

            
        },

        listStyle: {
            display: 'flex',
            flexDirection: 'row',
        },
        box: {
            backgroundColor: '#fbfbfb',
            paddingTop: '2%',
            paddingBottom: '2%',
            marginTop: '14%',
        },
        root: {
            marginTop: '5%',
            height: '10vh',
            marginBottom: '20%',
        },
        PopupBtnStyle: {
            fontFamily: 'sophia-regular',
            fontWeight: 1000,
            fontSize: 14,
            color: '#ffffff',
            padding: 10,
            width: '100%',
            backgroundColor: '#f28f16',
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#f28f16',
                    color: '#ffffff',
                    boxShadow: 'none',
                    textDecoration: 'none',
                },
            },
        },
        btnStyle: {
            fontFamily: 'sophia-regular',
            fontWeight: 1000,
            fontSize: 14,
            marginTop: 25,
            color: '#ffffff',
            padding: 10,
            backgroundColor: '#f28f16',
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#f28f16',
                    color: '#ffffff',
                    boxShadow: 'none',
                    textDecoration: 'none',
                },
            },
        },
        topContainer: {
            // borderBottom: '1px solid #efefef',
            marginBottom: '5%',
            paddingBottom: '2%',
        },
    }),
);
export const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    return (
        <div className={classes.root}>
            <Container maxWidth="md" className={classes.topContainer}>
                <Grid container direction="row"
                      justify="center"
                      alignItems="center"
                >

                    <Grid item lg={3}>
                    <Link href="/">
                        <img src={honeylogo} alt="image"/>
                        </Link>
                    </Grid>
                    <Grid item lg={6} xs={2}></Grid>
                    <Grid item lg={1}>
                        <Lock/>
                    </Grid>
                    <Grid item lg={2}>
                        <Typography align="left" variant="subtitle2">Secure Login</Typography>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="xs">
                <Grid container direction="row"
                      justify="center"
                      alignItems="center"
                >
                    <Grid item lg={12} style={{paddingBottom: 10}}>
                        <Typography variant='h6'>Please Login</Typography>
                        <Typography style={{minWidth: 435,}} variant="caption">You are required to log into your account before you can request a quote from Australian Honey Ventures</Typography>
                    </Grid>
                    <ValidatorForm onSubmit={async () => {
                        setLoading(true);
                        await LoginHelper.login(email, password).then(x => {
                            if (x.length == 0) {
                                var path = window.location.pathname;
                                if(window.location.search.length == 0) {
                                    createBrowserHistory().push('/');
                                    window.location.reload();
                                } else {
                                var c = window.location.search.substr(1).split('=');
                                createBrowserHistory().push(c[1]);
                                window.location.reload();
                            }
                            } else {
                                enqueueSnackbar(x)
                            }
                        })
                        setLoading(false)
                    }} className={classes.topContainer} noValidate>
                        <TextValidator
                            value={email}
                            onChange={e => setEmail(e.target['value'])}
                            validators={['required', 'isEmail']}
                            errorMessages={['email is required', 'email is in invalid format']}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            size='small'
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            style={{marginBottom : '1%'}}
                        />
                        
                        <TextValidator
                            value={password}
                            onChange={e => setPassword(e.target['value'])}
                            validators={['required']}
                            errorMessages={['Password is required']}
                            variant="outlined"
                            margin="normal"
                            size='small'
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Loader
                            type="submit"
                            fullWidth
                            icon={<div></div>}
                            variant="contained"
                            color="primary"
                            isLoading={loading}
                            className={classes.btnStyle}
                        >
                            Login
                        </Loader>
                    </ValidatorForm>

                    <Hidden only={['sm', 'xs']}>
                    <Grid item lg={4}>
                    <Typography className={classes.description} variant="body2" align="left"><Link href="forgot">Forgot Password ?</Link></Typography>
                    </Grid>
                    <Grid item lg={8}>
                        <List>
                            <ListItem>
                                <ListItemText>
                                <Typography variant="subtitle2" className={classes.description2} align="right">Don't have an account? <Link href="signup">Sign-up</Link></Typography>

                                </ListItemText>

                            </ListItem>
                            <ListItem>
                                
                            </ListItem>
                        </List>
                     
                    </Grid>
                    </Hidden>


                    <Hidden only={['lg', 'md', 'xl']}>
                    <Grid item xs={4} sm={4}>
                    <Typography style={{fontSize: 12}} variant="body2" align="left"><Link href="forgot">Forgot Password ?</Link></Typography>
                    </Grid>
                    <Grid item xs={8} sm={8}>
                        
                          
                                <Typography variant="subtitle2" style={{fontSize: 12}} align="right">Don't have an account? <Link href="signup">Sign-up</Link></Typography>

                            
                          
                       
                    
                     
                    </Grid>
                    </Hidden>



                    <Grid item lg={12} xs={12} style={{paddingTop: 25,}}>
                       
                    </Grid>
                </Grid>
            </Container>
            <Box className={classes.box}>
                <Container maxWidth="xl">
                <Grid container>
                    <Grid item lg={3}></Grid>
                    <Grid item lg={2}>
                        <Typography variant="body2">© 2020, Australian Honey Ventures</Typography>
                    </Grid>
                    <Grid item lg={3} xs={3}>
                    </Grid>
                    <Grid item lg={2} xs={1}>
                        {/* <Typography variant="body2">Privacy Policy</Typography>re */}
                    </Grid>
                    <Grid item lg={2}></Grid>
                </Grid>
                </Container>
            </Box>
        </div>
    )
}