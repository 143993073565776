import React, { useEffect, useState, FunctionComponent } from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, Grid, Typography, CircularProgress, ListItem, ListItemText, ListItemSecondaryAction, List, Tooltip } from '@material-ui/core';
import { Quantity } from './../Pages/SingleProduct/Quantity';
import productthumn from './../assets/productthumn.jpg';
import { API, LoginHelper, getval } from '../Helper';
import { useSnackbar } from 'notistack';
import { Loader, AutoLoader } from './LoaderButton';
import { GlobalState } from '../AppState';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
  table: {
    minWidth: '100%',
    overflowX: 'hidden',

  },

  bottomListStyle: {

    display: 'flex',
    flexDirection: 'column',
    width: '83%',

    [theme.breakpoints.down('sm')]: {
      width: '80%',
      
    },

  },

  mainheading: {
    fontFamily: 'sophia',
    paddingBottom: '5%',

  },

}),
);




export const CartListForQuote: FunctionComponent<{ quote: any }> = props => {

  const classes = useStyles();
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();


  var isQuoted = props.quote['status'] == 'Quoted';

  const getCartItems = async () => {
    // var req = await API.get('quote/items', {  });
    // var quo = await API.get('')
    // if (req.status == 200) {
    //   setLoading(false);
    //   setCart(req.data['data']);
    //   console.log(req.data);
    // } else {
    //   enqueueSnackbar("Error getting Cart Data");
    // }

  }
  useEffect(() => {
    getCartItems();
  }, [])

  // if (loading) {
  //   return <Grid
  //     container
  //     spacing={0}
  //     direction="column"
  //     alignItems="center"
  //     justify="center"
  //     style={{ minHeight: '30vh' }}
  //   >

  //     <Grid item xs={3}>
  //       < CircularProgress />
  //     </Grid>
  //   </Grid>
  // }

  if (!props.quote['cartItems']) {
    return <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '30vh' }}
    >

      <Grid item xs={3}>
        THIS IS WHEN NOTHING IS IN THE CART
    </Grid>
    </Grid>
  }

  const getSubTotalSingleCartItem = (row) => {
    if (row['cartItem']['discount'] == null || !row['cartItem']['offeredPrice'] == null) {
      return '-'
    }
    var op = Number(row['cartItem']['offeredPrice']);
    var quantity = Number(row['cartItem']['quantity']);
    var discount = Number(row['cartItem']['discount']);
    var itemsPerCarton = Number(row['cartItem']['variation']['itemsPerCarton'])
    var oTotal = op * quantity * itemsPerCarton;
    return (oTotal - ((discount / 100) * oTotal)).toLocaleString()
  }



  const getSubTotal = (cartItems: any[]): number => {

    var subtotal = 0;

    cartItems.forEach(x => {
      var c = {
        quantity: x['quantity'],
        discount: x['discount'],
        price: x['offeredPrice'],
        itemsPerCarton: x['variation']['itemsPerCarton']
      }

      var total = (c.quantity * c.price * c.itemsPerCarton);
      var price = total - (c.discount / 100 * total);
      subtotal += price;
    });

    return subtotal
  }




  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell  align="right">Product ID</TableCell>
              <TableCell align="right">Product Description</TableCell>
              <TableCell align="right">Image</TableCell>
              <TableCell align="right">Size(g)</TableCell>
              <TableCell align="right">No. Of Cartons</TableCell>
              <Tooltip title="Price of a unit  x  Items In A Carton">
                <TableCell align="left">Starting Price</TableCell>
              </Tooltip>
              <TableCell align="right">Discount</TableCell>
              <TableCell align="right">Subtotal</TableCell>
              {/* <TableCell align="right">Action</TableCell> */}
            </TableRow>
          </TableHead>
          {
            props.quote['cartItems'].map((x, i) => { return { product: props['quote']['products'][i], cartItem: x } }).map((x, i) => {
              return <TableBody style={{ paddingTop: -15, paddingBottom: -15, }}>
                <TableCell className="singlequote" align="right">HON-PRO-{x['product']['id']}</TableCell>
                <TableCell className="singlequote" align="right">{x['product']['productTitle']}</TableCell>
                <TableCell className="singlequote" align="right"><img src={x['product']['primaryImage']} width="35" height="35" /></TableCell>
                <TableCell className="singlequote" align="right">{x['cartItem']['variation']['weight']}</TableCell>
                {/* <TableCell align="right"><Quantity  cartItem={x}/></TableCell> */}
                <TableCell className="singlequote" align="right">{x['cartItem']['quantity']}</TableCell>

                <TableCell className="singlequote" align="right">${(x['cartItem']['variation']['itemsPerCarton'] * x['cartItem']['variation']['price']).toLocaleString()}</TableCell>
                <TableCell className="singlequote" align="right">{isQuoted ? x['cartItem']['discount'] + ' %' : '-'}</TableCell>
                <TableCell className="singlequote" align="left">{isQuoted ? <div>${getSubTotalSingleCartItem(x)}</div> : '-'}</TableCell>
              
              </TableBody>
            })
          }
        </Table>
      </TableContainer>
      <Grid container style={{ backgroundColor: '#fff' }}>
        <Grid item lg={8}>
        </Grid>
        <Grid item lg={4} sm={12} xs={12}>
          <List dense className={classes.bottomListStyle}>
            <ListItem>
              <ListItemText >
                <Typography variant="subtitle2">Sub Total</Typography>
              </ListItemText>

              <ListItemSecondaryAction >
                <Typography variant="body2">{isQuoted ? '$' + getSubTotal(props.quote['cartItems']).toLocaleString() : '-'}</Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography variant="subtitle2">Shipping Costs</Typography>
              </ListItemText>
              <ListItemSecondaryAction>
                <Typography variant="body2">{isQuoted ? '$' + getval(props.quote, 'shippingCost', '') : '-'}</Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography variant="subtitle2">Total</Typography>
              </ListItemText>
              <ListItemSecondaryAction>
                <Typography variant="body2">{isQuoted ? '$' + (Number(props.quote['shippingCost']) + Number(getSubTotal(props.quote['cartItems']))).toLocaleString() : '-'}</Typography>
              </ListItemSecondaryAction>
            </ListItem>



          </List>
        </Grid>

      </Grid>




    </div>
  )
}






export const CartListForOrder: FunctionComponent<{ order: any }> = props => {




  const classes = useStyles();
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();


  var isOrdered = props.order['status'] == 'Ordered';

  const getCartItems = async () => {
    // var req = await API.get('quote/items', {  });
    // var quo = await API.get('')
    // if (req.status == 200) {
    //   setLoading(false);
    //   setCart(req.data['data']);
    //   console.log(req.data);
    // } else {
    //   enqueueSnackbar("Error getting Cart Data");
    // }

  }
  useEffect(() => {
    getCartItems();
  }, [])

  // if (loading) {
  //   return <Grid
  //     container
  //     spacing={0}
  //     direction="column"
  //     alignItems="center"
  //     justify="center"
  //     style={{ minHeight: '30vh' }}
  //   >

  //     <Grid item xs={3}>
  //       < CircularProgress />
  //     </Grid>
  //   </Grid>
  // }

  if (!props.order['cartItems']) {
    return <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '30vh' }}
    >

      <Grid item xs={3}>
        THIS IS WHEN NOTHING IS IN THE CART
    </Grid>
    </Grid>
  }

  const getSubTotalSingleCartItem = (row) => {
    if (row['cartItem']['discount'] == null || row['cartItem']['offeredPrice'] == null) {
      return '-'
    }
    var op = Number(row['cartItem']['offeredPrice']);
    var quantity = Number(row['cartItem']['quantity']);
    var discount = Number(row['cartItem']['discount']);
    var itemsPerCarton = Number(row['cartItem']['variation']['itemsPerCarton'])
    var oTotal = op * quantity * itemsPerCarton;


    return (oTotal - ((discount / 100) * oTotal)).toLocaleString();
    return <div>
      {/* <div >{op + ' * ' + quantity + ' * ' + itemsPerCarton + " = "}<div style={{ textDecoration: 'line-through' }}> {oTotal}</div> </div> */}
    </div>
  }

  const getSubTotal = (cartItems: any[]): number => {

    var subtotal = 0;

    cartItems.forEach(x => {
      var c = {
        quantity: x['quantity'],
        discount: x['discount'],
        price: x['offeredPrice'],
        itemsPerCarton: x['variation']['itemsPerCarton']
      }

      var total = (c.quantity * c.price * c.itemsPerCarton);
      var price = total - (c.discount / 100 * total);
      subtotal += price;
    });
    return Math.round((subtotal + Number.EPSILON) * 100) / 100
    return subtotal
  }




  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right">ID</TableCell>
              <TableCell align="right">Product Description</TableCell>
              <TableCell align="right">Image</TableCell>
              <TableCell align="right">Size(g)</TableCell>
              <TableCell align="right">No. Of Cartons</TableCell>
              <Tooltip title="Price of a unit  x  Items In A Carton">
                <TableCell align="right">Price per carton</TableCell>
              </Tooltip>
              <TableCell align="right">Discount</TableCell>
              <TableCell align="right">Subtotal</TableCell>

            </TableRow>
          </TableHead>
          {
            props.order['cartItems'].map((x, i) => { return { product: props['order']['products'][i], cartItem: x } }).map((x, i) => {
              return <TableBody style={{ paddingTop: -15, paddingBottom: -15, }}>
                <TableCell className="singleorder" align="right">HON-PRO-{x['product']['id']}</TableCell>
                <TableCell className="singleorder" align="right">{x['product']['productTitle']}</TableCell>
                <TableCell className="singleorder" align="right"><img src={x['product']['primaryImage']} width="35" height="35" /></TableCell>
                <TableCell className="singleorder" align="right">{x['cartItem']['variation']['weight'] + 'g'}</TableCell>

                <TableCell className="singleorder" align="right">{x['cartItem']['quantity']}</TableCell>
                <TableCell className="singleorder" align="right">${(x['cartItem']['variation']['itemsPerCarton'] * x['cartItem']['variation']['price']).toLocaleString()}</TableCell>
                {/* <TableCell align="right">{x['cartItem']['variation']['price'] + ' * ' + x['cartItem']['variation']['itemsPerCarton'] + ' = ' + x['cartItem']['variation']['itemsPerCarton'] * x['cartItem']['variation']['price']}</TableCell> */}
                <TableCell className="singleorder" align="right">{x['cartItem']['discount'] + ' %'}</TableCell>
                {/* <TableCell align="right">{getSubTotalSingleCartItem(x)}</TableCell> */}
                {/* <TableCell align="right">{true ? '$' + getSubTotal(props.order['cartItems']) : '-'}</TableCell> */}
                <TableCell className="singleorder" align="right">{isOrdered ? '$' + getSubTotalSingleCartItem(x).toString() : '-'}</TableCell>
                {/* <TableCell align="right"><AutoLoader
                  onClick={async () => {
                    await API.post('cart/removeitem', { item: x['id'] }).then(res => {
                      if (res.status == 200) {
                        enqueueSnackbar('Item Removed');
                        var nc = [...cart.filter(z => z['id'] != x['id'])];
                        GlobalState.localCart = {items : nc.length , data : nc}
                        console.log(nc);
                        setCart([...nc])
                      }
                    })
                  }}
                  variant="contained">Remove</AutoLoader></TableCell> */}
              </TableBody>
            })
          }
        </Table>
      </TableContainer>
      <Grid container style={{ backgroundColor: '#fff' }}>
        <Grid item lg={8}>
        </Grid>
        <Grid item lg={4} xs={12} sm={12} md={12}>
          <List dense className={classes.bottomListStyle}>
            <ListItem>
              <ListItemText>
                <Typography variant="subtitle2">Sub Total</Typography>
              </ListItemText>

              <ListItemSecondaryAction>
                <Typography variant="body2">{true ? '$' + getSubTotal(props.order['cartItems']) : '-'}</Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography variant="subtitle2">Shipping Costs</Typography>
              </ListItemText>
              <ListItemSecondaryAction>
                <Typography variant="body2">{true ? '$' + getval(props.order, 'shippingCost') : '-'}</Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography variant="subtitle2">Total</Typography>
              </ListItemText>
              <ListItemSecondaryAction>
                <Typography variant="body2">${true ? Number(props.order['shippingCost']) + Number(getSubTotal(props.order['cartItems'])) : '-'}</Typography>
              </ListItemSecondaryAction>
            </ListItem>



          </List>
        </Grid>

      </Grid>



    </div>
  )
}