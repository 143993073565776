  import firebase from 'firebase';


export const SetupFirebase = ()=>{
    var firebaseConfig = {
        apiKey: "AIzaSyDFOLsTs22XrVTutQhfscianMAu5T93JM4",
        authDomain: "cobalt-broker-279401.firebaseapp.com",
        databaseURL: "https://cobalt-broker-279401.firebaseio.com",
        projectId: "cobalt-broker-279401",
        storageBucket: "cobalt-broker-279401.appspot.com",
        messagingSenderId: "190057079475",
        appId: "1:190057079475:web:fdd7e32202c0a5788a06c6",
        measurementId: "G-EDWKEX3NCV"
      };

    firebase.initializeApp(firebaseConfig)
}