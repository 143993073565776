import React from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, Hidden,  CardContent, Button, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import { Image, Work, BeachAccess } from '@material-ui/icons';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { MyButton } from './../Styles/MyButton';
import branding2 from './../assets/branding2.png';
import distributer from './../assets/distributer.jpg';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    listStyle: {
display: 'flex',
flexDirection: 'row',
width: '100%',

[theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },




    },

    actionArea: {

        maxWidth: 450,
        
      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },
    root: {
      
      // boxShadow: 'none',
      // width: 600,
      // height: 584,

      boxShadow: 'none',
    
      width: '100%',
      height: '100%',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    
      backgroundSize: 'cover',

      
    },
    media: {
      
      width: 514,
      height: 409,
      
    },
  
    imgStyle: {
  
      maxWidth: 120,
     
  
    },

    mainHeading: {
      fontFamily: 'sophia',
      color: '#303030',
      fontSize: 22,
  

  },

  
  largeHeading: {
    fontFamily: 'sophia',
    color: '#303030',
    fontSize: 24,

    paddingBottom: 15,

},


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: '1.25rem',
      color: '#726f6f',
      fontWeight: 500,

  },
  
  

    vector: {

        // width: 600,
        // height: 584,
        boxShadow: 'none',
    
        width: '100%',
        height: '100%',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',

        // [theme.breakpoints.down('sm')]: {
        //     width: 300,
        //     height: 290,
        //     marginLeft: '-20%',
        //   },
    }
  
}),
);




export const DistributorProducts = () => {
    const classes = useStyles();

    return(
        <div style={{paddingTop: '2%', }}>
        <Container maxWidth="lg">
        <Grid container
        
         direction="row"
         justify="center"
         alignItems="center"
         style={{marginTop: '1%', }}
         >

       

        <Grid item lg={6} sm={12} xs={12} md={6}>

       

        <Card className={classes.root}>
     
     <CardMedia
       >
           <img src={distributer} className={classes.vector} alt="services" />
       </CardMedia>

 </Card>
      

        </Grid>

        

{/* For Larger Screens */}

<Hidden only={['sm', 'md', 'xs']}>

        <Grid item lg={6} sm={12} xs={12} md={6}>

 <Typography className={classes.largeHeading} style={{ fontSize: '1.9rem', paddingLeft: '15%'}} align="left" gutterBottom variant="h3" component="h2">
 DISTRIBUTE OUR<br />
EXCITING HONEY PRODUCTS
       </Typography>
   
       
       <Typography className={classes.descriptionStyle} style={{paddingLeft: '15%'}} align="justify" variant="subtitle2" color="textSecondary" component="p">
       Innovating across the honey category means we can produce exciting products that consumers love. We then work closely with our distributors to build branding stories for these products to capture the heart and minds of consumers. Sometimes this means co-developing new brands to suit a niche local culture or demographic.<br /><br />

       Distributors enjoy the use of our well-designed marketing collateral such as fact sheets, specification sheets, flyers, brochures, pricing packets and help sell our products more effectively against their competition.<br /><br />

       We do everything possible to enable our distributors and set them up for success.
       </Typography>
       
     </Grid>

     </Hidden>


     {/* For Smaller Screens */}

     <Hidden only={['xl', 'lg',]}>

        <Grid item lg={6} sm={12} xs={12} md={6}>

 <Typography className={classes.largeHeading} style={{ fontSize: '1.9rem',}} align="center" gutterBottom variant="h3" component="h2">
 DISTRIBUTE OUR<br />
EXCITING HONEY PRODUCTS
       </Typography>
   
       
       <Typography className={classes.descriptionStyle} align="justify" variant="subtitle2" color="textSecondary" component="p">
       Innovating across the honey category means we can produce exciting products that consumers love. We then work closely with our distributors to build branding stories for these products to capture the heart and minds of consumers. Sometimes this means co-developing new brands to suit a niche local culture or demographic.<br /><br />

       Distributors enjoy the use of our well-designed marketing collateral such as fact sheets, specification sheets, flyers, brochures, pricing packets and help sell our products more effectively against their competition.<br /><br />

       We do everything possible to enable our distributors and set them up for success.
       </Typography>
       
     </Grid>

     </Hidden>



     <Hidden only={['sm', 'xs',]}>
     <Grid item lg={12}>
     <Typography className="headingquote" style={{ fontSize: '2.4rem', paddingTop: '2%', paddingBottom: '2%',  fontFamily: 'sophia',
    color: '#303030',
    
}} align="center" gutterBottom variant="h3" component="h2">
  
    Distributors can ensure their retailers stay stocked with the most<br /> in-demand product lines
       </Typography>

       <Typography  style={{ fontSize: '1.7rem', fontFamily: 'sophia-regular', paddingBottom: '2%'}} align="center" gutterBottom variant="h3" component="h2">
       Jay Curtin, CEO
       </Typography>

     </Grid>
     </Hidden>


     <Hidden only={['xl', 'lg', 'md']}>
     <Grid item lg={12}>
     <Typography className="headingquote" style={{ fontSize: '1.6rem', padding: '2%', marginTop: '5%',  fontFamily: 'sophia',
    color: '#303030',
    
}} align="center" gutterBottom variant="h3" component="h2">
  
    Distributors can ensure their retailers stay stocked with the most in-demand product lines
       </Typography>

       <Typography  style={{ fontSize: '1.7rem', fontFamily: 'sophia-regular', paddingBottom: '2%'}} align="center" gutterBottom variant="h3" component="h2">
       Jay Curtin, CEO
       </Typography>

     </Grid>
</Hidden>
     

        </Grid>



        

        
        </Container>
        </div>
    )
}