import Grid from "@material-ui/core/Grid"
import React, { FunctionComponent } from "react"

export const CenterContent:FunctionComponent = props => {
    return <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
    >

        <Grid item xs={3}>
            {props.children}
        </Grid>

    </Grid>
}