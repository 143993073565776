import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import {
    Badge,
    Box,
    Breadcrumbs,
    Container,
    Divider,
    Grid,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
    CircularProgress,
    Button,
    Card, 
    CardActionArea,
    CardMedia,
    CardContent,

} from '@material-ui/core';
import { observer } from 'mobx-react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import honeylogo from './../../assets/honeylogo.png';
// import { LoginHelper } from '../Helper';
// import { Loader } from '../Components/LoaderButton';
import { useSnackbar } from 'notistack';
import { CheckCircleOutlineOutlined, ShoppingCart } from '@material-ui/icons';
import tableproduct from './../../assets/tableproduct.jpg';
import { API, Cook, Ways,  getval, getOfferPrice } from '../../Helper';
import { createBrowserHistory} from 'history';
import { GlobalState } from '../../AppState';


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            backgroundColor: '#fff',

            [theme.breakpoints.down('sm')]: {
                paddingBottom: '15%',
            },
        },
        captionStyle: {
            fontSize: 14,
            marginTop: '-2%',
        },
        headingStyle: {
            fontSize: '1.5rem',
            color: '#303030',
            paddingTop: '10%',
            paddingBottom: '2%',
        },

        headingStyle1: {
            fontSize: '1rem',
            color: '#303030',
            paddingTop: '3%',
            paddingBottom: '2%',
        },
        footer: {
            padding: theme.spacing(3, 2),
            marginTop: 'auto',
            backgroundColor:
                theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
        },
        listStyle: {
            display: 'flex',
            flexDirection: 'row',
        },
        box: {
            backgroundColor: '#fbfbfb',
            paddingTop: '2%',
            paddingBottom: '2%',
            marginTop: '14%',
        },
        // root: {
        //   marginTop: '5%',
        //   height: '10vh',
        //   marginBottom: '20%',
        // },
        PopupBtnStyle: {
            fontFamily: 'sophia-regular',
            fontWeight: 1000,
            fontSize: 14,
            color: '#ffffff',
            padding: 10,
            width: '100%',
            backgroundColor: '#f28f16',
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#f28f16',
                    color: '#ffffff',
                    boxShadow: 'none',
                    textDecoration: 'none',
                },
            },
        },
        btnStyle: {
            fontFamily: 'sophia-regular',
            fontWeight: 1000,
            fontSize: 14,
            color: '#ffffff',
            padding: 10,
            backgroundColor: '#f28f16',
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#f28f16',
                    color: '#ffffff',
                    boxShadow: 'none',
                    textDecoration: 'none',
                },
            },
        },
        topContainer: {
            paddingTop: '2%',
            paddingBottom: '2%',
        },

        actionArea: {
        
            '&.MuiCardActionArea-root': {
      
              textAlign: 'center',
      
      
            },
      
          },


          card: {
            maxWidth: 540,
            minWidth: 400,
            boxShadow: 'none',
      
            
          },
          media: {
            height: 120,
            
          },
      
        cart: {
            marginLeft: 90,
            [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
            },
        },
    }),
);
export const ThanksPage = observer(() => {


    const [quote , setQuote] = useState({});

    const getData = async() => {

        var id = window.location.pathname.split('/')[2];
        console.log(id);
        var off = await API.get('offer', { offerid: id });
        console.log(off.data);
        setQuote(off.data);
    }

    useEffect(() => {
        getData();
    }, [])


  
    const classes = useStyles();
   


    // const { enqueueSnackbar } = useSnackbar();
  
  

 



    // let id: number = 1
    // const [data, setData] = useState<any[]>([]);
    // const [order, setOrder] = useState({});
    // useEffect(() => {
    //     API.get('order', { userid: Cook.getUserData()['user']['id'] }).then(x => {
    //         console.log(x.data);
    //         setData(x.data);
    //     })
    // }, []);
  


    // useEffect(() => {
    //     API.get('offer', { offerid: window.location.pathname.split('/')[2] }).then(x => {
    //         console.log(x.data);
    //         setOffer(x.data);
    //     })
    // }, [])

    // if (!offer['id']) {
    //     return <CircularProgress />
    // }

    
    return (
        <div className={classes.root}>
            <Box style={{ backgroundColor: '#fff', }}>
                <Container maxWidth="lg" className={classes.topContainer}>
                    <Grid container direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item lg={3}>
                            <Link href="/">
                            <img src={honeylogo} alt="image" onClick={()=>{
                                createBrowserHistory().push('/');
                                window.location.reload();
                            }} />
                            </Link>
                        </Grid>
                        <Grid item lg={7} xs={2}></Grid>
                       
                        {/* <Grid item lg={1}>
                            <Typography align="right" variant="subtitle2">Thank you</Typography>
                        </Grid> */}
                    </Grid>
                </Container>
            </Box>
            <Box style={{ backgroundColor: '#fff', padding: '1%', borderTop: '1px solid #efefef', marginBottom: '3%' }}>
                <Container>
                    <Grid container>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography color="textPrimary" variant="body1">Shipping</Typography>
                            <Link color="inherit" href="/getting-started/installation/">
                                <Typography variant="body2">Payment</Typography>
                            </Link>
                            <Link color="inherit" href="/getting-started/installation/">
                                <Typography variant="body2">Review</Typography>
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Container>
            </Box>
            <Container maxWidth="lg">
                <Grid container spacing={2} justify="center" alignItems="center">
                    <Grid item lg={3}></Grid>

                    <Grid item lg={6} xs={12} sm={12} style={{ backgroundColor: '#fff', padding: '3%', }}>


                    <Card className={classes.card}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          className={classes.media}>
              <CheckCircleOutlineOutlined style={{ fontSize: 150, color: '#f28f16'}} />
          </CardMedia>
        <CardContent>
           
           
          <Typography className={classes.headingStyle} align="center" gutterBottom variant="h5" component="h2">
        Thank you for your recent purchase!!
          </Typography>

          <Typography className={classes.headingStyle1} align="center" gutterBottom variant="subtitle2" component="h2">
        {/* Your Order No: HON-ORD-{order['rid']} */}
    
        <Typography  variant="h6">Order # HON-ORD-{quote['rid']} </Typography>
          </Typography>

          
          <br />
          <Typography className={classes.captionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
          When you are looking for something truly special, we hope that AHV will continue to be the first place you think of first.
          </Typography><br />
          <Typography className={classes.captionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
          We hope you enjoy your new products.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
                       
                            </Grid>

                            <Grid item lg={3}></Grid>

                            <Grid item lg={12}>
                            {/* <List style={{borderTop: '1px solid #bcbaba', marginTop: '3%'}}>
                            <ListItem>
                                <Typography variant="h6">Shipping Address</Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant="body2"><br />
                                    {getval(offer, 'user.firstName') + ' ' + getval(offer, 'user.lastName')}<br />
                                    {getval(offer, 'street1')} ,  {getval(offer, 'user.street2')}<br />
                                    {getval(offer, 'city')}, {getval(offer, 'state')}, {getval(offer, 'zipcode')}, {getval(offer, 'country')}<br />
                                    {getval(offer, 'user.email')}<br />
                                    {getval(offer, 'user.phone')}</Typography>
                            </ListItem>
                        </List> */}
                    </Grid>

                    <Grid item lg={4}>

                    </Grid>

                    <Grid item lg={2} sm={6} xs={12}>
                    <Link href="/shop"><Button style={{color: '#fff', backgroundColor: '#f28f16', marginBottom: '8%'}} fullWidth variant="contained">Shop More</Button></Link>
                       
                        </Grid>

                        <Grid item lg={2} sm={6} xs={12}>
                    <Link href="/dashboard/orders"><Button style={{color: '#fff', backgroundColor: '#f28f16', marginBottom: '8%'}} fullWidth variant="contained">View Orders</Button></Link>
                       
                        </Grid>

                        <Grid item lg={4}>
                        
                        </Grid>

                
                </Grid>
            </Container>
        </div>
    )
}
)
