import React from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, CardContent, Hidden, Button, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import { Image, Work, BeachAccess } from '@material-ui/icons';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { MyButton } from './../Styles/MyButton';
import branding2 from './../assets/branding2.png';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    listStyle: {
display: 'flex',
flexDirection: 'row',
width: '100%',

[theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },




    },

    actionArea: {

        maxWidth: 450,
        
      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },
    root: {
      
      boxShadow: 'none',
      width: 510,
      height: 409,

      
    },
    media: {
      
      width: 514,
      height: 409,
      
    },
  
    imgStyle: {
  
      maxWidth: 120,
     
  
    },

    mainHeading: {
      fontFamily: 'sophia',
      color: '#303030',
      fontSize: 22,
  

  },

  
  largeHeading: {
    fontFamily: 'sophia',
    color: '#303030',
    fontSize: 24,

    paddingBottom: 15,

},


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: '1.25rem',
      color: '#726f6f',
      fontWeight: 500,

  },
  
  

    vector: {

        width: 514,
        height: 409,

        [theme.breakpoints.down('sm')]: {
            width: 350,
            height: 340,
          },
    }
  
}),
);




export const HoneyInformation = () => {
    const classes = useStyles();

    return(
        <div style={{paddingTop: '2%', paddingBottom: '15%'}}>
        <Container maxWidth="lg">
        <Grid container
        
         direction="row"
         justify="center"
         alignItems="center"
         style={{marginTop: '1%', }}
         >

       

        <Grid item lg={4} sm={12} xs={12}>

<Hidden only={['sm', 'xs']}>
        <Card className={classes.root}>
     
        <CardMedia
          >
              <img src={branding2} className={classes.vector} alt="services" width="514" height="409" />
          </CardMedia>

    </Card>
    </Hidden>

    <Hidden only={['md', 'lg', 'xl']}>
        <Card style={{  boxShadow: 'none',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: 'transparent',
      paddingTop: '4%',
      paddingBottom:'20%'}}>
     
        <CardMedia
          >
              <img src={branding2} className={classes.vector} alt="services" width="514" height="409" />
          </CardMedia>

    </Card>
    </Hidden>


        </Grid>

        <Grid item lg={2}></Grid>

        <Grid item lg={4} sm={12} xs={12}>
       
           
        <Typography className={classes.largeHeading} style={{ fontSize: '1.9rem',}} align="left" gutterBottom variant="h3" component="h2">
       Know everything about your honey
       </Typography>
       <br />
       <Typography className={classes.mainHeading} align="left" gutterBottom variant="h5" component="h2">
       Visualise information
       </Typography>
       
       <Typography className={classes.descriptionStyle} align="justify" variant="subtitle2" color="textSecondary" component="p">
       Find useful information about your honey in a custom-built dashboard. 
       </Typography>
       <br /><br />

       <Typography className={classes.mainHeading} align="left" gutterBottom variant="h5" component="h2">
       View your stock holdings
       </Typography>
       
       <Typography className={classes.descriptionStyle} align="justify" variant="subtitle2" color="textSecondary" component="p">
       Manage your complex inventory stock levels and sales volumes with full visibility across each honey variety 
       </Typography>

       <br /><br />

        <Typography className={classes.mainHeading} align="left" gutterBottom variant="h5" component="h2">
        Improved transparency 
        </Typography>
        
        <Typography className={classes.descriptionStyle} align="justify" variant="subtitle2" color="textSecondary" component="p">
        AHV believes beekeepers should have access to unvarnished and raw information so they can become more effective businesses   
        </Typography>
  

     </Grid>

     

        </Grid>



        

        
        </Container>
        </div>
    )
}