import React, {FunctionComponent} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { ShoppingBasket, AccountCircle, Notifications, Mail } from '@material-ui/icons';
import { List, ListItem, Typography, Badge } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { API, AppEvent, Bbc, LoginHelper, Ways, Cook } from './../Helper';
import { GlobalState } from '../AppState';
import { observer } from 'mobx-react';


const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 70,
    bottom: 0,
    position: 'fixed',
    backgroundColor: '#fff',
    boxShadow: '0px -4px 22px 0px rgba(179,179,179,1)',
    zIndex: 1,


   
  },

  customBadge: {
    backgroundColor: "#BF3604",
    color: "white",
    

},

  iconStyle: {

fontSize: '1.6rem',
color: '#494949',
paddingBottom: 5,


  },

//   customBadge: {
//     backgroundColor: "#BF3604",
//     color: "white"
// },

// iconStyle: {

//   color: '#b5b3b3',
//   fontSize: '2.2rem',
// },

});


//  const MessageNotificationsButton = observer(() => {
//         return <MyBadge count={GlobalState.unreadMessages.length }>
//             <Mail className={classes.iconStyle2} />
//         </MyBadge>
//     })


// export const MyBadge:FunctionComponent<{count :number}> = props=> {
//   const classes = useStyles()
// return <Badge badgeContent={8} style={{ marginTop: 15, marginLeft: '-3%' }} classes={{ badge: classes.customBadge }}>{props.children}</Badge>
// }






export const BottomBarNavigation = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const CartBadge = observer(() => {
    return <Badge badgeContent={GlobalState.localCart['items']} classes={{ badge: classes.customBadge }}>
        <ShoppingBasket className={classes.iconStyle} />
    </Badge>
  })
  

  return (
      <div>
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
    >
     
    
      <BottomNavigationAction value="signal" component={Link} to="/dashboard" label="Account" icon={<AccountCircle className={classes.iconStyle} />}>
        
          </BottomNavigationAction>
         
    
          {
            (LoginHelper.isCookiePresent())
                            ?

      <BottomNavigationAction component={Link} value="basket" to="/quote-basket" label="Quote Basket" icon={<CartBadge />}>
          </BottomNavigationAction>

          :
<div>

</div>

          }





      {/* <MyBadge count={GlobalState.unreadMessages.length}> */}
      {/* <MyBadge count={GlobalState.unreadMessages.length}> */}
      <BottomNavigationAction component={Link} to="dashboard/chats" value="notifications" label="Messages" icon={<Mail className={classes.iconStyle} />}>
          </BottomNavigationAction>
          {/* </MyBadge> */}
     


    </BottomNavigation>

 

    </div>
  );
}