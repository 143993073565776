import { Button, Chip, Grid, Link, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { API, Cook, Ways } from '../../Helper';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        padding: theme.spacing(0),
        overflow: 'auto',
    },
    headingStyleMain: {
        fontFamily: 'sophia',
        fontSize: 28,
        color: '#3d3a3a',
    },
    btnStyle: {
        backgroundColor: '#FF0000',
        boxShadow: 'none',
        color: '#ffffff',
        '&.MuiButton-contained': {
            '&:hover': {
                backgroundColor: '#FF0000',
                color: '#ffffff',
                boxShadow: 'none',
            },
        },
    },
    fixedHeightPaperEmail: {
        boxShadow: 'none',
        padding: theme.spacing(0),
    },
}));
export const AllOrdersTable = () => {
    const classes = useStyles();
    let id: number = 1
    const [data, setData] = useState<any[]>([]);
    useEffect(() => {
        API.get('userOrders', { userid: Cook.getUserData()['user']['id'] }).then(x => {
            console.log(x.data);
            setData(x.data);
        })
    }, []);
    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item lg={12} md={12} xs={12} className="orderDiv">
                {/* <Paper className={classes.fixedHeightPaperEmail}> */}
                    <Typography align="left" className={classes.headingStyleMain} variant="h6">All
                        Orders</Typography><br />
                      
                    <MaterialTable
                        style={{
                            boxShadow: 'none',
                            backgroundColor: '#fbfbfb',
                            borderBottomLeftRadius: 8,
                            borderBottomRightRadius: 8,
                        }}
                        title=""
                        columns={[
                            { title: 'Order Id', render: (row) => <div>HON-ORD-{row['rid']}</div> },
                            // { title: ' Buyer Name', field: 'user.firstName' },

                            {title: 'Date & Time', field: 'lastUpdated', type: 'datetime' },
                         
                            { title: 'Products', render: (row) => <div>{row['cartItems'].length}</div> },
                            // { title: 'Weight', field: 'items', type: 'numeric' },
                            // { title: 'Order Value', field: 'totalBill', type: 'numeric' },
                            // {title: 'Order Value', render: (row) => <div>${row['totalBill']}</div>},
                            {title: 'Order Value', render: (row) =><div>${ (row['totalBill']).toLocaleString()}</div>},
                            
                            {
                                title: 'Status', render: (row) =>
                                    <Grid item lg={2}>
                                        <Chip label={row['status']}
                                            style={{ backgroundColor: '#FCE9D4', color: '#303030', }} />
                                    </Grid>
                            },
                            {
                                title: 'Actions', render: (row) =>
                                    <Grid item lg={2} style={{ marginLeft: 20, }}>
                                        <Link href={Ways.singleOrderPage + '/' + row['id']}
                                            style={{ textDecoration: 'none' }}>
                                            <Button style={{backgroundColor: '#FCB040'}} variant="contained" size="small"
                                                className={classes.btnStyle}>View</Button>
                                        </Link>
                                    </Grid>
                            },
                        ]}
                        data={data}
                        options={{
                            headerStyle: {
                                backgroundColor: '#ffffff',
                                fontSize: '1.0em',
                                fontWeight: 400,
                            }
                        }}
                    />
                    
                {/* </Paper> */}
            </Grid>
        </Grid>
    )
}