import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import comming from './../assets/comming.png';



export const CommingSoon = () => {
    return(
            <div style={{padding: 100}}>
                <Container>
                    <Grid container justify="center" alignItems="center">
                            
                        {/* <img src={comming} alt="image" width="850" height="650" /> */}
                        <Typography style={{color: '#FCB040', fontFamily: 'sophia'}} align="center" variant="h2">Coming Soon</Typography>
                   
                    </Grid>
                </Container>
 
        </div>
    )
}