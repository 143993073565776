
import React, { useState, FunctionComponent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { List, ListItem, IconButton } from '@material-ui/core';
import { AddBoxOutlined, IndeterminateCheckBoxOutlined, IndeterminateCheckBox } from '@material-ui/icons';
import minus from './../../assets/minus.png';
import add from './../../assets/add.png';
import { AutoLoaderIcon } from '../../Components/LoaderButton';
import { API } from '../../Helper';
import { AddBox } from '@material-ui/icons';
import { useSnackbar } from 'notistack';



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'row',
            marginLeft: -27,
            marginTop: -15,
            // border: '1px solid #efefef',
            width: 90,
        },

        listItem: {

            width: 30,
            marginLeft: 40,
            
            marginRight: -4,
            

        },

        iconStyle: {

            '&.MuiIconButton-root:hover': {
               backgroundColor: 'transparent',
               
            },

            '&.makeStyles-wrapper:hover': {
                    color: '#000',
            },
        },

    }),
);






export const Quantity: FunctionComponent<{   cartItem: any, disabled?: boolean, onChange?: (value: number) => void , minValue?:number, countOnly?:boolean }> = props => {
    const [count, setCount] = useState<number>(Number(props.cartItem.quantity));
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    
    
    const increment = async () => {
        if (!props.countOnly) {
            await API.post('cart/updateItem', { item: props.cartItem['id'], quantity: count + 1 });
        }
        setCount(count + 1);
        if (props.onChange) {
            props.onChange(count + 1);
        }
    }

    const decrement = async () => {

        if (count == props.minValue || count ==1) {
            enqueueSnackbar("Can't add less than " + props.minValue + ' cartons')
            return;
        }
        if (!props.countOnly) {
            await API.post('cart/updateItem', { item: props.cartItem['id'], quantity: count - 1 });

        }
        setCount(count - 1);
        if (props.onChange) {
            props.onChange(count - 1);
        }
    }

    return (
        <div>
            {/* <List><Typography variant="subtitle2">No. of Cartons</Typography></List> */}

            <List className={classes.root}>
                <ListItem style={{ width: 10 }}>
                    <AutoLoaderIcon  onClick={decrement} isDisabled={props.disabled}>
                        {/* <img src={minus} width="25" height="25" /> */}
                        <IndeterminateCheckBox className="autoloader" style={{width: 35, height: 35, color: '#cecdcc', backgroundColor: '#fff', border: '0px'}} />
                    </AutoLoaderIcon>

                </ListItem>
                <ListItem className={classes.listItem}>
                    {count}
                </ListItem>
                <ListItem style={{ width: 10 }} >
                    <AutoLoaderIcon  onClick={increment} isDisabled={props.disabled}>
                        <AddBox className="autoloader" style={{width: 35, height: 35, marginRight: 10, color: '#cecdcc', backgroundColor: '#fff'}} />
                        {/* <img src={add} width="25" height="25" /> */}
                    </AutoLoaderIcon>

                </ListItem>
            </List>



        </div>
    );
}
