import React from 'react';
import { SellerServices } from './SellerServices';
import { Strategy } from './Strategy';
import { Fees} from './Fees';
import { MainSlide } from './mainSlide';
import { Success } from './Success';
import { HoneyInformation } from './HoneyInformation';
import { HoneySalePlus } from './HoneySalePlus';
import { Test } from './Test';
import { FlagshipBrands } from './FlagshipBrands';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { MobileHeader } from './../Components/MobileHeader';
import { Hidden } from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
root: {
     
    overflowX: 'hidden',
    marginTop: -16,
    paddingBottom: 20,

    [theme.breakpoints.down('sm')]: {
        marginTop: -7,
      },

     
},
   
  }),
);



export const SellerPage = () => {
    const classes = useStyles();

    return(

        <div className={classes.root}>
           <Hidden only={['lg', 'xl']}>
            <MobileHeader />
            </Hidden>
            <MainSlide />
            <SellerServices />
            <Strategy />
            <Fees />
            <HoneyInformation />
            <Success />
            
            <HoneySalePlus />
            <FlagshipBrands /> 
        </div>
    )
}