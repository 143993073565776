import React from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, Hidden,  CardContent, Button, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import { Image, Work, BeachAccess } from '@material-ui/icons';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { MyButton } from './../Styles/MyButton';

import grass from './../assets/grass.png';
import activity from './../assets/activity.jpg';
import opacity from './../assets/opacity.png';
import bowl from './../assets/bowl.jpg';
import bowl1 from './../assets/bowl1.jpg';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    listStyle: {
display: 'flex',
flexDirection: 'row',
width: '100%',

[theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },




    },

    actionArea: {

        maxWidth: 450,
        
      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },
    root: {
      
      // boxShadow: 'none',
      // width: 600,
      // height: 584,
      // boxShadow: 'none',
    
      width: '100%',
      height: '100%',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    backgroundColor: 'transparent',
      backgroundSize: 'cover',

      
    },
    media: {
      
      width: 514,
      height: 409,
      
    },
  
    imgStyle: {
  
      maxWidth: 120,
     
  
    },

    mainHeading: {
      fontFamily: 'sophia',
      color: '#303030',
      fontSize: 22,
  

  },

  
  largeHeading: {
    fontFamily: 'sophia',
    color: '#303030',
    fontSize: 24,
    
    paddingBottom: 15,

},


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: '1.25rem',
      color: '#726f6f',
      fontWeight: 500,
      

  },
  
  

    vector: {

        width: 600,
        height: 584,

        [theme.breakpoints.down('sm')]: {
            width: 350,
            height: 340,
          },
    }
  
}),
);




export const PictureSections = () => {
    const classes = useStyles();

    return(
        <div style={{paddingTop: '5%', paddingBottom: '5%'}}>
        <Container maxWidth="lg">
        <Grid container
        spacing={10}
         direction="row"
         justify="center"
         alignItems="flex-start"
         style={{padding: 25 }}
         >

       

<Hidden only={['sm', 'xs']}>
<Grid item lg={6} xs={12} sm={12} md={6}>
<Typography className={classes.largeHeading} style={{ fontSize: '1.9rem', paddingLeft: '9%', paddingRight: '9%'}} align="left" gutterBottom variant="h3" component="h2">

WHAT CREATES THE ACTIVITY?
</Typography>

<Typography className={classes.descriptionStyle} style={{paddingLeft: '9%', paddingRight: '9%'}} align="justify" variant="subtitle2" color="textSecondary" component="p">

Redgum, Jarrah and Blackbutt honeys have several properties that contribute to its antimicrobial activity including a low pH and high osmolarity, combined with the enzymatic production of hydrogen peroxide. Combine, these exert an antimicrobial effect. Variations in the type and level of antimicrobial activity in West Australian honeys are associated with their floral source (i.e. the flower in which the bee takes sucks nectar).

</Typography>

</Grid>
</Hidden>


<Hidden only={['xl', 'lg', 'md']}>
<Grid item lg={6} xs={12} sm={12} md={6}>
<Typography className={classes.largeHeading} style={{ fontSize: '1.9rem', paddingTop: '8%',}} align="left" gutterBottom variant="h3" component="h2">

WHAT CREATES THE ACTIVITY?
</Typography>

<Typography className={classes.descriptionStyle} align="justify" variant="subtitle2" color="textSecondary" component="p">

Redgum, Jarrah and Blackbutt honeys have several properties that contribute to its antimicrobial activity including a low pH and high osmolarity, combined with the enzymatic production of hydrogen peroxide. Combine, these exert an antimicrobial effect. Variations in the type and level of antimicrobial activity in West Australian honeys are associated with their floral source (i.e. the flower in which the bee takes sucks nectar).

</Typography>

</Grid>
</Hidden>




<Grid item lg={6} sm={12} xs={12} md={6}>
       
       <Card className={classes.root}>
    
    <CardMedia
      >
          <img src={activity} width="100%" height="100%" alt="services" />
      </CardMedia>

</Card> 
 

    </Grid>


  


    <Grid item lg={6} sm={12} xs={12} md={6}>
       
       <Card className={classes.root}>
    
    <CardMedia
      >
          <img src={bowl1} width="100%" height="100%" alt="services" />
      </CardMedia>

</Card>
 

    </Grid>

   



<Hidden only={['sm', 'xs']}>

    <Grid item lg={6} xs={12} sm={12} md={6}>
<Typography className={classes.largeHeading} style={{ fontSize: '1.9rem', paddingLeft: '9%', paddingRight: '9%' }} align="left" gutterBottom variant="h3" component="h2">
HOW IS TOTAL ACTIVITY MEASURED?
</Typography>

<Typography className={classes.descriptionStyle} style={{paddingLeft: '9%', paddingRight: '9%' }} align="justify" variant="subtitle2" color="textSecondary" component="p">

An agar well diffusion method to determine the antibacterial activity of honey with reference to phenol is the de facto standard in medical honey testing and is used commercially to assign a ‘TA’ value to medicinal honeys.  <br /><br />

West Australia honeys are tested against Staphylococcus aureus in an agar well diffusion assay, with reference to phenol as a standard. AHV’s Redgum, Jarrah and Blackbutt honeys are recording antibacterial activity of up to 61% (w/v) phenol, compared to an Australian median of 13.6%(w/v) phenol. These results are extremely high which has been the cause of interest being shown by pharmaceutical companies worldwide.


</Typography>

</Grid>
</Hidden>



<Hidden only={['xl', 'lg', 'md']}>
    <Grid item lg={6} xs={12} sm={12} md={6}>
<Typography className={classes.largeHeading} style={{ fontSize: '1.9rem',  }} align="left" gutterBottom variant="h3" component="h2">
HOW IS TOTAL ACTIVITY MEASURED?
</Typography>

<Typography className={classes.descriptionStyle} align="justify" variant="subtitle2" color="textSecondary" component="p">

An agar well diffusion method to determine the antibacterial activity of honey with reference to phenol is the de facto standard in medical honey testing and is used commercially to assign a ‘TA’ value to medicinal honeys.  <br /><br />

West Australia honeys are tested against Staphylococcus aureus in an agar well diffusion assay, with reference to phenol as a standard. AHV’s Redgum, Jarrah and Blackbutt honeys are recording antibacterial activity of up to 61% (w/v) phenol, compared to an Australian median of 13.6%(w/v) phenol. These results are extremely high which has been the cause of interest being shown by pharmaceutical companies worldwide.


</Typography>

</Grid>
</Hidden>








<Hidden only={['sm', 'xs']}>
<Grid item lg={6} xs={12} sm={12} md={6}>
<Typography className={classes.largeHeading} style={{ fontSize: '1.9rem', paddingLeft: '9%', paddingRight: '9%'}} align="left" gutterBottom variant="h3" component="h2">

INDEPENDENTLY TESTED AND CERTIFIED
</Typography>

<Typography className={classes.descriptionStyle} style={{paddingLeft: '9%', paddingRight: '9%'}}  align="justify" variant="subtitle2" color="textSecondary" component="p">

When it comes to Total Activity (TA) antibacterial honey, there really is no comparison. Issued by the National Measurement Institute, which is an independent Australian government body specialising in food and environmental testing, the TA rating certifies the quality, purity and authenticity of every single batch of AHV medicinal honey. This process gives you a much more comprehensive and assured view of what is in your honey.

</Typography>

</Grid>
</Hidden>


<Hidden only={['lg', 'md', 'xl']}>
<Grid item lg={6} xs={12} sm={12} md={6}>
<Typography className={classes.largeHeading} style={{ fontSize: '1.9rem', paddingTop: '8%',}} align="left" gutterBottom variant="h3" component="h2">

INDEPENDENTLY TESTED AND CERTIFIED
</Typography>

<Typography className={classes.descriptionStyle}   align="justify" variant="subtitle2" color="textSecondary" component="p">

When it comes to Total Activity (TA) antibacterial honey, there really is no comparison. Issued by the National Measurement Institute, which is an independent Australian government body specialising in food and environmental testing, the TA rating certifies the quality, purity and authenticity of every single batch of AHV medicinal honey. This process gives you a much more comprehensive and assured view of what is in your honey.

</Typography>

</Grid>
</Hidden>




<Grid item lg={6} sm={12} xs={12} md={6}>
       
       <Card className={classes.root}>
    
    <CardMedia
      >
          <img src={bowl} width="100%" height="100%" alt="services" />
      </CardMedia>

</Card>
 

    </Grid>








     

        </Grid>



        

        
        </Container>
        </div>
    )
}