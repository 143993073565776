import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
    table: {
        minWidth: 400,
        boxShadow: 'none',
    }
});

function createData(
    name: string,
    quantity1: string,
    quantity2: string,
) {
    return {name, quantity1, quantity2};
}

const rows = [
    createData("Energy", '294Kj', '1401Kj',),
    createData("Protein", '0.06', '0.3g',),
    createData("Fat Total", 'Nil', 'Nil',),
    createData("Fat Saturated", 'Nil', 'Nil',),
    createData("Trans Fat", 'Nil', 'Nil',),
    createData("Carbohydrate", '17.2g', '82.1g',),
    createData("Sugars", '17.2g', '82.1g',),
    createData("Sodium", '2.9mg', '14mg',),
];
export const NeutritionTable = () => {
    const classes = useStyles();
    return (
        <TableContainer component={Paper} style={{boxShadow: 'none'}}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Per Serving Size</TableCell>
                        <TableCell align="right">21(g) 1 TBS</TableCell>
                        <TableCell align="right">100 g</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(row => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.quantity1}</TableCell>
                            <TableCell align="right">{row.quantity2}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
