import React, { useEffect, useState } from 'react';
import { Grid, Container, TextField, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { API, Cook, LoginHelper } from '../Helper';
import { useSnackbar } from 'notistack';




const useStyles = makeStyles({
  root: {
    paddingLeft: '5%',
    paddingRight: '5%',
  },

  heading: {
    fontFamily: 'sophia',
    fontSize: 35,
    marginTop: '2%',
  },

  subheading: {
    fontFamily: 'sophia',
    fontSize: 17,
    marginTop: '2%',
    marginBottom: '2%',
  },

  headingStyleMain: {
    fontFamily: 'sophia',
    fontSize: 28,
    color: '#3d3a3a',
  },

  btnStyle: {


    color: '#ffffff',
    backgroundColor: '#f28f16',
    boxShadow: 'none',
    borderRadius: 4,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    paddingBottom: 10,
    fontFamily: 'sophia',
    width: '100%',

    '&.MuiButton-contained': {


      '&:hover': {


        backgroundColor: '#D3D3D2',
        color: '#ffffff',
        boxShadow: 'none',
      },
    },


  },



});








export const ProfileSettings = () => {
  const classes = useStyles();

  const [firstName, setfName] = useState('');
  const [lastName, setlName] = useState('');
  const [phone, setphone] = useState('');
  const [email, setemail] = useState('');
  const [role,setrole] = useState('');
  const [country, setcountry] = useState('');
 


  useEffect(() => {
    var x = Cook.getUserData()['user'];
    console.log(Cook.getUserData());

    setfName(x.firstName);
    setemail(x.email);
    setphone(x.phone);
    setrole(x.role);
    setlName(x.lastName);
    setcountry(x.country);
  
  },[])
  
 
  

  const {enqueueSnackbar} = useSnackbar();





  return (
    <div className={classes.root}>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item lg={12} xs={12}>
            <Typography className={classes.headingStyleMain} variant="h6">My Profile</Typography>
          </Grid>

          <Grid item lg={6} xs={12}>


            <Typography className={classes.subheading} variant="h6">Personal Information</Typography>


            <TextField
              fullWidth
              margin="dense"
              id="fname"
              label="First Name"
              
              type="name"
              variant="outlined"
              value={firstName}
              onChange={(x) => { setfName(x.target['value']) }}
            />

            <TextField
              fullWidth
              margin="dense"
              id="lname"
              label="Last Name"
              type="name"

              variant="outlined"
              value={lastName}
              onChange={(x) => { setlName(x.target['value']) }}
            />


             <TextField
                                fullWidth
                                margin="dense"
                                id="phone"
                                label="Phone"
                                // errorMessages={['Phone is required', 'Phone number is invalid']}
                                type="phone"
                                name='phone'
                                value={phone}
                                onChange={(x) => { setphone(x.target['value']) }}
                                variant="outlined"
                            />

<TextField
                             
                             fullWidth
                             margin="dense"
                             id="name"
                             label="Your Role"
                             type="name"
                             value={role}
                             onChange={(x) => {setrole(x.target['value']) }}
                             name='role'
                             variant="outlined"
                         />
                    



           <TextField
              fullWidth
              margin="dense"
              id="fname"
              label="Email"
              type="name"
              value={email} disabled
              onChange={(x) => { setemail(x.target['value']) }}
              
              variant="outlined"
            /> 
            

            
                        

                                <TextField
                             
                                fullWidth
                                margin="dense"
                                id="name"
                                label="Country of origin"
                                value={country} disabled
                                onChange={(x) => {setrole(x.target['value']) }}
                                type="name"
                                name='country'
                                variant="outlined"
                            />
                    
           
          </Grid>

          <Grid item lg={6} xs={12}>
          </Grid>
          <Grid item lg={2} xs={12}>
            <Button className={classes.btnStyle} variant="contained" onClick={async () => {
              var udata = Cook.getUserData();

              var newData = {
                firstName: firstName,
                lastName: lastName,
                phone: phone,
                email: email,
                role: role,
                country: country,
                
              }
              console.log(newData);
              var res = await API.put('user', newData, { userid: udata.user.id });
              enqueueSnackbar("Information Updated")
              window.location.reload();
              Cook.saveUserData({session : udata.session , user : res.data})

            }}>Update</Button>
          </Grid>
          <Grid item lg={9}>

          </Grid>



        </Grid>
      </Container>
    </div>
  )
}