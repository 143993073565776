import React from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, Link,  CardContent, Button, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import { Image, Work, BeachAccess } from '@material-ui/icons';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { MyButton } from './../Styles/MyButton';
import branding2 from './../assets/branding2.png';
import introduction from './../assets/introduction.jpg';
import forest from './../assets/forest.png';
import beewelljar from './../assets/beewelljar.png';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    listStyle: {
display: 'flex',
flexDirection: 'row',
width: '100%',

[theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },




    },

    actionArea: {

        maxWidth: 450,
        
      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },
    root: {
      width: '60%',
      height: '60%',
      backgroundColor: 'transparent',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    
      backgroundSize: 'cover',

      [theme.breakpoints.down('sm')]: {
        marginLeft: '18%',
      },

      
    },
    media: {
      
      width: 514,
      height: 409,
      
    },
  
    imgStyle: {
  
      maxWidth: 120,
     
  
    },

    mainHeading: {
      fontFamily: 'sophia',
      color: '#303030',
      fontSize: 22,
  

  },

  
  largeHeading: {
    fontFamily: 'sophia',
    color: '#303030',
    fontSize: 24,
    paddingTop: '6%',
    paddingBottom: 15,

},


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: '1.25rem',
      color: '#726f6f',
      fontWeight: 500,
      

  },
  
  

    vector: {

      

      width: '100%',
      height: '100%',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    
      backgroundSize: 'cover',
    }
  
}),
);




export const ProductSection = () => {
    const classes = useStyles();

    return(
        <div style={{marginTop: '5%', marginBottom: '5%', backgroundColor: '#efefef',}}>
        <Container maxWidth="xl">
        <Grid container
         
         direction="row"
         justify="center"
         spacing={0}
         alignItems="center"
         style={{ padding: '4%'}}
         >

             <Grid item lg={2}></Grid>

        <Grid item lg={4} sm={4} xs={12} md={4}>
       
          
        <Card className={classes.root}>
     
     <CardMedia
       >
           <img src={beewelljar} className={classes.vector}  alt="services" />
       </CardMedia>

 </Card>
  

     </Grid>

<Grid item lg={4} sm={6} xs={12} md={6}>

<Typography className={classes.largeHeading} style={{ fontSize: '1.9rem',}} align="left" gutterBottom variant="h3" component="h2">
WHAT IS TOTAL ACTIVITY?
</Typography>

<Typography className={classes.descriptionStyle}  align="justify" variant="subtitle2" color="textSecondary" component="p">
The term ‘Total Activity’ is used to describe a honey that possesses naturally occurring antimicrobial properties as well as wound-healing activity. Antimicrobial properties are essentially important in reducing the global burden of infectious diseases.<br /><br />
Evaluation of West Australian honey including Redgum, Jarrah and Blackbutt has proven to be a fantastic alternative antimicrobial strategy to bacteria resistant antibiotics. 

</Typography>
<Link href="/shop">
<Button variant="contained" style={{color: '#fff', backgroundColor: '#f28f16', marginTop: '6%'}} size="large">Shop Honey</Button>
</Link>

</Grid>

<Grid item lg={2}>


</Grid>






  </Grid>


        </Container>
        </div>
    )
}