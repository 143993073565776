import React, { useState } from 'react';
import {createStyles, makeStyles, Theme, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu, {MenuProps} from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {AccountCircle, Favorite, Help, Reorder, ViewList,} from '@material-ui/icons';
import {Divider, Link, List, ListItem, Typography, IconButton, Badge, Dialog, DialogContent, DialogActions, Grid} from '@material-ui/core';
import {Cook, getval, LoginHelper, Ways} from '../Helper';
import account from './../assets/account.png';
import {createBrowserHistory} from 'history';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btnStyle: {
            fontFamily: 'sophia-regular',
            fontWeight: 1000,
            fontSize: 14,
            color: '#ffffff',
            padding: 10,
            margin: 10,
            backgroundColor: '#f28f16',
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#f28f16',
                    color: '#ffffff',
                    boxShadow: 'none',
                    textDecoration: 'none',
                },
            },
        },


        lastitem: {

            '&.MuiListItem-root':{
               '&:hover': {

                backgroundColor: 'transparent',
               },
            },
        },

        iconStyle: {

            color: '#b5b3b3',
            fontSize: '2.2rem',
        },
        menuHeading: {
            fontFamily: 'sophia-regular',
            fontWeight: 1000,
            fontSize: 16,
        },
        menulastHeading: {
            fontFamily: 'sophia-regular',
            fontWeight: 1000,
            fontSize: 16,
            color: '#ffffff'
        },
        listText1: {
            fontFamily: 'sophia',
            lineHeight: -2.0,
            color: '#565252',
            fontSize: 16,
            letterSpacing: 0.8,
            fontWeight: 600,
        },

        linkStyle: {


            fontFamily: 'sophia-light',
            lineHeight: 2.0,
            color: '#565252',
            fontSize: '0.9rem',
            letterSpacing: 0.8,
            fontWeight: 1000,
            backgroundColor: 'transparent',
            boxShadow: 'none',


            '&:hover': {

                backgroundColor: 'transparent',
                boxShadow: 'none',
            },
        },


        listText2: {
            fontFamily: 'sophia-light',
            lineHeight: -2.0,
            color: '#565252',
            fontSize: 12,
            letterSpacing: 0.8,
            fontWeight: 600,
            marginTop: '-3%',
            marginLeft: '8%',
        },
        mainList: {
            color: '#000000',
            marginTop: '-1.5%',
            '& .MuiListItem-root': {
                '&:hover': {
                    backgroundColor: '#fbfbfb',
                    textDecoration: 'underline',
                },
            },
        },
        accountBtn: {
            fontFamily: 'sophia',
            color: '#494646',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: 'transparent',
                    color: '#000000',
                    boxShadow: 'none',
                },
            },
        },
    }),
);
const StyledMenu = withStyles((theme) => ({
    paper: {
        border: '1px solid #d3d4d5',
        width: 275,
        height: 420,
        textDecoration: 'none',
        marginLeft: '7%',

        [theme.breakpoints.down('md')]: {
            marginLeft: '17%',
            marginTop: '-7%',
        },
    },
    heading: {
        fontFamily: 'sophia',
        color: '#ffffff',
        backgroundColor: '#F28F16',
    },
}))((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));
const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: '#fbfbfb',
            color: '#757575',
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                // color: theme.palette.common.white,
                backgroundColor: '#fbfbfb',
                color: '#757575',
            },
        },
    },

  
}))(MenuItem);
export const HoverMenu = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open , setOpen] = useState(false);

    function handleClick(event) {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <div>
            <List dense={true} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end',}}>
             
                <ListItem >
                
                                 <IconButton
              
              aria-label="account of current user"
             aria-controls="customized-menu"
              aria-haspopup="true"
              onClick={handleClick}
            //   onMouseOver={handleClick}
         
            >
                 
              <AccountCircle className={classes.iconStyle} />
              
            </IconButton>


                </ListItem>
           
                <ListItem>
                </ListItem>
            </List>
           
            <StyledMenu
                className={classes.mainList}
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{onMouseLeave: handleClose}}
            >
                <List style={{pointerEvents: 'none',}}>
                    <ListItem>
                        <Typography style={{ fontFamily: 'sophia-regular',
            fontWeight: 1000,
            fontSize: '1rem',}} className={classes.menuHeading}
                                    variant="h6">Welcome {Cook.getUserData()!=null  ? Cook.getUserData()['user']['firstName'] : ''}</Typography>
                    </ListItem>
                </List>
                <Divider/>
                <Link href={Ways.dashboard} underline="none" style={{color: '#303030'}}>
                    <StyledMenuItem>
                        <ListItemIcon>
                            <AccountCircle fontSize="small"/>
                        </ListItemIcon>
                        <Button className={classes.linkStyle} variant="contained">
                                             Account
                                        </Button>
                    </StyledMenuItem>
                </Link>

                <Link href={Ways.quotesPage}>
                <StyledMenuItem>
                    <ListItemIcon>
                        <Reorder fontSize="small"/>
                    </ListItemIcon>
                    <Button className={classes.linkStyle} variant="contained">
                                             Quotes
                                        </Button>
                </StyledMenuItem>
                </Link>
                <Divider/>


                <Link href="/dashboard/wishlist">
                <StyledMenuItem>
                    <ListItemIcon>
                        <Favorite fontSize="small"/>
                    </ListItemIcon>
                    <Button className={classes.linkStyle} variant="contained">
                                            Wishlist
                                        </Button>
                </StyledMenuItem>
                </Link>



                <Link href="/dashboard/orders">
                <StyledMenuItem>
                    <ListItemIcon>
                        <ViewList fontSize="small"/>
                    </ListItemIcon>
                    <Button className={classes.linkStyle} variant="contained">
                                             Orders
                                        </Button>
                </StyledMenuItem>



                </Link>
                <Divider/>
                {/* <Link href="#">
                <StyledMenuItem>
                    <ListItemIcon>
                        <Help fontSize="small"/>
                    </ListItemIcon>
                   
                                        <Button className={classes.linkStyle} variant="contained">
                                            Help & Contact
                                        </Button>
                                   
                </StyledMenuItem>
                </Link> */}
                <List>
                    <ListItem className="lastitem"  style={{textDecoration: 'none'}}>
                        <Button fullWidth variant="contained" color="primary" className={classes.btnStyle}
                                onClick={async () => {
                                    if(LoginHelper.isCookiePresent()){

                                        setOpen(true);
                                    }else {
                                        createBrowserHistory().push('/login')
                                        window.location.reload();
                                    }
                                }}>
                            {!LoginHelper.isCookiePresent() ? 'Signin or Create an Account' : 'Logout\n'}
                        </Button> {/* <Typography className={classes.menulastHeading} variant="h6">Signin or Create an Account</Typography> */}
                    </ListItem>
                </List>
            </StyledMenu>
            <Dialog open={open} onClose={()=>setOpen(false)}>
                <DialogContent style={{padding: 40,}}>
                    Are you sure you want to logout ?
                </DialogContent>
                <DialogActions style={{paddingBottom: 15}}>
                    <Grid container direction="row" >
                        <Grid item lg={4}></Grid>
                        <Grid item lg={3}>
                        <Button variant="contained" style={{backgroundColor: '#f28f16', color: '#fff', boxShadow: 'none'}} onClick={()=>{
                          if (LoginHelper.isCookiePresent()) {
                            LoginHelper.logout()
                        } else {
                            createBrowserHistory().push('/login')
                            window.location.reload();
                        }
                    }}>Logout</Button>
                        </Grid>
                        <Grid item lg={1}></Grid>

                        <Grid item lg={3}>
                                       
                    <Button variant="outlined" onClick={()=>{
                        setOpen(false)
                    }}>Cancel</Button>
                        </Grid>

                        <Grid item lg={1}></Grid>
                    </Grid>
               
                </DialogActions>
            </Dialog>
        </div>
    );
}