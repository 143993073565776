import React, { useState , FunctionComponent } from 'react';
import emailjs from 'emailjs-com';
import { Box, Container, Grid, Link, Typography, List, Card, CardMedia, Button, IconButton, ListItem, CircularProgress } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import honeylogo from './../assets/honeylogo.png';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { API } from '../Helper';
import { Loader } from '../Components/LoaderButton';
import { useSnackbar } from 'notistack';
import { createBrowserHistory } from 'history';
import { Add } from '@material-ui/icons';
import { Ways } from './../Helper';
import imgplaceholder from './../assets/imgplaceholder.png';
import { Cancel } from '@material-ui/icons';
import firebase from 'firebase';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
            backgroundColor: '#ffffff',
        },

          
  largeHeading: {
    fontFamily: 'sophia',
    color: '#303030',
    fontSize: 24,

    paddingBottom: 15,

},


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: '1.25rem',
      color: '#726f6f',
      fontWeight: 500,

  },

        listStyle: {
            display: 'flex',
            flexDirection: 'row',
        },
        box: {
            backgroundColor: '#fbfbfb',
            paddingTop: '2%',
            paddingBottom: '2%',
            marginTop: '8%',
        },
        root: {
            marginTop: '10%',
            // height: '10vh',
            // marginBottom: '15%',
            // [theme.breakpoints.down('sm')]: {
            //     marginBottom: '30%',
            //    },
            
        },
        PopupBtnStyle: {
            fontFamily: 'sophia-regular',
            fontWeight: 1000,

            fontSize: 14,
            marginTop: 25,
            color: '#ffffff',
            padding: 10,
            width: '100%',
            backgroundColor: '#f28f16',
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#f28f16',
                    color: '#ffffff',
                    boxShadow: 'none',
                    textDecoration: 'none',
                },
            },
        },
        btnStyle: {
            fontFamily: 'sophia-regular',
            fontWeight: 1000,
            fontSize: 14,
            color: '#ffffff',

            padding: 10,
            backgroundColor: '#f28f16',
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#f28f16',
                    color: '#ffffff',
                    boxShadow: 'none',
                    textDecoration: 'none',
                },
            },
        },
        topContainer: {
            borderBottom: '1px solid #efefef',
            marginBottom: '5%',
            paddingBottom: '2%',
        },
    }),
);



function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

export const ImageCard:FunctionComponent<{ url :any , onDelete:()=>void}>  = props => {
    return <Card style={{ width: '100%', height: 300, backgroundColor: '#fbfbfb' }}>,
    <IconButton onClick={async ()=>{
        var ref = firebase.app().storage().refFromURL(props.url);
        await ref.delete();
        props.onDelete();
   }}>
            <Cancel style={{ paddingLeft: 5, }} />
        </IconButton>
        <CardMedia>
        <img src={props.url!=null ? props.url : imgplaceholder} style={{height: '100%', width: '100%', objectFit: 'cover'}}/>
        </CardMedia>


    </Card>
}

export const ContactUsForm = () => {


       //snackbar

       const [open, setOpen] = React.useState(false);

       const handleClick = () => {
         setOpen(true);
       };
     
       const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
         if (reason === 'clickaway') {
           return;
         }
     
         setOpen(false);
       };
   
   
       //snackbar

    const set = (e: React.FormEvent) => {
        var n = e.target['name'];
        var v = e.target['value'];
        contact[n] = v;
        setContact({ ...contact })
        console.log(contact);
      }
    
      const { enqueueSnackbar } = useSnackbar();
      const [contact , setContact] = useState<any[]>([])
      const [loading, setLoading] = useState(false);


    const classes = useStyles();
    return (
        <div className={classes.root}>
           
           <ValidatorForm onSubmit={async () => {
    // alert('Starting')
    setLoading(true);
  
    await API.post('sendMail', contact).then(x => {
        console.log(x);
        if (x.status == 200) {
            enqueueSnackbar("Your message has been sent successfully!")
            // createBrowserHistory().push("/success");
            window.location.reload();
        }
        //  else {
        //     enqueueSnackbar("Fields are missing")
        // }
    })
    setLoading(false)
}}>
                <Container maxWidth="lg">
                    <Grid container direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item lg={8} xs={12} style={{ paddingBottom: 10 }}>
                        <Typography className={classes.largeHeading} style={{ fontSize: '1.9rem',}} align="left" gutterBottom variant="h3" component="h2">CONTACT US
       </Typography>
       <Typography className={classes.descriptionStyle}  align="left" variant="subtitle2" color="textSecondary" component="p">
       Have questions about your order? Had an amazing experience you’d like to share? Seeking media information? We’d love to hear from you!<br /><br />
       </Typography>

                          
                        </Grid>
                        <Grid item lg={4}></Grid>

                      
                        <Grid item lg={6} xs={12} spacing={2} >
                            <TextValidator
                                value={contact['name']}
                                onChange={set}
                                fullWidth
                                margin="dense"
                                id="name"
                                label="Name:"
                                validators={['required', 'minStringLength:2']}
                                errorMessages={['First Name is required', 'First Name should be more than 2 characters']}
                                type="name"
                                name='name'
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <TextValidator
                                fullWidth
                                margin="dense"
                                value={contact['email']}
                                onChange={set}
                                id="name"
                                name='email'
                                validators={['required']}
                                errorMessages={['Email is required',]}
                                label="Email:"
                                type="email"
                                variant="outlined"
                            />
                        </Grid>
                        
                        <Grid item lg={6} xs={12} style={{ marginTop: 15, }}>
                            <TextValidator
                                fullWidth
                                value={contact['phone']}
                                onChange={set}
                                margin="dense"
                                id="phone"
                                label="Phone:"
                                validators={['required', 'isNumber']}
                                errorMessages={['Phone is required', 'Phone number is invalid']}
                                type="phone"
                                name='phone'
                                variant="outlined"
                            />
                        </Grid>

                        
                        <Grid item lg={6} xs={12} style={{marginTop: 15}}>
                            <TextValidator
                                value={contact['subject']}
                                onChange={set}
                                fullWidth
                                margin="dense"
                                id="subject"
                                label="Subject:"
                                validators={['required']}
                                errorMessages={['Subject is required',]}
                                type="name"
                                name='subject'
                                variant="outlined"
                            />
                        </Grid>
                      
                      <br /><br />
                        <Grid item lg={12} xs={12} style={{marginTop: 15}}>
                            <TextValidator
                                value={contact['message']}
                                onChange={set}
                                multiline
                                rows={5}
                                margin="dense"
                                fullWidth
                                id="message"
                                label="Your Message:"
                                validators={['required', 'minStringLength:2']}
                                errorMessages={['Message is required',]}
                                type="name"
                                name='message'
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item lg={3} xs={12}>
                            <Button type="submit"  style={{marginTop: '2%', color: '#fff', backgroundColor: '#f28f16'}} size="large" variant="contained">Submit Message</Button>
                            {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Your message has been sent successfully!
        </Alert>
        </Snackbar> */}
                        </Grid>
                        <Grid item lg={9}></Grid>
                      
                     
                      

                    </Grid>
                </Container>
            </ValidatorForm>
           
        </div>
    )
}