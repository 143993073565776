import React from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, CardContent, Link, List, Hidden,  ListItem, ListItemAvatar, Avatar, Button,  ListItemText } from '@material-ui/core';
import { Image, Work, BeachAccess } from '@material-ui/icons';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { MyButton } from './../Styles/MyButton';
import branding1 from './../assets/branding1.png';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    listStyle: {
display: 'flex',
flexDirection: 'row',
width: '100%',

[theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },




    },

    actionArea: {

        maxWidth: 450,
        
      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },
    root: {
      
      boxShadow: 'none',
      width: 530,
      height: 409,

      
    },
    media: {
      
      width: 514,
      height: 409,
      
    },
  
    imgStyle: {
  
      maxWidth: 120,
     
  
    },

    mainHeading: {
      fontFamily: 'sophia',
      color: '#303030',
      fontSize: 22,
  

  },


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: '1.25rem',
      color: '#726f6f',
      fontWeight: 500,

  },

  mainHeading2: {
    fontFamily: 'sophia',
    color: '#303030',
    fontSize: 22,
    marginTop: 50,

    paddingBottom: 15,

},


descriptionStyle2: {

    fontFamily: 'sophia-regular',
    fontSize: '1.25rem',
    color: '#726f6f',
    fontWeight: 500,

},

btnStyle: {

  padding: 15,
  width: 230,
  fontFamily: 'sophia',
  
},

avatarStyle: {

  marginTop: -25,
},

link: {

  '&.MuiLink-underlineHover': {
    color: '#f28f16 !important',
  },

},
  

    vector: {

        width: 514,
        height: 409,

        [theme.breakpoints.down('sm')]: {
            width: 350,
            height: 340,
          },
    }
  
}),
);




export const PaymentPolicy = () => {
    const classes = useStyles();

    return(
        <div style={{ paddingBottom: '8%', paddingTop: '14%'}}>
        <Container maxWidth="xl">
        <Grid container
        
         direction="row"
         justify="center"
         alignItems="center"
         
         >

        <Grid item lg={4} sm={12} xs={12}>
       
           
           
          <Typography className={classes.mainHeading} style={{fontSize: '1.9rem'}} align="left" gutterBottom variant="h5" component="h2">
          What’s our payment policy?
          </Typography>
         
          <Typography className={classes.descriptionStyle} align="justify" variant="body2">
          {/* We will not do sales under the value of $1000 because our minimum purchase amount is 1 pallet (96 cartons) which is significantly more than $1000 */}
          Our minimum order quantity is one pallet. If you are looking for smaller quatities, please view our Stockists page <Link className={classes.link} href="/stockist">here.</Link>
          </Typography>
        
        
          <Typography className={classes.mainHeading2} variant="body2">First order</Typography>
          <Typography className={classes.descriptionStyle2} variant="body2">50% of the order value shall be paid at time of placing the order,
           with the final 50% paid prior to the goods leaving AHV’s warehouse. </Typography>
           
           <Typography className={classes.mainHeading2} variant="body2">Futures orders</Typography>
          <Typography className={classes.descriptionStyle2} variant="body2">Payment terms for future orders will determined on a case-by-case basis*. </Typography>
     
     

        </Grid>

        <Grid item lg={1}></Grid>

        <Grid item lg={4} sm={12} xs={12}>


          <Hidden only={['sm', 'xs']}>
        <Card className={classes.root}>
     
        <CardMedia
          >
              <img src={branding1} className={classes.vector} alt="services" width="464" height="359" />
          </CardMedia>

    </Card>
    </Hidden>

    <Hidden only={['md', 'lg', 'xl']}>
        <Card style={{boxShadow: 'none',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: 'transparent',}}>
     
        <CardMedia
          >
              <img src={branding1} className={classes.vector} alt="services" width="464" height="359" />
          </CardMedia>

    </Card>
    </Hidden>
        </Grid>

     

        </Grid>



        <Grid container
        
         
        direction="row"
        justify="center"
        alignItems="flex-start"
         style={{marginTop: '5%'}}
         >
             <Grid item lg={12}>

          

   

            
         </Grid>

         <Grid container direction="row" alignItems="center" justify="center">
         <Grid item lg={3}>

</Grid>
<Grid item lg={6}>
    <br />
<Typography className={classes.descriptionStyle2} variant="body2" align="center">Buyers must submit, and have approved by AHV, a credit application form. </Typography>
<br /><br />
<Typography align="center">
<Button className={classes.btnStyle} variant="contained">
                Credit Application
            </Button>
            </Typography>

</Grid>
<Grid item lg={3}></Grid>

         </Grid>
         </Grid>


        

        
        </Container>
        </div>
    )
}