import React, { FunctionComponent, useState, useRef } from "react"
import { Button, Dialog, Grid, Input, Typography, ListItem, Hidden } from "@material-ui/core"
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { API } from "../../Helper";
import { Loader } from "../../Components/LoaderButton";
import { createBrowserHistory } from 'history';
import payment from './../../assets/payment.png';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';



const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        btnStyle: {
            color: '#fff',
            backgroundColor: '#f28f16',
            width: '100%',
            boxShadow: 'none',
        },

        mainDiv: {

            minHeight: 450,
            maxHeight: 550,
            minWidth: 450,
            maxWidth: 450,
            overflowX: 'hidden',

            [theme.breakpoints.down('sm')]: {
                minWidth: 350,
                // marginLeft: '7%',
                maxHeight: 450,
                
              },
        }
    }),
);




export const PaymentDialog: FunctionComponent<{ amount: number, customer: string, offer: any }> = props => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const stripe = useStripe();
    const elements = useElements();

    var inRef = useRef<HTMLElement>(null);
    return <div>
        <Grid container>
        <Grid item lg={12} sm={12} xs={12}>
        <Loader fullWidth icon={<div></div>} onClick={() => {
            setOpen(true);
        }}><Typography variant="body1" style={{fontSize: 15}}>Continue to payment</Typography></Loader>
        </Grid>
        </Grid>

        <Dialog open={open} onClose={() => setOpen(false)}>
            <Grid container className={classes.mainDiv} style={{paddingTop: '5%', overflowX: 'hidden' }}>
                <Grid item lg={12} style={{ marginBottom: '-5%' }} xs={12} sm={12}>
                    <Typography variant="h5" align="center">Payment</Typography>
                </Grid>
                <Grid item lg={12} sm={12} xs={12} style={{overflowX: 'hidden'}}>
                    <fieldset className="FormGroup">

                        <Typography align="justify" variant="body2"></Typography>
                        <br />
                        <Hidden only={['sm', 'xs']}>
                        <Grid item xs={12} lg={12}>
                            <img src={payment} alt="image" />
                        </Grid>
                        </Hidden>

                    </fieldset>
                    <fieldset className="FormGroup">
                        <form className="FormRow" onSubmit={async (event) => {
                            console.log("Starting Process");

                            event.preventDefault();
                            if (!stripe || !elements) {
                                console.log("Stripe NOt Loaded");

                                // Stripe.js has not loaded yet. Make sure to disable
                                // form submission until Stripe.js has loaded.
                                return;
                            }

                            // Get a reference to a mounted CardElement. Elements knows how
                            // to find your CardElement because there can only ever be one of
                            // each type of element.
                            const cardElement = elements.getElement(CardElement);

                            if (cardElement == null) return;


                            // Use your card Element with other Stripe.js APIs
                            const result = await stripe.confirmCardPayment((await API.post('startIntent', { amount: props.offer['totalBill'] * 100, customer: props.customer })).data['client_secret'], {
                                payment_method: {
                                    card: cardElement,
                                    billing_details: {
                                        name: props.customer
                                    }
                                }
                            });
                            if (result.paymentIntent?.status == 'succeeded') {
                                var res = await API.post('offer/submit', props.offer);

                                console.log(res);

                                if (res.status == 200) {
                                    createBrowserHistory().push('/thanks' + '/' + props.offer['id']);
                                    window.location.reload();
                                }
                            }
                            console.log(result);


                        }}>
                            <CardElement
                                options={{
                                    style: {

                                        base: {
                                            iconColor: 'green',
                                            color: '#000',
                                            // fontWeight: 500,
                                            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                                            fontSize: '16px',
                                            fontSmoothing: 'antialiased',
                                            ':-webkit-autofill': { color: '#0ddd4b' },
                                            '::placeholder': { color: '#000' },
                                        },
                                        invalid: {
                                            iconColor: 'red',
                                            color: 'red',
                                        },
                                    },
                                }}
                            /><br /><br />
                            <Grid container >
                                <Grid item lg={12} xs={12}>
                            <Button className={classes.btnStyle} type="submit" fullWidth variant="contained" onClick={() => {
                            }}>Pay</Button>
                            </Grid>
                            </Grid>
                        </form>
                    </fieldset>

                    <ListItem>
                    </ListItem>
                </Grid>

            </Grid>

        </Dialog>
    </div>
}