import React, { useState , FunctionComponent } from 'react';
import { Box, Container, Grid, Link, Typography, List, Card, CardMedia, Button, IconButton, ListItem, CircularProgress, MenuItem } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import honeylogo from './../assets/honeylogo.png';
import { TextValidator, ValidatorForm, SelectValidator } from 'react-material-ui-form-validator';
import { API, LoginHelper } from '../Helper';
import { Loader } from '../Components/LoaderButton';
import { useSnackbar } from 'notistack';
import { createBrowserHistory } from 'history';
import { Add } from '@material-ui/icons';
import { Ways } from './../Helper';
import imgplaceholder from './../assets/imgplaceholder.png';
import { Cancel } from '@material-ui/icons';
import firebase from 'firebase';
// import  getUserData from './../Helper';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
            backgroundColor: '#ffffff',
        },
        listStyle: {
            display: 'flex',
            flexDirection: 'row',
        },
        box: {
            backgroundColor: '#fbfbfb',
            padding: '2%',
            
            marginTop: '8%',
        },
        root: {
            marginTop: '5%',
            height: '10vh',
            marginBottom: '20%',
        },
        PopupBtnStyle: {
            fontFamily: 'sophia-regular',
            fontWeight: 1000,

            fontSize: 14,
            marginTop: 25,
            color: '#ffffff',
            padding: 10,
            width: '100%',
            backgroundColor: '#f28f16',
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#f28f16',
                    color: '#ffffff',
                    boxShadow: 'none',
                    textDecoration: 'none',
                },
            },
        },
        btnStyle: {
            fontFamily: 'sophia-regular',
            fontWeight: 1000,
            fontSize: 14,
            color: '#ffffff',

            padding: 10,
            backgroundColor: '#f28f16',
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#f28f16',
                    color: '#ffffff',
                    boxShadow: 'none',
                    textDecoration: 'none',
                },
            },
        },
        topContainer: {
            borderBottom: '1px solid #efefef',
            marginBottom: '5%',
            paddingBottom: '2%',
        },
    }),
);




export const RegisterYourInterestUpdated:FunctionComponent<{currentBeekeeper : any}> = props => {
  

    
 
    const set = (e: React.FormEvent) => {
        var n = e.target['name'];
        var v = e.target['value'];
        register[n] = v;
        setRegister({ ...register })
        console.log(register);
      }
    
      const { enqueueSnackbar } = useSnackbar();
      const [register , setRegister] = useState<any[]>([])
      const [loading, setLoading] = useState(false);

 
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Container maxWidth="md" className={classes.topContainer}>
                <Grid container direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item lg={3}>
                        <Link href="/">
                            <img src={honeylogo} alt="image" />
                        </Link>
                    </Grid>
                    <Grid item lg={6} xs={2}></Grid>
                    <Grid item lg={1}>
                        {/* <Lock /> */}
                    </Grid>
                    {/* <Grid item lg={2}>
                        <Typography align="left" variant="subtitle2">Secure Registration</Typography>
                    </Grid> */}
                </Grid>
            </Container>

         


       
<ValidatorForm onSubmit={async () => {
    // alert('Starting')
    setLoading(true);
  
    await API.post('registration', {...register , data : LoginHelper.userData()}).then(x => {
        console.log(x);
        if (x.status == 200) {
            enqueueSnackbar("Registration Successfull")
            createBrowserHistory().push("/success");
            window.location.reload();
        }
        //  else {
        //     enqueueSnackbar("Fields are missing")
        // }
    })
    setLoading(false)
}}>


                <Container maxWidth="md">
                    <Grid container direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={3}
                    >
                

                
<Grid item lg={6} xs={12} sm={12} md={12}> 

<TextValidator
variant="outlined"
required
size="small"
fullWidth
onChange={set}
// disabled
value={register['companyName']}
label="Company Name"
name="company Name"
errorMessages={['this field is required']}
>

</TextValidator>
</Grid>
      
 <br />
      <Grid item lg={6} xs={12} sm={12} md={12}> 

<TextValidator
variant="outlined"
required
size="small"
onChange={set}
fullWidth
value={register['acn']}
label="ACN/ABN"
name="acn"
errorMessages={['this field is required']}
>

</TextValidator>
</Grid>

<br />
      <Grid item lg={6} xs={12} sm={12} md={12}> 

<TextValidator
variant="outlined"
required
size="small"
onChange={set}
fullWidth
value={register['firstName']}
label="First Name"
// disabled
name="firstName"
errorMessages={['this field is required']}
>

</TextValidator>
</Grid>

<br />

<Grid item lg={6} xs={12} sm={12} md={12}> 

<TextValidator
variant="outlined"
required
size="small"
onChange={set}
fullWidth
value={register['lastName']}
label="Last Name"
// disabled
name="lastName"
errorMessages={['this field is required']}
>

</TextValidator>
</Grid>

<br />

{/* 
      <Grid item lg={6}> 

<TextValidator
variant="outlined"
required
size="small"
onChange={set}
fullWidth
value={register['lastName']}
label="Last Name"
name="lastName"
errorMessages={['this field is required']}
>

</TextValidator>
</Grid>
<br /> */}


      <Grid item lg={6} xs={12} sm={12} md={12}> 

<TextValidator
variant="outlined"
required
size="small"
onChange={set}
fullWidth
value={register['What market segment are you involved in ?']}
label="What market segment are you involved in ?"
name="What market segment are you involved in ?"
errorMessages={['this field is required']}
>

</TextValidator>
</Grid>

<br />
      <Grid item lg={6} xs={12} sm={12} md={12}> 

<TextValidator
variant="outlined"
required
size="small"
onChange={set}
fullWidth
value={register['What geographic territory are you requesting ?']}
label="What geographic territory are you requesting ?"
name="What geographic territory are you requesting ?"
errorMessages={['this field is required']}
>

</TextValidator>
</Grid>

<br />
      <Grid item lg={12} xs={12} sm={12} md={12}> 

<TextValidator
variant="outlined"
required
size="small"
onChange={set}
fullWidth
value={register['What is the size of the market are you requesting exclusively for ?']}
label="What is the size of the market are you requesting exclusively for ?"
name="What is the size of the market are you requesting exclusively for ?"
errorMessages={['this field is required']}
>

</TextValidator>
</Grid>

<br />
      <Grid item lg={12} xs={12} sm={12} md={12}> 

<TextValidator
variant="outlined"
required
size="small"
onChange={set}
fullWidth
value={register['How large is your distribution network ?']}
label="How large is your distribution network ?"
name="How large is your distribution network ?"
errorMessages={['this field is required']}
>

</TextValidator>
</Grid>

<br />
      <Grid item lg={12} xs={12} sm={12} md={12}> 

<TextValidator
variant="outlined"
required
size="small"
onChange={set}
fullWidth
value={register['What FMCG s does your distribution network specialize in ?']}
label="What FMCG's does your distribution network specialize in ?"
name="What FMCG's does your distribution network specialize in ?"
errorMessages={['this field is required']}
>

</TextValidator>
</Grid>

<br />
      {/* <Grid item lg={12} xs={12} sm={12} md={12}> 

<TextValidator
variant="outlined"
required
size="small"
onChange={set}
fullWidth
value={register['medicinal']}
label="Are you requesting exclusivity over AHV TA (medicinal) honey?"
name="medicinal"
errorMessages={['this field is required']}
>

</TextValidator>
</Grid> */}

<Grid item lg={12} sm={12} xs={12} md={12}>


<SelectValidator  name='Are you requesting exclusivity over AHV TA (medicinal) honey ?' value={register['Are you requesting exclusivity over AHV TA (medicinal) honey ?']} variant="outlined" size="small" style={{ width: '100%' }}
  onChange={set}
  label="Are you requesting exclusivity over AHV TA (medicinal) honey ?"
  
  errorMessages={['this field is required']}


>
 <MenuItem value={'Yes'}>Yes</MenuItem>
  <MenuItem value={'No'}>No</MenuItem>



</SelectValidator>

</Grid>

<br />
      {/* <Grid item lg={12} xs={12} sm={12} md={12}> 

<TextValidator
variant="outlined"
required
size="small"
onChange={set}
fullWidth
value={register['taHoney']}
label="Are you requesting exclusivity over AHV non-TA honey?"
name="taHoney"
errorMessages={['this field is required']}
>

</TextValidator>
</Grid> */}


<Grid item lg={12} md={12} sm={12} xs={12}>


<SelectValidator  name='Are you requesting exclusivity over AHV non-TA honey ?' value={register['Are you requesting exclusivity over AHV non-TA honey ?']} variant="outlined" size="small" style={{ width: '100%' }}
  onChange={set}
  label="Are you requesting exclusivity over AHV non-TA honey ?"
  
  errorMessages={['this field is required']}


>
 <MenuItem value={'Yes'}>Yes</MenuItem>
  <MenuItem value={'No'}>No</MenuItem>



</SelectValidator>

</Grid>

<br />
      {/* <Grid item lg={12} xs={12} sm={12} md={12}> 

<TextValidator
variant="outlined"
required
size="small"
onChange={set}
fullWidth
value={register['honeybaseFood']}
label="Are you requesting exclusivity over AHV honey-based food and confectionary products?"
name="honeybaseFood"
errorMessages={['this field is required']}
>

</TextValidator>
</Grid> */}


<Grid item lg={12} sm={12} md={12} xs={12}>


<SelectValidator  name='Are you requesting exclusivity over AHV honey-based food and confectionary products?' value={register['Are you requesting exclusivity over AHV honey-based food and confectionary products?']} variant="outlined" size="small" style={{ width: '100%' }}
  onChange={set}
  label="Are you requesting exclusivity over AHV honey-based food and confectionary products?"
  
  errorMessages={['this field is required']}


>
 <MenuItem value={'Yes'}>Yes</MenuItem>
  <MenuItem value={'No'}>No</MenuItem>



</SelectValidator>

</Grid>

<br />
      <Grid item lg={12} xs={12} sm={12} md={12}> 

<TextValidator
variant="outlined"
required
size="small"
onChange={set}
fullWidth
value={register['What volume/quota will you commit to purchasing in the first six months?']}
label="What volume/quota will you commit to purchasing in the first six months?"
name="What volume/quota will you commit to purchasing in the first six months?"
errorMessages={['this field is required']}
>

</TextValidator>
</Grid>
<Grid item lg={12} xs={12}>
                            <Loader className={classes.PopupBtnStyle} icon={<div></div>} fullWidth type='submit' variant="contained"
                                isLoading={loading}>Register</Loader>
                                {/* <Button variant="contained" style={{color: '#fff', backgroundColor: '#f28f16'}} type="submit" fullWidth>Register</Button> */}
                        </Grid>


                   

                    </Grid>
                </Container>
            </ValidatorForm>
            <Box className={classes.box}>
                <Container maxWidth="md">
                <Grid container>
                   
                    <Grid item lg={7} xs={7}>
                        <Typography align="left" variant="subtitle2">© 2020, Australian Honey Ventures</Typography>
                    </Grid>
                    <Grid item lg={2} xs={2}>
                    </Grid>
                    <Grid item lg={3} xs={3}>
                        {/* <Typography variant="subtitle2" align="right">Privacy Policy</Typography> */}
                    </Grid>
                   
                </Grid>
                </Container>
            </Box>
        </div>
    )
}