import {Button, Chip, CircularProgress, Grid, Link, Paper, Typography, List, Container, ListItem} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import React, {useEffect, useState} from 'react';
import {API, Cook, getval, Ways} from '../../Helper';
import {createBrowserHistory} from 'history';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        padding: theme.spacing(0),
        // overflow: 'auto',
        maxWidth: '100%',
    },
    headingStyleMain: {
        fontFamily: 'sophia',
        fontSize: 28,
        color: '#3d3a3a',
    },

    secondBtn: {

        [theme.breakpoints.down('sm')]: {
            marginLeft: -15,
            marginTop: -15,
            
          },
    },
    btnStyle: {
        backgroundColor: 'red',
        boxShadow: 'none',
        color: '#ffffff',
        '&.MuiButton-contained': {
            '&:hover': {
                backgroundColor: 'red',
                color: '#ffffff',
                boxShadow: 'none',
            },
        },
    },
    fixedHeightPaperEmail: {
        boxShadow: 'none',
        // padding: theme.spacing(0),
        // position: 'sticky',
    },
}));
export const AllQuotesTable = () => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        API.get('offers/buyer', {userid: getval(Cook.getUserData(), 'user.id')}).then(x => {
            setData(x.data);
            setLoading(false);
        })
    }, []);
    let id: number = 1
    const getID = () => {
        return id++;
    }
    if (loading) {
        return <Grid container justify='center'>
            <CircularProgress style={{color: '#f28f16'}}/>
        </Grid>
    }
    return (
        <Container id="quotespage" >
        <Grid container className={classes.root}>
            <Grid item lg={12} md={12} xs={12} sm={12} className="mainGrid" >
                {/* <Paper className={classes.fixedHeightPaperEmail}> */}
                    <Typography align="left"  className={classes.headingStyleMain} variant="h6">All
                        Quotes</Typography><br/>
                    <MaterialTable
                    
                    
                        style={{
                            boxShadow: 'none',
                            backgroundColor: '#fbfbfb',
                            borderBottomLeftRadius: 8,
                            borderBottomRightRadius: 8,
                            overflow: 'auto',
                            
                        }}
                        title=""
                        columns={[
                            
                            {title: 'Quote Id' ,  render: (row) => <div >HON-QUO-{row['rid']}</div>},
                            // {title: ' Buyer Name', render: (row) => <div>{row['user']['firstName']}</div>},
                            // {title: 'Product', render: (row) => <div>{row['product']['productTitle']}</div>},
                            {title: 'No. of Products', field: 'items', type: 'numeric'},
                            {title: 'Date & Time', field: 'lastUpdated', type: 'datetime' },
                            {
                                title: 'Status', render: (row) =>
                                    <Grid item lg={2}>
                                        <Chip label={row['status']}
                                              style={{backgroundColor: '#FCE9D4', color: '#303030',}}/>
                                    </Grid>
                            },
                            {
                                title: 'Actions', render: (row) =>
                                    <div>
                                        <List style={{display: 'flex', flexDirection: 'row'}}>


                                        <ListItem className={classes.secondBtn}>
                                        <Link href={Ways.singleQuotepage + '/' + row['id']}
                                                      style={{textDecoration: 'none',}}>
                                                    <Button style={{backgroundColor: '#FCB040'}} variant="contained" size="small"
                                                            className={classes.btnStyle}>View</Button>
                                                </Link>
                                                
                                         </ListItem>

                                                <ListItem className={classes.secondBtn}>

                                                <Button variant="outlined" color='primary' onClick={async () => {
                                                    API.get('singleChat', {
                                                        offerid: row['id'],
                                                        userid: Cook.getUserData()['user']['id']
                                                    }).then(x => {
                                                        createBrowserHistory().push(Ways.chatPage + '/' + x.data['id'])
                                                        window.location.reload();
                                                    })
                                                }} size="small">Chat</Button>
                                                
                                                </ListItem>

                                        </List>
                                      
                                    </div>
                            },
                        ]}
                        data={data}
                        options={{
                            headerStyle: {
                                backgroundColor: '#ffffff',
                                fontSize: '1.0em',
                                fontWeight: 400,
                            }
                        }}
                    />
                {/* </Paper> */}
            </Grid>
            
        </Grid>
        </Container>
    )
}