import React from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, CardContent, Hidden,  List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import { Image, Work, BeachAccess } from '@material-ui/icons';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { MyButton } from './../Styles/MyButton';
import branding1 from './../assets/branding1.png';
import mobbranding1 from './../assets/mobbranding1.png';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    listStyle: {
display: 'flex',
flexDirection: 'row',
width: '100%',

[theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },




    },




    actionArea: {

        maxWidth: 310,
        
      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },
    root: {
      
      boxShadow: 'none',
     
      backgroundColor: 'transparent',

      
    },
    media: {
      
      width: 514,
      height: 409,
      
    },
  
    imgStyle: {
  
      maxWidth: 120,
     
  
    },

    mainHeading: {
      fontFamily: 'sophia',
      color: '#303030',
      fontSize: 22,
  

  },


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: '1.25rem',
      color: '#726f6f',
      // fontWeight: 500,

  },

  mainHeading2: {
    fontFamily: 'sophia',
    color: '#303030',
    fontSize: 22,
    marginTop: 50,
    
    

},


descriptionStyle2: {

    fontFamily: 'sophia-regular',
    fontSize: '1.25rem',
    color: '#726f6f',
    fontWeight: 500,
    

},

avatarStyle: {

  marginTop: -25,
},

mobvector: {

  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
},
  

    vector: {

        width: 514,
        height: 409,

        [theme.breakpoints.down('sm')]: {
            width: 350,
            height: 340,
          },
    }
  
}),
);




export const Strategy = () => {
    const classes = useStyles();

    return(
        <div style={{paddingTop: '4%', marginTop: '4%', paddingBottom: '4%', backgroundColor: '#fbfbfb'}}>
        <Container maxWidth="xl">
        <Grid container
        
         direction="row"
         justify="center"
         alignItems="center"
         
         >

        <Grid item lg={4} sm={12} xs={12}>
       
           
           
          <Typography className={classes.mainHeading} style={{ fontSize: '1.9rem',}} align="left" gutterBottom variant="h5" component="h2">
          Strategic branding that propels Australian honey forward 
          </Typography>
          <br />
          <Typography className="quotestyle" align="justify" variant="body2">
          Marketing and differentiation is the single most important contributor to a brand’s success.
           Many honey brands across Australia seem to be suffering from a crisis of differentiation. It seems every time one company finds something that works well,
           others race to copy the idea. Major brands in the industry have become nearly indistinguishable from one another. <br /><br /> <b>Jay Curtin CEO</b>
          </Typography>
          <br /><br />

          <Typography className={classes.mainHeading} align="left" gutterBottom variant="h5" component="h2">
          We push the boundaries
          </Typography>
          
          <Typography className={classes.descriptionStyle} align="justify" variant="body2">
          We never say “that’s how its always been done”, our brands are more, way more
          </Typography>
            <br />
          <Typography className={classes.mainHeading} align="left" gutterBottom variant="h5" component="h2">
          We build brands that consumers love
          </Typography>
        
          <Typography className={classes.descriptionStyle} align="justify" variant="body2">
          Our brands are layered, sculptured, and reflect our customers intangible needs and desires
          </Typography>
<br />
          <Typography className={classes.mainHeading} align="left" gutterBottom variant="h5" component="h2">
          Brand loyalty
          </Typography>
        
          <Typography className={classes.descriptionStyle} align="justify" variant="body2">
          Differentiation of our brands increases consumer loyalty and our ability to command premium pricing in challenging market conditions
          </Typography>



     

        </Grid>

        <Grid item lg={1}></Grid>

        <Grid item lg={4} sm={12} xs={12}>
        <Card className={classes.root}>
     
        <CardMedia
          >
               <Hidden only={['xs', 'sm']}>
              <img src={branding1} className={classes.vector} alt="services" width="364" height="259" />
              </Hidden>

              <Hidden only={['md', 'lg', 'xl']}>
              {/* <img src={mobbranding1} className="mobvector" alt="services" width="50%" height="50%" /> */}
              <Card style={{  boxShadow: 'none',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: 'transparent',}}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia>

             {/* <Favorite className={classes.iconStyle} /> */}
             <img src={branding1} width="100%" height="100%" />
          </CardMedia>
       
      </CardActionArea>
    </Card>
              </Hidden>
          </CardMedia>

    </Card>
        </Grid>

     

        </Grid>



        {/* <Grid container
        
         
        direction="row"
        justify="center"
        alignItems="flex-start"
         style={{marginTop: '5%'}}
         >
             <Grid item lg={12}>

             <List className={classes.listStyle}>
      <ListItem>
        <ListItemAvatar className={classes.avatarStyle}>
          <Avatar>
            <Image />
          </Avatar>
        </ListItemAvatar>
        <ListItemText>
          <Typography className={classes.mainHeading} variant="body2">We push the boundaries</Typography>
          <Typography className={classes.descriptionStyle} variant="body2">We never say “that’s how its always been done”, our brands are more, way more</Typography>
        </ListItemText>
       
      </ListItem>
      <ListItem>
        <ListItemAvatar className={classes.avatarStyle}>
          <Avatar>
            <Work />
          </Avatar>
        </ListItemAvatar>
        <ListItemText>
          <Typography className={classes.mainHeading2} variant="body2">We build brands that consumers love</Typography>
          <Typography className={classes.descriptionStyle2} variant="body2">Our brands are layered, sculptured, and reflect our customers intangible needs and desires</Typography>
        </ListItemText>
      
      </ListItem>
      <ListItem>
        <ListItemAvatar className={classes.avatarStyle}>
          <Avatar>
            <BeachAccess />
          </Avatar>
        </ListItemAvatar>
        <ListItemText>
          <Typography className={classes.mainHeading2} variant="body2">Brand loyalty</Typography>
          <Typography className={classes.descriptionStyle2} variant="body2">Differentiation of our brands increases consumer loyalty and our ability to command premium pricing in challenging market conditions</Typography>
        </ListItemText>
      </ListItem>
    </List>

             </Grid>

            
         </Grid> */}

        
        </Container>
        </div>
    )
}