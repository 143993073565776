import {Chip, Divider, Typography} from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import { Grid } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import {createStyles, makeStyles, Theme, withStyles} from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import userplace from './../../assets/userplace.png';
import {API, AppEvent, Bbc, Cook} from '../../Helper';
import { GlobalState } from './../../AppState';
import { observer } from 'mobx-react';





const MessagesPanel = observer(() => {

    if(GlobalState.unreadMessages.length == 0){
        return <div>
           
<Typography align="center" variant="body2"></Typography>

            </div>
           
    }


    return <div> <List style={{paddingLeft: 20, maxHeight: 10}}>
            {(GlobalState.unreadMessages ?? []).map((x, i) => {
                return <div>
                    <Typography style={{overflow: 'hidden', maxWidth: '15ch', maxHeight: 10}} variant="caption">{x['text']}</Typography>
                </div>
            })}


        </List>



        </div>
})










const MsgBadge = observer(() => {
    return <Badge style={{marginTop: -20}} color="secondary" badgeContent={GlobalState.unreadMessages.length} >
        
    </Badge>
})

const StyledBadge = withStyles((theme: Theme) =>
    createStyles({
        badge: {
            backgroundColor: '#44b700',
            color: '#44b700',
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: '$ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
            },
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0,
            },
        },
    }),
)(Badge);
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: '100%',
            //   backgroundColor: theme.palette.background.paper,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#ffffff',
        },
        Divider: {
            border: '0.1px solid #fbfbfb',
        },
        profile: {
            borderRadius: 5,
        },
        text: {
            marginLeft: 20,
            maxWidth: 120,
            fontSize: '0.9rem',
            fontWeight: 400,
            fontFamily: 'sophia',
        },
        listStyle: {
            paddingTop: 15,
            paddingBottom: 15,
        },
        headingStyleMain: {
            fontFamily: 'sophia',
            fontSize: 28,
            color: '#3d3a3a',
        },
    }),
);
export const ChatList = () => {
    const classes = useStyles();
    const [chats, setChats] = useState([]);
    useEffect(() => {
        API.get('userchats', {userid: Cook.getUserData()['user']['id']}).then(x => {
            setChats(x.data);
            console.log(x.data)
            var list = window.location.pathname.split('/');
            if(list.length < 4){
                return;
            }
            var c = (x.data as []).filter(e=>e['id'] == list[3]);
            console.log(c[0]);
            Bbc.fire(AppEvent.newChatSelected, c[0]);

        })

       
    }, [])

   
    useEffect(() => {
        if (Cook.getUserData() == null) return
        API.get('notifications', { userid: Cook.getUserData()['user']['id'] }).then(x => {
            GlobalState.unreadMessages = x.data['messages'];
            console.log(x.data);
        })
    }, [])
    return (
        <div>
            <List dense className={classes.root}>
                <ListItem>
                    <Typography className={classes.headingStyleMain} variant="h6">All Quotes</Typography>
                </ListItem>
                <Divider className={classes.Divider}/>
                {chats.map((x, i) => {
                    return <div key={i}>
                        <ListItem button onClick={() => {
                            Bbc.fire(AppEvent.newChatSelected, x);
                        }} className={classes.listStyle}>
                           
                            {/* <ListItemAvatar> */}
                            
                                {/* <StyledBadge
                                    overlap="circle"
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    variant="dot"
                                > */}
                                <MsgBadge />
                                    <img src={userplace} alt="placeholder-image" width="50" height="50"
                                         className={classes.profile}/>
                                        
                                {/* </StyledBadge> */}
                            {/* </ListItemAvatar> */}
                            
                            {/* <React.Fragment>
                                <ListItemText primary={x['partner']['firstName'] + ' ' + x['partner']['lastName']}
                                              secondary={x['text']}
                                              className={classes.text}/>
                            </React.Fragment> */}

                            <React.Fragment>
                                <ListItemText ><Typography variant="body2" className={classes.text}>{'HON-QOU-' + x['offer']['rid'] }</Typography><br />
                                <Typography variant="caption" >{chats['lastmessage']}</Typography>
                                
                               
                                {/* <MessagesPanel />  */}
                                </ListItemText>
                            </React.Fragment>
                            <ListItemSecondaryAction>
                                <Chip label={x['offer']['status']}/>
                            </ListItemSecondaryAction>
                            <ListItemSecondaryAction>
                            </ListItemSecondaryAction>
                        </ListItem>

                                





                        <Divider className={classes.Divider}/>
                    </div>
                })}
            </List>
        </div>
    );
}