import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { Grid, Button, Paper, Link, Dialog, DialogContent, Typography, List, IconButton, CircularProgress, ListItem } from '@material-ui/core';
import honey from './../assets/honey.png';
import { makeStyles } from '@material-ui/core/styles';
import { createBrowserHistory } from 'history';
import { API, Cook, Ways } from '../Helper';
import { Delete } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    // padding: theme.spacing(2),
    overflow: 'auto',
  },
  headingStyleMain: {
    fontFamily: 'sophia',
    fontSize: 28,
    color: '#3d3a3a',
},



  btnStyle: {
    backgroundColor: '#f28f16',
    boxShadow: 'none',
    color: '#ffffff',
    '&.MuiButton-contained': {
      '&:hover': {
        backgroundColor: '#FECF51',
        color: '#ffffff',
        boxShadow: 'none',
      },
    },
  },

  fixedHeightPaperEmail: {
    boxShadow: 'none',
    padding: theme.spacing(0),
  },


  secondBtn: {

    [theme.breakpoints.down('sm')]: {
        marginLeft: -15,
        marginTop: -15,
        
      },
},

 

}));


export const WishlistTable = () => {
  const classes = useStyles();
  const [data, setData] = useState<any[]>([]);
  const [cp, setCp] = useState(undefined)
  const [loading, setLoading] = useState(true);
  let id: number = 1;

  useEffect(() => {
    getdata();
  }, [])


  const getdata = () => {
    API.get('user/wishlist', { userid: Cook.getUserData()['user']['id'] }).then(x => {
      console.log(x.data);
      setData(x.data);
    });
  }



  const getID = () => {
    return id++;
  }

  
  return (
    <Grid container className={classes.root} >

      <Grid item lg={12} md={12} xs={12} className="wishlist">
        <Paper className={classes.fixedHeightPaperEmail}>
        <Typography align="left" className={classes.headingStyleMain} variant="h6">Wishlist</Typography><br/>
          <MaterialTable
        
            style={{ boxShadow: 'none', backgroundColor: '#fbfbfb', overflowX: 'hidden', borderBottomLeftRadius: 8, borderBottomRightRadius: 8, }}
            title=""
            columns={[
              { title: 'Product Id', render: row => <div>{'HON-PRO-' + row['id']}</div> },
              {
                title: 'Image', render: rowData => (
                  <Grid item lg={2}>
                    <img src={rowData['primaryImage']} style={{ height: 35, width: 35, }} />
                  </Grid>
                )
              },
            
            { title: 'Starting Price(kg)', render: (cp) => { return <p> {cp['minPrice']}</p>}},
              {
                title: 'Actions', render: (cr) =>
                 
                      <List style={{display: 'flex', flexDirection: 'row'}}>

                        <ListItem className={classes.secondBtn}>
                        <Button onClick={() => {
                        createBrowserHistory().push(Ways.singleProduct + cr['id']);
                        window.location.reload();
                      }}
                        variant="contained" size="small" style={{ backgroundColor: '#f28f16', boxShadow: 'none', color: '#ffffff', marginLeft: '-9%' }}>View</Button>
                
                   
                  
                
                        </ListItem>

                        <ListItem className={classes.secondBtn} >
                            <Button variant="contained" style={{color: '#fff', backgroundColor: '#ff0000'}} className="deleteIcon"  onClick={() => {
                        setCp(cr);

                      }}  size="small">Delete</Button>
                          </ListItem>


                      </List>
                    
              },
            ]}
            data={data}




          />
        </Paper>

        <Dialog open={cp != undefined} onClose={x => setCp(undefined)}>
          <DialogContent style={{padding: 40}}>
            <Typography variant='body2'>Are you sure you want to remove this product from wishlist ?</Typography>
            <br />
          
              <Button variant="contained" style={{backgroundColor: '#f28f16', color: '#fff', marginRight: '30'}} onClick={() => {
                if (cp != undefined) {
                  var prod = {};
                  try {
                    prod = cp || {};
                  } catch (x) {

                  }
                  API.delete('products/wishlist', { userid: Cook.getUserData()['user']['id'], productid: prod['id'] }).then(x => {
                    getdata();
                    setCp(undefined);
                  })
                }
              }} 
              >Delete</Button>
          
              <Button variant="outlined" style={{ backgroundColor: '#f7f7f7', marginLeft: '2%' }} onClick={() => {
                setCp(undefined);
              }}>Cancel</Button>
            
          </DialogContent>
        </Dialog>
      </Grid>
    </Grid>
  )
}