import {MenuItem} from "@material-ui/core"
import React from 'react';

export const packaging = {
    0: "None",
    1: "Bulk IBC",
    2: "Bulk Drum 200L",
    3: "Bulk IBC 1000L",
    4: "Plastic Container 12kg",
    5: "Plastic Container 5kg",
    6: "Plastic Container 1kg",
    7: "Plastic Container 500kg",
    8: "Plastic Container 250kg",
    9: "Glass Jar 1kg",
    10: "Glass Jar 500kg",
    11: "Glass Jar 250kg",
    12: "Plastic Tubs 5 & 12kg",
}
export const shippingStrategy = {
    // 0: "None",
    1: "I want my purchase to be packed as one shipment",
    2: "I want my purchase to be split across 2 shipments",
    3: "I want my purchase to be split across 3 shipments",
    4: "I want my purchase to be split across 4 shipments",
    5: "I want my purchase to be split across 5 shipments",
}
export const timing = {
    // 0: "None",
    1: "Shipped within 2 weeks",
    2: "Shipped within 3 weeks",
    3: "Shipped within 4 weeks",
    // 4: "Within 28 Days",
}
export const cnf = {
    // 1: "CNF/Seller to include shipping costs in their counter-offer",
    // 2: "EX/WORKS/Buyer to arrange shipping at their own costs outside of this transaction",
    // 3: "Shipping not required - local pickup please",
    1: "FOB (delivered to Fremantle Port WA)",
    2: "CIF - Airfreight (delivered to buyer's nearest airport)",
    3: "CIF - Sea (delivered to buyer's nearest port)",
    4: "DDP - Express (delivered to buyer's warehouse via air & road)",
    5: "DDP - Economy (delivered to buyer's warehouse via sea & road)",
    6: "Within Australia (airfreight to destination)",
    7:  "Within Australia (via road to destination)",
    8: "No Shipping - I will arrange collection"
}

export const cnfDetailed = {
    'DDP':'DDP - AHV arranges and pays for the whole delivery direct to my warehouse' ,
    'CIF':'CIF - AHV arranges and pays for delivery to the my country’s port and I will handle the rest',
    'FOB' :'FOB - AHV arranges and pays for delivery to nearest port and I will handle the rest ',
    'No Shipping - I will arrange collection' : 'No Shipping - I will arrange collection'
}
export const timingMenuList = Object.keys(timing).map((x, i) => <MenuItem key={i} value={x}>{timing[x]}</MenuItem>)
export const shippingStrategyMenuList = Object.keys(shippingStrategy).map((x, i) => <MenuItem key={i}
                                                                                              value={x}>{shippingStrategy[x]}</MenuItem>)
export const cnfMenuList = Object.keys(cnf).map((x, i) => <MenuItem key={i} value={x}>{cnf[x]}</MenuItem>)
export const packagingMenuList = Object.keys(packaging).map((x, i) => <MenuItem key={i} value={x}>{packaging[x]}</MenuItem>)