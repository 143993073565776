import React, {FunctionComponent, useEffect, useState} from 'react';
import { Button, Box, TextField, Tooltip, } from '@material-ui/core';
import question from './../assets/question.png';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import clsx from 'clsx';
import {createStyles, withStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import productthumn from './../assets/productthumn.jpg';
import {
    Card,
    CardMedia,
    CircularProgress,
    Grid,
    IconButton,
    ListItem,
    ListItemText,
    MenuItem,
    Typography, 
    
} from '@material-ui/core';
import {Cancel} from '@material-ui/icons';
import {API, AppEvent, Bbc, Cook, LoginHelper} from "../Helper";
import {SelectValidator, TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {Loader} from './LoaderButton';
import ui from './../assets/ui.png';
import {cnfMenuList, packagingMenuList, shippingStrategyMenuList, timingMenuList} from '../Constants';



const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: '#666666',
    //   color: 'rgba(0, 0, 0, 0.87)',
    color: '#fff',
      maxWidth: 360,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);


const useStyles = makeStyles((theme: Theme) =>
createStyles({
    list: {
        maxWidth: 550,
        minWidth: 250,
    },
    MuiFormControl: {
        '&:hover': {
            borderColor: '#000000',
        },
    },
    description: {
        fontFamily: 'sophia-regular',
        fontWeight: 600,
        fontSize: 13,
    },
    description2: {
        fontFamily: 'sophia-regular',
        fontWeight: 400,
        fontSize: 12,
        marginTop: '-3%',
        color: 'red',
    },

    description3: {
        fontFamily: 'sophia-regular',
        fontWeight: 400,
        fontSize: 12,
        marginTop: '-3%',
       
    },
    Card: {
        boxShadow: 'none',
        backgroundColor: '#fbfbfb',
    },
    cancel: {
        marginTop: '1%',
        marginLeft: '2%',
        color: '#ffffff',
        backgroundColor: '#737373',
        boxShadow: 'none',
        borderRadius: 4,
        fontFamily: 'sophia',
        fontWeight: 600,
        '&.MuiButton-contained': {
            '&:hover': {
                backgroundColor: '#938f8f',
                color: '#ffffff',
                boxShadow: 'none',
            },
        },
    },
    mainheading: {
        fontFamily: 'sophia',
    },
    heading: {
        fontFamily: 'sophia',
    },
    priceheading: {
        fontFamily: 'sophia',
        marginTop: '-5%',
    },
    headingTop: {
        fontFamily: 'sophia',
        marginTop: '-5%',
    },
    makeOffer: {
        marginTop: '1%',
        marginRight: '1%',
        color: '#ffffff',
        backgroundColor: '#f28f16',
        boxShadow: 'none',
        borderRadius: 4,
        fontFamily: 'sophia',
        fontWeight: 600,
        '&.MuiButton-contained': {
            '&:hover': {
                backgroundColor: '#FECF51',
                color: '#ffffff',
                boxShadow: 'none',
            },
        },
    },
    productBtn: {
        color: '#ffffff',
        backgroundColor: '#f28f16',
        boxShadow: 'none',
        borderRadius: 4,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 10,
        paddingBottom: 10,
        fontFamily: 'sophia',
        '&.MuiButton-contained': {
            '&:hover': {
                backgroundColor: '#D3D3D2',
                color: '#ffffff',
                boxShadow: 'none',
            },
        },
    },
    media: {
        maxWith: 95,
        maxHeight: 95,
        marginTop: '5%',
    },
    fullList: {
        width: 'auto',
    },
    cardStyle: {
        boxShadow: 'none',
        width: 200,
    },
    tick: {
        width: 100,
        height: 100,
    },
    successmsg: {
        fontFamily: 'sophia',
        color: '#303030',
        marginTop: '2%',
    },

    typography: {
        padding: 8,
      },

      tooltipItem: {
          marginLeft: '-2.5%',
          marginRight: '-3%',
          minWidth: 340,
          [theme.breakpoints.down('sm')]: {
            minWidth: 250,
        },

      },
    }),
    );
type Anchor = 'top' | 'left' | 'bottom' | 'right';
export const DrawerPanel: FunctionComponent = () => {
    const classes = useStyles();
    const [location, setLocation] = React.useState({
        right: false,
    });
    const [product, SetProduct] = useState({})
    const [loginDialogOpen, setLoginDialogOpen] = useState(false);
    useEffect(() => {
        Bbc.subscribe(AppEvent.requestOfferDrawerOpen, async (data) => {
            SetProduct(data);
            setLocation({...location, ['right']: true});
        });
    }, []);
    const toggleDrawer = (anchor: Anchor, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setLocation({...location, [anchor]: open});
    };
    const DrawerContent = (product: any) => {


        const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget);
        };
      
        const handleClose = () => {
          setAnchorEl(null);
        };
      
        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;


        const [offer, setOffer] = useState({});
        const [loading, setLoading] = useState(false);
        const [status, setStatus] = useState('editing');
        useEffect(() => {
            Bbc.subscribe(AppEvent.loggedIn, async (data) => {
                setOffer({...offer, address: (Cook.getUserData() || {})['user']['address'] ?? {}})
            });
            if (Cook.getUserData() != null) {
                var address = (Cook.getUserData() || {})['user']['address'] ?? {};
                delete address['id']
                setOffer({...offer, ...address})
            }
        }, [])
        const set = (e: React.FormEvent) => {
            var n = e.target['name'];
            var v = e.target['value'];
            offer[n] = v;
            setOffer({...offer})
            console.log(offer);
        }
        if (!product['id']) {
            return <p>Product Not defined</p>
        }
        console.log(Cook.getUserData());
        if (loading) {
            return <div style={{minWidth: 425, maxWidth: 425, paddingLeft: '26%', paddingTop: '5%'}}>
                <Grid item lg={12}>
                    <Typography style={{marginBottom: '3%'}} variant="body2" className={classes.successmsg}>Your quote
                        is being submited to AHV Please wait...</Typography>
                </Grid>
                <Grid item lg={12} style={{paddingLeft: '25%'}}>
                    {status != 'done' && <CircularProgress style={{color: '#f29f16'}}/>}
                </Grid>
                <Grid item lg={12} xs={12}>
                    {status == 'done' &&
                    // <List style={{width: 420}}>
                    //     <ListItem style={{paddingLeft: '20%'}}>
                    //         <Card className={classes.cardStyle}>
                    //             <CardMedia>
                    //                 <img className={classes.tick} src={ui} alt="image"/>
                    //             </CardMedia>
                    //         </Card>
                    //     </ListItem>
                    //     <ListItem>
                    //         </ListItem>
                    //     <ListItem style={{paddingLeft: '8%'}}>
                    //         <ListItemText>
                    //             <Typography className={classes.successmsg} variant="h6">Offer Sent
                    //                 Successfully</Typography>
                    //         </ListItemText>
                    //     </ListItem>
                    // </List>
                        <p>Offer Sent SuccessFully <a href="/dashboard/offers/">Go to quotes</a></p>
                    }
                </Grid>
            </div>
        }
        return (
            <div
                className={clsx(classes.list, {})}
                role="presentation"
                // onClick={}
                // onKeyDown={toggleDrawer('right', false)}
            >
                <ValidatorForm onSubmit={async () => {
                    setLoading(true);
                    try {
                        var req = await API.post('offer', {
                            ...offer,
                            product: product,
                            user: Cook.getUserData()['user']
                        }).then(async x => {
                            console.log(x.data);
                            if (offer['saveAddress'] && offer['saveAddress'] == true) {
                                await LoginHelper.updateUserData();
                            }
                            setOffer({});
                            setStatus('done');
                        })
                    } catch (x) {
                        setStatus('error')
                    }
                }}>
                    <Grid container style={{paddingLeft: '6%', paddingRight: '6%', paddingTop: '3%'}}>
                        <Grid item lg={11} xs={11}>
                            <Typography variant="h5" className={classes.mainheading}>Submit Your Quote Request</Typography>
                        </Grid>
                        <Grid item lg={1} xs={1}>
                            <IconButton onClick={toggleDrawer('right', false)}>
                                <Cancel/>
                            </IconButton>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <Typography className={classes.description} variant="caption">Lorem ipsum dolor sit amet,
                                consectetur adipiscing elit. Etiam eget rutrum libero,
                                sed maximus magna. Aenean vel elit in est sagittis accumsan,Lorem ipsum dolor sit amet,
                                consectetur adipiscing elit.</Typography>
                        </Grid>
                    </Grid>
                    <Grid container style={{padding: '6%', marginTop: '9%', backgroundColor: '#fbfbfb'}}>
                        {/*<Grid item lg={12} xs={12}>*/}
                        {/*    <Typography variant="h6" className={classes.heading}>Velve Texture</Typography>*/}
                        {/*    <br/>*/}
                        {/*</Grid>*/}
                        <Grid item lg={3}>
                            <Card className={classes.Card}>
                                <CardMedia>
                                    <img className={classes.media} src={productthumn} alt="image"/>
                                </CardMedia>
                            </Card>
                        </Grid>
                        <Grid item lg={9} xs={12}>
                            <List>
                                <ListItem>
                                    <Typography variant="h6"
                                                className={classes.heading}>{product['productTitle']}</Typography>
                                </ListItem>
                                <List style={{display: 'flex', flexDirection: 'row', maxWidth: 350, marginTop: '-5%'}}>
                           
                           <ListItem style={{minWidth: 230}}>
                               <Typography className={classes.description2} variant="body2">Minimum Order Quantity : 5 carton</Typography>
                             
                           </ListItem>
                           <ListItem style={{marginLeft: '-5%', marginRight: '-5%'}}>
                               <Divider orientation="vertical" flexItem/>
                           </ListItem>
                           <ListItem style={{minWidth: 160}}>
                               <Typography className={classes.description2} align="left" variant="body2">Units Per Carton : 32</Typography>
                           </ListItem>
                       </List>
                                <ListItem>
                                    <Typography variant="subtitle2"
                                                className={classes.priceheading}> {product['minPrice']} - {product['maxPrice']} AUD</Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography align="justify" className={classes.description3} variant="caption">Lorem
                                        ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget rutrum libero,
                                        sed maximus magna.</Typography>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    <Grid container style={{padding: '6%'}}>
                        <Grid item lg={12} xs={12}>
                            <br/>
                            <Typography variant="h6" className={classes.heading}>How Many Do I Want ?</Typography>
                            <br/>
                        </Grid>
                        {/* <Grid item lg={12} xs={12}>
                            <TextValidator
                                value={offer['price']}
                                onChange={set}
                                
                                id="amount"
                                label="Total Amount"
                                type="amount"
                                validators={['isNumber', 'required']}
                                errorMessages={['Invalid Value', 'Price is required']}
                                fullWidth
                                variant="outlined"
                                name='price'/>
                        </Grid> */}
                        <Grid style={{paddingRight: '0.5%'}} item lg={8} xs={12}>
                            <br/>
                            <TextValidator
                                variant="outlined"
                                fullWidth
                                value={offer['items']}
                                validators={['required']}
                                errorMessages={['Quantity is required']}
                                label="Quantity"
                                onChange={set}
                                name={'items'}
                            />
                             
                        </Grid>

                        <Grid item lg={4} xs={12}>
                            <br/>
                            <SelectValidator
                                variant="outlined"
                                fullWidth
                                value={offer['items']}
                                validators={['required']}
                                errorMessages={['Quantity is required']}
                                // label="Per Carton/Per Pallete"
                                onChange={set}
                                defaultValue={5}
                                name={'carton/palette'}
                                shrink={false}
                                
                            >
                               
                               
                                <MenuItem selected value={5}>Carton</MenuItem>
                                <MenuItem value={6}>Pallet</MenuItem>
                              
                            </SelectValidator>
                        </Grid>

                   
                       
                      
                        
                        <Grid item lg={12} xs={12}>
                        
                            <List style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                                <ListItem className={classes.tooltipItem}><Typography variant="h6" className={classes.heading}>What’s My Shipping Preference?</Typography></ListItem>
                                <ListItem >   
                                <HtmlTooltip
        title={
          <React.Fragment>
           
            <Typography variant="caption">FOB - AHV arranges and pays for delivery to nearest port and I will handle the rest </Typography><br />
            <Typography variant="caption">CIF - AHV arranges and pays for delivery to the my country’s port and I will handle the rest  </Typography><br />
            <Typography variant="caption">DDP - AHV arranges and pays for the whole delivery direct to my warehouse  </Typography><br />
            

          </React.Fragment>
        }
      >
        <img src={question} />
      </HtmlTooltip>
                                </ListItem>
                            </List>
                            
                         
                        </Grid>
                    
                        <Grid style={{paddingBottom:'2%'}} item lg={12} xs={12}>
                        
                            <SelectValidator
                                name='cnf'
                                fullWidth={true}
                                variant="outlined"
                                validators={['required']}
                                errorMessages={['CNF is required']}
                                onChange={set}
                                value={offer['cnf']}
                                label="I am Looking For"
                            >
                                {cnfMenuList}
                            </SelectValidator>
                        </Grid>
                        
                      
                        <Grid item lg={12} xs={12}>
                            <br/>
                            <Typography variant="h6" className={classes.heading}>Delivery Schedule</Typography>
                        </Grid>
                      
                        <Grid item lg={12} xs={12}>
                            <br/>
                            <SelectValidator
                                fullWidth={true}
                                variant="outlined"
                                name={'shippingStrategy'}
                                onChange={set}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                value={offer['shippingStrategy']}
                                label="How Do I Want This Order Sent?"
                            >
                                {shippingStrategyMenuList}
                            </SelectValidator>
                        </Grid>

                        

                        <Grid item lg={12} xs={12}>
                            <br/>
                            <SelectValidator
                                name={'timing'}
                                value={offer['timing']}
                                onChange={set}
                                fullWidth={true}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                variant='outlined'
                                label="How quickly do I need this item shipped ?"
                            >
                                {timingMenuList}
                            </SelectValidator>
                        </Grid>

                       
                     
                       
{/* 
                      { if (offer['cnf'] == 'FOB' || offer['cnf'] == 'No Shipping - I will arrange collection') {

                          <div>nothing to display</div>
                          
                      }
                      else if (offer['cnf'] == 'CIF') {
                          <div>display field according to CIF</div>

                      } else if (offer['cnf'] == 'DDP') {
                          <div>display field according to DDP</div>
                      }
                      } */}

                    
                      



                                { offer['cnf'] != undefined || offer['cnf'] == 'FOB' ? <div></div> :   <div style={{width: '100%'}}>
                                {/* <Grid item lg={12} xs={12}>
                            <br/>
                            <Typography variant="h6" className={classes.heading}>Here’s My Shipping Address</Typography>
                            <br/>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <TextValidator
                                name={'street1'}
                                onChange={set}
                                value={offer['street1']}
                                margin="dense"
                                validators={['required']}
                                errorMessages={['This field is required']}
                                id="amount"
                                label="Street Address 1"
                                type="amount"
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>


                        <Grid item lg={12} xs={12}>
                            <TextValidator
                                name={'street2'}
                                margin="dense"
                                value={offer['street2']}
                                onChange={set}
                                id="amount"
                                validators={['required']}
                                errorMessages={['This field is required is required']}
                                label="Street Address 2"
                                type="amount"
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>


                        <Grid item lg={12} xs={12}>
                            <TextValidator
                                name={'city'}
                                margin="dense"
                                value={offer['city']}
                                id="amount"
                                label="City"
                                onChange={set}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                type="amount"
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>

                        
                        <Grid item lg={12} xs={12}>
                            <TextValidator
                                name={'zipcode'}
                                margin="dense"
                                value={offer['zipcode']}
                                id="amount"
                                label="Zip Code"
                                onChange={set}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                type="amount"
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item lg={12} xs={12}>
                          <TextValidator
                              name={'state'}
                              onChange={set}
                              margin="dense"
                              value={offer['state']}
                              id="amount"
                              validators={['required']}
                              errorMessages={['State is required']}
                              label="State/Province"
                              type="amount"
                              fullWidth
                              variant="outlined"
                          />
                      </Grid>

                                    <Grid item lg={12} xs={12}>
                            <TextValidator
                                value={offer['country']}
                                onChange={set}
                                margin="dense"
                                id="amount"
                                validators={['required']}
                                errorMessages={['Country is required']}
                                label="Country"
                                type="text"
                                fullWidth
                                variant="outlined"
                                name={'country'}
                            />
                        </Grid> */}
                      
                        
                      
                      </div>
                       
                       }


                    {offer['cnf'] != undefined && offer['cnf'] == '2' ? 
                    <div style={{width: '100%'}}>
                       <Grid item lg={12} xs={12}>
                       <br/>
                       <Typography variant="h6" className={classes.heading}>Here’s My Shipping Address</Typography>
                       <br/>
                   </Grid>

                   <Grid item lg={12} xs={12}>
                          <TextValidator
                              name={'port'}
                              onChange={set}
                              margin="dense"
                              value={offer['port']}
                              id="port-name"
                              validators={['required']}
                              errorMessages={['port is required']}
                              label="Port Name"
                              type="name"
                              fullWidth
                              variant="outlined"
                          />
                      </Grid>


                   <Grid item lg={12} xs={12}>
                          <TextValidator
                              name={'state'}
                              onChange={set}
                              margin="dense"
                              value={offer['state']}
                              id="amount"
                              validators={['required']}
                              errorMessages={['State is required']}
                              label="State/Province"
                              type="amount"
                              fullWidth
                              variant="outlined"
                          />
                      </Grid>

                      <Grid item lg={12} xs={12}>
                            <TextValidator
                                name={'zipcode'}
                                margin="dense"
                                value={offer['zipcode']}
                                id="amount"
                                label="Zip Code"
                                onChange={set}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                type="amount"
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>

                    <Grid item lg={12} xs={12}>
                            <TextValidator
                                value={offer['country']}
                                onChange={set}
                                margin="dense"
                                id="amount"
                                validators={['required']}
                                errorMessages={['Country is required']}
                                label="Country"
                                type="text"
                                fullWidth
                                variant="outlined"
                                name={'country'}
                            />
                             </Grid></div> : <div></div> }   


                            {offer['cnf'] != undefined && offer['cnf'] == '3' ? 
                            <div style={{width: '100%'}}>
                               <Grid item lg={12} xs={12}>
                               <br/>
                               <Typography variant="h6" className={classes.heading}>Here’s My Shipping Address</Typography>
                               <br/>
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                <TextValidator
                                name={'house'}
                                onChange={set}
                                value={offer['house']}
                                margin="dense"
                                validators={['required']}
                                errorMessages={['This field is required']}
                                id="amount"
                                label="Building/House No"
                                type="amount"
                                fullWidth
                                variant="outlined"
                                />
                        </Grid>

                           <Grid item lg={12} xs={12}>
                            <TextValidator
                                name={'street1'}
                                onChange={set}
                                value={offer['street1']}
                                margin="dense"
                                validators={['required']}
                                errorMessages={['This field is required']}
                                id="amount"
                                label="Street Address 1"
                                type="amount"
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>


                        <Grid item lg={12} xs={12}>
                            <TextValidator
                                name={'street2'}
                                margin="dense"
                                value={offer['street2']}
                                onChange={set}
                                id="amount"
                                validators={['required']}
                                errorMessages={['This field is required is required']}
                                label="Street Address 2"
                                type="amount"
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>

                                  <Grid item lg={12} xs={12}>
                                  <TextValidator
                                      name={'state'}
                                      onChange={set}
                                      margin="dense"
                                      value={offer['state']}
                                      id="amount"
                                      validators={['required']}
                                      errorMessages={['State is required']}
                                      label="State/Province"
                                      type="amount"
                                      fullWidth
                                      variant="outlined"
                                  />
                              </Grid>
                              <Grid item lg={12} xs={12}>
                            <TextValidator
                                name={'zipcode'}
                                margin="dense"
                                value={offer['zipcode']}
                                id="amount"
                                label="Zip Code"
                                onChange={set}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                type="amount"
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>

                    <Grid item lg={12} xs={12}>
                            <TextValidator
                                value={offer['country']}
                                onChange={set}
                                margin="dense"
                                id="amount"
                                validators={['required']}
                                errorMessages={['Country is required']}
                                label="Country"
                                type="text"
                                fullWidth
                                variant="outlined"
                                name={'country'}
                            />
                        </Grid>
                              </div>
                            : <div></div> }              



                       

                       




                        
                      

{/*                     
                        {
                            Cook.getUserData()['user']['address'] == null && 
                            <Grid item lg={12} xs={12}>
                                <input checked={offer['saveAddress']} onChange={e => {
                                    setOffer({...offer, saveAddress: e.target.checked})
                                    console.log(offer);
                                }} type="checkbox"/> Use this as my Address
                            </Grid>
                        }
                        */}



                        
<Grid item lg={12} xs={12}>
                            <br/>
                            <Typography variant="h6" className={classes.heading}>Other Details</Typography>
                            <br/>
                        </Grid>

                        <Grid item lg={12} xs={12}>
                            <TextValidator
                                name={'description'}
                                margin="dense"
                                value={offer['description']}
                                id="offer-description"
                                label="Describe Your Offer in Words"
                                type="offer"
                                onChange={set}
                                multiline
                                rows={5}
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>


                        <br/>
                        <Grid item lg={7}>
                        </Grid>
                        <Grid item lg={5}>
                            <br/><br/>
                            <Loader isLoading={loading} variant="contained" type='submit' className={classes.makeOffer}>
                                Submit My Offer
                            </Loader>
                        </Grid>
                    </Grid>
                </ValidatorForm>
                <Divider/>
            </div>
        )
    }
    return (
        <div>
            {(['right',] as Anchor[]).map((anchor) => (
                <React.Fragment key={anchor}>
                    {/* <Button variant="contained" className={classes.productBtn} onClick={toggleDrawer(anchor, true)}>Request
                        Quote--</Button> */}
                    <Drawer anchor={anchor} open={location[anchor]} onClose={() => {
                        toggleDrawer(anchor, false)
                        setLocation({...location, ['right']: false});
                        SetProduct({});
                    }}>
                        {DrawerContent(product)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}