import React, {useState, useEffect, FunctionComponent} from 'react';
import Button from '@material-ui/core/Button';
import { ListItem, List, Typography, Grid, Paper, ListItemText, CircularProgress, Container, ListItemSecondaryAction, Card, CardActionArea, CardMedia, CardContent, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Ways, API, Cook, LoginHelper,} from './../Helper';
import { database } from 'firebase';
import { LocalMall } from '@material-ui/icons';
import { CheckCircle } from '@material-ui/icons';
import { AssignmentReturned } from '@material-ui/icons';
import dollar from './../assets/dollar.png';
import purse from './../assets/purse.png';






const useStyles = makeStyles((theme) => ({
    
    
    Paper: {
        boxShadow: 'none',
        padding: 25,
        width: '100%',
        
    },
    
        listStyle: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            
            
    
         
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              flexDirection: 'column',
            },
        },
    
        headingListStyle: {
    
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            
    
         
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              flexDirection: 'column',
            },
    
    
        },
    
    
     listheading: {
    
    fontSize: 16, 
    fontWeight: 400,
     },
    
     leftItem: {
    
        backgroundColor: '#f28f14',
        color: '#fff',
        // borderBottom: '1px solid #fff',
     },
    
     rightItem: {
    
        border: '1px solid #efefef',
     },


     actionArea: {
        
        '&.MuiCardActionArea-root': {
  
          textAlign: 'center',
  
  
        },
  
      },

      media: {


      },

      mainHeading: {
        
        color: '#303030',
        fontSize: '1.3rem',
        fontWeight: 500,
    
  
    },
  
  
    descriptionStyle: {
  
       
        fontSize: '1.0rem',
        fontWeight: 400,
        color: '#303030',
  
    },

      Paper3: {
        backgroundColor: '#fff',
        height: '25vh',
        boxShadow: 'none',
        
       
        [theme.breakpoints.down('sm')]: {
          height: 200,
        },
  
      },

      card: {
        maxWidth: 400,
      
        boxShadow: 'none',
        backgroundColor: '#f28f16',
        paddingTop: 10,
        paddingBottom: 10,
        pointerEvents: 'none',

       
  
        
      },

      root: {
        maxWidth: 530,
        boxShadow: 'none',
        backgroundColor: '#f4f4f4',
        paddingTop: 50,
        paddingBottom: 50,
        pointerEvents: 'none',
  
        
      },



    
    }));




export const MainContent = () => {
    const classes = useStyles();


    const [data , setData] = useState<any[]>([])
  



    useEffect(() => {
      API.get('userOverview', {userid: LoginHelper.userid()}).then(z => {
         console.log(z.data);
          setData(z.data);
      
          
      })
     
  }, [])
  
   

    
  


 
  return (
    <div>
    
<Container>
    <Grid container justify="center" spacing={2}>

     
    <Grid item lg={4} xs={12} sm={6} md={4}>

<Card className={classes.root}>
<CardActionArea className={classes.actionArea}>
<CardMedia
className={classes.media}>


<AssignmentReturned style={{fontSize: 60, color: '#f28f16'}} />
</CardMedia>
<CardContent>


<Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
{/* {props.totalData.totalPayload.length > 0 ? props.totalData.totalPayload : 0  } (t) */}
{data['quotes']}

</Typography>
<br />
{/* <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
Pay Load
</Typography> */}

<Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
Total Quotes Submitted
{/* payload */}
</Typography>
</CardContent>
</CardActionArea>
</Card>
 
</Grid>
              


<Grid item lg={4} xs={12} sm={6} md={4}>

<Card className={classes.root}>
<CardActionArea className={classes.actionArea}>
<CardMedia
className={classes.media}>

<CheckCircle style={{fontSize: 60, color: '#f28f16'}} />
</CardMedia>
<CardContent>


<Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">

{/* {props.totalData.totalSold == null ? 0 : props.totalData.totalSold} (t) */}
{data['approved']}


</Typography>
<br />

<Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
Total Quotes Approved
</Typography>
</CardContent>
</CardActionArea>
</Card>
  
</Grid>

<Grid item lg={4} xs={12} sm={6} md={4}>

<Card className={classes.root}>
<CardActionArea className={classes.actionArea}>
<CardMedia
className={classes.media}>

<LocalMall style={{fontSize: 60, color: '#f28f16'}} />
</CardMedia>
<CardContent>


<Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
{data['orders']}

</Typography>
<br />
{/* <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
Remaining
</Typography> */}

<Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
Total Order Placed
</Typography>
</CardContent>
</CardActionArea>
</Card>

</Grid>




</Grid>


</Container>
      
    </div>
  );
}