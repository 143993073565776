import React, { useState } from 'react';
import { Box, Container, Grid, Link, Typography, List, ListItemText, ListItemSecondaryAction, ListItem } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import honeylogo from './../assets/honeylogo.png';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { API, LoginHelper } from '../Helper';
import { createBrowserHistory } from 'history';
import { Loader } from '../Components/LoaderButton';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
            backgroundColor: '#ffffff',
        },
        listStyle: {
            display: 'flex',
            flexDirection: 'row',
        },
        box: {
            backgroundColor: '#fbfbfb',
            paddingTop: '2%',
            paddingBottom: '2%',
            marginTop: '14%',
        },
        root: {
            marginTop: '5%',
            height: '10vh',
            marginBottom: '20%',
        },
        PopupBtnStyle: {
            fontFamily: 'sophia-regular',
            fontWeight: 1000,
            fontSize: 14,
            color: '#ffffff',
            padding: 10,
            width: '100%',
            backgroundColor: '#f28f16',
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#f28f16',
                    color: '#ffffff',
                    boxShadow: 'none',
                    textDecoration: 'none',
                },
            },
        },
        btnStyle: {
            fontFamily: 'sophia-regular',
            fontWeight: 1000,
            fontSize: 14,
            marginTop: 25,
            color: '#ffffff',
            padding: 10,
            backgroundColor: '#f28f16',
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#f28f16',
                    color: '#ffffff',
                    boxShadow: 'none',
                    textDecoration: 'none',
                },
            },
        },
        topContainer: {
            // borderBottom: '1px solid #efefef',
            marginBottom: '5%',
            paddingBottom: '2%',
            width: '100%',
        },
    }),
);

export const ForgetPassword = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    var resendOnly = false;
    return (
        <div className={classes.root}>
            <Container maxWidth="md" className={classes.topContainer}>
                <Grid container direction="row"
                    justify="center"
                    alignItems="center"
                >

                    <Grid item lg={3}>
                        <Link href="/">
                            <img src={honeylogo} alt="image" />
                        </Link>
                    </Grid>
                    <Grid item lg={6} xs={2}></Grid>
                    <Grid item lg={1}>
                        <Lock />
                    </Grid>
                    <Grid item lg={2}>
                        <Typography align="left" variant="subtitle2">Password Recovery</Typography>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="xs">
                <Grid container direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item lg={12} style={{ paddingBottom: 10 }}>
                        <Typography variant='h6'>Password Recovery</Typography>
                        <Typography style={{ minWidth: 435, }} variant="caption">You are required to enter your email address for password recovery.</Typography>
                    </Grid>
                    <ValidatorForm onSubmit={async () => {


                        setLoading(true);
                        var res = (await API.post('requestRecovery', { email: email , resend : resendOnly }));
                        enqueueSnackbar(res.data['message'])
                        setLoading(false)
                    }} className={classes.topContainer} noValidate>
                        <Grid item lg={12}>
                            <TextValidator
                                value={email}
                                onChange={e => setEmail(e.target['value'])}
                                validators={['required', 'isEmail']}
                                errorMessages={['email is required', 'email is in invalid format']}
                                variant="outlined"
                                margin="normal"
                                required

                                fullWidth
                                size='small'
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            // style={{minWidth : 400}}
                            />
                            <Loader
                                type="submit"
                                onClick={()=>{
                                    resendOnly = false;
                                }}
                                fullWidth
                                icon={<div></div>}
                                variant="contained"
                                color="primary"
                                isLoading={loading}
                                className={classes.btnStyle}
                            >
                                Send Email
                        </Loader>
                        </Grid>
                        {/* <TextValidator
                            value={password}
                            onChange={e => setPassword(e.target['value'])}
                            validators={['required']}
                            errorMessages={['Password is required']}
                            variant="outlined"
                            margin="normal"
                            size='small'
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        /> */}

                        <Grid item lg={4}>
                        </Grid>
                        <Grid item lg={8}>
                            <List>
                                <ListItem>
                                    <ListItemText>
                                        <Typography variant="subtitle2" align="left">Didn't receive email?</Typography>
                                    </ListItemText>
                                    <ListItemSecondaryAction onClick={() => { }} style={{ paddingLeft: 80 }} >
                                        <Button type='submit' onClick={() => {
                                            resendOnly = true;
                                        }} style={{ backgroundColor: 'transparent', boxShadow: 'none', fontVariant: 'normal' }} variant='contained'>
                                            <Typography align="right" variant="subtitle2" style={{ paddingLeft: 1, fontVariant: 'normal' }}>Send agian</Typography>
                                        </Button>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem>
                                </ListItem>
                            </List>
                            {/* <Link style={{color: 'blue',}} href="/signup">Don't have an accout ?</Link> */}


                            {/* <Link style={{color: 'blue'}} href="/signup">Singup</Link> */}
                        </Grid>
                    </ValidatorForm>

                    <Grid item lg={12} xs={12} style={{ paddingTop: 25, }}>

                    </Grid>
                </Grid>
            </Container>
            <Box className={classes.box}>
                <Grid container>
                    <Grid item lg={3}></Grid>
                    <Grid item lg={2}>
                        <Typography variant="subtitle2">© 2020, Australian Honey Ventures</Typography>
                    </Grid>
                    <Grid item lg={3} xs={3}>
                    </Grid>
                    <Grid item lg={2} xs={3}>
                        {/* <Typography variant="subtitle2">Privacy Policy</Typography> */}
                    </Grid>
                    <Grid item lg={2}></Grid>
                </Grid>
            </Box>
        </div>
    )
}