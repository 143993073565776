import React, {useEffect} from 'react';
import {Container, Grid, Paper} from '@material-ui/core';
import {ChatList} from './ChatList';
import {Messages} from './Messages';
import {makeStyles} from '@material-ui/core/styles';
import {Cook} from '../../Helper';
import {OfferDetailFotChatSideBar} from './OfferDetailForChat';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        padding: theme.spacing(0),
        overflow: 'auto',
    },
    fixedHeightPaperchatlist: {
        maxHeight: 630,
        minHeight: 630,
        overflow: 'auto',
        boxShadow: 'none',
        // padding: theme.spacing(4),
        paddingTop: 25,
        backgroundColor: '#ffffff',
        border: '1px solid #efefef',
    },

    fixedHeightPapermessages: {
        height: 630,
        boxShadow: 'none',
        paddingTop: 25,
        backgroundColor: '#fff',
        border: '1px solid #efefef',

    },
    
    fixedHeightPaperOffer: {
        height: 600,
        boxShadow: 'none',
        // padding: theme.spacing(4),
        paddingTop: 25,
        backgroundColor: '#fff',
        border: '1px solid #efefef',
    },
    fixedHeightPaperOfferDetail: {
        height: 620,
        boxShadow: 'none',
        // padding: theme.spacing(4),
        paddingTop: 25,
        [theme.breakpoints.down('sm')]: {
            height: 930,
        },
    },
}));
export const ChatLayout = () => {
    const classes = useStyles();
    useEffect(() => {
        console.log(Cook.getUserData());
    })
    return (
        <div>
            {/* <Container maxWidth="xl"> */}
                {/* <SocketTest /> */}
                <Grid container className={classes.root} spacing={1}>
                
                    <Grid item lg={4} md={12} xs={12} xl={4} style={{borderRight: '1px solid #fbfbfb'}}>
                        <Paper className={classes.fixedHeightPaperchatlist}>
                            <ChatList/>
                        </Paper>
                    </Grid>
                    <Grid item xl={8} lg={8} md={12} xs={12} sm={12}>
                        <Paper className={classes.fixedHeightPapermessages}>
                            <Messages/>
                        </Paper>
                    </Grid>
                    {/* <Grid item lg={3} md={3} xs={12}>
                        <Paper className={classes.fixedHeightPaperOffer}>
                            <OfferDetailFotChatSideBar/>
                        </Paper>
                    </Grid> */}
                </Grid>
            {/* </Container> */}
        </div>
    )
}