import React from 'react';
import { Grid , Container, Typography, ListItemText, Button, List, ListItem } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';



const useStyles = makeStyles((theme: Theme) =>
createStyles({
    actionArea: {

        maxWidth: 450,
        
      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },
    root: {
      
      boxShadow: 'none',
      width: 514,
      height: 409,

      
    },
    media: {
      
      width: 514,
      height: 409,
      
    },
  
    imgStyle: {
  
      maxWidth: 120,
     
  
    },

    mainHeading: {
      fontFamily: 'sophia',
      // color: '#303030',
      fontSize: 22,
      color: '#fff',
  
      paddingBottom: 15,

  },


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: '1.25rem',
      fontWeight: 500,
      // color: '#726f6f',
      color: '#fff',

  },

  newStyle: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '-6%',
     
       },
  },

  btnStyle: {

    padding: 15, 
    fontFamily: 'sophia',
    backgroundColor: '#fff',
    width: 230,
    marginTop: '5%',
  },
  
  
  
}),
);
  




export const FAQ = () => {
    const classes = useStyles();

    return(
        <div style={{backgroundColor: '#FCB040', paddingTop: '8%', paddingBottom: '8%',}}>
        <Container maxWidth="lg">
        <Grid container
         spacing={2}
         direction="row"
         justify="center"
         alignItems="center"
         style={{paddingBottom: '3%'}}
         
         >

        <Grid item lg={12} sm={12} xs={12}>
    
           
           
          <Typography className={classes.mainHeading} style={{fontSize: '1.9rem'}} align="center" variant="h5" component="h2">
          Frequently Asked Questions
          </Typography>
          <Typography variant="h2" align="center" style={{color: '#fff', fontSize: '1.25rem'}}>Here are some common questions about buying from AHV:</Typography>

        </Grid>

        </Grid>

      
    

            <Grid container justify="center" alignItems="center">
             <Grid item lg={6} xs={12}>
                 <List style={{ paddingTop: '5%', paddingBottom: '5%'}}>
      <ListItem>
    
        <ListItemText>
          <Typography className={classes.mainHeading} variant="body2">Is there a minimum purchase amount? </Typography>
          <Typography className={classes.descriptionStyle} variant="body2">Purchase is by carton, not by individual unit. The minimum order size is 96 cartons (i.e. 1 pallet). </Typography>
          </ListItemText>

      </ListItem>
      <ListItem style={{paddingTop: '5%'}}>
    
        <ListItemText>
          <Typography className={classes.mainHeading} variant="body2">What shipping options are available to me?</Typography>
          <Typography className={classes.descriptionStyle} variant="body2">We can arrange FOB, CIP, DDP shipping options for international sales, or local pick-up and domestic<br /> express within Australia.</Typography>
        </ListItemText>
      </ListItem>
    
    </List>


   
                 </Grid>

                 <Grid item lg={6} xs={12}>
                 <List style={{ paddingTop: '5%'}}>
      <ListItem className={classes.newStyle} >
     
        <ListItemText>
          <Typography className={classes.mainHeading} variant="body2">Can I order a mixed pallet of products?</Typography>
          <Typography className={classes.descriptionStyle} variant="body2">Mixing and matching pallets is fine. This is because all orders are calculated by carton quantity not pallet quantity. </Typography>
        </ListItemText>
      
      </ListItem>
      <ListItem style={{paddingTop: '8%'}}>
    
        <ListItemText>
          <Typography className={classes.mainHeading} variant="body2">What is the shipping cost? </Typography>
          <Typography className={classes.descriptionStyle} variant="body2">Shipping costs vary from purchase to purchase depending on the shipping destination. Product prices displayed on this website exclude shipping. Shipping prices are included in all quotes. </Typography>
        </ListItemText>
      
      </ListItem>
    
    </List>


   
                 </Grid>

                 <Grid item lg={12}>
                     <Typography align="center">
                 <Button className={classes.btnStyle} variant="contained">
                    View More FAQ's
                    </Button>
                    </Typography>
                 </Grid>

                 
                 </Grid>


    






        </Container>


        </div>
    )
}