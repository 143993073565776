import React, { FunctionComponent } from 'react'
import thumbnail from '../../assets/brand1.jpg';
import { Card, CardMedia, Divider, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { getval } from '../../Helper';
import { CartListForOrder } from '../../Components/CartListForQuote';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            maxWidth: 50,
            maxHeight: 50,
            boxShadow: 'none',
        },
        btnStyle: {
            fontFamily: 'sophia-regular',
            fontWeight: 1000,
            fontSize: 14,
            color: '#ffffff',
            padding: 10,
            backgroundColor: '#f28f16',
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#f28f16',
                    color: '#ffffff',
                    boxShadow: 'none',
                    textDecoration: 'none',
                },
            },
        },
        listStyle: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            // borderLeft: '1px solid #efefef',
            // borderRight: '1px solid #efefef',
        },
        bottomListStyle: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
        },
        listHeading: {
            fontSize: 17,
            fontFamily: 'sophia',
            marginTop: '-2%',
            marginBottom: '2%',
        },
        mainHeading: {
            fontFamily: 'sophia',
            fontSize: 32,
            marginBottom: '3%',
            marginLeft: '3%',
        },
        listsubHeading: {
            fontSize: 16,
            fontFamily: 'sophia-regular',
            fontWeight: 800,
        },
        leftHeading: {
            fontFamily: 'sophia',
            fontSize: 14,
        },
        totalHeading: {
            fontFamily: 'sophia',
            fontSize: 18,
        },
    }),
);
export const OrderItem: FunctionComponent<{ offer: any }> = props => {
    const classes = useStyles({});
    return (
        <div>

            <CartListForOrder order={props.offer} />
        </div>
    )
}