import React, { useEffect, useState } from 'react';
import { CircularProgress, Container, Grid, Paper, Typography, List, ListItem, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useSnackbar } from 'notistack';
import { OrderStatus } from './OrderStatus';
import { OrderItem } from './OrderItem';
import { OtherDetailsForOffer } from '../SingleOffer/OtherDetailsForOffer';
import { API } from '../../Helper';
import { GlobalState } from '../../AppState';
import { observer } from 'mobx-react';
import { ShippingDetailsForOrder } from "./ShippingDetailsForOrder";
import { Status } from '../../Quote/Status';
import { QuoteForm } from '../../Quote/QuoteForm';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        padding: theme.spacing(0),
        overflow: 'auto',
    },
    fixedHeightPaperEmail: {
        height: 830,
        boxShadow: 'none',
        paddingTop: 25,
        backgroundColor: '#ffffff',
        border: '1px solid #efefef',
    },
    fixedHeightPaperShipping: {
        height: 330,
        boxShadow: 'none',
        paddingTop: 25,
        backgroundColor: '#ffffff',
        border: '1px solid #efefef',
    },
    fixedHeightPaperOfferDetail: {
        height: 320,
        boxShadow: 'none',
        paddingTop: 25,
        [theme.breakpoints.down('sm')]: {
            height: 600,
        },
    },
    fixedHeightPaperOfferDetailDescription: {
        height: 1050,
        boxShadow: 'none',
        paddingTop: 25,
        [theme.breakpoints.down('sm')]: {
            height: 960,
         
        },
    },
    fixedHeightPaperOfferDetailShipping: {
        height: 640,
        boxShadow: 'none',
        paddingTop: 25,
        [theme.breakpoints.down('sm')]: {
            height: 630,
        },
    },

    dateStyle: {

        marginLeft: '-12%',
         marginTop: '-0.8%',

         [theme.breakpoints.down('sm')]: {
            marginLeft: '-4%',
        },
    },

    listStyle: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: 450,
        },
    },

    orderHeading: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem',
        },

    },

}));
export const OrderLayout = observer(() => {

    const [quote , setQuote] = useState({});
    const getData = async() => {

        var id = window.location.pathname.split('/')[4];

        var off = await API.get('offer', { offerid: id });
        console.log(off.data);
        setQuote(off.data);
    }

    useEffect(() => {

        getData();
    }, [])


    
    const classes = useStyles();
    const [co, setCo] = useState({});
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    // let changedOffer = {};
    useEffect(() => {
        var id = window.location.pathname.split('/')[4];
        API.get('offer', { offerid: id }).then(x => {
            GlobalState.currentOffer = x.data;
            setCo(x.data);
        })
    }, [])
    if (!co['cnf']) {
        return<Container maxWidth="sm">
            <Grid container
            style={{padding: 50}}
        direction="row"
        justify="center"
        alignItems="center"><Grid item lg={5}></Grid><Grid item lg={2}><CircularProgress style={{color: '#f28f16'}} /></Grid><Grid item lg={5}></Grid></Grid> 
        </Container>
    }
    return (
        <div>
            <ValidatorForm onSubmit={async () => {
             
            }}>
                <Container maxWidth="lg" style={{paddingBottom: 100}}>
                    <Grid container className={classes.root} spacing={1}>
                        <Grid item lg={12}>
                       


                        <List className={classes.listStyle} style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingTop: '2%', marginBottom: '-2%', }}>
                            <ListItem >
                            <Typography className={classes.orderHeading}  variant="h6">Order # HON-ORD-{quote['rid']} </Typography>
                            </ListItem>
                            <ListItem className={classes.dateStyle} >
                                <Hidden only={['sm', 'xs']}>
                                <Typography align="left" variant="body2">Placed: {new Date(Date.parse(quote['createdOn'])).toDateString()}</Typography>
                                </Hidden>
                                <Hidden only={['lg', 'md', 'xl']}>
                                <Typography style={{marginLeft: 15}} align="left" variant="body2">{new Date(Date.parse(quote['createdOn'])).toDateString()}</Typography>
                                </Hidden>
                            </ListItem>
                        
                          
                        </List>


                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <Status quote={co} />
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <Paper className={classes.fixedHeightPaperOfferDetail}>
                                <OrderItem offer={co} />
                            </Paper>
                        </Grid>
                        <Grid item lg={12} xs={12} sm={12} md={12}>
                            <Paper className={classes.fixedHeightPaperOfferDetailDescription}>
                                {/* <OtherDetailsForOffer offer={co} /> */}
                                <QuoteForm quote={co} />
                            </Paper>
                        </Grid>
                     
                        <Grid item lg={12} md={12} xs={12}>
                            <Grid item lg={9} md={9} xs={6}>
                            </Grid>
                          
                        </Grid>
                    </Grid>
                </Container>
            </ValidatorForm>
        </div>
    )
}
)