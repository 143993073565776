import React, {useEffect, useState} from 'react';
import {Badge, Box, Breadcrumbs, CircularProgress, Container, Grid, Link, Typography} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import honeylogo from '../../assets/honeylogo.png';
// import { LoginHelper } from '../Helper';
// import { Loader } from '../Components/LoaderButton';
import {useSnackbar} from 'notistack';
import {ShoppingCart} from '@material-ui/icons';
import {PersonalDetailsForCheckout} from './Shipping';
import {OrderSummary} from './OrderSummary';
import {createBrowserHistory} from 'history';
import {API, getval} from '../../Helper';
import { CartListForQuote } from './../../Components/CartListForQuote';
import { AllQuotesTable } from './../Offer/AllQuotesTable';
import { QuoteForm } from './../../Quote/QuoteForm';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: '#fbfbfb',
            },
            footer: {
                padding: theme.spacing(3, 2),
                marginTop: 'auto',
                backgroundColor:
                    theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
            },
            listStyle: {
                display: 'flex',
                flexDirection: 'row',
            },
            box: {
                backgroundColor: '#fbfbfb',
                paddingTop: '2%',
                paddingBottom: '2%',
                marginTop: '14%',
            },
// root: {
//   marginTop: '5%',
            //   height: '10vh',
            //   marginBottom: '20%',
            // },
            PopupBtnStyle: {
                fontFamily: 'sophia-regular',
                fontWeight: 1000,
                fontSize: 14,
                color: '#ffffff',
                padding: 10,
                width: '100%',
                backgroundColor: '#f28f16',
                '&.MuiButton-contained': {
                    '&:hover': {
                        backgroundColor: '#f28f16',
                        color: '#ffffff',
                        boxShadow: 'none',
                        textDecoration: 'none',
                    },
                },
            },
            btnStyle: {
                fontFamily: 'sophia-regular',
                fontWeight: 1000,
                fontSize: 14,
                color: '#ffffff',
                padding: 10,
                backgroundColor: '#f28f16',
                '&.MuiButton-contained': {
                    '&:hover': {
                        backgroundColor: '#f28f16',
                        color: '#ffffff',
                        boxShadow: 'none',
                        textDecoration: 'none',
                    },
                },
            },
            topContainer: {
                paddingTop: '2%',
                paddingBottom: '2%',
            },
            cart: {
                marginLeft: 90,
                [theme.breakpoints.down('sm')]: {
                    marginLeft: 0,
                },
            },
        }),
);
export const Checkout = () => {


    const [quote , setQuote] = useState({});

    const getData = async() => {

        var id = window.location.pathname.split('/')[4];

        var off = await API.get('offer', { offerid: id });
        console.log(off.data);
        setQuote(off.data);
    }



    const [loading, setLoading] = useState(false);
    const [offer, setOffer] = useState({});
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    useEffect(() => {
        API.get('offer', {offerid: window.location.pathname.split('/')[2]}).then(x => {
            setOffer(x.data);
        })
    }, []);
    if (!getval(offer, 'id')) {
        return<Container maxWidth="sm"><Grid container justify="center" alignItems="center" style={{padding: '50%'}}><Grid item lg={5}></Grid><Grid item lg={2}><CircularProgress style={{color: '#f28f16'}}/></Grid><Grid item lg={5}></Grid></Grid> 
        </Container>
    }
    return (
        <div className={classes.root}>
            <Box style={{backgroundColor: '#fff',}}>
                <Container maxWidth="lg" className={classes.topContainer}>
                    <Grid container direction="row"
                          justify="flex-start"
                          alignItems="flex-start"
                    >
                        <Grid item lg={3}>
                            <Link href="/">
                            <img src={honeylogo} alt="image" onClick={()=>{
                                createBrowserHistory().push('/');
                                window.location.reload();
                            }}/>
                            </Link>
                        </Grid>
                        <Grid item lg={7} xs={2}></Grid>
                        
                        {/* <Grid item lg={1}>
                            <Typography align="right" variant="subtitle2">Checkout</Typography>
                        </Grid> */}
                    </Grid>
                </Container>
            </Box>
            <Box style={{backgroundColor: '#fff', padding: '1%', borderTop: '1px solid #efefef', marginBottom: '3%'}}>
                <Container>
                    <Grid container>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography color="textPrimary" variant="body1">Shipping</Typography>
                            <Link color="inherit" href="/getting-started/installation/">
                                <Typography variant="body2">Payment</Typography>
                            </Link>
                            <Link color="inherit" href="/getting-started/installation/">
                                <Typography variant="body2">Review</Typography>
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Container>
            </Box>
            <Container maxWidth="lg">
                <Grid container spacing={2}>
                    {/* <Grid item lg={7}>
                        <PersonalDetailsForCheckout offer={offer}/>
                    </Grid> */}
                    
                    <Grid item lg={12}>
                        <OrderSummary offer={offer}/>
                    </Grid>

                  
                </Grid>
            </Container>
        </div>
    )
}