import {autorun, observable} from 'mobx';

export enum NotificationStatus {
    seen, new
}

export class Notification {
    status?: NotificationStatus;

    text?: string;

    header?: string;
}

export class AppState {
    @observable
    unreadMessages: any[] = [];

    @observable
    userName: String = 'name';

    @observable
    currentChat?: any;

    @observable
    messages: any[] = [];

    @observable
    notifications : any[] = [];

    @observable
    currentOffer?: any;

    @observable
    selectedItems:any[] =[];

    @observable
    localCart : any = {items : 0 , data :[]};
}

autorun(() => {
    console.log(GlobalState);
});

export const GlobalState = new AppState();
window['store'] = GlobalState;
