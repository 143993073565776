import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { MainImage } from './MainImage';
import { ApiIntroduction } from './ApiIntroduction';
import Video from './Video';
import { ImageSections } from './ImageSections';
import { EmptyBox } from './EmptyBox';
import { Hidden } from '@material-ui/core';
import { MobileHeader } from './../Components/MobileHeader';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
root: {
  
// marginTop: -16,
overflowX: 'hidden',
overflowY: 'hidden',
[theme.breakpoints.up('xl')]: {
  marginTop: -16,
},
},
   
  }),
);



export const ApiariesLayout = () => {
    const classes = useStyles();

    return(

        <div className={classes.root}>
             <Hidden only={['xl', 'lg', 'md']}>
  <MobileHeader />
</Hidden>
       <MainImage />
       <ApiIntroduction />
       {/* <EmptyBox /> */}
       <Video />
       <ImageSections />
     
        </div>
    )
}