import React from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, CardContent, List, ListItem, Button, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import circle1 from './../assets/circle1.png';
import circle2 from './../assets/circle2.png';
import circle3 from './../assets/circle3.png';
import circle4 from './../assets/circle4.png';
import filledicon from './../assets/filledicon.png';
import filledicon4 from './../assets/filledicon4.png';
import filledicon3 from './../assets/filledicon3.png';
import filledicon2 from './../assets/filledicon2.png';
import { ArrowRightAlt } from '@material-ui/icons';
// import { MyButton } from './../Styles/MyButton';



const useStyles = makeStyles({

    actionArea: {
        
      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },

    btnStyle: {

      padding: 15,
    },


    root: {
      maxWidth: 460,
      boxShadow: 'none',


      '&.MuiCardMedia-root': {
          '&:hover': {
              marginTop: '-10',
          },
      },

    },
    media: {
      height: 125,
   
      
    },

    headingStyleMain: {
      fontFamily: 'sophia-regular',
      fontSize: 42,
      fontWeight: 600,
      color: '#3d3a3a',
      paddingBottom: '5%',
      paddingTop: '5%',
  },
  
    imgStyle: {
  
      maxWidth: 80,
  
     
  
    },

    headingStyleMain5: {
      fontFamily: 'sophia',
      fontSize: '1.9rem',
      color: '#303030',

  },
  headingStyleMain2: {
      fontFamily: 'sophia',
      fontSize: 22,
      color: '#3d3a3a',
  },

    mainHeading: {
      fontFamily: 'sophia-regular',
      color: '#303030',
      fontSize: '1.25rem',
      marginTop: '-8%',
      marginLeft: '-2%',
      paddingBottom: 15,
      minWidth: 180,

     

  },


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: 16,
      color: '#726f6f',

  },
  
  
  });
  




export const FourPoints = () => {
    const classes = useStyles();

    return(
        <div>
        <Container maxWidth="lg">
        <Grid container
         spacing={2}
         direction="row"
         
         justify="center"
         alignItems="center"
         style={{marginBottom: '1%', marginTop: '2%'}}
         >

{/* <Grid item xs={12} lg={12} style={{paddingTop: '4%', paddingBottom: '6%'}}>
  <br />
                        <Typography className={classes.headingStyleMain5} variant="h3">How to purchase at AHV</Typography><br/>
                      
                    </Grid> */}



        <Grid item lg={2} sm={6} xs={12} md={6}>
        <Card className={classes.root}>
      <CardActionArea className={classes.actionArea}>
        
        <CardMedia
          className={classes.media}>
              
              <img src={filledicon} alt="services" className={classes.imgStyle} />
          </CardMedia>
        <CardContent>
           
           
          <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
          Select item
          </Typography>
       
        </CardContent>
      </CardActionArea>
    </Card>

        </Grid>


        <Hidden only={['sm', 'md', 'xs']}>
        <Grid item lg={1} style={{marginTop: '-7%'}}>
      

          <ArrowRightAlt style={{fontSize: 40, color: '#FBB84D'}} />

       
        </Grid>

        </Hidden>

        <Grid item lg={2} sm={6} xs={12} md={6}>
        <Card className={classes.root}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          className={classes.media}>
              <img src={filledicon2} alt="services" className={classes.imgStyle} />
          </CardMedia>
        <CardContent>
        
          <Typography className={classes.mainHeading} align="center"  variant="h5" >
          Request a quote
          </Typography>
     
        </CardContent>
      </CardActionArea>
    </Card>
        </Grid>
      

        <Hidden only={['sm', 'md', 'xs']}>
        <Grid item lg={1} style={{marginTop: '-7%'}}>
      
          <ArrowRightAlt style={{fontSize: 40, color: '#FBB84D'}} />
        
        </Grid>
        </Hidden>



        <Grid item lg={2} sm={6} xs={12} md={6}>
        <Card className={classes.root}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          className={classes.media}>
              <img src={filledicon3} alt="services" className={classes.imgStyle} />
          </CardMedia>
        <CardContent>
           
       
          <Typography className={classes.mainHeading} align="center" variant="h5">
          Agree on the deal
          </Typography>
       
        </CardContent>
      </CardActionArea>
    </Card>

        </Grid>


        <Hidden only={['sm', 'md', 'xs']}>
        <Grid item lg={1} style={{marginTop: '-7%'}}>
        
          <ArrowRightAlt style={{fontSize: 40, color: '#FBB84D',}} />
         
        </Grid>
        </Hidden>

        <Grid item sm={6} lg={2} xs={12} md={6}>
        <Card className={classes.root}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          className={classes.media}>
              <img src={filledicon4} alt="services" className={classes.imgStyle} />
          </CardMedia>
        <CardContent>
        
          <Typography className={classes.mainHeading} align="center" variant="h5">
          Place order
          </Typography>
       
        </CardContent>
      </CardActionArea>
    </Card>
        </Grid>

           <Grid item xs={12} style={{paddingTop: '5%', paddingBottom: '-2%'}}>
                        <Typography className={classes.headingStyleMain5} align="center" variant="h3">Our Products</Typography>
                      
                       
                    </Grid>
     

        </Grid>

       
        </Container>
        </div>
    )
}