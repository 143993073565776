import React from 'react';
import {Box, Container, Grid, Typography} from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {ProductsRow} from './../Pages/ProductsRow';
import {AllWidgets} from './AllWidgets';
import {DrawerPanel} from '../Components/DrawerPanel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        breadcrumbBox: {
            paddingLeft: '5%',
            paddingRight: '5%',
            paddingTop: '1%',
            paddingBottom: '1%',
            backgroundColor: '#fbfbfb',
        },
        headingStyleMain2: {
            fontFamily: 'sophia',
            fontSize: 22,
            color: '#3d3a3a',
        },
        productContainer: {
            paddingTop: '5%',
            paddingBottom: '5%',
        },
        card: {
            boxShadow: 'none',
        },
        headingStyleMain: {
            fontFamily: 'sophia',
            fontSize: 28,
            color: '#3d3a3a',
        },
    }),
);
export const AllProducts = () => {
    const classes = useStyles();
    return (
        <div>
            <Box className={classes.breadcrumbBox}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/">
                        <Typography variant="body2">Home</Typography>
                    </Link>
                    <Link color="inherit" href="/getting-started/installation/">
                        <Typography variant="body2">Products</Typography>
                    </Link>
                    <Typography color="textPrimary" variant="subtitle2">Single Product</Typography>
                </Breadcrumbs>
            </Box>
            <Container maxWidth="lg" className={classes.productContainer}>
                <Grid container spacing={6}>
                    <Grid item lg={4} xs={12}>
                        <Typography align="left" className={classes.headingStyleMain}
                                    variant="h6">Categories</Typography><br/>
                        <AllWidgets/>
                    </Grid>
                    <Grid item lg={8} xs={12}>
                        <Typography align="left" className={classes.headingStyleMain} variant="h6">All
                            Products</Typography><br/>
                        <ProductsRow/>
                    </Grid>
                </Grid>
                <DrawerPanel/>
            </Container>
        </div>
    )
}