import React from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, CardContent, Hidden, Button, List, ListItem, ListItemAvatar, Link, Avatar, ListItemText } from '@material-ui/core';
import { Image, Work, BeachAccess } from '@material-ui/icons';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { MyButton } from './../Styles/MyButton';
import branding2 from './../assets/branding2.png';
import { API, AppEvent, Bbc, LoginHelper, Ways, Cook } from './../Helper';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    listStyle: {
display: 'flex',
flexDirection: 'row',
width: '100%',

[theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },




    },

    actionArea: {

        maxWidth: 450,
        
      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },
    root: {
      
      boxShadow: 'none',
      width: 510,
      height: 409,

      
    },
    media: {
      
      width: 514,
      height: 409,
      
    },
  
    imgStyle: {
  
      maxWidth: 120,
     
  
    },

    mainHeading: {
      fontFamily: 'sophia',
      color: '#303030',
      fontSize: 22,
  

  },


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: '1.25rem',
      color: '#726f6f',
      fontWeight: 500,

  },
  link: {

    '&.MuiLink-underlineHover': {
      color: '#f28f16 !important',
    },
  
  },
  
  

    vector: {

        width: 514,
        height: 409,

        [theme.breakpoints.down('sm')]: {
            width: 350,
            height: 340,
          },
    }
  
}),
);




export const HoneyInformation = () => {
    const classes = useStyles();

    return(
        <div style={{paddingTop: '5%'}}>
        <Container maxWidth="lg">
        <Grid container
        
         direction="row"
         justify="center"
         alignItems="center"
         style={{marginTop: '5%'}}
         >
           

       

        <Grid item lg={4} sm={12} xs={12}>

          <Hidden only={['sm', 'xs']}>
        <Card className={classes.root}>
     
        <CardMedia
          >
              <img src={branding2} className={classes.vector} alt="services" width="514" height="409" />
          </CardMedia>

    </Card>

    </Hidden>


    <Hidden only={['md', 'lg', 'xl']}>
        <Card style={{boxShadow: 'none',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: 'transparent',
      paddingBottom: '20%'}}>
     
        <CardMedia
          >
              <img src={branding2} className={classes.vector} alt="services" width="514" height="409" />
          </CardMedia>

    </Card>

    </Hidden>
        </Grid>

        <Grid item lg={2}></Grid>

        <Grid item lg={4} sm={12} xs={12}>
       
           
        <Typography className={classes.mainHeading} style={{fontSize: '1.9rem'}} align="left" gutterBottom variant="h3" component="h2">
        Getting set-up as a stockist is easy
       </Typography>
       <br /><br />
       <Typography className={classes.mainHeading} align="left" gutterBottom variant="h5" component="h2">
       Sign-up for buyer account
       </Typography>
       
       <Typography className={classes.descriptionStyle} align="justify" variant="subtitle2" color="textSecondary" component="p">
         {
             (!LoginHelper.isCookiePresent())
             ?


         <div>
       Register your details by clicking <Link className={classes.link}  onClick={async () => {
                              
                              Bbc.fire(AppEvent.requestLoginDialog,
                              );
                        
                         
                      }}>Here
                        </Link>
                      
       </div>

       :

       <div>
       Register your details by clicking <Link className={classes.link} href="/registration">here</Link>
       </div>

         }
       </Typography>
       <br /><br />

       <Typography className={classes.mainHeading} align="left" gutterBottom variant="h5" component="h2">
       Submit for approval
       </Typography>
       
       <Typography className={classes.descriptionStyle} align="justify" variant="subtitle2" color="textSecondary" component="p">
       You will receive an email from us confirming your application has been received
       </Typography>
       <br /><br />

       <Typography className={classes.mainHeading} align="left" gutterBottom variant="h5" component="h2">
       AHV review & approval
       </Typography>
       
       <Typography className={classes.descriptionStyle} align="justify" variant="subtitle2" color="textSecondary" component="p">
       An AHV representative will be in touch within 48hrs to advise if you have been successful
       </Typography>

       <br /><br />

        <Typography className={classes.mainHeading} align="left" gutterBottom variant="h5" component="h2">
        Start buying
        </Typography>
        
        <Typography className={classes.descriptionStyle} align="justify" variant="subtitle2" color="textSecondary" component="p">
        Once approved, you can start requesting quotes  
        </Typography>
  

     </Grid>

     <Grid item lg={12}>
       <br /><br /><br />
     <Typography className={classes.descriptionStyle} align="justify" variant="subtitle2" color="textSecondary" component="p">
     We then list your store as a Stockist on our ever-expanding Stockists page. We will check in with you on a regular basis to see how AHV product sales are going in your store. If there is anything we can do to help you, please let us know. We love feedback from our Stockists about how we can enhance yours and your customers' experience with our goods.

        </Typography>
       
     </Grid>

     

        </Grid>



        

        
        </Container>
        </div>
    )
}