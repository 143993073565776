import {
    Badge, Button,
    CircularProgress,
    Grid,
    IconButton, List,
    List as MList,
    ListItem,
    ListItemAvatar, ListItemSecondaryAction,
    ListItemText,
    TextField,
    Typography, 
    Hidden, ListItemIcon
} from '@material-ui/core';
import InputAdornment from "@material-ui/core/InputAdornment";
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import profile from './../../assets/profile.jpg';
import userplace from './../../assets/userplace.png';
import adminplace from './../../assets/adminplace.png';
import { API, AppEvent, Bbc, createUUID, Socket, Ways } from '../../Helper';
import { Virtuoso, VirtuosoState } from 'react-virtuoso';
import { GlobalState } from '../../AppState';
import { observer } from 'mobx-react'
import { format } from 'date-fns';
import adminicon from './../../assets/adminicon.png';

import { Chat } from '@material-ui/icons';
// import socketIOClient from "socket.io-client";
const StyledBadge = withStyles((theme: Theme) =>
    createStyles({
        badge: {
            backgroundColor: '#44b700',
            color: '#44b700',
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: '$ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
            },
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0,
            },
        },
    }),
)(Badge);
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        firstGrid: {
            padding: 20,
            backgroundColor: '#f28f16',
            color: '#ffffff',
            borderTopLeftRadius: 12,
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
            marginLeft: 20,
        },
        msgNameText: {
            color: '#efefef',
            fontSize: 12,
            marginLeft: 70,
        },
        secondGrid: {
            padding: 20,
            backgroundColor: '#f28f16',
            color: '#ffffff',
            borderTopLeftRadius: 12,
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
            marginRight: 20,
        },
        thirdGrid: {
            marginTop: '2%',
        },
        sendButton: {
            color: '#ffffff',
            backgroundColor: '#f28f16',
        },
        msgBottomText: {
            marginLeft: '80%',
            marginTop: '-15%',
            fontSize: 10,
            color: '#efefef',
        },
        msgText: {
            fontSize: 12,
            borderTopRightRadius: 8,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            padding: 10,
            marginBottom: 2,
            marginTop: -10,
            backgroundColor: '#536883',
            color: '#ffffff',
            overflow: 'hidden',
            wordBreak: 'break-all',
            display: 'block',
            
        },
        inline: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },
        virtList: {},
        msgText2: {
            fontSize: 8,
            borderTopRightRadius: 8,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            padding: 10,
            marginBottom: -10,
            marginTop: -10,
            backgroundColor: '#efefef',
            color: '#000000',
            overflow: 'hidden',
            wordBreak: 'break-all',
            display: 'block',
        },
        secondList: {
            marginTop: '-20%',
        },
        container: {
            minHeight: 480,
            maxHeight: 480,
        },
        selectText: {
            fontSize: '1.25rem',
            color: '#303030',
            fontFamily: 'sophia-regular',
        },

        msgsComponent: {
            transform: 'scaleY(-1)',
             width: '100%',
              marginTop: '9%',
             
               maxHeight: 400,

               [theme.breakpoints.down('sm')]: {
                marginLeft: '35%',
            },
        },


        mobileListStyle: {
            [theme.breakpoints.down('xs')]: {
            maxWidth: '50%',
            },

        },

        msgGrid: {
            paddingLeft: '5%',

            [theme.breakpoints.down('sm')]: {
                marginLeft: '-14%',
                },

        },

        msgID: {
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.8rem',
                },
        },

        mobileMsgList: {

            width: '100%',

            [theme.breakpoints.down('md')]: {
                width: '70%',
                },

        },

        chatIcon: {

            [theme.breakpoints.down('sm')]: {
               paddingLeft: '45%'
                },
        },
    }),
);



export const Bar: FunctionComponent<{ quote: any }> = props => {
    const classes = useStyles();

    const [quote, setQuote] = useState({});

    const getData = async () => {
        var id = window.location.pathname.split('/')[4];
        var off = await API.get('offer', { offerid: id });
        console.log(off.data);
        setQuote(off.data);

    }


    useEffect(() => {
        getData();
    },[])


    const getStatusText = () => {
  
        if (props.quote['status'] == 'Pending' || props['quote']['status'] == 'await') {
            return "Your quote is awaiting AHV's response"
        } else if (props.quote['status'] == 'Expired') {
            return "Your quote is expired. Please request a new quote"
        } else if (props.quote['status'] == 'Quoted') {
            return "AHV has updated your quote. "
        } else if (props.quote['status'] == 'Ordered') {
            return "Order Has been placed"
        }
    }
    if (!props.quote['lastUpdated']) {
        return <div style={{
            width: '99.5%',
            borderTopLeftRadius: 3,
            borderBottomLeftRadius: 3,
            height: 50,
            marginBottom: '-125%',
        }}></div>
    }
    return (
        <List style={{
            backgroundColor: '#fce9d4',
            color: '#303030',
            width: '99.5%',
            borderTopLeftRadius: 3,
            borderBottomLeftRadius: 3,
            height: 50,
            marginTop :'-3.6%',
            marginBottom: '-100%',

        }}>
            <ListItem>
                <ListItemText>
                    <Typography variant="h6" className={classes.msgID}>
                        {props.quote['lastUpdated'] && 'HON-QUO-' + props.quote.rid +  ' Last Updated : '  + new Date(props.quote['lastUpdated']).toLocaleDateString()}
                    </Typography>
                </ListItemText>
                <ListItemSecondaryAction>
                    <Button color="inherit" variant="outlined" onClick={() => {
                        Ways.go((props.quote['status'] == 'Ordered' ? Ways.singleOrderPage : Ways.singleQuotepage) + '/' + props.quote['id'])
                    }}>View</Button>
                </ListItemSecondaryAction>
            </ListItem>
        </List>
    )
}
// let socket: SocketIOClient.Socket;
export const Messages = observer((props) => {
    const classes = useStyles({});
    const [sc, setSc] = useState({});
    const [cm, setCm] = useState('');
    const [page, setPage] = useState(0);
    const [totalMessages, setTotalMessages] = useState(0);
    const virt: React.RefObject<VirtuosoState> = useRef(null);
    useEffect(() => {
        // socket = socketIOClient(API.network, {});
        // socket.emit('clientConnected')
        // socket.on("fromServer", data => {
        //   console.log(data);
        // });
        Bbc.subscribe(AppEvent.messageAllSeen, async (data) => {
            if (sc['id'] == data['chat']) {
                var nList = GlobalState.messages;
                GlobalState.messages = await Promise.all(nList.map(x => {
                    if (x['sender'] == data['sender']['id'] && x['status'] == 'Sent') {
                        return { ...x, status: 'Seen' }
                    } else {
                        return x;
                    }
                }));
            }
        })
        Bbc.removeLitseners(AppEvent.newChatSelected)
        Bbc.subscribe(AppEvent.newChatSelected, async (newChat) => {
            GlobalState.messages = [];
            setSc(newChat);
            await API.get('pagedMessages', { chatid: newChat['id'] }).then(msgs => {
                var data = msgs.data['messages'];
                setTotalMessages(msgs.data['total']);
                GlobalState.messages = data;
                Socket.emit('messageAllSeen', { chat: newChat['id'], sender: newChat['owner'] });
                // setTimeout(() => {
                //   scrollToBottom();
                // }, 1)
            })
            Bbc.removeLitseners(AppEvent.newMessageRecieved)
            Bbc.subscribe(AppEvent.newMessageRecieved, x => {
                if (x['chat'] == newChat['id']) {
                    GlobalState.messages.unshift(x);
                    // setTimeout(() => {
                    //   scrollToBottom();
                    // }, 1)
                }
            })
        })
    })
    const scrollToBottom = () => {
        if (virt != null) {
            if (virt.current != null) {
                virt.current.scrollToIndex(GlobalState.messages.length);
            }
        }
    }
    const MessageRow: FunctionComponent<{ index: number }> = props => {
        const classes = useStyles({});
        var cm = GlobalState.messages[props.index];
        var isForeign = cm['sender'] == sc['partner']['id'];
        if (!isForeign && cm['status'] == 'Sent') {
            Socket.emit('messageSeen', { ...cm, status: 'Seen' });
            setTimeout(() => {
                GlobalState.unreadMessages = GlobalState.unreadMessages.filter(x => x['id'] != cm['id']);
                GlobalState.messages[props.index] = { ...cm, status: 'Seen' }
            }, 40)
        }
        return <Grid item lg={8} xs={12} key={cm['id']} className={classes.msgGrid}>
            <ListItem className={classes.mobileMsgList} style={{ marginLeft: isForeign ? '50%' : 0, overflow: 'hidden' }}
                alignItems="flex-start">
                <ListItemAvatar style={{ marginTop: '6.5%', display: isForeign ? 'none' : 'inline' }}>
                    <img src={adminicon} alt="Remy Sharp" width="30" height="30" />
                </ListItemAvatar>
                <ListItemText
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                            >
                            </Typography>
                            <Typography className={classes.msgText} style={{
                                backgroundColor: isForeign ? '#536883' : '#EFEFEF',
                                color: isForeign ? '#ffffff' : '#303030', marginTop: 18, borderTopLeftRadius: isForeign ? 8 : 0, borderTopRightRadius: isForeign ? 0 : 8,
                            }} variant="caption">{cm['text']}</Typography>
                            
                            
                            <Typography style={{ minWidth: 150, display: 'block', marginBottom: -17, }}
                                variant="caption" align="left">{format(new Date(cm['created']), "H:mm a")}</Typography>
                            {isForeign && <Typography style={{ width: 200, marginLeft: '80%', }} align="right"
                                variant="caption">{cm['status']}</Typography>}
                        </React.Fragment>
                    }
                />
                  <ListItemAvatar style={{ marginTop: '6.5%', paddingLeft: 22, display: !isForeign ? 'none' : 'inline'}}>
                    <img src={userplace} alt="Remy Sharp" width="30" height="30" />
                </ListItemAvatar>
               
            </ListItem>
            
        </Grid>
     



    }
    if (!sc['id']) {
        return <Grid container justify="center" direction="row" alignItems="center" style={{ paddingTop: '25%' }}>
            <MList>
                <ListItem style={{ paddingLeft: 140, }}>
                    <Typography align="center">
                        <Chat className={classes.chatIcon} style={{ fontSize: 140, color: '#edeaea' }} />
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography align="center" className={classes.selectText} variant="h2">Select a conversation thread to begin chatting</Typography>
                </ListItem>
            </MList>
        </Grid>
    }
    const ItemContainer = props => <div {...props} style={{ transform: 'rotate(180deg) scaleX(-1)' }}></div>;
    return (
        <div>
            <Grid container spacing={1} className={classes.container}>
                <Bar quote={sc['offer']} />
                <Grid item lg={12}>
                    <Virtuoso
                        ref={virt}
                        // msg box starts here
                       
                        className={classes.msgsComponent}
                        totalCount={GlobalState.messages.length}
                        item={index => <MessageRow index={index} />}
                        ItemContainer={ItemContainer}
                        footer={() => (
                            <div style={{ transform: 'rotate(180deg) scaleX(-1)', padding: '1rem', textAlign: 'center', }}>
                                {
                                    GlobalState.messages.length < totalMessages ? <CircularProgress style={{color: '#f28f16'}} /> :
                                        // <div>-- no more messages --</div>
                                        <div></div>
                                }
                            </div>
                        )}
                        endReached={async (endNumber) => {
                            if (GlobalState.messages.length < totalMessages) {
                                await API.get('pagedMessages', { chatid: sc['id'], offset: page + 1 }).then(msgs => {
                                    setPage(page + 1);
                                    GlobalState.messages = (msgs.data['messages'] as any[]).concat(GlobalState.messages);
                                    setTotalMessages(msgs.data['total'])
                                })
                            }
                        }}
                    />
                </Grid>
            </Grid>
            <Grid item lg={12} xs={12} sm={12} className={classes.thirdGrid}>
                <ListItem alignItems="flex-start">
                    <TextField
                        style={{ marginTop: '5%', }}
                        variant="outlined"
                        fullWidth
                        autoFocus
                        rows="6"
                        value={cm}
                        onChange={e => setCm(e['target']['value'])}
                        onKeyDown={e => {
                            var newid = createUUID();
                            if (e.key == 'Enter') {
                                Socket.emit("messageup", {
                                    id: newid,
                                    text: cm,
                                    chat: sc['id'],
                                    sender: sc['partner']['id'],
                                    senderEmail: sc['partner']['email'],
                                    reciever: sc['owner']['id'],
                                    recieverEmail: sc['owner']['email'],
                                    created: new Date(),
                                    status: 'Sent'
                                });
                                GlobalState.messages.unshift({
                                    id: newid,
                                    text: cm,
                                    chat: sc['id'],
                                    sender: sc['partner']['id'],
                                    senderEmail: sc['partner']['email'],
                                    reciever: sc['owner']['id'],
                                    recieverEmail: sc['owner']['email'],
                                    created: new Date(),
                                    status: 'Sent'
                                });
                                setCm('');
                            }
                        }}
                        label="start typing here..."
                        id="input-with-icon-textfield"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" style={{marginLeft: 15}}>
                                    <IconButton aria-label="send">
                                        <SendIcon style={{ color: '#536883', }} />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    /><br />
                </ListItem>
            </Grid>
        </div>
    )
})