import React, { FunctionComponent, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Paper, Tabs, Tab, Box, Typography, MenuList, ListItemIcon, List, ListItem, Grid, Button, ListItemAvatar, Avatar, Link, ListItemText, ListItemSecondaryAction, Divider, Badge } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import men from './../assets/men.png';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Assignment, Notifications, Mail,  PriorityHigh, Send } from '@material-ui/icons';
import { API, Cook, LoginHelper, Ways } from '../Helper';
import { GlobalState } from '../AppState';
import { observer } from 'mobx-react';
import { createBrowserHistory } from 'history';


interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,

       
        },


        fixedmsg: {

            position: 'fixed',
            top: '46%',
            width: 371,
            backgroundColor: '#fbfbfb',

            [theme.breakpoints.down('lg')]: {

                position: 'fixed',
                top: '55%',
                width: 371,
                backgroundColor: '#fbfbfb',
               
            },

            
        },

        fixedquote: {

            position: 'fixed',
            top: '46%',
            width: 371,
            backgroundColor: '#fbfbfb',

            [theme.breakpoints.down('lg')]: {

                position: 'fixed',
                top: '55%',
                width: 371,
                backgroundColor: '#fbfbfb',
               
            },
        },

        paperBox: {

            marginTop: '4.5%',

            [theme.breakpoints.down('md')]: {
                marginTop: '-9%',
                marginLeft: '5%',
            },
        },

        grouppaper: {

            boxShadow: 'none', maxWidth: 450, minWidth: 450,  maxHeight: 400, paddingBottom: 25,

            // [theme.breakpoints.down('lg')]: {
            //     boxShadow: 'none', maxWidth: 450, maxHeight: 325, paddingBottom: 25,
            // },
        },

        iconStyle: {

            color: '#b5b3b3',
            fontSize: '2.2rem',

        },

        iconStyle2: {

            color: '#b5b3b3',
            fontSize: '1.5rem',

        },

        customBadge: {
            backgroundColor: "#BF3604",
            color: "white",

        },
        menuButton: {
            marginRight: theme.spacing(0),
        },
        title: {
            flexGrow: 1,
        },
    }),
);

const MsgBadge = observer(() => {
    return <Badge color="secondary" badgeContent="2" >
        
    </Badge>
})


export const MyBadge:FunctionComponent<{count :number}> = props=> {
    const classes = useStyles()
return <Badge badgeContent={props.count} style={{ marginTop: 15, marginLeft: '-3%' }} classes={{ badge: classes.customBadge }}>{props.children}</Badge>
}








export const NotificationsComponent = () => {

    const classes = useStyles();
    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAuth(event.target.checked);
    };

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [value, setValue] = React.useState(0);

    const tabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };



   

    
   


   

   

    const NotificationPanel = observer(() => {

        if(GlobalState.notifications.length == 0){
            return <div style={{width: 370}}>
                <Grid
  container
  direction="row"
  justify="center"
  alignItems="center"
  style={{paddingLeft: 50, paddingRight: 50, paddingTop: 100, paddingBottom: 100}}
>

  <Grid item lg={12}>
    <Typography align="center">
  <Notifications style={{fontSize: 50, color: '#efefef'}} />
  </Typography>

  </Grid>

  <Grid item lg={12}>
  <Typography align="center" variant="body2">No new Notifications!</Typography>
    
    </Grid>

  </Grid>
                </div>
               
        }





        return <div><List dense style={{ display: 'flex', flexDirection: 'column', width:370, marginTop: 50, maxHeight: 400, paddingTop: -6,  }}>
                {GlobalState.notifications.map((x, i) => {
                    return <div>
                        <ListItem button onClick={() => {
                            createBrowserHistory().push(Ways.singleQuotepage + '/' + x.data);
                            window.location.reload();
                        }}>
                            <ListItemText primary={x['title']} secondary={x['description']} />

                        </ListItem>
                        
                        <Divider />
                    </div>
                })}

                

<div className={classes.fixedquote} >
<ListItem style={{width:370, padding: 15}} >
                           <ListItemText>
                               <Link href="#">
                               <Button onClick={async () => {
                                var res = await API.post('notificationsread', { user: Cook.getUserData()['user']['id'] });
                                GlobalState.notifications = [];
                                console.log(res.data);
                            }} size="small" variant="outlined">
                                Mark As Read
                                    </Button>
                               </Link>
                               
                           </ListItemText>

                           <ListItemSecondaryAction>
                               <Link href="/dashboard/quotes/">
                               <Button variant="outlined" size="small">
                                Go to Quotes
                                    </Button>
                               </Link>

                           </ListItemSecondaryAction>

                       </ListItem>
                       </div>
                    
            </List>
            {/* <List style={{   width: 387,
    
    
   
    backgroundColor: '#fbfbfb',
    position: 'fixed', top: '48.8%', borderBottom: '1px solid #aaa7a7'}}>
                       <ListItem>
                            <ListItemText>
                                <Link href="#">
                                    <Button size="small" variant="outlined">
                                    Mark As Read
                                    </Button>
                                </Link>
                            </ListItemText>

                            <ListItemSecondaryAction>
                                <Link href="/dashboard/quotes/">
                                    <Button variant="outlined" size="small">
                                    Go to Quotes
                                    </Button>
                                </Link>

                            </ListItemSecondaryAction>

                        </ListItem>
            </List> */}
            </div>
    })






    const MessagesPanel = observer(() => {

        if(GlobalState.unreadMessages.length == 0){
            return <div style={{width: 370}}>
                <Grid
  container
  direction="row"
  justify="center"
  alignItems="center"
  style={{paddingLeft: 50, paddingRight: 50, paddingTop: 100, paddingBottom: 100}}
>

  <Grid item lg={12}>
    <Typography align="center">
  <Mail style={{fontSize: 50, color: '#efefef'}} />
  </Typography>

  </Grid>

  <Grid item lg={12}>
  <Typography align="center" variant="body2">No new messages!</Typography>
    
    </Grid>

  </Grid>
                </div>
               
        }


        return <div> <List style={{width: 370, paddingTop: 70, paddingBottom: 90, maxHeight: 400}}>
                {GlobalState.unreadMessages.map((x, i) => {
                    return <div>
                        <ListItem button>
                                <ListItemIcon>
                                <ListItemAvatar>
                                    <img src={men} width="35" height="35" />
                                </ListItemAvatar>
                                </ListItemIcon>
                                <ListItemText primary="Mark" secondary={x['text']} />
                                    <ListItemSecondaryAction>
                                        <Typography variant="caption">12:45</Typography>
                                    </ListItemSecondaryAction>
                                    </ListItem>

                                    {/* <ListItem>
                        <ListItemText>
                            <Link href="#">
                                <Typography style={{ color: 'darkblue' }} variant="caption">Mark As Read</Typography>
                            </Link>
                        </ListItemText>
                        <ListItemSecondaryAction>
                            <Link href="#">
                                <Typography style={{ color: 'darkblue' }} variant="caption">View All</Typography>
                            </Link>
                        </ListItemSecondaryAction>

                    </ListItem> */}
                    <Divider />
                    </div>
                })}

<div className={classes.fixedmsg} >
<ListItem style={{width:370, padding:15}} >
                           <ListItemText>
                               <Link href="#">
                               <Button onClick={async ()=>{
                               await API.post('messagesread' , {user : Cook.getUserData()['user']['id']});
                               GlobalState.unreadMessages = [];
                            }} variant="outlined" size="small">
                                Mark As Read
                                    </Button>
                               </Link>
                               
                           </ListItemText>

                           <ListItemSecondaryAction>
                               <Link href="/dashboard/chats">
                               <Button variant="outlined" size="small">
                                Go to messages
                                    </Button>
                               </Link>

                           </ListItemSecondaryAction>

                       </ListItem>
                       </div>
                      
            </List>

{/* 
<List style={{   width: 387,
    
    
   
    backgroundColor: '#fbfbfb',
    position: 'fixed',  top: '48.8%', borderBottom: '1px solid #aaa7a7'}}>
                       <ListItem>
                            <ListItemText>
                                <Link href="#">
                                    <Button variant="outlined" size="small">
                                    Mark As Read
                                    </Button>
                                </Link>
                            </ListItemText>

                            <ListItemSecondaryAction>
                                <Link href="/dashboard/chats">
                                    <Button variant="outlined" size="small">
                                    Go to messages
                                    </Button>
                                </Link>

                            </ListItemSecondaryAction>

                        </ListItem>
            </List> */}
            </div>
    })















    

   


    const AllNotificationsButton = observer(() => {
        return <MyBadge count={GlobalState.unreadMessages.length + GlobalState.notifications.length}>
            <Notifications className={classes.iconStyle} />
        </MyBadge>
    })


    

    const MessageNotificationsButton = observer(() => {
        return <MyBadge count={GlobalState.unreadMessages.length }>
            <Mail className={classes.iconStyle2} />
        </MyBadge>
    })


    

    const NotificationNotificationsButton = observer(() => {
        return <MyBadge count={GlobalState.notifications.length}>
            <Notifications className={classes.iconStyle2} />
        </MyBadge>
    })


    useEffect(() => {
        if (Cook.getUserData() == null) return
        API.get('notifications', { userid: Cook.getUserData()['user']['id'] }).then(x => {
            GlobalState.notifications = x.data['notifications']
            GlobalState.unreadMessages = x.data['messages'];
            console.log(x.data);
        })
    }, [])



  

    if (Cook.getUserData() == null) return <div></div>

    return (
        <div className={classes.root}>

            <div>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                >
                    <AllNotificationsButton />
                </IconButton>

                <Menu
                    
                    className={classes.paperBox}
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                >
                    <Paper style={{ maxHeight: 400, minHeight: 400, maxWidth: 370, minWidth: 370}} >
                        <List style={{display: 'flex', flexDirection: 'row', marginBottom: '-8.7%', overflow: 'hidden', paddingTop: 0, paddingRight: 0,}}>
                           
                            <ListItem>
                                <Typography style={{position: 'fixed', zIndex: 2, paddingTop: 10}}>
                                    <NotificationNotificationsButton/>
                                    </Typography>
                            </ListItem>

                            <ListItem>

                            <Typography style={{position: 'fixed', zIndex: 2, paddingTop: 10 }}>
                                <MessageNotificationsButton/>
                                    </Typography>
                                </ListItem>
                            
                        </List>
                    
                        <Tabs
                            value={value}
                            onChange={tabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            style={{position: 'fixed',}}
                            // variant="scrollable"
                            // scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                           <Tab style={{paddingLeft: 55, paddingTop: 15}} label="Notifications" {...a11yProps(0)}></Tab>
                           
                            <Tab style={{paddingLeft: 70, paddingTop: 15 }} label="Messages" {...a11yProps(1)} ></Tab>
                                
                             
                           

                        </Tabs>
                        <div>
                        
                        <TabPanel value={value} index={0}>

    

                            <NotificationPanel />
                       
                           


                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            
                            <div>
                        <MessagesPanel />
                        </div>
                       
                             
                              
            
                        </TabPanel>

                        </div>
                    </Paper>
                </Menu>

            </div>


        </div>);
}