import React, {useState, useEffect} from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, CardContent, CircularProgress, Link,  List, ListItem, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import honeyservice1 from './../assets/honeyservice1.png';
import honeyservice2 from './../assets/honeyservice2.png';
import honeyservice3 from './../assets/honeyservice3.png';
import { RegisterYourInterest } from './../Components/RegisterYourInterest';
import { API, AppEvent, Bbc, LoginHelper, Ways, Cook } from './../Helper';

import { RegisterYourInterestUpdated } from './../Components/RegisterYourInterestUpdated';
// import { MyButton } from './../Styles/MyButton';



const useStyles = makeStyles({

    actionArea: {
        
      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },

    btnStyle: {
      padding: 15,
      fontFamily: 'sophia',
      width: 230,
      
    },


    root: {
      maxWidth: 350,
      boxShadow: 'none',

      
    },
    media: {
      height: 120,
      
    },
  
    imgStyle: {
  
      maxWidth: 120,
     
  
    },

    mainHeading: {
      fontFamily: 'sophia',
      color: '#303030',
      fontSize: 22,
  
      paddingBottom: 15,

  },


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: '1.25rem',
      fontWeight: 500,
      color: '#726f6f',

  },
  
  
  });
  




export const BuyerServices = () => {
    const classes = useStyles();


    const [data, setData] = useState<any[]>([]);
 

    

    useEffect(() => {
      // API.get('beekeepers').then(z => {
      //    console.log(z.data);
      //     setData(z.data);
      
      // })

      API.get('beekeeper', { beekeeper: 'd7afca1d-d499-45e0-a9a2-faa363c33c20' }).then(x => {
        console.log(x.data)
       setData(x.data);

     })


      },[])

  


  
    let id: number = 1
    const getID = () => {
      return id++;
    }
  
  








// useEffect(() => {
    
//       API.get('beekeeper', {beekeeper: window.location.pathname.split('/')[3]}).then(x => {
//         setData(x.data);
//         // console.log(x.data);
  
        
//     })
//   }, [])


//   let id: number =1
//   const getID = () => {
//     return id++;]
//   }



    return(
        <div style={{marginBottom: '1%', marginTop: '8%'}}>
        <Container fixed>
        <Grid container
         spacing={4}
         direction="row"
         justify="center"
         alignItems="center"
         
         >
           <Grid item lg={12}>
           <Typography className={classes.mainHeading} style={{fontSize: '1.9rem'}} align="center" variant="h5" component="h2">
          What are we looking for ?
          </Typography>
           </Grid>

        <Grid item lg={4} sm={6} xs={12}>
        <Card className={classes.root}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          className={classes.media}>
              <img src={honeyservice1} alt="services" className={classes.imgStyle} />
          </CardMedia>
        <CardContent>
      
           
          <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
          Established Retailers
          </Typography>
          <br />
          <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
          Attractive bricks and mortar and online stores to stock our<br /> products
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>

        </Grid>

        <Grid item lg={4} sm={6} xs={12}>
        <Card className={classes.root}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          className={classes.media}>
              <img src={honeyservice2} alt="services" className={classes.imgStyle} />
          </CardMedia>
        <CardContent>
    
          <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
          Health & Wellness Exposure
          </Typography>
          <br />
          <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
          Retailers that stock premium/gourmet health and wellness food products

          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
        </Grid>

        <Grid item lg={4} sm={6} xs={12}>
        <Card className={classes.root}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          className={classes.media}>
              <img src={honeyservice3} alt="services" className={classes.imgStyle} />
          </CardMedia>
        <CardContent>
        
          <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
          Sports Supplement Exposure
          </Typography>
          <br />
          <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
          Retailers that currently stock performance enhancing supplements
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
        </Grid>
     

        </Grid>

        <Grid container justify="center" style={{marginTop: '6%'}}>
        <Grid item lg={3} xs={12} sm={12} md={12}>
        
                  <Typography align="center">
         
            {/* <RegisterYourInterestUpdated currentBeekeeper={data} /> */}
            {
            (!LoginHelper.isCookiePresent())
                            ?

                            <div>       <Button className={classes.btnStyle}
                           
                         
                            variant="contained"
    
                        
                            fullWidth
                         
                            onClick={async () => {
                              
                                    Bbc.fire(AppEvent.requestLoginDialog,
                                    );
                              
                               
                            }}>
                                Register Your Interest
                           </Button></div>
                          
                        

                        :
<div>
            <Link href="/registration">
            <Button className={classes.btnStyle} variant="contained"
        >
        Register Your Interest
      </Button>
      </Link>

      </div>
}
            </Typography>
           
        </Grid>

        </Grid>
        </Container>
        </div>
    )
}