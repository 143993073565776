import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, createStyles, Grid, Hidden, Link, makeStyles, Slider, Theme, Typography } from '@material-ui/core'
import React from 'react'
import { LoginHelper, Bbc, AppEvent } from '../Helper'
import { Introduction } from '../LandingPage/Introduction'
import { LandingLayout } from '../LandingPage/LandingLayout'
import { MainSlide } from '../Seller/mainSlide'
import { DrawerPanel } from './DrawerPanel'
import { GlobalLogin } from './GlobalLogin'
import { MobileHeader } from './MobileHeader'
import { NewFooter } from './NewFooter'
import { NewHeader } from './NewHeader'
import sectionimg from './../assets/sectionimg.jpg';
import seniorimg from './../assets/senior-img-2.png';
import { useTimer } from 'react-timer-hook';

import { url } from 'inspector'


const useStyles = makeStyles((theme: Theme) =>
    createStyles({


        mainSlide: {

            marginTop: 0,
            [theme.breakpoints.up('xl')]: {
                marginTop: -16,
            },
        },

        actionArea: {

            '&.MuiCardActionArea-root': {

                textAlign: 'center',



            },

        },

        description: {
            fontFamily: 'sophia-regular',
            color: '#fff',

            fontSize: '1.3rem',


            [theme.breakpoints.down('sm')]: {
                fontSize: '0.9rem',
                color: '#fff',

            },

        },

        mainHeading: {

            fontFamily: 'sophia',
            color: '#fff',
            marginTop: '-24%',
            fontSize: 44,
            marginBottom: '35px',


            [theme.breakpoints.down('md')]: {
                fontSize: 30,
                marginTop: '-50%',

            },


            // [theme.breakpoints.down('sm')]: {
            //   fontSize: 16,
            // marginTop: '-48%',
            // color: '#303030',
            // },


        },

        slideHeading: {

            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',


            [theme.breakpoints.down('lg')]: {
                top: '50%',
                marginTop: '0%',
            },

            [theme.breakpoints.down('md')]: {
                position: 'absolute',
                top: '78%',
                left: '50%',
                marginTop: '-2%',


            },


            [theme.breakpoints.down('sm')]: {
                position: 'absolute',
                top: '78%',
                left: '50%',
                marginTop: '-2%',


            },


        },

        root: {


            maxHeight: 560,
            height: 'auto',
            maxWidth: '100%',
            margin: "auto",
            boxShadow: 'none',
            position: 'relative',





        },
        media: {
            objectFit: 'cover',
        },

        imgStyle: {

            maxWidth: 120,


        },

        btnStyle: {
            padding: 15,
            fontFamily: 'sophia',
            boxShadow: 'none',

            [theme.breakpoints.down('sm')]: {
                fontSize: 10,
                padding: 8,

            },
        },

        imggStyle: {
            width: 700,

            [theme.breakpoints.down('sm')]: {
                width: 400,

            },
        },

        contentDivContainer: {
            padding: '10px 10px',
            textAlign: 'center',
            marginTop: '5%',
            marginBottom: '5%',
            [theme.breakpoints.down('md')]: {
                marginTop: '0%',
                marginBottom: '5%'

            },
        },

        btnTwoStyle: {
            padding: 15,
            fontFamily: 'sophia',
            boxShadow: 'none',

            [theme.breakpoints.down('sm')]: {
                fontSize: 10,
                padding: 8,

            },
        },

        mainImage: {
            width: '100%',
            height: '100%',
            backgroundAttachment: 'fixed',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            paddingBottom: '3%',
            backgroundSize: 'cover',
            position: 'relative',
            textAlign: 'center',


            [theme.breakpoints.down('sm')]: {
                height: 300,
                width: '100%',

                backgroundAttachment: 'fixed',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                paddingBottom: '3%',
                backgroundSize: 'cover',
                position: 'relative',
                textAlign: 'center',

            },
        },

        teamImage: {
            width: '100%',
            height: '100%',
            // position: 'absolute',



            [theme.breakpoints.down('sm')]: {
                width: '310px',
                height: '100%'

            },
        },

        mainHeadingStyle: {
            fontFamily: 'sophia',
            color: '#303030',
            fontSize: 22,

            paddingBottom: 15,

        },


    }),
);




export const InvestWithUs = () => {
    const classes = useStyles();
    return (
        <div>

            <Hidden only={['xs', 'sm', 'md',]}>
                <NewHeader />
            </Hidden>
            <Hidden only={['lg', 'xl']}>
                <MobileHeader />
            </Hidden>







            <DrawerPanel />

            {/* ______ Main Slide _______  */}

            <div className={classes.mainSlide} >

                <Grid container

                    direction="row"
                    justify="center"
                    alignItems="center"

                >

                    <Grid item lg={12} sm={12} xs={12}>


                        <Card className={classes.root}>
                            <CardActionArea>

                                <CardMedia className={classes.media} >
                                    <img src={sectionimg} alt="bakcground" className={classes.mainImage} />
                                </CardMedia>

                                <CardContent className={classes.slideHeading} >
                                  
                                    <Typography className={classes.mainHeading} align="center" gutterBottom variant="h4" component="h2" >
                                    LAST CHANCE TO INVEST WITH US!

                                    </Typography>
                                    {/* <Typography className={classes.description} align="center" variant="subtitle2">AHV is an innovator in the Australian honey and bee product space,
                                        constantly creating new concepts to fill your store with newness and best-selling products.<br /><br />
                                        Are you a bricks and mortar or online store looking to stock our brands?</Typography> */}

                                    {/* <Typography style={{ marginTop: '3%', }} align="center" gutterBottom> */}

                                    {/* <RegisterYourInterest currentBeekeeper={data}  /> */}

                                    {/* {
                                            (!LoginHelper.isCookiePresent())
                                                ?

                                                <div>       <Button className={classes.btnStyle}


                                                    variant="contained"




                                                    onClick={async () => {

                                                        Bbc.fire(AppEvent.requestLoginDialog,
                                                        );


                                                    }}>
                                                    Register Your Interest
                                                </Button></div>



                                                :
                                                <div>
                                                    <Link href="/registration">
                                                        <Button className={classes.btnStyle} variant="contained"
                                                        >
                                                            Register Your Interest
                                                        </Button>
                                                    </Link>

                                                </div>
                                        }
                                    </Typography> */}


                                    <div>
                                        <img className={classes.teamImage} src={seniorimg} alt="senior-image" width="100%" height="100%" />
                                    </div>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>


                            </CardActions>
                        </Card>








                    </Grid>
                </Grid>

            </div>

            {/* ____ content _____ */}

            <div className={classes.contentDivContainer} >
                <Grid container spacing={2} >
         <Grid item xs={12} >
                    
                    <NewTimer/>
                   </Grid>
                    <Grid item xs={12} >
                        <Typography className={classes.mainHeadingStyle} style={{ fontSize: '1.9rem' }} align="center" variant="h5" component="h2" >
                        LAST CHANCE TO INVEST WITH US!

                        </Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography  >
                        Thank you to over 1000 of shareholders who’ve staked their claim in Australian Honey Ventures through our second capital raise. We now have 



                        </Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography  >
over 1000 Aussies choosing change with us!
                        </Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography  >
Don't miss your chance to stake your claim in a hungry market offers up to $40 million* of untapped opportunity in Western Australia alone.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography>
Choose change and invest in Australia's next powerhouse industry with AHV.
                            Now’s your chance join us and express your interest in being a part of Australia's next powerhouse industry.
                        </Typography>
                    </Grid>
                   
                    <Grid item xs={12} >
                        <div style={{ margin: '25px 0px' }} >
                            <Link href="https://www.birchal.com/company/ahv?fbclid=IwAR1IcG4x5_pV9zzJi1B5HQKPN2u2IkgHkbff594I1C9_Otry0ijrfH8BhoM">
                                <Button className={classes.btnTwoStyle} variant="contained"
                                >
BUY YOUR SHARES
                                </Button>
                            </Link>
                            <p style={{ fontSize: '14px', marginTop: '30px', fontStyle: 'italic' }} >
                                *Based on market value. <br />
                                Always consider the general CSF risk warning and offer document before investing.
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={12} >

                    </Grid>
                </Grid>
            </div>


            <NewFooter />
            {/* <Hidden only={['lg', 'xl']}>
        <BottomBar/>
        </Hidden> */}

            <GlobalLogin />
        </div>
    )
}


export const NewTimer = () => {
    const classes = useStyles();
    
    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
      } = useTimer({autoStart : true , expiryTimestamp : new Date(2022,2,2)});
    
    return <div>
                        <Typography className={classes.mainHeadingStyle} style={{ fontSize: '2.9rem' }} align="center" variant="h5" component="h2" >
                { days}d: {hours}h : {minutes}m : {seconds}s
                        </Typography>
    </div>
}