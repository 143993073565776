import React from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, CardContent, ListItemText, ListItemAvatar, Avatar,  ListItemIcon, Divider, List, ListItem, ListItemSecondaryAction } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Done } from '@material-ui/icons';
import { Image, Work, BeachAccess, Lock } from '@material-ui/icons';
import padlock from './../assets/padlock.png';
import box from './../assets/box.png';
import securepayment from './../assets/securepayment.png';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    actionArea: {

        maxWidth: 450,
        
      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },
    root: {
      
      boxShadow: 'none',
      width: 514,
      height: 409,

      
    },
    media: {
      
      width: 514,
      height: 409,
      
    },
  
    imgStyle: {
  
      maxWidth: 120,
     
  
    },

    
    mainHeading: {
      fontFamily: 'sophia',
      // color: '#303030',
      color: '#fff',
      fontSize: '1.9rem',
  
      paddingBottom: 15,

  },


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: '1.25rem',
      paddingLeft: '6%',
      fontWeight: 500,
      // color: '#726f6f',
      color: '#fff',
      [theme.breakpoints.down('sm')]: {
     fontSize: '1.2rem',
           },

  },

  lineStyle3: {
    fontFamily: 'sophia-regular',
    fontSize: '1rem',
    fontWeight: 500,
    color: '#fff',
    paddingLeft: '5%',
    paddingRight: '5%',

  },

  lineStyle: {

    fontFamily: 'sophia-regular',
    fontSize: '1.55rem',
    fontWeight: 500,
    paddingTop: '2%',

    // color: '#726f6f',
    color: '#fff',

    [theme.breakpoints.down('sm')]: {
   paddingTop: '18%',
   paddingBottom: '3%',
      },

  },

  lineStyle2: {

    fontFamily: 'sophia-regular',
    fontSize: '1.35rem',
    fontWeight: 500,
  

    // color: '#726f6f',
    color: '#fff',
  },


  mobListStyle: {

    maxWidth: '100%',
     paddingTop: '10%',
     display: 'flex',
     flexDirection: 'row',

     [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      },
  },


  lock: {

    [theme.breakpoints.up('xl')]: {
      paddingLeft: '8%',
      },

  },

  secondList: {

    [theme.breakpoints.down('sm')]: {
      marginTop: '-11%',
      },

  },
  
  
  
}),
);
  




export const Fees = () => {
    const classes = useStyles();

    return(
        <div style={{backgroundColor: '#FCB040', paddingTop: '7%', marginBottom: '5%'}}>
        <Container maxWidth="xl">
        <Grid container
         spacing={2}
         direction="row"
         justify="center"
         alignItems="center"
         
         
         >

        <Grid item lg={5} sm={12} xs={12}>
    
           
           
          <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
          Simple, transparent, secure
          </Typography>

        </Grid>

        </Grid>

      
        <Grid container
         spacing={2}
         direction="row"
         justify="center"
         alignItems="center"
         style={{ paddingLeft: '7%', paddingRight: '7%', borderBottom: '2px solid #fff', borderTop: '2px solid #fff'}}
         >
            
             <Grid item lg={5} >
                 <List style={{width: '100%', pointerEvents: 'none'}}>
                 <ListItem button>
          <ListItemIcon>
            <Done style={{color: '#fff'}} />
          </ListItemIcon>
          <ListItemText style={{minWidth: 250}}>
            <Typography className={classes.descriptionStyle} variant="body2">Get paid when delivering honey</Typography>
          </ListItemText>
       
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <Done style={{color: '#fff'}} />
          </ListItemIcon>
          <ListItemText>
            <Typography className={classes.descriptionStyle} variant="body2">Qualify for bonus distributions</Typography>
          </ListItemText>
         
        </ListItem>
                 </List>

             </Grid>

             <Grid item lg={5}>

             <List  style={{width: '100%', pointerEvents: 'none'}}>
                     <ListItem className={classes.secondList}>
                     <ListItemIcon>
                         <Done style={{color: '#fff'}} />

                     </ListItemIcon>
                      <ListItemText style={{minWidth: 250}}><Typography className={classes.descriptionStyle} variant="body2">Automatic deposits made to you AHV account</Typography></ListItemText>
                  
                     </ListItem>

                     <ListItem button>
          <ListItemIcon>
            <Done style={{color: '#fff'}} />
          </ListItemIcon>
          <ListItemText>
            <Typography className={classes.descriptionStyle} variant="body2">No hidden fees</Typography>
          </ListItemText>
    
        </ListItem>
                 </List>

                 
                 
                 </Grid>

                
             </Grid>

             <Grid container justify="center" alignItems="center">
               <Grid item lg={12}>
               <Typography className={classes.lineStyle} align="center" variant="h5"><b>We will pack, market, sell and distribute your honey and share the profits with you.</b></Typography>
               </Grid>
               <Grid item lg={12}>
                 <List style={{paddingLeft: '20.5%', paddingTop: '2%'}} >
          <ListItem className={classes.lock}>
            <ListItemIcon>
<img src={padlock} alt="icon" width="35" height="35" />
            </ListItemIcon>
            <ListItemText>
          <Typography className={classes.lineStyle2} variant="body2">Beekeepers receive payments directly through our SSL encrypted trading platform.</Typography>
          </ListItemText>
          </ListItem>
          </List>
    

               </Grid>

               {/* <Grid item lg={12}>
                 <br />
               <Typography className={classes.lineStyle3} variant="body2" align="center">Commission fees are all-inclusive and work out to be approx 50-60% of the value of each executed sale. For example,
         a TA10 blend of honey sold at an agreed packaged rate of $48/kg will return up to $24/kg to the beekeeper once the commission fee has been deducted.</Typography>
               </Grid> */}
             </Grid>

            <Grid container justify="center">
             <Grid item lg={9} xs={12} sm={12}>
                 <List className={classes.mobListStyle} >
     

    
    
    </List>


    {/* <List style={{maxWidth: 660, paddingTop: '5%', marginLeft: '10%'}}>
      <ListItem>
        <ListItemAvatar style={{marginRight: '3%'}}>
          
            <img src={padlock} width=" 50" height="50" alt="icon" />
        
        </ListItemAvatar>
        <ListItemText>
          <Typography className={classes.descriptionStyle} variant="body2">Commission fees are all-inclusive and work out to be approx 50-60% of the value of each executed sale. For example,
         a TA10 blend of honey sold at an agreed packaged rate of $48/kg will return up to $24/kg to the beekeeper once the commission fee has been deducted.</Typography>
        </ListItemText>
      
      </ListItem>
    
    
    </List> */}
                 </Grid>

                 
                 </Grid>


    






        </Container>


        </div>
    )
}