import React from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, CardContent, CardActions, Button, Hidden,  List, ListItem } from '@material-ui/core';
import { fade, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import mainimage from './../assets/mainimage.png';
import homepagedesign from './../assets/homepagedesign.jpg';
// import { MyButton } from './../Styles/MyButton';




const useStyles = makeStyles((theme: Theme) =>
  createStyles({


    mainSlide: {

      marginTop: -17,
      [theme.breakpoints.up('xl')]: {
        marginTop: -16,
      },
    },

    actionArea: {
        
      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },

    mainHeading: {

      fontFamily: 'sophia',
      color: '#fff',
      marginTop: '-26%',
      fontSize: 44,

      [theme.breakpoints.down('md')]: {
        fontSize: 20,
       marginTop: '-43%',
      },

      [theme.breakpoints.down('sm')]: {
        fontSize: '1.1rem',
      marginTop: 20,
      },


    },

    slideHeading: {

      position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  [theme.breakpoints.down('sm')]: {
  left: '50%',
  },
     

    },
     
    root: {
      
    
      maxHeight: 560,
      height: 'auto',
       maxWidth: '100%',
       margin: "auto",
      boxShadow : 'none',
      position: 'relative',

      
    },
    media: {
        objectFit: 'cover',
      
    },
  
    imgStyle: {
  
      maxWidth: 120,
     
  
    },

    btnStyle: {
      padding: 15,
      fontFamily: 'sophia',
      boxShadow: 'none',

      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
        padding: 8,
     
      },
    },

    mainImage: {
      width: '100%',
      height: '100%',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      paddingBottom: '3%',
      backgroundSize: 'cover',
      position: 'relative',
      textAlign: 'center',
    },
  
  
  }),
  );
  




export const MainSlide = () => {
    const classes = useStyles();

    return(
        <div className={classes.mainSlide} >
        
        <Grid container
         
         direction="row"
         justify="center"
         alignItems="center"
         
         >

        <Grid item lg={12} sm={12} xs={12}>
        <Card className={classes.root}>
      <CardActionArea>
        <CardMedia className={classes.media}>
            <img src={homepagedesign} alt="bakcground" className={classes.mainImage} />
        </CardMedia>
        <CardContent className={classes.slideHeading} >
          <Hidden only={['sm', 'xs']}>
          <Typography className={classes.mainHeading}  align="center" gutterBottom variant="h4" component="h2">
          We find the buyers. You get a higher price.
          </Typography>
          </Hidden>

          <Hidden only={['md', 'lg', 'xl']}>
          <Typography className={classes.mainHeading} style={{minWidth: 250}} align="center" gutterBottom variant="h4" component="h2">
          We find the buyers. You get a higher price.
          </Typography>
          </Hidden>

          <Typography  style={{marginTop: '3%',}} align="center" gutterBottom>
          <Button className={classes.btnStyle} size="small" variant="contained">
          Register Your Interest
        </Button>
          </Typography>
        
        </CardContent>
      </CardActionArea>
      <CardActions>
      
      
      </CardActions>
    </Card>
       

        </Grid>
        </Grid>
    
        </div>
    )
}