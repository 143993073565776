import React, { useEffect, useState } from 'react';
import {
    Grid, Container, Link, IconButton, Badge, List, ListItem, ListItemText, TextField, Typography, MenuList, MenuItem, ListItemAvatar, ListItemSecondaryAction,
    Divider, Avatar, Popper, Grow, Paper, ClickAwayListener, AppBar, Toolbar, Button,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import updatedlogo from './../assets/updatedlogo.png';
import { fade, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { Search, Mail, Notifications, AccountCircle, Favorite, ShoppingCart } from '@material-ui/icons';
import { BrandMenus } from './BrandMenues';
import { HoverMenu } from './HoverMenu';
import men from './../assets/men.png';
import { withRouter } from 'react-router-dom';
import { ExpansionBrands } from './../Components/ExpansionBrands';
import productthumn from './../assets/productthumn.jpg';
import { API, LoginHelper, Cook, Ways } from '../Helper';
import { GlobalState } from '../AppState';
import { observer } from 'mobx-react';
import { NotificationsComponent } from './../Components/NotificationComponent';
import { ShoppingBasket } from '@material-ui/icons';



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
        },

        appBar: {

            backgroundColor: 'transparent',
            color: '#303030',
            boxShadow: 'none',
            marginLeft: -40,
            marginTop: -20,


            [theme.breakpoints.down('lg')]: {
                marginTop: -5,
            },


            '&.MuiButton-contained': {

                boxShadow: 'none',
                backgroundColor: '#000',
            },
        },


        basketPaper: {


            boxShadow: 'none',
            border: '1px solid #d3d1d1',
            marginTop: '-12%',
            marginLeft: '-25%',
            maxWidth: 360,
            minWidth: 260,




        },


        searchIconStyle: {


            color: '#fff',
            // border: '1px solid #fcb040',
            zIndex: 1,
        },


        textField: {





            '&.MuiFormLabel-root': {
                transform: 'translate(17px, 20px) scale(1)',
            },

        },


        listBtn: {
            backgroundColor: '#f28f16',
            color: '#fff',
            boxShadow: 'none',
            fontSize: 16,
            padding: 8,

            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#f28f16',
                    boxShadow: 'none',
                },
            }
        },

        iconBtn: {

            backgroundColor: '#fcb040',
            borderTopRightRadius: 6,
            borderBottomRightRadius: 6,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            border: '2px solid #fcb040',
            marginRight: '-3%',
            zIndex: 1,




            '&:hover': {

                backgroundColor: '#fcb040',
                borderRadius: 6,
                marginRight: '-3%',
                color: '#fff',


            },
        },

        listStyle2: {

            display: 'flex',
            flexDirection: 'row',
            width: '80%',
            alignItems: 'center',


            '&.MuiListItem-button': {

                backgroundColor: '#fbfbfb',
            },



        },




        listStyle: {
            display: 'flex',
            flexDirection: 'row',
            maxWidth: 200,


            marginLeft: '2%',
        },

        mainContainer: {

            // paddingTop: 24,


        },

        secondContainer: {

            backgroundColor: '#fbfbfb',
        },

        CardStyle: {
            maxHeight: 400,
        },

        topBar: {

            backgroundColor: '#000',
            height: 34,
        },

        iconStyle: {

            color: '#b5b3b3',
            fontSize: '2.2rem',
        },

        btnStyle: {

            backgroundColor: 'transparent',
            fontFamily: 'sophia-light',
            lineHeight: 2.0,
            color: '#565252',
            fontSize: '1.0rem',
            letterSpacing: 0.8,
            fontWeight: 600,
            boxShadow: 'none',
            textTransform: 'capitalize',

            '&:hover': {

                backgroundColor: 'transparent',
                color: '#fcb040',
                boxShadow: 'none',


            },


        },

        customBadge: {
            backgroundColor: "#BF3604",
            color: "white"
        },

        logoGrid: {

            textAlign: 'initial',
        },
        msgHeading: {
            fontFamily: 'sophia-regular',
            fontWeight: 1000,
            fontSize: '1rem',
        },

        priceItem: {
            paddingTop: 20,
            paddingBottom: 20,
        },



    }),
);




export const NewHeader = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        if (LoginHelper.isCookiePresent()) {
            var user = { user: Cook.getUserData()['user']['id'] }
            API.get('cart/items', user).then(x => {
                GlobalState.localCart = x.data;
            });

        }

    }, [])

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }




    const CartBadge = observer(() => {
        return <Badge badgeContent={GlobalState.localCart['items']} classes={{ badge: classes.customBadge }}>
            <ShoppingBasket className={classes.iconStyle} />
        </Badge>
    })


    const CartItems = observer(() => {

        var d = GlobalState.localCart['data'] as [];

        if (GlobalState.localCart['data'] == 0) {
            return <div style={{ width: 386 }}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ padding: 30 }}
                >

                    <Grid item lg={12}>
                        <Typography align="center">
                            <ShoppingBasket style={{ fontSize: 50, color: '#efefef' }} />
                        </Typography>

                    </Grid>

                    <Grid item lg={12}>
                        <Typography align="center" variant="body2">Empty Basket!</Typography>

                    </Grid>

                </Grid>
            </div>
        }

        return <div>
            {
                (d ?? []).map((x, i) => {
                    return <div>
                        <MenuItem onClick={handleClose}>
                            <List dense={true} style={{
                                width: '100%',
                                minWidth: 230,
                                // maxWidth: 270
                            }}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <img src={x['product']['primaryImage']} width="35" height="35" />

                                    </ListItemAvatar>
                                    <ListItemText primary={x['product']['productTitle']}
                                        secondary={"Quantity: " + x['quantity']} />
                                    <ListItemSecondaryAction style={{ marginTop: '3%', color: '#a5a2a2' }}><Typography
                                        variant="caption">Size: {x['variation']['weight']} g</Typography></ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </MenuItem>
                        <Divider />
                    </div>
                })
            }
        </div>
    })

    return (

        <div style={{ marginBottom: '-70' }} >
            <div className={classes.topBar}></div>
            <Container maxWidth="lg" className={classes.mainContainer}>
                <Grid container justify="center" alignItems="center" >
                    <Grid item lg={4} className={classes.logoGrid}>
                        <Link href="/">
                            <img src={updatedlogo} alt="logo-image" />
                        </Link>

                    </Grid>
                    <Grid item lg={5}>


                        <TextField id="outlined-search" className={classes.textField} fullWidth size="small" label="Search AHV Products..." type="search" variant="filled"
                            InputProps={{
                                endAdornment: <IconButton className="searchicon"><Search className={classes.searchIconStyle} /></IconButton>
                            }}

                        />
                    </Grid>

                    <Grid item lg={3} className="iconsGrid">
                        <List className={classes.listStyle}>
                            <ListItem style={{ marginRight: '-10%', marginTop: '3%' }}>
                                <HoverMenu />


                            </ListItem>



                            {/* <ListItem>
                                <Link href="dashboard/wishlist">
                                    <IconButton aria-label="show 17 new notifications" color="inherit">
                                
                                        <Favorite className={classes.iconStyle} />
                                       
                                    </IconButton>
                                </Link>


                            </ListItem> */}






                            <ListItem>

                                <IconButton ref={anchorRef}
                                    aria-controls={open ? 'menu-list-grow' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggle}
                                    disableRipple={true}
                                >

                                    <CartBadge />

                                </IconButton>



                                {/* For Larger Screens */}
                                <Popper style={{ zIndex: 1 }} open={open} anchorEl={anchorRef.current} role={undefined} transition
                                    disablePortal>
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                        >
                                            <Paper className={classes.basketPaper} >
                                                <ClickAwayListener onClickAway={handleClose}>

                                                    <MenuList autoFocusItem={open} id="menu-list-grow"
                                                        onKeyDown={handleListKeyDown}>
                                                        <List style={{ paddingBottom: 0 }}>
                                                            <Typography className={classes.msgHeading} style={{ paddingLeft: 20, paddingTop: 8, paddingBottom: 8 }}>
                                                                Quote Basket</Typography>
                                                        </List>

                                                        <Divider />
                                                        <CartItems />
                                                        <ListItem>
                                                            <ListItemText>
                                                                <Link href={Ways.cartPage}>
                                                                    <Button style={{ fontSize: 12 }} className={classes.listBtn} size="large" fullWidth variant="contained">View quote  Basket</Button>
                                                                </Link>
                                                            </ListItemText>

                                                        </ListItem>
                                                        <MenuItem onClick={handleClose}>

                                                        </MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>



                                {/* For Smaller Screens                             */}







                            </ListItem>


                            <ListItem style={{ marginTop: '-6%' }}>
                                <NotificationsComponent />
                            </ListItem>



                        </List>


                    </Grid>

                    {/* <Grid item lg={2}> */}



                    {/* {
                            window.location.pathname.includes('beewell/') || window.location.pathname.includes('/dashboard/') || window.location.pathname.includes('/beegenix/') || (window.location.pathname.includes('/shop/product/')) || (window.location.pathname.includes(Ways.sellerpage)) || (window.location.pathname.includes(Ways.buyerpage))
                                || window.location.pathname.includes(Ways.termspage) || (window.location.pathname.includes('/apiariespage')) || (window.location.pathname.includes('/')) || (window.location.pathname.includes('/honeyhiit')) || (window.location.pathname.includes(Ways.honeyyummiespage)) || (window.location.pathname.includes('/shop')) || (window.location.pathname.includes('/medicinalpage')) || (window.location.pathname.includes('/brands/cart')) || (window.location.pathname.includes('/cart')) || (window.location.pathname.includes('/quote')) || (window.location.pathname.includes('/landingpage')) ? <ExpansionBrands /> : <BrandMenus />
                        } */}





                    {/* <BrandMenus /> */}

                    {/* </Grid> */}

                    <Grid item lg={10}>

                        <AppBar position="static" className={classes.appBar}>
                            <Toolbar>

                                <div>
                                    <Link href="/">
                                        <Button className={classes.btnStyle} variant="contained">
                                            Home
                                        </Button>
                                    </Link>
                                    <Link href={Ways.shoppage}>
                                        <Button className={classes.btnStyle} variant="contained">
                                            Shop
                                        </Button>
                                    </Link>
                                    <Link href="/seller">
                                        <Button className={classes.btnStyle} variant="contained">
                                            Seller Guide
                                        </Button>
                                    </Link>
                                    <Link href="/buyer">
                                        <Button className={classes.btnStyle} variant="contained">
                                            Buyer Guide
                                        </Button>
                                    </Link>
                                    <Link href="/medicinal-honey">
                                        <Button className={classes.btnStyle} variant="contained">
                                            Medicinal Honey
                                        </Button>
                                    </Link>

                                    <Link href="/our-apiaries">
                                        <Button className={classes.btnStyle} variant="contained" >
                                            Our Apiaries
                                        </Button>
                                    </Link>

                                    {/* <Link href="/invest-with-us">
                                        <Button className={classes.btnStyle} variant="contained" >
                                            Invest With Us
                                        </Button>
                                    </Link> */}
                                    <Link href="https://birchal.com/company/ahv">
                                        <Button className={classes.btnStyle} variant="contained" >
                                            Invest With Us
                                        </Button>
                                    </Link>

                                </div>

                            </Toolbar>
                        </AppBar>


                    </Grid>
                    <Grid item lg={4}></Grid>










                </Grid>
            </Container>





        </div>
    )
}