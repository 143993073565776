import React from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, CardContent, Hidden,  Button, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import { Image, Work, BeachAccess } from '@material-ui/icons';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { MyButton } from './../Styles/MyButton';
import higherprice from './../assets/higherprice.jpg';
import dashboard1  from './../assets/dashboard1.png';
import dashboard2  from './../assets/dashboard2.png';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    listStyle: {
display: 'flex',
flexDirection: 'row',
width: '100%',

[theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },




    },

    actionArea: {

        maxWidth: 450,
        
      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },
    root: {
      
      // boxShadow: 'none',
      // width: 600,
      // height: 584,

      boxShadow: 'none',
    
      width: '100%',
      height: '100%',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    
      backgroundSize: 'cover',

      
    },
   
  
    imgStyle: {
  
      maxWidth: 120,
     
  
    },

    mainHeading: {
      fontFamily: 'sophia',
      color: '#303030',
      fontSize: 22,
  

  },

  
  largeHeading: {
    fontFamily: 'sophia',
    color: '#303030',
    fontSize: 24,

    paddingBottom: 15,

},


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: '1.25rem',
      color: '#726f6f',
      fontWeight: 500,

  },
  
  

    vector: {

      boxShadow: 'none',
    
      width: '100%',
      height: '100%',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    
      backgroundSize: 'cover',

        // width: 600,
        // height: 584,

        // [theme.breakpoints.down('sm')]: {
        //     width: 300,
        //     height: 290,
        //     marginLeft: '-23%',
        //     marginTop: '4%',
        //   },
    },


    dashboardImg: {

      boxShadow: 'none',
    
      width: '100%',
      height: '100%',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    
      backgroundSize: 'cover',

      // width: 600, 
      // height: 294, 

      // [theme.breakpoints.down('sm')]: {
      //   width: 300,
      //   height: 150,
       
      // },

    },
  
}),
);




export const PricesKeepers = () => {
    const classes = useStyles();

    return(
        <div style={{paddingTop: '2%', paddingBottom: '4%'}}>
        <Container maxWidth="lg">
        <Grid container
        spacing={4}
         direction="row"
         justify="center"
         alignItems="center"
         style={{marginTop: '1%', }}
         >


{/* For larger screens */}
       
<Hidden only={['sm', 'xs',]}>
        <Grid item lg={6} sm={6} xs={12} md={6}>

        <Typography className={classes.largeHeading} style={{ fontSize: '1.9rem',}} align="left" gutterBottom variant="h3" component="h2">
        HIGHER PRICES<br />
FOR BEEKEEPERS
       </Typography>
   
       
       <Typography className={classes.descriptionStyle} style={{paddingRight: '16%'}} align="justify" variant="subtitle2" color="textSecondary" component="p">
       AHV is a typical brokerage model in the sense we arrange sales between Beekeepers and buyers, for a fee. You pay us a commission to pack and broker your quality honey into premium markets, and we get you the best price.<br /><br />

       Investing in product development and marketing will play a pivotal role in lifting the value of Australian honey and returning higher prices to Beekeepers. Over the next five years, our goal is to have converted quality active and non-active Australian honey into various forms ready for use in the global therapeutic, supplement and health food industries.<br /><br />

       Beekeepers will soon be digital thanks to an app we are building. An impressive dashboard will display intricate details about the honey they have delivered to our warehouse.
       </Typography>
      
        </Grid>

        </Hidden>


        {/* For smaller Screens */}


        <Hidden only={['xl', 'lg', 'md']}>
        <Grid item lg={6} sm={6} xs={12} md={6}>

        <Typography className={classes.largeHeading} style={{ fontSize: '1.9rem',}} align="center" gutterBottom variant="h3" component="h2">
        HIGHER PRICES FOR BEEKEEPERS
       </Typography>
   
       
       <Typography className={classes.descriptionStyle} align="justify" variant="subtitle2" color="textSecondary" component="p">
       AHV is a typical brokerage model in the sense we arrange sales between Beekeepers and buyers, for a fee. You pay us a commission to pack and broker your quality honey into premium markets, and we get you the best price.<br /><br />

       Investing in product development and marketing will play a pivotal role in lifting the value of Australian honey and returning higher prices to Beekeepers. Over the next five years, our goal is to have converted quality active and non-active Australian honey into various forms ready for use in the global therapeutic, supplement and health food industries.<br /><br />

       Beekeepers will soon be digital thanks to an app we are building. An impressive dashboard will display intricate details about the honey they have delivered to our warehouse.
       </Typography>
      
        </Grid>

        </Hidden>

        

        <Grid item lg={6} sm={12} xs={12} md={6}>
       
           
      


        <Card className={classes.root}>
     
     <CardMedia
       >
           <img src={higherprice} className={classes.vector} alt="services" />
       </CardMedia>

 </Card>
  

     </Grid>



{/* For larger screens */}


     <Hidden only={['sm', 'xs',]}>
     <Grid item lg={12}>
     <Typography className={classes.largeHeading} style={{ fontSize: '2.4rem', paddingTop: '6%', }} align="center" gutterBottom variant="h3" component="h2">
     EXCITING NEW e-commerce site and beekeeper app
       </Typography>

       <Typography variant="h2"  style={{ fontSize: '4.7rem', fontWeight: 500, color: '#f28f16', marginTop: '-2%' }} align="center" gutterBottom component="h2">
       COMING SOON
       </Typography>

     </Grid>

     </Hidden>

     {/* For smaller screens */}


     <Hidden only={['xl', 'lg', 'md']}>
     <Grid item lg={12}>
     <Typography className={classes.largeHeading} style={{ fontSize: '1.7rem', paddingTop: '6%', }} align="center" gutterBottom variant="h3" component="h2">
     EXCITING NEW e-commerce site and beekeeper app
       </Typography>

       <Typography variant="h2"  style={{ fontSize: '3.7rem', fontWeight: 500, color: '#f28f16', marginTop: '-2%' }} align="center" gutterBottom component="h2">
       COMING SOON
       </Typography>

     </Grid>

     </Hidden>
     <Grid item lg={6} style={{marginTop: '-2%'}}>
         <img src={dashboard1} className={classes.dashboardImg}  />
     </Grid>

     <Grid item lg={6} style={{marginTop: '-2%'}}>
         <img src={dashboard2} className={classes.dashboardImg}  />
     </Grid>

     

        </Grid>



        

        
        </Container>
        </div>
    )
}