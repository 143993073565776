import React, { useEffect, useState } from 'react';
import { Container, Grid, Hidden, Typography, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { API } from "./../Helper";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Status } from './Status';
import { QuoteForm } from './QuoteForm';
import { CartListForQuote } from './../Components/CartListForQuote';



const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        breadcrumbBox: {
            paddingLeft: '5%',
            paddingRight: '5%',
            paddingTop: '1%',
            paddingBottom: '1%',
            backgroundColor: '#efeded',
            // marginTop: '2%',
            // borderTop: '1px solid #efefef',
        },


        productBtn: {
            color: '#ffffff',
            backgroundColor: '#f28f16',
            border: '1px solid #f28f16',
            boxShadow: 'none',
            borderRadius: 4,
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 10,
            paddingBottom: 10,
            fontFamily: 'sophia',
            width: '100%',
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#FCB040',
                    color: '#ffffff',
                    boxShadow: 'none',
                },
            },

        },

        productBtnoutlined: {

            backgroundColor: 'transparent',
            border: '1px solid #303030',
            marginLeft: '19%',
            color: '#303030',
            boxShadow: 'none',
            borderRadius: 4,
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 10,
            paddingBottom: 10,
            fontFamily: 'sophia',
            width: '100%',
            '&.MuiButton-outlined': {
                '&:hover': {
                    backgroundColor: '#fbfbfb',
                    border: '1px solid #a5a4a4',
                    color: '#303030',
                    boxShadow: 'none',
                },
            },


            [theme.breakpoints.down('sm')]: {
                marginLeft: '0%',
            },
        },


        listStyle: {
            [theme.breakpoints.down('sm')]: {
                maxWidth: 450,
            },
        },


        headingStyleMain: {
            fontFamily: 'sophia',
            fontSize: '1.2rem',
            color: '#3d3a3a',
            marginBottom: '3%',
        
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.9rem',
            },
            
        },


        dateStyle: {

            marginLeft: '-12%',
             marginTop: '-0.8%',

             [theme.breakpoints.down('sm')]: {
                marginLeft: '-4%',
            },
        },

        mainDiv: {
            [theme.breakpoints.down('sm')]: {
                
                // paddingRight: '16%',
            },


        },



    }),
);



export const Quote = () => {

    const [quote , setQuote] = useState({});

    const getData = async() => {

        var id = window.location.pathname.split('/')[4];

        var off = await API.get('offer', { offerid: id });
        console.log(off.data);
        setQuote(off.data);
    }

 


    useEffect(() => {

        getData();
    }, [])
    const classes = useStyles();
    return (
        <div className={classes.mainDiv}>


            <div>
                <div style={{paddingLeft: '2%', paddingRight: '2%'}}>
            <Hidden only={['lg', 'xl', 'md',]}>
                        <Grid item lg={12}>
                            <List>
                                <ListItem>
                                    <ListItemText>
                                    <Typography  variant="h6">Quote # HON-QUO-{quote['rid']} </Typography>
                                    </ListItemText>
                                    <ListItemSecondaryAction>
                                    <Typography  variant="caption"> {new Date(Date.parse(quote['createdOn'])).toDateString()}</Typography>
                                    </ListItemSecondaryAction>
                                
                                </ListItem>
                              
                            </List>
                        </Grid>
                        </Hidden>
                        </div>
                <Container>
                    <Grid container spacing={2}>
                  
                        <Hidden only={['xs', 'sm']}>
                        <List className={classes.listStyle} style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingTop: '2%', marginBottom: '-2%', }}>
                            <ListItem >
                                <Typography className={classes.headingStyleMain} variant="h6">Quote # HON-QUO-{quote['rid']} </Typography>
                            </ListItem>
                            <ListItem className={classes.dateStyle} >
                                <Typography align="left" variant="body2">Submitted: {new Date(Date.parse(quote['createdOn'])).toDateString()}</Typography>
                            </ListItem>
                            <ListItem>
                           
                            </ListItem>
                          
                        </List>
                        </Hidden>
                     
                        <Grid item lg={12} sm={12} xs={12} md={12}>
                            <Status quote={quote} />
                        </Grid>
                        
                      
                        <Grid item lg={12} xs={12} sm={12} md={12} style={{overflow: 'auto'}}>
                            {quote['id'] && <CartListForQuote quote={quote}/>}
                        </Grid>
                    

                        <Grid item lg={12} sm={12} xs={12} md={12}>
                            {quote['id'] && <QuoteForm quote={quote} />}
                        </Grid>
                        {/* <Grid item lg={12}>
                    <AdminResponse />
                </Grid> */}
                    </Grid>
                </Container>
                {/* <NewFooter /> */}
            </div>

        </div>




    )
}