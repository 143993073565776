import React from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, CardContent, ListItemText, ListItemAvatar, Avatar,  ListItemIcon, Divider, List, ListItem, ListItemSecondaryAction } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Done } from '@material-ui/icons';
import { Image, Work, BeachAccess, Lock } from '@material-ui/icons';
import password from './../assets/password.png';
import bestbee from './../assets/bestbee.png';
import honeyorigin from './../assets/honeyorigin.png';
import negotiation from './../assets/negotiation.png';
import niche from './../assets/niche.png';



const useStyles = makeStyles({

    actionArea: {

        maxWidth: 450,
        
      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },
    root: {
      
      boxShadow: 'none',
      width: 514,
      height: 409,

      
    },
    media: {
      
      width: 514,
      height: 409,
      
    },
  
    imgStyle: {
  
      maxWidth: 120,
     
  
    },

    mainHeading: {
      fontFamily: 'sophia',
      // color: '#303030',
      fontSize: 22,
      color: '#fff',
  
      paddingBottom: 15,

  },


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: '1.25rem',
      fontWeight: 500,
      // color: '#726f6f',
      color: '#fff',

  },
  
  
  
  });
  




export const HoneySalePlus = () => {
    const classes = useStyles();

    return(
        <div style={{backgroundColor: '#FCB040', paddingTop: '10%', marginTop: '-3%', paddingBottom: '8%',}}>
        <Container maxWidth="lg">
        <Grid container
         spacing={2}
         direction="row"
         justify="center"
         alignItems="center"
         style={{paddingBottom: '3%'}}
         
         >

        <Grid item lg={12} sm={12} xs={12}>
    
           
           
          <Typography className={classes.mainHeading} style={{ fontSize: '1.9rem',}} align="center" variant="h5" component="h2">
          Here's what we believe at Australian Honey Ventures
          </Typography>

        </Grid>

        </Grid>

      
    

            <Grid container justify="center">
             <Grid item lg={6} xs={12}>
                 <List style={{ paddingTop: '5%', paddingBottom: '5%'}}>
      <ListItem>
        <ListItemAvatar style={{marginRight: '3%'}}>
         <img src={negotiation} width="70" height="70" />
        </ListItemAvatar>
        <ListItemText>
          <Typography className={classes.mainHeading} variant="body2">Better negotiating position for Beekeepers</Typography>
          <Typography className={classes.descriptionStyle} variant="body2">AHV offers beekeepers a safeguard to their bargaining position and independence. Why sell to packers who continue to offer a low price?</Typography>
          </ListItemText>

      </ListItem>
      <ListItem style={{paddingTop: '8%'}}>
        <ListItemAvatar style={{marginRight: '3%'}}>
         <img src={niche} width="70" height="70" />
        </ListItemAvatar>
        <ListItemText>
          <Typography className={classes.mainHeading} variant="body2">Niche is a point of difference</Typography>
          <Typography className={classes.descriptionStyle} variant="body2">Niche honey products is a way forward for non-active honey which is currently being squeezed by big retailers.</Typography>
        </ListItemText>
      </ListItem>
    
    </List>


   
                 </Grid>

                 <Grid item lg={6} xs={12}>
                 <List style={{ paddingTop: '5%'}}>
      <ListItem >
        <ListItemAvatar style={{marginRight: '3%'}}>
         <img src={bestbee} width="70" height="70" />
        </ListItemAvatar>
        <ListItemText>
          <Typography className={classes.mainHeading} variant="body2">Some of the best in the world</Typography>
          <Typography className={classes.descriptionStyle} variant="body2">Australia produces some of the most unique and premium honey in the world, leaving honey from other countries tasting bland in comparison.</Typography>
        </ListItemText>
      
      </ListItem>
      <ListItem style={{paddingTop: '8%'}}>
        <ListItemAvatar style={{marginRight: '3%'}}>
        <img src={honeyorigin} width="70" height="70" />
        </ListItemAvatar>
        <ListItemText>
          <Typography className={classes.mainHeading} variant="body2">Increased awareness about honey origin</Typography>
          <Typography className={classes.descriptionStyle} variant="body2">More consumers are becoming increasingly interested in where their food is coming from and have a growing desire to source locally produced products.</Typography>
        </ListItemText>
      
      </ListItem>
    
    </List>


   
                 </Grid>

                 
                 </Grid>


    






        </Container>


        </div>
    )
}