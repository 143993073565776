import React from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, CardContent, ListItemText, ListItemAvatar, Avatar,  ListItemIcon, Divider, List, ListItem, ListItemSecondaryAction } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Done } from '@material-ui/icons';
import { Image, Work, BeachAccess, Lock } from '@material-ui/icons';
import password from './../assets/password.png';
import bestbee from './../assets/bestbee.png';
import honeyorigin from './../assets/honeyorigin.png';
import negotiation from './../assets/negotiation.png';
import niche from './../assets/niche.png';



const useStyles = makeStyles({

    actionArea: {

        maxWidth: 450,
        
      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },
    root: {
      
      boxShadow: 'none',
      width: 514,
      height: 409,

      
    },
    media: {
      
      width: 514,
      height: 409,
      
    },
  
    imgStyle: {
  
      maxWidth: 120,
     
  
    },

    mainHeading: {
      fontFamily: 'sophia',
      // color: '#303030',
      fontSize: 22,
      color: '#fff',
  
      paddingBottom: 15,

  },


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: '1.25rem',
      fontWeight: 500,
      // color: '#726f6f',
      color: '#fff',

  },
  
  
  
  });
  




export const HoneySalePlus = () => {
    const classes = useStyles();

    return(
        <div style={{backgroundColor: '#FCB040', paddingTop: '8%', paddingBottom: '8%',}}>
        <Container maxWidth="lg">
        <Grid container
         spacing={2}
         direction="row"
         justify="center"
         alignItems="center"
         style={{paddingBottom: '3%'}}
         
         >

        <Grid item lg={12} sm={12} xs={12}>
    
           
           
          <Typography className={classes.mainHeading} style={{fontSize: '1.9rem'}} align="center" variant="h5" component="h2">
          Our tools for success
          </Typography>
          <Typography variant="h2" style={{fontSize: '1.25rem', color: '#fff' }} align="center">Here at AHV we are all about providing the best customer experience.
           That’s why when we partner with you, we provide the utmost support in all areas of merchandising,
           sales or best outcomes for incorporating our products into your business.</Typography>

        </Grid>

        </Grid>

      
    

            <Grid container justify="center">
             <Grid item lg={6} xs={12}>
                 <List style={{ paddingTop: '5%', paddingBottom: '5%'}}>
      <ListItem>
        <ListItemAvatar style={{marginRight: '3%'}}>
         <img src={negotiation} width="70" height="70" />
        </ListItemAvatar>
        <ListItemText>
          <Typography className={classes.mainHeading} variant="body2">Wholesale quoting</Typography>
          <Typography className={classes.descriptionStyle} variant="body2">Submit quote requests and complete orders online 24/7 via our wholesale website</Typography>
          </ListItemText>

      </ListItem>
      <ListItem style={{paddingTop: '8%'}}>
        <ListItemAvatar style={{marginRight: '3%'}}>
         <img src={niche} width="70" height="70" />
        </ListItemAvatar>
        <ListItemText>
          <Typography className={classes.mainHeading} variant="body2">Marketing support</Typography>
          <Typography className={classes.descriptionStyle} variant="body2">Download the latest high-res images/videos of our brands to use in your social feeds.</Typography>
        </ListItemText>
      </ListItem>
    
    </List>


   
                 </Grid>

                 <Grid item lg={6} xs={12}>
                 <List style={{ paddingTop: '5%'}}>
      <ListItem >
        <ListItemAvatar style={{marginRight: '3%'}}>
         <img src={bestbee} width="70" height="70" />
        </ListItemAvatar>
        <ListItemText>
          <Typography className={classes.mainHeading} variant="body2">Sales team</Typography>
          <Typography className={classes.descriptionStyle} variant="body2">Highly skilled sales team ensures your store has all the tools for success.</Typography>
        </ListItemText>
      
      </ListItem>
      <ListItem style={{paddingTop: '8%'}}>
        <ListItemAvatar style={{marginRight: '3%'}}>
        <img src={honeyorigin} width="70" height="70" />
        </ListItemAvatar>
        <ListItemText>
          <Typography className={classes.mainHeading} variant="body2">Product design</Typography>
          <Typography className={classes.descriptionStyle} variant="body2">Innovative product team can custom design brands to suit your buyer market</Typography>
        </ListItemText>
      
      </ListItem>
    
    </List>


   
                 </Grid>

                 
                 </Grid>


    






        </Container>


        </div>
    )
}