import React, { useEffect, useState } from 'react';
import { createBrowserHistory } from 'history';
import { Grid, Container, Typography, Card, CardActionArea, CardMedia, CardContent, CardActions, Button, Hidden, Link, List, ListItem } from '@material-ui/core';
import { fade, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import mainimage from './../assets/mainimage.png';
import sectionimg from './../assets/sectionimg.jpg';
import { RegisterYourInterest } from '../Components/RegisterYourInterest';
import { API, AppEvent, Bbc, LoginHelper, Ways, Cook } from './../Helper';
// import { MyButton } from './../Styles/MyButton';




const useStyles = makeStyles((theme: Theme) =>
  createStyles({


    mainSlide: {

      marginTop: -17,
      [theme.breakpoints.up('xl')]: {
        marginTop: -16,
      },
    },

    actionArea: {

      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },

    description: {
      fontFamily: 'sophia-regular',
      color: '#fff',

      fontSize: '1.3rem',


      [theme.breakpoints.down('sm')]: {
        fontSize: '0.9rem',
        color: '#fff',

      },

    },

    mainHeading: {

      fontFamily: 'sophia',
      color: '#fff',
      marginTop: '-29%',
      fontSize: 44,

      [theme.breakpoints.down('md')]: {
        fontSize: 20,
        marginTop: '-43%',
      },


      // [theme.breakpoints.down('sm')]: {
      //   fontSize: 16,
      // marginTop: '-48%',
      // color: '#303030',
      // },


    },

    slideHeading: {

      position: 'absolute',
      top: '45%',
      left: '50%',
      transform: 'translate(-50%, -50%)',

      [theme.breakpoints.down('lg')]: {
        top: '49%',

      },

      [theme.breakpoints.down('sm')]: {
        position: 'relative',
        top: '50%',
        left: '50%',
        marginTop: '-21%'

      },


    },

    root: {


      maxHeight: 560,
      height: 'auto',
      maxWidth: '100%',
      margin: "auto",
      boxShadow: 'none',
      position: 'relative',


    },
    media: {
      objectFit: 'cover',

    },

    imgStyle: {

      maxWidth: 120,


    },

    btnStyle: {
      padding: 15,
      fontFamily: 'sophia',
      boxShadow: 'none',

      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
        padding: 8,

      },
    },

    mainImage: {
      width: '100%',
      height: '100%',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      paddingBottom: '3%',
      backgroundSize: 'cover',
      position: 'relative',
      textAlign: 'center',


      [theme.breakpoints.down('sm')]: {
        height: 300,
        width: '100%',

        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        paddingBottom: '3%',
        backgroundSize: 'cover',
        position: 'relative',
        textAlign: 'center',

      },
    },


  }),
);





export const MainSlide = () => {
  const classes = useStyles();
  const [data, setData] = useState<any[]>([]);





  useEffect(() => {
    API.get('ibcs').then(x => {
      console.log(x.data);
      setData(x.data);
    })



  }, [])

  let id: number = 1
  const getID = () => {
    return id++;
  }





  return (
    <div className={classes.mainSlide} >

      <Grid container

        direction="row"
        justify="center"
        alignItems="center"

      >

        <Grid item lg={12} sm={12} xs={12}>


          <Card className={classes.root}>
            <CardActionArea>

              <CardMedia className={classes.media}>
                <img src={sectionimg} alt="bakcground" className={classes.mainImage} />
              </CardMedia>

              <CardContent className={classes.slideHeading} >
                <Typography className={classes.mainHeading} align="center" gutterBottom variant="h4" component="h2">
                  Become a part of the AHV family
                </Typography>
                <Typography className={classes.description} align="center" variant="subtitle2">AHV is an innovator in the Australian honey and bee product space,
                  constantly creating new concepts to fill your store with newness and best-selling products.<br /><br />
                  Are you a bricks and mortar or online store looking to stock our brands?</Typography>

                <Typography style={{ marginTop: '3%', }} align="center" gutterBottom>

                  {/* <RegisterYourInterest currentBeekeeper={data}  /> */}

                  {
                    (!LoginHelper.isCookiePresent())
                      ?

                      <div>       <Button className={classes.btnStyle}


                        variant="contained"




                        onClick={async () => {

                          Bbc.fire(AppEvent.requestLoginDialog,
                          );


                        }}>
                        Register Your Interest
                      </Button></div>



                      :
                      <div>
                        <Link href="/registration">
                          <Button className={classes.btnStyle} variant="contained"
                          >
                            Register Your Interest
                          </Button>
                        </Link>

                      </div>
                  }
                </Typography>

              </CardContent>
            </CardActionArea>
            <CardActions>


            </CardActions>
          </Card>








        </Grid>
      </Grid>

    </div>
  )
}