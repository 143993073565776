// import React, { FunctionComponent } from 'react'
import React, { useEffect, useState, FunctionComponent } from 'react';
import { Grid, List, ListItem, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TimerComponent } from './TimerComponent';
import { API } from '../Helper';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({


        subHeading: {
            fontFamily: 'sophia',
            fontSize: 20,
            paddingTop: '2%',
            marginBottom: '0.5%',
            color: '#303030',
            paddingLeft: '3%',
        },
        textDescription: {
            fontFamily: 'sophia-regular',
            fontSize: 18,
            color: '#303030',
            fontWeight: 600,
            paddingLeft: '3%',
            paddingBottom: '2%',
        },

        headingStyleMain: {
            fontFamily: 'sophia',
            fontSize: '1.2rem',
            color: '#3d3a3a',
            marginBottom: '3%',
        
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.9rem',
            },
            
        },

        mainDiv: {
            [theme.breakpoints.down('sm')]: {
                paddingRight: '2%',
            },
        }



    }),
);
export const Status: FunctionComponent<{ quote: any }> = props => {

    const classes = useStyles({});

    const [quote , setQuote] = useState({});
    const getData = async() => {

        var id = window.location.pathname.split('/')[4];

        var off = await API.get('offer', { offerid: id });
        console.log(off.data);
        setQuote(off.data);
    }

    useEffect(() => {

        getData();
    }, [])
   

    const getOrdermessage = () =>{
        var heading = ''
        var offer = props.quote;
        if (offer.orderStatus == 'pending') {
            heading = 'Your order is Pending';
        } else if (offer.orderStatus == 'cancelled') {
            heading = 'Your order is Cancelled';
        } else if (offer.orderStatus == 'shipped') {
            heading = 'Your order is Shipped';
        } else if (offer.orderStatus == 'complete') {
            heading = 'Your order is completed'
        } else if (offer.orderStatus == 'processing') {
            heading = 'Your order is being processed';
        }

        return heading;
    }
    

    const getStatusText = ()=>{

        if(props.quote['status'] == 'Pending' || props['quote']['status'] == 'await'){
            return "Your quote is awaiting AHV's response"
        }else if (props.quote['status'] == 'Expired'){
            return "Your quote is expired. Please request a new quote"
        }else if (props.quote['status'] == 'Quoted'){
            return <div>
                 
            
                <Typography variant="body2" style={{marginTop: '-1%', marginBottom: '1%', paddingRight: '5%'}}>AHV has updated your quote. Once you have reviewed the details of the quote, you have the option of placing an order or opening a chat with the AHV Team.</Typography><br />
                {quote['id'] && <TimerComponent quote={quote} /> }
                </div> 
        }else if (props.quote['status'] == 'Ordered'){
            return (
                <div>
                {getOrdermessage()}
               
                </div>
                )
        }
    }
    var isOrdered = props.quote['status'] == 'Ordered';
    return (
        <div className={classes.mainDiv}>

            <Grid container
                style={{ borderTop: '0px solid #fec121', paddingLeft: 5, paddingRight: 5, backgroundColor: '#fce9d4' }}>
                <Grid item lg={12} xs={12} sm={12}>
                    
                <List style={{display: 'flex', flexDirection: 'row'}}>
                    <ListItem>
                    <Typography variant="h6" className={classes.subHeading}>{isOrdered ?  'Order' :'Quote'} Status</Typography>
                    </ListItem>
                   
                    <ListItem style={{marginTop: '3%'}}>
                    {/* {quote['id'] && <TimerComponent quote={quote} />} */}
                    {/* <TimerComponent quote={quote} /> */}
                    
                    </ListItem>
                </List>
   
   
                    <Typography className={classes.textDescription} variant="subtitle2">{getStatusText()}</Typography>

                </Grid>
            </Grid>
        </div>
    )
}