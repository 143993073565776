import Link from '@material-ui/core/Link';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import { Button, Dialog, DialogActions, DialogContent, IconButton } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Email, Lock, Restore, Settings, Storage, Favorite, Dashboard, LocalMall } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { ListItemSecondaryAction, Badge, Typography } from '@material-ui/core';
import 'typeface-roboto';
import { API, Cook, LoginHelper, Ways } from '../Helper';
import { GlobalState } from '../AppState';
import { observer } from 'mobx-react';
import  {createBrowserHistory} from 'history';


const MsgBadge = observer(() => {
    return <Badge color="secondary" badgeContent={GlobalState.unreadMessages.length} >
        
    </Badge>
})

const useStyles = makeStyles({
    mainStyle: {
        width: 300,
        border: '1px solid #efefef',
        backgroundColor: '#fbfbfb',
        borderRadius: 16,
    },
    menuHeading: {
        paddingLeft: '5%',
        paddingTop: '5%',
        backgroundColor: 'transparent',
        fontFamily: 'sophia',
        lineHeight: 2.0,
        color: '#565252',
        fontSize: '1.0rem',
        letterSpacing: 0.8,
        fontWeight: 600,
        boxShadow: 'none',
    },
});
const StyledMenuItem = withStyles((theme) => ({
    root: {
        // width: 300,
         borderBottom: '1px solid #efefef',
        borderRadius: 6,
    },
}))(MenuItem);
export const UnreadCount = observer((props: { count }) =>
    <ListItemSecondaryAction>{GlobalState.unreadMessages.length}</ListItemSecondaryAction>);
export const Menus = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [messageCount, setMessageCount] = useState(0);
    useEffect(() => {
        // API.get('unreadMessages', { userid: Cook.getUserData()['user']['id'] }).then(x => {
        //     console.log(x.data);
        //     GlobalState.unreadMessages = x.data;
        // })
    }, [])
    const menuItems: { path?: string, title: string, icon: JSX.Element, onClick?: () => void }[] = [
        { path: Ways.dashboard, title: 'Dashboard', icon: <Dashboard fontSize="small" style={{ fontSize: 21 }} /> },
        { path: Ways.quotesPage, title: 'Quotes', icon: <Restore fontSize="small" style={{ fontSize: 21, }} /> },
        { path: Ways.ordersPage, title: 'Orders', icon: <LocalMall fontSize="small" style={{ fontSize: 21, }} /> },
        { path: Ways.chatPage, title: 'Messages',  icon: <Email fontSize="small" style={{ fontSize: 21, }} /> },
        { path: Ways.wishlistPage, title: 'Wishlist', icon: <Favorite fontSize="small" style={{ fontSize: 21 }} /> },
        {
            path: Ways.settingsPage,
            title: 'Profile Settings',
            icon: <Settings fontSize="small" style={{ fontSize: 21, }} />
        },
        {
            title: 'Logout', icon: <Lock fontSize="small" style={{ fontSize: 21, }} />, onClick: () => {
                setOpen(true)
            }
        }
    ];
    return (
        <div className={classes.mainStyle}>
            {/* <Typography className={classes.menuHeading} variant="h6">Menu</Typography>
            <br/> */}
            {menuItems.map((x, i) => {
                let isSelected = window.location.pathname == (x.path);
                return <Link key={i} href={x.path} style={{ textDecoration: 'none', color: '#4c4a4a', }}>
                    <StyledMenuItem onClick={e => {
                        if (x.onClick != null) {
                            x.onClick();
                        }
                    }} style={{
                        fontSize: 25,
                        paddingTop: 15,
                        paddingBottom: 15,
                        backgroundColor: isSelected ? '#FCB040' : '4c4a4a',
                        color: isSelected ? 'white' : '#4c4a4a'
                    }}>
                        <ListItemIcon style={{ marginLeft: 5, color: isSelected ? 'white' : '#4c4a4a' }}>
                            {x.icon}
                        </ListItemIcon>
                        <ListItemText primary={x.title} />
                        {/* <IconButton> */}
                            {/* <Badge badgeContent={GlobalState.unreadMessages.length} color="secondary"> */}
                            {/* {
                                (x.path == Ways.chatPage) && <Badge color="secondary" badgeContent={GlobalState.unreadMessages.length} />
                            } */}
                            {/* </Badge> */}
                        {/* </IconButton> */}
                                    
                        { (x.path == Ways.chatPage) && <MsgBadge />}
                    </StyledMenuItem>
                </Link>

            })}


            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogContent style={{ padding: 20, width: 350 }}>
                    Are you sure you want to logout ?
                </DialogContent>
                <DialogActions style={{ paddingBottom: 15 }}>
                    <Button variant="contained" style={{ backgroundColor: '#f28f16', color: '#fff', boxShadow: 'none' }} onClick={() => {
                        if (LoginHelper.isCookiePresent()) {
                            LoginHelper.logout()
                        } else {
                            createBrowserHistory().push('/login')
                            window.location.reload();
                        }
                    }}>Logout</Button>
                    <Button variant="outlined" onClick={() => {
                        setOpen(false)
                    }}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}