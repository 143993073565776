import React from 'react';
import { Grid, Typography, Container, List, ListItem, ListItemText, ListItemIcon , Hidden } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { BreadCrumbsBar } from './BreadCrumbs';
import { NewHeader } from './../Components/NewHeader';
import { NewFooter } from './../Components/NewFooter';
import { MobileHeader } from './MobileHeader';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({

   paragraph: {
    //    paddingTop: 5,
       paddingBottom: 35,
       fontSize: '1.25rem',
       fontFamily: 'sophia-regular',
   },

   heading: {
       fontSize: '1.35rem',
       fontWeight: 600,
       fontFamily: 'sophia-regular',

   },

   listStyle: {

    width: '100%',
   },

   listDescriptionStyle: {
       paddingLeft: 5,
       fontSize: '1.25rem',
       fontFamily: 'sophia-regular',
   },

   root: {
    [theme.breakpoints.down('sm')]: {
        paddingLeft: '12%',
        paddingRight: '12%',
        overflowX: 'hidden',
      },

   },

   descriptionStyle: {

    fontSize: '1.25rem',
    fontFamily: 'sophia-regular',
   },

}),
);






{/* <div style={{ borderBottom: '1px solid #efefef' }}>
<Hidden only={['sm', 'md', 'xs']}>
    <NewHeader />
</Hidden>

<Hidden only={['lg', 'xl']}>
    <MobileHeader />
</Hidden>
<BreadCrumbsBar/>
<div style={{ paddingTop: '7%',paddingBottom : '4%' }}>
  
    <Container maxWidth="lg">
        <Grid container spacing={4} justify="center">
            <Grid item lg={5} xs={12}>

 */}









export const Terms = () => {
    const classes = useStyles();
    return(

<div style={{backgroundColor: '#fff', marginTop: '-0.8%'}}>
    {/* <NewHeader /> */}
    <Hidden only={[ 'lg', 'xl']}>
    <MobileHeader />
    </Hidden>
    
<BreadCrumbsBar/>
 <Container maxWidth="lg" style={{paddingBottom: '5%',}} className={classes.root}>
     <Grid container justify="center" alignItems="center" style={{paddingTop: '4%'}}>


     <Grid item xs={12} lg={12} md={12} sm={12}>
         <Typography variant="h4" style={{paddingTop: 15, paddingBottom: 30}}>Terms And Conditions</Typography>
  
                     <Typography variant="h2" className={classes.heading}>
                        Applicable Laws
                    </Typography>

                    <Typography variant="h2" className={classes.paragraph}>
                    means legal acts and regulations,
                    licenses and permits in effect in state / territory from which a User accesses or use the Services.
                    </Typography>


                    <Typography variant="h2" className={classes.heading}>
                        Buyer
                    </Typography>

                    <Typography variant="h2" className={classes.paragraph}>
                    a person who at the time of registration provides Australian Honey Ventures Pty Ltd with evidence of his/her identifiable name and contact details,
                     or in the event or an entity an ACN (ACN or ABN in Australia).
                    </Typography>





                    <Typography variant="h2" className={classes.heading}>
                        Escrow
                    </Typography>

                    <Typography variant="h2" className={classes.paragraph}>
                   
                    a financial arrangement where a third party holds and regulates payment of the funds required for two parties involved in a given transaction.
                    It helps make transactions more secure by keeping the payment in a secure escrow account which is only released when all of the terms of an agreement are met as overseen by the escrow company.

                    </Typography>



                    <Typography variant="h2" className={classes.heading}>
                        Packing Fee
                    </Typography>

                    <Typography variant="h2" className={classes.paragraph}>
                    means a fee that is charged by Australian Honey Ventures Pty Ltd to cover the cost of processing, packing and marketing of products.
                    </Typography>





                    <Typography variant="h2" className={classes.heading}>
                        Product Listing
                    </Typography>

                    <Typography variant="h2" className={classes.paragraph}>
                    means a product that has been listed by the Seller on the Platform and includes description, price and key physical attributes, etc. 
                    </Typography>




                    <Typography variant="h2" className={classes.heading}>
                        Platform
                    </Typography>

                    <Typography variant="h2" className={classes.paragraph}>
                    means the website http://www.australianhoneyventures.com.au and of which is owned and managed by Australian Honey Ventures Pty Ltd,
                     trading as Australian Honey Ventures Pty Ltd.
                    </Typography>



                    
                    <Typography variant="h2" className={classes.heading}>
                        Product
                    </Typography>

                    <Typography variant="h2" className={classes.paragraph}>
                    means all products provided by the Seller to a Buyer as described in a Product Listing; 
                    </Typography>



                    <Typography variant="h2" className={classes.heading} >
                        Sale Profit
                    </Typography>

                    <Typography variant="h2" className={classes.paragraph}>
                    means the value of a sale, minus the Packing Fee.
                    </Typography>


                    <Typography variant="h2" className={classes.heading}>
                        Seller
                    </Typography>

                    <Typography variant="h2" className={classes.paragraph}>
                    means Australian Honey Ventures Pty Ltd;
                    </Typography>



                    <Typography variant="h2" className={classes.heading}>
                        Service Fee
                    </Typography>

                    <Typography variant="h2" className={classes.paragraph}>
                    the fee payable to Australian Honey Ventures Pty Ltd by the Seller for introducing the Seller to the Buyer.
                    </Typography>



                    <Typography variant="h2" className={classes.heading}>

                        Supplier Fee
                    </Typography>

                    <Typography variant="h2" className={classes.paragraph}>
                    means a person who delivers honeybee products to an Australian Honey Ventures Pty Ltd facility for processing, packing, marketing and/or distribution.
                    </Typography>





                    <Typography variant="h2" className={classes.heading}>
                    Third Party Services
                    </Typography>

                    <Typography variant="h2" className={classes.paragraph}>
                    means the promotions and links to services offered by third parties as featured on the Platform from time to time.
                    </Typography>


                    <Typography variant="h2" className={classes.heading}>
                    Upfront Payment
                    </Typography>

                    <Typography variant="h2" className={classes.paragraph}>
                    means the down payment paid to a Supplier within 4 weeks of he/she delivering honeybee products to an Australian Honey Ventures Pty Ltd facility.
                    </Typography>



                    <Typography variant="h2" className={classes.heading}>
                    User Account
                    </Typography>

                    <Typography variant="h2" className={classes.paragraph}>
                    means a Buyer, Seller, or Supplier account set registered on Australian Honey Ventures Pty Ltd.
                    </Typography>


                    <Typography variant="h2" className={classes.heading}>
                    Users
                    </Typography>

                    <Typography variant="h2" className={classes.paragraph}>
                    means the people who have signed up to use the Platform, whether as the Buyer, Seller or Supplier.
                    </Typography>






                    <Typography variant="h5">1. Contractual Relationship</Typography>

                    <List className={classes.listStyle} aria-label="contacts">
                    <ListItem>
                        <ListItemIcon>
                            <Typography variant="body2">1.1.</Typography>
                        </ListItemIcon>
                    
                      
                
                        <ListItemText className={classes.listDescriptionStyle}>
                        <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                        These Terms of Use (“Terms”) govern the access or use by you, an individual, from within any country in the world of applications, websites, content, products,
                         and Services (the “Services”) made available by Australian Honey Ventures Pty Ltd, a private limited liability company established in Australia,
                          registered at the Australian Securities Investment Commission as Australian Honey Ventures Pty Ltd.
                         By accessing the Services, you agree to be bound by these Terms which establishes a contract ("Contract") between you and Australian Honey Ventures Pty Ltd.
                         </Typography>
                        </ListItemText>
                    </ListItem>



                    <ListItem>
                        <ListItemIcon>
                            <Typography variant="body2">1.2.</Typography>
                        </ListItemIcon>
                    
                      
                
                        <ListItemText className={classes.listDescriptionStyle}>
                        <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                        These Terms are an electronic contract that establishes the legally binding Terms you must accept and agree to use the Services. If you do not accept and agree to be bound by all of the Terms, including the Privacy Policy, you may not access or use the Services. Australian Honey Ventures Pty Ltd may immediately terminate these Terms or any Services with respect to you, or generally cease offering or deny access to the Services or any portion thereof, at any time for any reason.
                        </Typography>
                        </ListItemText>
                    </ListItem>




                            
                    <ListItem>
                        <ListItemIcon>
                            <Typography variant="body2">1.3.</Typography>
                        </ListItemIcon>
                    
                      
                
                        <ListItemText className={classes.listDescriptionStyle}>
                        <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                        By accessing or using the Services, you accept and agree to the Terms,
                         conditions and notices contained or referenced herein and consent to have these Terms and all notices,
                         provided to you in electronic form. To withdraw this consent,
                         you must cease using the Services and terminate your User Account.
                         </Typography>
                        </ListItemText>
                    </ListItem>





                    <ListItem>
                        <ListItemIcon>
                            <Typography variant="body2">1.4.</Typography>
                        </ListItemIcon>
                    
                      
                
                        <ListItemText className={classes.listDescriptionStyle}>
                        <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                        Supplemental terms may apply to certain Services, such as policies for a particular event,
                         activity or promotion, and such supplemental terms will be disclosed to you in connection with the applicable Services. Supplemental terms are in addition to,
                         and shall be deemed a part of, the Terms for the purposes of the applicable Services.
                         Supplemental terms shall prevail over these Terms in the event of a conflict with respect to the applicable Services.
                         </Typography>
                        </ListItemText>
                    </ListItem>



                        
                    <ListItem>
                        <ListItemIcon>
                            <Typography variant="body2">1.5.</Typography>
                        </ListItemIcon>
                    
                      
                
                        <ListItemText className={classes.listDescriptionStyle}>
                        <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                        The Services and all rights therein are and shall remain Australian Honey Ventures Pty Ltd property or the property of Australian Honey Ventures Pty Ltd licensors.
                         Neither these Terms nor your use of the Services convey or grant to you any rights: (i) in or related to the Services except for the limited license granted above;
                          or (ii) to use or reference in any manner Australian Honey Ventures Pty Ltd' company name, logos, product and Services names,
                         trademarks or Services marks.
                         </Typography>
                        </ListItemText>
                    </ListItem>



                    <ListItem>
                        <ListItemIcon>
                            <Typography variant="body2">1.6.</Typography>
                        </ListItemIcon>
                    
                      
                
                        <ListItemText className={classes.listDescriptionStyle}>
                        <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                        These Terms may be modified by Australian Honey Ventures Pty Ltd at any time and each such modification shall be
                         effective upon posting by Australian Honey Ventures Pty Ltd at this location or on the applicable Services.
                         Your continued use of the Platform or Services after such posting constitutes your consent to be bound by the Terms, as amended.
                         </Typography>
                        </ListItemText>
                    </ListItem>


                    <ListItem>
                        <ListItemIcon>
                            <Typography variant="body2">1.7.</Typography>
                        </ListItemIcon>
                    
                      
                
                        <ListItemText className={classes.listDescriptionStyle}>
                        <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                        You are responsible for identifying and complying with all Applicable Laws. By accessing and using the Services, you represent and warrant that (a) you have the right,
                         authority and capacity to enter into this agreement and to abide by all of the Terms; (b) all registration information you submit is truthful and accurate;
                         (c) you will maintain the accuracy of such information; and (d) your use of the Services does not violate any Applicable Laws.
                         </Typography>
                        </ListItemText>
                    </ListItem>





                    <ListItem>
                        <ListItemIcon>
                            <Typography variant="body2">1.8.</Typography>
                        </ListItemIcon>
                    
                      
                
                        <ListItemText className={classes.listDescriptionStyle}>
                        <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                        Except as otherwise set forth in these Terms, these Terms shall be exclusively governed by and construed in accordance with the laws of Western Australia,
                         excluding its rules on conflicts of laws. These Terms,
                          and any dispute between you and Australian Honey Ventures Pty Ltd,
                           shall be governed by the laws of the state of Western Australia without regard to principles of conflicts of law.
                         Any action shall take place in Perth, Western Australia and you consent to said jurisdiction.
                         </Typography>
                        </ListItemText>
                    </ListItem>



                    <ListItem>
                        <ListItemIcon>
                            <Typography variant="body2">1.9.</Typography>
                        </ListItemIcon>
                    
                      
                
                        <ListItemText className={classes.listDescriptionStyle}>
                        <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                        Australian Honey Ventures Pty Ltd may, in Australian Honey Ventures Pty Ltd' sole discretion, permit you from time to time to submit, upload, publish or otherwise make available to Australian Honey Ventures Pty Ltd through the Services, textual, audio, and/or visual content and information, including commentary and feedback related to the Services, initiation of support requests, and submission of entries for competitions and promotions (“User Content”). Any User Content provided by you remains your property. However, by providing User Content to Australian Honey Ventures Pty Ltd, you grant Australian Honey Ventures Pty Ltd a worldwide, perpetual, irrevocable, transferrable, royalty-free license, with the right to sublicense, to use, copy, modify, create derivative works of, distribute, publicly display, publicly perform, and otherwise exploit in any manner such User Content in all formats and distribution channels now known or hereafter devised (including in connection with the Services and Australian Honey Ventures Pty Ltd' business and on third-party sites and Services), without further notice to or consent from you, and without the requirement of payment to you or any other person or entity.
                       </Typography>
                        </ListItemText>
                    </ListItem>


                    <ListItem>
                        <ListItemIcon>
                            <Typography variant="body2">1.10.</Typography>
                        </ListItemIcon>
                    
                      
                
                        <ListItemText className={classes.listDescriptionStyle}>
                        <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                        You represent and warrant that: (i) you either are the sole and exclusive owner of all User Content or you have all rights,
                         licenses, consents and releases necessary to grant Australian Honey Ventures Pty Ltd the license to the User Content as set forth above; and (ii)
                          neither the User Content nor your submission, uploading,
                           publishing or otherwise making available of such User Content nor Australian Honey Ventures Pty Ltd' use of the User Content as permitted herein will infringe,
                            misappropriate or violate a third party’s intellectual property or proprietary rights,
                         or rights of publicity or privacy, or result in the violation of any applicable law or regulation.
                         </Typography>
                        </ListItemText>
                    </ListItem>



                    <ListItem>
                        <ListItemIcon>
                            <Typography variant="body2">1.11.</Typography>
                        </ListItemIcon>
                    
                      
                
                        <ListItemText className={classes.listDescriptionStyle}>
                        <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                        You agree to not provide User Content that is defamatory, libelous, hateful, violent, obscene,
                         pornographic, unlawful, or otherwise offensive, as determined by Australian Honey Ventures Pty Ltd in its sole discretion, whether or not such material may be protected by law.
                          Australian Honey Ventures Pty Ltd may, but shall not be obligated to, review, monitor, or remove User Content,
                         at Australian Honey Ventures Pty Ltd' sole discretion and at any time and for any reason, without notice to you. 
                         </Typography>
                        </ListItemText>
                    </ListItem>



                    
                    <ListItem>
                        <ListItemIcon>
                            <Typography variant="body2">1.12.</Typography>
                        </ListItemIcon>
                    
                      
                
                        <ListItemText className={classes.listDescriptionStyle}>
                        <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                        Australian Honey Ventures Pty Ltd is not responsible for providing insurance coverage as required by applicable legislation to Buyers,
                         or Suppliers, nor does Australian Honey Ventures Pty Ltd have any obligation to do so.
                         </Typography>
                        </ListItemText>
                    </ListItem>



                    <ListItem>
                        <ListItemIcon>
                            <Typography variant="body2">1.13.</Typography>
                        </ListItemIcon>
                    
                      
                
                        <ListItemText className={classes.listDescriptionStyle}>
                        <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                        Notwithstanding the above, Australian Honey Ventures Pty Ltd may also take out particular insurance coverage itself and at its sole discretion.
                        </Typography>
                        </ListItemText>
                    </ListItem>

                    </List>


                    <Typography variant="h5">2. Term & Termination</Typography>
                        <List>
                    <ListItem>
                        <ListItemIcon>
                            <Typography variant="body2">2.1.</Typography>
                        </ListItemIcon>
                    
                      
                
                        <ListItemText className={classes.listDescriptionStyle}>
                        <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                        These Terms will remain in full force and effect while you use the Services and/or have a User Account.
                         Australian Honey Ventures Pty Ltd reserves the right to investigate,
                          suspend and/or terminate your User Account if you have misused the Services or behaved in a way Australian Honey Ventures Pty Ltd regards as inappropriate or unlawful.
                         The following is a partial list of the type of actions that you may not engage in with respect to the Services. You will not: 
                        </Typography>
                        </ListItemText>



                    </ListItem>

                    </List>



                    <List dense style={{paddingLeft: 60}}>
                            <ListItem>
                            <ListItemIcon>
                            <Typography variant="body2">2.1.1.</Typography>
                        </ListItemIcon>

                        <ListItemText>
                            <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                            impersonate any person or entity;
                            </Typography>
                        </ListItemText>

                            </ListItem>



                            <ListItem>
                            <ListItemIcon>
                            <Typography variant="body2">2.1.2.</Typography>
                        </ListItemIcon>

                        <ListItemText>
                            <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                            use the Services in an illegal manner or to commit an illegal act;
                            </Typography>
                        </ListItemText>

                            </ListItem>


                            
                            <ListItem>
                            <ListItemIcon>
                            <Typography variant="body2">2.1.3.</Typography>
                        </ListItemIcon>

                        <ListItemText>
                            <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                            access the Services in a jurisdiction in which it is illegal or unauthorised;
                            </Typography>
                        </ListItemText>

                            </ListItem>



                                
                            <ListItem>
                            <ListItemIcon>
                            <Typography variant="body2">2.1.4.</Typography>
                        </ListItemIcon>

                        <ListItemText>
                            <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                            ask or use Users to conceal the identity, source, or destination of any illegally gained money or products;
                            </Typography>
                        </ListItemText>

                            </ListItem>



                            <ListItem>
                            <ListItemIcon>
                            <Typography variant="body2">2.1.5.</Typography>
                        </ListItemIcon>

                        <ListItemText>
                            <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                            use any robot, spider, site search/retrieval application, or other manual or automatic device or process to retrieve,
                             index, “data mine”, or in any way reproduce or circumvent the navigational structure or presentation of the Services or its contents;
                            </Typography>
                        </ListItemText>

                            </ListItem>




                            <ListItem>
                            <ListItemIcon>
                            <Typography variant="body2">2.1.6.</Typography>
                        </ListItemIcon>

                        <ListItemText>
                            <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                            collect Usernames and/or email addresses of Users by electronic or other means for the purpose of sending unsolicited email or
                             unauthorised framing of or linking to the Services;
                            </Typography>
                        </ListItemText>

                            </ListItem>




                            <ListItem>
                            <ListItemIcon>
                            <Typography variant="body2">2.1.7.</Typography>
                        </ListItemIcon>

                        <ListItemText>
                            <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                            interfere with or disrupt the Services or the servers or networks connected to the Services;
                            </Typography>
                        </ListItemText>

                            </ListItem>





                            <ListItem>
                            <ListItemIcon>
                            <Typography variant="body2">2.1.8.</Typography>
                        </ListItemIcon>

                        <ListItemText>
                            <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                            email or otherwise transmit any material that contains software viruses or any other computer code, files or programs designed to interrupt,
                             destroy or limit the functionality of any computer software or hardware or telecommunications equipment;
                            </Typography>
                        </ListItemText>

                            </ListItem>






                            <ListItem>
                            <ListItemIcon>
                            <Typography variant="body2">2.1.9.</Typography>
                        </ListItemIcon>

                        <ListItemText>
                            <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                            forge headers or otherwise manipulate identifiers
                             in order to disguise the origin of any information transmitted to or through the Services (either directly or indirectly through use of third party software);
                            </Typography>
                        </ListItemText>

                            </ListItem>








                            
                            <ListItem>
                            <ListItemIcon>
                            <Typography variant="body2">2.1.10.</Typography>
                        </ListItemIcon>

                        <ListItemText>
                            <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                            remove any copyright, trademark or other proprietary notices from any portion of the Services; 
                            </Typography>
                        </ListItemText>

                            </ListItem>





                                 
                            <ListItem>
                            <ListItemIcon>
                            <Typography variant="body2">2.1.11.</Typography>
                        </ListItemIcon>

                        <ListItemText>
                            <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                            reproduce, modify, prepare derivative works based upon, distribute, license, lease, sell, resell, transfer, publicly display, publicly perform, transmit,
                             stream, broadcast or otherwise exploit the Services except as expressly permitted by Australian Honey Ventures Pty Ltd; 
                            </Typography>
                        </ListItemText>

                            </ListItem>






                            <ListItem>
                            <ListItemIcon>
                            <Typography variant="body2">2.1.12.</Typography>
                        </ListItemIcon>

                        <ListItemText>
                            <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                            decompile, reverse engineer or disassemble the Services except as may be permitted by Applicable Laws; 
                            </Typography>
                        </ListItemText>

                            </ListItem>


                            
                            <ListItem>
                            <ListItemIcon>
                            <Typography variant="body2">2.1.13.</Typography>
                        </ListItemIcon>

                        <ListItemText>
                            <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                            link to, mirror or frame any portion of the Services; 
                            </Typography>
                        </ListItemText>

                            </ListItem>



                            <ListItem>
                            <ListItemIcon>
                            <Typography variant="body2">2.1.14.</Typography>
                        </ListItemIcon>

                        <ListItemText>
                            <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                            attempt to gain unauthorised access to or impair any aspect of the Services or its related systems or networks.
                            </Typography>
                        </ListItemText>

                            </ListItem>

                        </List>


                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">2.2</Typography>
                                    </ListItemIcon>
                                    <ListItemText>
                            <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                            Australian Honey Ventures Pty Ltd may terminate or suspend your User Account at any time without notice if Australian Honey Ventures Pty Ltd believes that you have breached these Terms, or for any other reason, with or without cause, in its sole discretion. Australian Honey Ventures Pty Ltd is not required to disclose, and may be prohibited by law from disclosing, the reason for the termination or suspension of your User Account.  After your User Account is terminated for any reason,
                             these Terms survive such termination, and continue in full force and effect, except for any terms that by their nature expire or are fully satisfied. 
                            </Typography>
                        </ListItemText>
                                </ListItem>
                            </List>


                            <Typography variant="h5">3. Term & Termination</Typography>

                            <List>


                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">3.1</Typography>
                                    </ListItemIcon>
                                    <ListItemText>
                            <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                            The Services constitutes a technology platform ("Platform") and supporting applications that enables Suppliers to supply honeybee products for sale via the Seller and consumers to buy honeybee products from Australian Honey Ventures Pty Ltd. 
                            </Typography>
                        </ListItemText>
                                </ListItem>

                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">3.2</Typography>
                                    </ListItemIcon>
                                    <ListItemText>
                            <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                            Subject to your compliance with these Terms, Australian Honey Ventures Pty Ltd grants you a limited, non-exclusive, non-sublicensable, revocable, non-transferrable license to: (i) access and use the Platform on your personal device solely in connection with your use of the Services; and (ii) access and use any content, information and related materials that may be made available through the Services, in each case solely for your personal use. Any rights not expressly granted herein are reserved by Australian Honey Ventures Pty Ltd.
                            </Typography>
                        </ListItemText>
                                </ListItem>

                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">3.3</Typography>
                                    </ListItemIcon>
                                    <ListItemText>
                            <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                            The Services may be made available or accessed in connection with Third Party Services and content (including advertising) that Australian Honey Ventures Pty Ltd does not control. You acknowledge that different terms of use and privacy policies may apply to your use of such Third Party Services and content. Australian Honey Ventures Pty Ltd does not endorse such Third Party Services and content and in no event shall Australian Honey Ventures Pty Ltd be responsible or liable for any products or Services of such third party providers.
                            </Typography>
                        </ListItemText>
                                </ListItem>


                            </List>


                            <Typography variant="h5">4. Your use of the services</Typography>
                            <Typography variant="h2" style={{fontSize: 18, paddingLeft: 30, paddingTop: 10}}>4.1 General user accounts</Typography>

                            <List style={{paddingLeft: 40}}>
                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.1.1</Typography>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography className={classes.descriptionStyle} variant="h2">To be eligible for an Australian Honey Ventures Pty Ltd User Account you must:</Typography>
                                    </ListItemText>
                                </ListItem>
                            </List>




                            <List dense style={{paddingLeft: 90}}>


                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.1.1.1</Typography>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant="h2" className={classes.descriptionStyle}>be a natural person either representing themselves or a business entity;</Typography>
                                    </ListItemText>
                                </ListItem>


                                    
                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.1.1.2</Typography>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant="h2" className={classes.descriptionStyle}>be over the age of 18 (at Australian Honey Ventures Pty Ltd' absolute sole discretion,
                                         we may allow for a person under the age of 18 to create a profile/account if they have appropriate permission and direct supervision
                                         by the owner of the account and the owner acknowledges and agrees to be responsible for any and all account activity conducted by a minor on their account);</Typography>
                                    </ListItemText>
                                </ListItem>



                                        
                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.1.1.3</Typography>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography className={classes.descriptionStyle} variant="h2">possess Products and services that align with the Australian Honey Ventures Pty Ltd' objectives and brand;</Typography>
                                    </ListItemText>
                                </ListItem>


                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.1.1.4</Typography>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography className={classes.descriptionStyle} variant="h2">be able to satisfy Australian Honey Ventures Pty Ltd vetting requirements;</Typography>
                                    </ListItemText>
                                </ListItem>


                                
                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.1.1.5</Typography>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography className={classes.descriptionStyle} variant="h2">be able to form legally binding contracts;</Typography>
                                    </ListItemText>
                                </ListItem>


                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.1.1.6</Typography>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography className={classes.descriptionStyle} variant="h2">have a valid email address;</Typography>
                                    </ListItemText>
                                </ListItem>

                                
                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.1.1.7</Typography>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography className={classes.descriptionStyle} variant="h2">have a valid ABN;</Typography>
                                    </ListItemText>
                                </ListItem>

                            </List>




                            




                            <List style={{paddingLeft: 40}}>



                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.1.2</Typography>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography className={classes.descriptionStyle} align="justify" variant="h2">You are responsible for maintaining the confidentiality of any Username and password you designate while utilising the Services, and you are solely responsible for all activities that occur under your Username and password. You agree to immediately notify Australian Honey Ventures Pty Ltd of any disclosure or unauthorised use of your Username or password or any other breach of security on the Platform and ensure that you log out from your User Account at the end of each session.</Typography>
                                    </ListItemText>
                                </ListItem>



                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.1.3</Typography>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography className={classes.descriptionStyle} align="justify"  variant="h2">You are solely responsible for your interactions with other Users including any sales between Users. You understand that Australian Honey Ventures Pty Ltd currently does not conduct criminal background checks or screenings on its Users. Australian Honey Ventures Pty Ltd also does not inquire into the backgrounds of all of its Users. Australian Honey Ventures Pty Ltd makes no representations or warranties as to the conduct of Users, their posted content and information, or their products or Services. Furthermore, Australian Honey Ventures Pty Ltd makes no representations or warranties regarding any products or Services.</Typography>
                                    </ListItemText>
                                </ListItem>




                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.1.4</Typography>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography className={classes.descriptionStyle} align="justify" variant="h2">Australian Honey Ventures Pty Ltd is not responsible for the conduct of any User or their products and Services. In no event shall Australian Honey Ventures Pty Ltd, its affiliates or its partners be liable (directly or indirectly) for any losses or damages whatsoever, whether direct, indirect, general, special, compensatory, consequential, and/or incidental, arising out of or relating to the conduct of you or anyone else in connection with the use of the Services including, without limitation, death, bodily injury, emotional distress, and/or any other damages resulting from communications or transactions with other Users or persons you engage through the Services. You agree to take all necessary precautions in all interactions with other Users.</Typography>
                                    </ListItemText>
                                </ListItem>



                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.1.5</Typography>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography align="justify" variant="h2" className={classes.descriptionStyle}>You assume all risk when using the Services, including but not limited to all of the risks associated with any online or offline purchases or sales. You agree to take all necessary precautions when selling or purchasing through the Platform. Australian Honey Ventures Pty Ltd also does not verify the statements of its Users. </Typography>
                                    </ListItemText>
                                </ListItem>




                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.1.6</Typography>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography align="justify" variant="h2" className={classes.descriptionStyle}>Users are solely responsible for the content and information that they post, upload, publish, link to, transmit, record, display or otherwise make available ("Post") on the Services or transmit to other Users, including messages, chat ,photographs, or profile text, whether publicly posted or privately transmitted ("Content"). You may not post as part of the Services, or transmit to Australian Honey Ventures Pty Ltd or any other User (either on or off the Services), any offensive, inaccurate, incomplete, abusive, obscene, profane, threatening, intimidating, harassing, racially offensive, or illegal material, or any material that infringes or violates another person’s rights (including intellectual property rights, and rights of privacy and publicity). You represent and warrant that (i) all information that you submit upon creation of your User Account, is accurate and truthful and that you will promptly update any information provided by you that subsequently becomes inaccurate, incomplete, misleading or false and (ii) you have the right to post the Content on the Services and grant the licenses set forth below.</Typography>
                                    </ListItemText>
                                </ListItem>




                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.1.7</Typography>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography align="justify" variant="h2" className={classes.descriptionStyle}>You understand and agree that Australian Honey Ventures Pty Ltd may, but is not obligated, monitor or review any Content you post as part of a Services. Australian Honey Ventures Pty Ltd may delete any Content, in whole or in part, that in the sole judgment of Australian Honey Ventures Pty Ltd violates this Agreement or may harm the reputation of the Services or Australian Honey Ventures Pty Ltd.</Typography>
                                    </ListItemText>
                                </ListItem>



                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.1.8</Typography>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography align="justify" variant="h2" className={classes.descriptionStyle}>You agree that Australian Honey Ventures Pty Ltd may access, preserve and disclose your User Account information and Content if required to do so by law or in good faith believe that such access, preservation or disclosure is reasonably necessary, such as to: (i) comply with legal process; (ii) enforce this Agreement; (iii) respond to claims that any Content violates the rights of third parties; (iv) respond to your requests for customer Services or allow you to use the Services in the future; or (v) protect the rights, property or personal safety of Australian Honey Ventures Pty Ltd or any other person.</Typography>
                                    </ListItemText>
                                </ListItem>



                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.1.9</Typography>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography align="justify" variant="h2" className={classes.descriptionStyle}>You acknowledge and agree that any Content you place on the Services may be viewed by other Users and may be viewed by any person visiting or participating in the Services.</Typography>
                                    </ListItemText>
                                </ListItem>

                            </List>






                            <Typography variant="h2" style={{fontSize: 18, paddingLeft: 30, paddingTop: 10}}>4.2 Suppliers</Typography>

                            <List style={{paddingLeft: 40}}>


                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.2.1</Typography>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography align="justify" variant="h2" className={classes.descriptionStyle}>Suppliers interested in having Australian Honey Ventures Pty Ltd sell their honeybee products on the Platform will be
                                         required to complete the registration process.
                                         Australian Honey Ventures Pty Ltd reviews Supplier registrations to ensure applicants and their products meet the criteria to sell on the Platform. </Typography>
                                    </ListItemText>
                                </ListItem>


                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.2.2</Typography>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant="h2" className={classes.descriptionStyle}>Seller User Accounts can be cancelled anytime.</Typography>
                                    </ListItemText>
                                </ListItem>



                                
                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.2.3</Typography>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant="subtitle2">Supply Agreement</Typography>
                                    </ListItemText>
                                </ListItem>


                            </List>



                            <List style={{paddingLeft: 90}}>



                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.2.3.1.</Typography>
                                    </ListItemIcon>

                                    <ListItemText>
                                        <Typography align="justify" variant="h2" className={classes.descriptionStyle}>A Supply Agreement is deemed to have been entered into when the Supplier has expressed to the Seller the wish to use
                                         its services and the Seller has agreed to provide the services. The Supplier and Seller enter into a written or an electronic Supply Agreement.
                                          The Supply Agreement is also deemed entered into, if the Supplier delivers their first load of honeybee products to an Australian Honey Ventures Pty Ltd facility,
                                           or any other drop-off location as arranged between the two parties.
                                         In this case, the Supply Agreement will enter into force retroactively as of the moment when the Seller received the Supplier’s honeybee products.</Typography>
                                    </ListItemText>
                                </ListItem>





                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.2.3.2.</Typography>
                                    </ListItemIcon>

                                    <ListItemText>
                                        <Typography align="justify" variant="h2" className={classes.descriptionStyle}>The Supply Agreement applies only to the honeybee products delivered by the Supplier on any particular day and shall be concluded between the parties upon the Seller executing a sale for all such products, unless the parties agree for the Supply Agreement to be extended for a period of time.  </Typography>
                                    </ListItemText>
                                </ListItem>




                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.2.3.3.</Typography>
                                    </ListItemIcon>

                                    <ListItemText>
                                        <Typography align="justify" variant="h2" className={classes.descriptionStyle}>The Supply Agreement documents and process include the application, agreements, notifications, Supplier interest questionnaires, supply terms and other documents, invoices and letters of the parties. </Typography>
                                    </ListItemText>
                                </ListItem>


                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.2.3.4.</Typography>
                                    </ListItemIcon>

                                    <ListItemText>
                                        <Typography align="justify" variant="h2" className={classes.descriptionStyle}>After receiving an expression of will from the Supplier, Australian Honey Ventures Pty Ltd will store data regarding the Suppliers honeybee products on the Platform which can be accessed by the Supplier. </Typography>
                                    </ListItemText>
                                </ListItem>



                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.2.3.5.</Typography>
                                    </ListItemIcon>

                                    <ListItemText>
                                        <Typography align="justify" variant="h2" className={classes.descriptionStyle}>The Supplier agrees that by delivering honeybee products and entry into a Supply Agreement, the Supplier or the representative thereof confirms having the complete right to submit such application and enter into the Supply Agreement, assuming liability in case of the absence of relevant authorisations.</Typography>
                                    </ListItemText>
                                </ListItem>


                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.2.3.6.</Typography>
                                    </ListItemIcon>

                                    <ListItemText>
                                        <Typography align="justify" variant="h2" className={classes.descriptionStyle}>Making amendments to the Supply Agreement is possible upon mutual agreement between the Seller and the Supplier. The Seller has the right to amend the general terms of the Supply Agreement unilaterally by informing the Supplier or publicly via its website thereof no less than 1 (one) month in advance.</Typography>
                                    </ListItemText>
                                </ListItem>



                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.2.3.7.</Typography>
                                    </ListItemIcon>

                                    <ListItemText>
                                        <Typography align="justify" variant="h2" className={classes.descriptionStyle}>The Supplier has the right to cancel the Supply Agreement if the Seller notifies the Supplier of
                                         amending the general terms without the consent of the Supplier. The Supplier must inform the Seller of cancelling the Supply Agreement in
                                          written or electronic form no less than 7 (seven) days in advance.</Typography>
                                    </ListItemText>
                                </ListItem>



                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.2.3.8.</Typography>
                                    </ListItemIcon>

                                    <ListItemText>
                                        <Typography align="justify" variant="h2" className={classes.descriptionStyle}>The Supplier confirms being aware of the fact that if the Seller pays the Upfront Payment on account thereof,
                                         the Seller will have the right of recourse to the Supplier’s honeybee products in the Seller’s
                                          possession, should the Supplier can el the Supply Agreement.</Typography>
                                    </ListItemText>
                                </ListItem>



                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.2.3.9.</Typography>
                                    </ListItemIcon>

                                    <ListItemText>
                                        <Typography align="justify" variant="h2" className={classes.descriptionStyle}>The amount of upfront payments and additional bonus payment arrangements shall be specified in the Supply Agreement. All expenses related to the Seller’s service are specified in either the Supply Contract or these Terms. The Supplier shall bear all its expenses related to means of producing/manufacturing their honeybee products, and communication with the Seller and/or any third party.</Typography>
                                    </ListItemText>
                                </ListItem>


                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.2.3.10.</Typography>
                                    </ListItemIcon>

                                    <ListItemText>
                                        <Typography align="justify" variant="h2" className={classes.descriptionStyle}>The Supply Agreement will enter into force on the date specified in the Supply Agreement, or the date in which the Supplier delivers their honeybee products.  </Typography>
                                    </ListItemText>
                                </ListItem>



                            </List>








                            <List style={{paddingLeft: 40}}>


                                <ListItem>
                                    <ListItemIcon>
                                        <Typography variant="body2">4.2.4</Typography>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant="subtitle2">Tax Obligations</Typography>
                                    </ListItemText>
                                </ListItem>


                                </List>









                                

                            <List style={{paddingLeft: 90}}>



                            <ListItem>
                                <ListItemIcon>
                                    <Typography variant="body2">4.2.4.1.</Typography>
                                </ListItemIcon>

                                <ListItemText>
                                    <Typography align="justify" variant="h2" className={classes.descriptionStyle}>It is the Supplier’s responsibility to ensure that,
                                     if necessary, proper sales tax is applied to specific products. </Typography>
                                </ListItemText>
                            </ListItem>


                            <ListItem>
                                <ListItemIcon>
                                    <Typography variant="body2">4.2.4.2.</Typography>
                                </ListItemIcon>

                                <ListItemText>
                                    <Typography align="justify" variant="h2" className={classes.descriptionStyle}>Suppliers acknowledge and agree to seek independent advice from the ATO,
                                     Accountant or Lawyer as to what structure is appropriate for your person situation. </Typography>
                                </ListItemText>
                            </ListItem>


                            <ListItem>
                                <ListItemIcon>
                                    <Typography variant="body2">4.2.4.3.</Typography>
                                </ListItemIcon>

                                <ListItemText>
                                    <Typography align="justify" variant="h2" className={classes.descriptionStyle}>Suppliers are solely responsible for being familiar with and compliant to all applicable tax and tax regulatory obligations in
                                     relation to any payment received through the Platform from Australian Honey Ventures Pty Ltd,
                                     including filing of tax returns and making necessary payments required by any federal, state, local or foreign tax authority. </Typography>
                                </ListItemText>
                            </ListItem>



                            <ListItem>
                                <ListItemIcon>
                                    <Typography variant="body2">4.2.4.4.</Typography>
                                </ListItemIcon>

                                <ListItemText>
                                    <Typography align="justify" variant="h2" className={classes.descriptionStyle}>The Supplier agrees and accepts that Australian Honey Ventures Pty Ltd does not collect and remit
                                     sales tax reporting on behalf of a Supplier.</Typography>
                                </ListItemText>
                            </ListItem>



                            </List>






                            <Typography variant="h2" style={{fontSize: 18, paddingLeft: 30, paddingTop: 10}}>4.3 Buyers</Typography>

                                <List style={{paddingLeft: 40}}>


                                    <ListItem>
                                        <ListItemIcon>
                                            <Typography variant="body2">4.3.1</Typography>
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography align="justify" variant="h2" className={classes.descriptionStyle}>in order to use most aspects of the Services, you must register for and maintain an active
                                             personal User Services User Account (“User Account”). You must be at least 18 years of age, or the age of legal majority in your jurisdiction
                                              (if different than 18), to obtain a User Account. User Account registration requires you to submit to Australian Honey Ventures Pty Ltd certain personal information,
                                               such as your name, address, mobile phone number and age, as well as at least one valid payment method (either a credit card or accepted payment partner).
                                                You agree to maintain accurate, complete, and up-to-date information in your User Account. Your failure to maintain accurate, complete, and up-to-date User Account information,
                                                 including having an invalid or expired payment method on file,
                                             may result in your inability to access and use the Services or Australian Honey Ventures Pty Ltd' termination of these Terms with you. 
                                             </Typography>
                                        </ListItemText>
                                    </ListItem>


                                    <ListItem>
                                        <ListItemIcon>
                                            <Typography variant="body2">4.3.2</Typography>
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography align="justify" variant="h2" className={classes.descriptionStyle}>You are responsible for all activity that occurs under your User Account,
                                             and you agree to maintain the security and secrecy of your User Account Username and password at all times. 
                                             </Typography>
                                        </ListItemText>
                                    </ListItem>



                                    </List>







                                        
                            <Typography variant="h2" style={{fontSize: 18, paddingLeft: 30, paddingTop: 10}}>4.4 Fees & Services</Typography>

<List style={{paddingLeft: 40}}>


    <ListItem>
        <ListItemIcon>
            <Typography variant="body2">4.4.1</Typography>
        </ListItemIcon>
        <ListItemText>
            <Typography align="justify" variant="h2" className={classes.descriptionStyle}>Australian Honey Ventures Pty Ltd charges fees for certain Services, such as:
             </Typography>
        </ListItemText>
    </ListItem>

    </List>


    <List dense style={{paddingLeft: 90}}>


    <ListItem>
        <ListItemIcon>
            <Typography variant="body2">4.4.1.1.</Typography>
        </ListItemIcon>
        <ListItemText>
            <Typography align="justify" variant="h2" className={classes.descriptionStyle}>a packing fee for each successful sale processed by the Platform.
             This fee covers the cost of packing and marketing a bulk raw honeybee product, ready for wholesale distribution. The packing fee is $6/kg;
             </Typography>
        </ListItemText>
    </ListItem>



    
    <ListItem>
        <ListItemIcon>
            <Typography variant="body2">4.4.1.2.</Typography>
        </ListItemIcon>
        <ListItemText>
            <Typography align="justify" variant="h2" className={classes.descriptionStyle}>a Service Fee for each successful sale processed by the Platform. This is considered an introduction
             fee owed to Australian Honey Ventures Pty Ltd for arranging the sale. The Service Fee is charged at the Supplier’s end, with the Service Fee being deducted from the
             transaction value that was agreed by Australian Honey Ventures and the Buyer at the time of purchase. The Service Fee is 75% of Sale Profit; 
             </Typography>
        </ListItemText>
    </ListItem>


    <ListItem>
        <ListItemIcon>
            <Typography variant="body2">4.4.1.3.</Typography>
        </ListItemIcon>
        <ListItemText>
            <Typography align="justify" variant="h2" className={classes.descriptionStyle}>fees for processing payments on successful transactions
             (e.g. PayPal and Stripe processing fees). The payment processing fee is charged at the Buyer's end and is 2.5% of the transaction value.
             </Typography>
        </ListItemText>
    </ListItem>



    </List>







    <List style={{paddingLeft: 40}}>


    <ListItem>
        <ListItemIcon>
            <Typography variant="body2">4.4.2</Typography>
        </ListItemIcon>
        <ListItemText>
            <Typography align="justify" variant="h2" className={classes.descriptionStyle}>Australian Honey Ventures Pty Ltd may choose to temporarily change the fees for the Services
             for promotional events (for example, discounts on memberships) or new Services,
             and such changes are effective when a temporary promotional event or new service on the Platform is posted, or as notified through promotional correspondence. 
             </Typography>
        </ListItemText>
    </ListItem>



    <ListItem>
        <ListItemIcon>
            <Typography variant="body2">4.4.3</Typography>
        </ListItemIcon>
        <ListItemText>
            <Typography align="justify" variant="h2" className={classes.descriptionStyle}>As between you and Australian Honey Ventures Pty Ltd,
             Australian Honey Ventures Pty Ltd reserves the right to establish, remove and/or revise Charges for any or all of the goods obtained through the use of the Platform at
              any time in Australian Honey Ventures Pty Ltd sole discretion. Australian Honey Ventures Pty Ltd may from time to time provide certain Users with promotional offers and
               discounts that may result in different amounts charged for the same or similar goods obtained through the use of the Platform, and you agree that such promotional offers and discounts,
             unless also made available to you, shall have no bearing on your use of the Services or the Charges applied to you. 
             </Typography>
        </ListItemText>
    </ListItem>




    <ListItem>
        <ListItemIcon>
            <Typography variant="body2">4.4.4</Typography>
        </ListItemIcon>
        <ListItemText>
            <Typography align="justify" variant="h2" className={classes.descriptionStyle}>Australian Honey Ventures Pty Ltd reserves the right to change the fee structure and terms applying to payments from time to time, albeit it a temporary or permanent change. Any change relating to the fees is effective seven (7) days after Australian Honey Ventures Pty Ltd notifies Users of that change by sending a message to Users via email or through their User Account, or by notifying Users the next time they login to the Platform.
             </Typography>
        </ListItemText>
    </ListItem>




    
    <ListItem>
        <ListItemIcon>
            <Typography variant="body2">4.4.5</Typography>
        </ListItemIcon>
        <ListItemText>
            <Typography align="justify" variant="h2" className={classes.descriptionStyle}>If Australian Honey Ventures Pty Ltd introduces a new service on the Platform that attracts a fee, the fee applying to that service will be payable as from the launch of the service.
             </Typography>
        </ListItemText>
    </ListItem>



    
    <ListItem>
        <ListItemIcon>
            <Typography variant="body2">4.4.6</Typography>
        </ListItemIcon>
        <ListItemText>
            <Typography align="justify" variant="h2" className={classes.descriptionStyle}>Unless otherwise stated, all fees are quoted in Australian Dollars.
             </Typography>
        </ListItemText>
    </ListItem>




    
    <ListItem>
        <ListItemIcon>
            <Typography variant="body2">4.4.7</Typography>
        </ListItemIcon>
        <ListItemText>
            <Typography align="justify" variant="h2" className={classes.descriptionStyle}>Australian Honey Ventures Pty Ltd may, in Australian Honey Ventures Pty Ltd' sole discretion, create promotional codes that may be redeemed for User Account credit, or other features or benefits related to the Services, subject to any additional terms that Australian Honey Ventures Pty Ltd establishes on a per promotional code basis (“Promo Codes”). You agree that Promo Codes: (i) must be used for the intended audience and purpose, and in a lawful manner; (ii) may not be duplicated, sold or transferred in any manner, or made available to the general public (whether posted to a public form or otherwise), unless expressly permitted by Australian Honey Ventures Pty Ltd; (iii) may be disabled by Australian Honey Ventures Pty Ltd at any time for any reason without liability to Australian Honey Ventures Pty Ltd; (iv) may only be used pursuant to the specific terms that Australian Honey Ventures Pty Ltd establishes for such Promo Code; (v) are not valid for cash; and (vi) may expire prior to your use. Australian Honey Ventures Pty Ltd reserves the right to withhold or deduct credits or other features or benefits obtained through the use of Promo Codes by you or any other User in the event that Australian Honey Ventures Pty Ltd determines or believes that the use or redemption of the Promo Code was in error, fraudulent, illegal, or in violation of the applicable Promo Code terms or these Terms.
             </Typography>
        </ListItemText>
    </ListItem>

    </List>



    <Typography variant="h5">5. Invoicing, Payments & Funds</Typography>

<List className={classes.listStyle} aria-label="contacts">
<ListItem>
    <ListItemIcon>
        <Typography variant="body2">5.1.</Typography>
    </ListItemIcon>

  

    <ListItemText className={classes.listDescriptionStyle}>
    <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
    You understand that use of the Platform may result in charges to you for the products you receive from a Third Party Seller (“Charges”).
     After you have purchased goods obtained through your use of the Platform, Australian Honey Ventures Pty Ltd will facilitate your payment of the applicable Charges.
     Charges paid by you are final and non-refundable, unless otherwise determined by Australian Honey Ventures Pty Ltd. 
     </Typography>
    </ListItemText>
</ListItem>



<ListItem>
    <ListItemIcon>
        <Typography variant="body2">5.2.</Typography>
    </ListItemIcon>

  

    <ListItemText className={classes.listDescriptionStyle} >
    <Typography variant="h2" align="justify" className={classes.descriptionStyle}>All Charges are due immediately at check-out, unless otherwise agreed by Australian Honey Ventures Pty Ltd,
     and payment will be facilitated by Australian Honey Ventures Pty Ltd using the preferred payment method designated in your User Account,
      after which Australian Honey Ventures Pty Ltd will send you a receipt by email.
       If your primary User Account payment method is determined to be expired,
        invalid or otherwise not able to be charged, you agree that Australian Honey Ventures Pty Ltd may, use a secondary payment method in your User Account, if available.
    
     </Typography>
    </ListItemText>
</ListItem>




<ListItem>
    <ListItemIcon>
        <Typography variant="body2">5.3.</Typography>
    </ListItemIcon>

  

    <ListItemText className={classes.listDescriptionStyle} >
    <Typography variant="h2" align="justify" className={classes.descriptionStyle}>Payment made by Buyers will be held in Escrow until the Buyer marks the transaction as complete (received),
     which is when the funds (minus the Packing Fee and Service Fee) will then be released to the Supplier. 
    
     </Typography>
    </ListItemText>
</ListItem>




<ListItem>
    <ListItemIcon>
        <Typography variant="body2">5.4.</Typography>
    </ListItemIcon>

  

    <ListItemText className={classes.listDescriptionStyle}>
    <Typography variant="h2" align="justify" className={classes.descriptionStyle}>All fees and charges payable to Australian Honey Ventures Pty Ltd are non-cancellable and non-refundable, and subject to these Terms. 
    
     </Typography>
    </ListItemText>
</ListItem>



</List>







                    <Typography variant="h5">6. Copyright</Typography>

                    <List className={classes.listStyle} aria-label="contacts">
                    <ListItem>
                        <ListItemIcon>
                            <Typography variant="body2">6.1.</Typography>
                        </ListItemIcon>

                    

                        <ListItemText className={classes.listDescriptionStyle}>
                        <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                        You may not post, distribute, or reproduce in any way any copyrighted material, trademarks,
                         or other proprietary information without obtaining the prior written consent of the owner of such proprietary rights. Without limiting the foregoing,
                          if you believe that your work has been copied and posted on the Platform in a way that constitutes copyright infringement,
                         please provide send the following information to support@australianhoneyventures.com.au 
                        </Typography>
                        </ListItemText>
                    </ListItem>


                    </List>





                    <List dense style={{paddingLeft: 90}} aria-label="contacts">
                    <ListItem>
                        <ListItemIcon>
                            <Typography variant="body2">6.1.1.</Typography>
                        </ListItemIcon>

                    

                        <ListItemText className={classes.listDescriptionStyle}>
                        <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                        identification of the copyrighted work that you claim has been infringed;
                        </Typography>
                        </ListItemText>
                    </ListItem>



                    <ListItem>
                        <ListItemIcon>
                            <Typography variant="body2">6.1.2.</Typography>
                        </ListItemIcon>

                    

                        <ListItemText className={classes.listDescriptionStyle}>
                        <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                        identification of the material that you claim is infringing and information reasonably
                         sufficient to enable Australian Honey Ventures Pty Ltd to find the material on the Services, such as a URL;
                        </Typography>
                        </ListItemText>
                    </ListItem>




                    <ListItem>
                        <ListItemIcon>
                            <Typography variant="body2">6.1.3.</Typography>
                        </ListItemIcon>

                    

                        <ListItemText className={classes.listDescriptionStyle}>
                        <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                        your address, telephone number and email address;
                        </Typography>
                        </ListItemText>
                    </ListItem>




                    <ListItem>
                        <ListItemIcon>
                            <Typography variant="body2">6.1.4.</Typography>
                        </ListItemIcon>

                    

                        <ListItemText className={classes.listDescriptionStyle}>
                        <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                        a written statement by you that you have a good faith belief that the disputed use is not authorised by the copyright owner, its agent, or the law; and
                        </Typography>
                        </ListItemText>
                    </ListItem>




                    <ListItem>
                        <ListItemIcon>
                            <Typography variant="body2">6.1.5.</Typography>
                        </ListItemIcon>

                    

                        <ListItemText className={classes.listDescriptionStyle}>
                        <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                        a statement by you, made under penalty of perjury,
                         that the above information in your notice is accurate and that you are the copyright owner or authorised to act on the copyright owner’s behalf; and
                        </Typography>
                        </ListItemText>
                    </ListItem>




                    <ListItem>
                        <ListItemIcon>
                            <Typography variant="body2">6.1.6.</Typography>
                        </ListItemIcon>

                    

                        <ListItemText className={classes.listDescriptionStyle}>
                        <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
                        a statement by you, made under penalty of perjury,
                        an electronic or physical signature of the person authorised to act on behalf of the owner of the copyright interest.
                        </Typography>
                        </ListItemText>
                    </ListItem>


                    </List>







                    <Typography variant="h5">7. Disclaimers, Limitation of Liability & Indemnity</Typography>
                    <Typography variant="h2" style={{fontSize: 18, paddingLeft: 30, paddingTop: 10}}>7.1.  Disclaimer</Typography>

<List className={classes.listStyle} aria-label="contacts">
<ListItem>
    <ListItemIcon>
        <Typography variant="body2">7.1.1.</Typography>
    </ListItemIcon>



    <ListItemText className={classes.listDescriptionStyle}>
    <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
    The Services are provided “as is” and “as available.” Australian Honey Ventures Pty Ltd disclaims all representations and warranties, express, implied or statutory,
     not expressly set out in these terms, including the implied warranties of merchantability, fitness for a particular purpose and non-infringement.
      In addition, Australian Honey Ventures Pty Ltd makes no representation, warranty, or guarantee regarding the reliability,
       quality, suitability or availability of the products listed for sale by third party sellers. Australian Honey Ventures Pty Ltd does not guarantee the quality,
        suitability, safety or ability of third party sellers. Australian Honey Ventures Pty Ltd does not represent or warrant that the Platform will be uninterrupted or error free,
         secure or that any defects or errors in the Platform will be corrected. You agree that the entire risk arising out of your use of the Platform,
     and any Services or product requested in connection therewith, remains solely with you, to the maximum extent permitted under applicable law.
    </Typography>
    </ListItemText>
</ListItem>



<ListItem>
    <ListItemIcon>
        <Typography variant="body2">7.1.2.</Typography>
    </ListItemIcon>



    <ListItemText className={classes.listDescriptionStyle}>
    <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
    The Platform may contain, and the Platform may provide, advertisements and promotions offered by third parties and links to other web sites or resources.
     You acknowledge and agree that Australian Honey Ventures Pty Ltd is not responsible for the availability of such external websites or resources,
      and does not endorse and is not responsible or liable for any content, information, statements, advertising, goods or Services,
       or other materials on or available from such websites or resources. Your correspondence or business dealings with, or participation in promotions of,
        third parties found in or through the Services, including payment and delivery of related goods or Services, and any other terms,
         conditions, warranties or representations associated with such dealings, are solely between you and such third party. You further acknowledge and agree that Australian
          Honey Ventures Pty Ltd shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of,
     or reliance upon, any such content, information, statements, advertising, goods or Services or other materials available on or through any such website or resource.
    </Typography>
    </ListItemText>
</ListItem>




<ListItem>
    <ListItemIcon>
        <Typography variant="body2">7.1.3.</Typography>
    </ListItemIcon>



    <ListItemText className={classes.listDescriptionStyle}>
    <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
    The tax and business structure information provided in these Terms is for informational purposes and shall not be construed as accounting / legal advice.
     Australian Honey Ventures Pty Ltd disclaims all responsibility for any or all losses, damages,
     or causes of action that a Supplier or Buyer may encounter as a result of such guidance. 
    </Typography>
    </ListItemText>
</ListItem>

</List>






<Typography variant="h5">8. Limitation of Liability</Typography>

<List className={classes.listStyle} aria-label="contacts">
<ListItem>
    <ListItemIcon>
        <Typography variant="body2">8.1.</Typography>
    </ListItemIcon>



    <ListItemText className={classes.listDescriptionStyle}>
    <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
    Australian Honey Ventures Pty Ltd shall not be liable for indirect, incidental, special, exemplary, punitive or consequential damages,
     including lost profits, lost data, loss of good-will, personal injury, emotional stress or property damage related to, in connection with,
      or otherwise resulting from any use of the Platform, even if Australian Honey Ventures Pty Ltd has been advised of the possibility of such damages.
       Australian Honey Ventures Pty Ltd shall not be liable for any damages, liability or losses arising out of:
        (i) any transaction or relationship between you and Australian Honey Ventures Pty Ltd, or any third party provider,
     even if Australian Honey Ventures Pty Ltd has been advised of the possibility of such damages. 
    </Typography>
    </ListItemText>
</ListItem>




<ListItem>
    <ListItemIcon>
        <Typography variant="body2">8.2.</Typography>
    </ListItemIcon>



    <ListItemText className={classes.listDescriptionStyle}>
    <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
    The limitations and disclaimer in this section do not purport to limit liability or alter your rights as a consumer that cannot be excluded under applicable law.
    </Typography>
    </ListItemText>
</ListItem>

</List>
















<Typography variant="h5">9. Indemnity</Typography>

<List className={classes.listStyle} aria-label="contacts">
<ListItem>
    <ListItemIcon>
        <Typography variant="body2">9.1.</Typography>
    </ListItemIcon>



    <ListItemText className={classes.listDescriptionStyle}>
    <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
    You agree to indemnify and hold Australian Honey Ventures Pty Ltd and its officers, directors, employees and agents harmless from any and all claims, demands, losses,
     liabilities, and expenses (including attorneys’ fees) arising out of or in connection with: (i) your use of the Services or Services or products obtained through your use of the Services;
     (ii) your breach or violation of any of these Terms; (iii) Australian Honey Ventures Pty Ltd' use of your User Content; or (iv) your violation of the rights of any third party.
    </Typography>
    </ListItemText>
</ListItem>


</List>






<Typography variant="h5">10. Other Provisions</Typography>

<List className={classes.listStyle} aria-label="contacts">
<ListItem>
    <ListItemIcon>
        <Typography variant="body2">10.1.</Typography>
    </ListItemIcon>



    <ListItemText className={classes.listDescriptionStyle}>
    <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
    If any provision of these Terms is held to be illegal, invalid or unenforceable, in whole or in part,
     under any law, such provision or part thereof shall to that extent be deemed not to form part of these Terms but the legality,
      validity and enforceability of the other provisions in these Terms shall not be affected. In that event, the parties shall replace the illegal,
       invalid or unenforceable provision or part thereof with a provision or part thereof that is legal, valid and enforceable and that has,
        to the greatest extent possible,
         a similar effect as the illegal, invalid or unenforceable provision or part thereof,
          given the contents and purpose of these Terms.
           These Terms constitute the entire agreement and understanding of the parties with respect to its subject matter and replaces and
            supersedes all prior or contemporaneous agreements or undertakings regarding such subject matter.
     In these Terms, the words “including” and “include” mean “including, but not limited to.”
    </Typography>
    </ListItemText>
</ListItem>


</List>






<Typography variant="h5">11. Dispute Resolution</Typography>

<List className={classes.listStyle} aria-label="contacts">
<ListItem>
    <ListItemIcon>
        <Typography variant="body2">11.1.</Typography>
    </ListItemIcon>
    <ListItemText className={classes.listDescriptionStyle}>
    <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
    Users acknowledge and agree to alert Australian Honey Ventures Pty Ltd if they're expectations are not being met satisfactorily. 
    </Typography>
    </ListItemText>
</ListItem>


<ListItem>
    <ListItemIcon>
        <Typography variant="body2">11.2.</Typography>
    </ListItemIcon>
    <ListItemText className={classes.listDescriptionStyle}>
    <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
    If a dispute arises between a User and Australian Honey Ventures Pty Ltd, the User should contact Australian Honey Ventures Pty Ltd straight away.
    </Typography>
    </ListItemText>
</ListItem>

</List>







<Typography variant="h5">12. Abuse Within the Platform</Typography>

<List className={classes.listStyle} aria-label="contacts">
<ListItem>
    <ListItemIcon>
        <Typography variant="body2">12.1.</Typography>
    </ListItemIcon>
    <ListItemText className={classes.listDescriptionStyle}>
    <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
    The moment you login to the Platform or communicate with the Australian Honey Ventures Pty Ltd team, you can expect a level of Service that is of a high standard. 
    </Typography>
    </ListItemText>
</ListItem>


<ListItem>
    <ListItemIcon>
        <Typography variant="body2">12.2.</Typography>
    </ListItemIcon>
    <ListItemText className={classes.listDescriptionStyle}>
    <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
    You agree to be respectful and courteous in your dealings on the Platform, to other Users, Third Party Service providers and to the Australian Honey Ventures Pty Ltd team, by:
    </Typography>
    </ListItemText>
</ListItem>

</List>




<List dense style={{paddingLeft: 90}} aria-label="contacts">
<ListItem>
    <ListItemIcon>
        <Typography variant="body2">12.2.1.</Typography>
    </ListItemIcon>
    <ListItemText className={classes.listDescriptionStyle}>
    <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
    Using the Platform and Services for legitimate and bona fide purposes; 
    </Typography>
    </ListItemText>
</ListItem>


<ListItem>
    <ListItemIcon>
        <Typography variant="body2">12.2.2.</Typography>
    </ListItemIcon>
    <ListItemText className={classes.listDescriptionStyle}>
    <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
    Not infringing the intellectual property rights of Users, Third Party Services or Australian Honey Ventures Pty Ltd;
    </Typography>
    </ListItemText>
</ListItem>


<ListItem>
    <ListItemIcon>
        <Typography variant="body2">12.2.3.</Typography>
    </ListItemIcon>
    <ListItemText className={classes.listDescriptionStyle}>
    <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
    Acting consistently with the letter or spirit of Australian Honey Ventures Pty Ltd Terms and policies; and
    </Typography>
    </ListItemText>
</ListItem>



<ListItem>
    <ListItemIcon>
        <Typography variant="body2">12.2.4.</Typography>
    </ListItemIcon>
    <ListItemText className={classes.listDescriptionStyle}>
    <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
    Speaking respectfully to other Users, Third Party Service providers and the Australian Honey Ventures Pty Ltd team.
    </Typography>
    </ListItemText>
</ListItem>




</List>





<List className={classes.listStyle} aria-label="contacts">
<ListItem>
    <ListItemIcon>
        <Typography variant="body2">12.3.</Typography>
    </ListItemIcon>
    <ListItemText className={classes.listDescriptionStyle}>
    <Typography variant="h2" align="justify" className={classes.descriptionStyle}>
    Australian Honey Ventures Pty Ltd reserves the right, without limiting any other remedies, to remove any Content, limit, suspend or terminate Services and/or User Accounts,  and to take any and all technical or legal steps to ban Users indefinitely, if a User contravenes the respect standards mentioned above. 
    </Typography>
    </ListItemText>
</ListItem>

</List>



                           



                        



                        


                    
       
        
     </Grid>
       
   
     </Grid>
 </Container>
 {/* <NewFooter /> */}

</div>














    )
}