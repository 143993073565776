import React from 'react';
import { Button, Container, Grid, Link, List, ListItem, Hidden, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { AccessTime, Call } from '@material-ui/icons';
import { BottomBarNavigation } from './BottomBarNavigation';
import {createBrowserHistory} from 'history'


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderBottom: '1px solid #efefef',
            backgroundColor: '#fbfbfb',

            [theme.breakpoints.down('sm')]: {

                marginBottom: '10%',
                paddingBottom: '6%',
            },

            // borderTop: '1px solid #efefef',
        },

        mainDiv: {

            [theme.breakpoints.down('sm')]: {

                paddingBottom: '5%',

            },


        },
        button: {},
        listStyle: {
            display: 'flex',
            flexDirection: 'column',
        },
        headingStyle: {
            fontSize: '1.3rem',
            fontFamily: 'sophia',
            paddingBottom: 15,
            [theme.breakpoints.down('sm')]: {

                fontSize: '1rem',

            },
        },
        linkStyle: {
            fontFamily: 'sophia-light',
            lineHeight: 2.0,
            color: '#565252',
            fontSize: '1.0rem',
            letterSpacing: 0.8,
            fontWeight: 600,
        },
        mediumStyle: {
            fontFamily: 'sophia-regular',
        },
        shopButton: {
            marginBottom: '6%',
            color: '#ffffff',
            backgroundColor: '#f28f16',
            borderRadius: 20,
            boxShadow: 'none',
            fontFamily: 'sophia',
        },
        btnStyle: {
            color: '#ffffff',
            backgroundColor: '#f28f16',
            fontSize: 17,
            width: 210,
            boxShadow: 'none',
            borderRadius: 28,
            paddingLeft: 22,
            paddingRight: 22,
            paddingTop: 14,
            paddingBottom: 14,
            fontFamily: 'sophia',
            fontWeight: 400,
            letterSpacing: 0.8,
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#fecf51',
                    color: '#ffffff',
                    boxShadow: 'none',
                },
            },
        },
        callbtnStyle: {
            color: '#f28f16',
            backgroundColor: '#ffffff',
            fontSize: 17,
            width: 210,
            border: '2px solid #f28f16',
            boxShadow: 'none',
            borderRadius: 28,
            paddingLeft: 22,
            paddingRight: 22,
            paddingTop: 14,
            paddingBottom: 14,
            fontFamily: 'sophia',
            fontWeight: 400,
            letterSpacing: 0.8,
            '&.MuiButton-contained': {
                '&:hover': {
                    color: '#f28f16',
                    backgroundColor: '#ffffff',
                    boxShadow: 'none',
                    border: '3px solid #f28f16',
                },
            },
        },
        mainStyle: {
            // marginTop: '6%',
            // marginBottom: '6%',
            paddingTop: '6%',
            paddingBottom: '12%',
            backgroundColor: '#fbfbfb',
        },
    }),
);
export const NewFooter = () => {
    const classes = useStyles();
    return (
        <div style={{ backgroundColor: '#fbfbfb' }} className={classes.mainDiv}>
            <Container className={classes.root} maxWidth="lg" style={{ padding: '5%' }}>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item lg={4} xs={6}>
                        <List component="nav" className={classes.listStyle}>
                            <ListItem>
                                <Typography className={classes.headingStyle} variant="h4">
                                    About US
                                </Typography>
                            </ListItem>
                            {/* <ListItem>
                            <Link href="#">
                                <Typography className={classes.linkStyle} variant="subtitle2">
                                    About AVH
                                </Typography>
                            </Link>
                        </ListItem> */}
                            <ListItem>
                                <Link href="/buyer">
                                    <Typography className={classes.linkStyle} variant="subtitle2">
                                        Buy On AHV
                                    </Typography>
                                </Link>
                            </ListItem>
                            <ListItem>
                                <Link href="/seller">
                                    <Typography className={classes.linkStyle} variant="subtitle2">
                                        Sell On AHV
                                    </Typography>
                                </Link>
                            </ListItem>
                            <ListItem>
                                <Link href="https://firebasestorage.googleapis.com/v0/b/cobalt-broker-279401.appspot.com/o/AHV%202022%20Financials.pdf?alt=media&token=423a6872-9d9b-402f-9b6e-9775e6d31984">
                                    <Typography className={classes.linkStyle} variant="subtitle2">
                                        Shareholders
                                    </Typography>
                                </Link>
                            </ListItem>
                            {/* <ListItem>
                            <Link href="#">
                                <Typography className={classes.linkStyle} variant="subtitle2">
                                    Locations
                                </Typography>
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link href="#">
                                <Typography className={classes.linkStyle} variant="subtitle2">
                                    Gift Cards
                                </Typography>
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link href="#">
                                <Typography className={classes.linkStyle} variant="subtitle2">
                                    Investor Relations
                                </Typography>
                            </Link>
                        </ListItem> */}
                        </List>
                    </Grid>
                    <Grid item lg={4} xs={6}>
                        <List component="nav" className={classes.listStyle}>
                            <ListItem>
                                <Typography className={classes.headingStyle} variant="h4">
                                    Customer Service
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Link href="dashboard/orders">
                                    <Typography className={classes.linkStyle} variant="subtitle2">
                                        My Orders
                                    </Typography>
                                </Link>
                            </ListItem>
                            {/* <ListItem>
                            <Link href="#">
                                <Typography className={classes.linkStyle} variant="subtitle2">
                                    Track My Order
                                </Typography>
                            </Link>
                        </ListItem> */}
                            <ListItem>
                                <Link href="/shop">
                                    <Typography className={classes.linkStyle} variant="subtitle2">
                                        Honey Products
                                    </Typography>
                                </Link>
                            </ListItem>
                            <ListItem>
                                <Link href="/contact-us">
                                    <Typography className={classes.linkStyle} variant="subtitle2">
                                        Contact Us
                                    </Typography>
                                </Link>
                            </ListItem>
                            {/* TODO : THIS IS REMOVED FOR LIVE VERSION <ListItem>
                            <Link href="#">
                                <Typography className={classes.linkStyle} variant="subtitle2">
                                    Privacy Policy
                                </Typography>
                            </Link>
                        </ListItem> */}
                            <ListItem>
                                <Link href="/terms">
                                    <Typography className={classes.linkStyle} variant="subtitle2">
                                        Terms & Conditions
                                    </Typography>
                                </Link>
                            </ListItem>

                            {/* <ListItem>
                                <Link href="/invest-with-us" onClick={()=> {
                                    createBrowserHistory().push('/invest-with-us')
                                }} >
                                    <Typography className={classes.linkStyle} variant="subtitle2">
                                        Invest With Us
                                    </Typography>
                                </Link>
                            </ListItem> */}
                            <ListItem>
                                <Link href="https://birchal.com/company/ahv" onClick={()=> {
                                    // createBrowserHistory().push('/invest-with-us')
                                }} >
                                    <Typography className={classes.linkStyle} variant="subtitle2">
                                        Invest With Us 
                                    </Typography> 
                                </Link>
                            </ListItem>
                        </List>
                    </Grid>
                    <Hidden only={['sm', 'xs']}>
                        <Grid item lg={4}>
                            <List component="nav" className={classes.listStyle}>
                                <ListItem>
                                    <Typography className={classes.headingStyle} variant="h4">Contact Us</Typography>
                                </ListItem>
                                <ListItem>
                                    <Link style={{ textDecoration: 'none' }} href="contact-us">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            className={classes.btnStyle}
                                            startIcon={<AccessTime />}
                                        >
                                            Quick Service
                                        </Button>
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <a style={{ textDecoration: 'none' }} href="tel:+61407920954">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            className={classes.callbtnStyle}
                                            startIcon={<Call />}
                                        >
                                            Call Us
                                        </Button>
                                    </a>
                                </ListItem>
                                {/* <ListItem>
                            <Link href="#">
                                <Typography className={classes.linkStyle} variant="subtitle2">
                                    Mon - Fri
                                </Typography>
                            </Link>
                        </ListItem> */}
                                <ListItem>

                                    <Typography className={classes.linkStyle} variant="subtitle2">
                                        8.30am - 5.00pm
                                    </Typography>

                                </ListItem>
                                <ListItem>

                                    <Typography className={classes.linkStyle} variant="subtitle2">
                                        Time zone is (UTC+08:00) Perth
                                    </Typography>

                                </ListItem>

                            </List>
                        </Grid>
                    </Hidden>
                </Grid>

            </Container>

            <Hidden only={['xl', 'lg',]}>
                <BottomBarNavigation />
            </Hidden>


        </div>
    )
}