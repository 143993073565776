import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { ContactUsForm } from './ContactUsForm';
import { Container, Grid, Hidden } from '@material-ui/core';
import { Team } from '../LandingPage/Team';
import { HeaderImage } from './HeaderImage';
import { ThanksPage } from '../Dashboard/Order/ThanksPage';
import { MobileHeader } from '../Components/MobileHeader';
import { TestComponent } from './TestComponent';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
root: {
// marginTop: 7,
marginTop: -22,

paddingBottom: '10%',

[theme.breakpoints.down('lg')]: {
  marginTop: -4,
},
},
   
  }),
);



export const ContactLayout = () => {
    const classes = useStyles();

    return(

        <div className={classes.root}>

    <Hidden only={['xl', 'lg', 'md']}>
      <MobileHeader />
    </Hidden>
                 
                    <HeaderImage />
                    <Team />
                    <ContactUsForm />
                 
                   
                  
                 
              
        
        </div>
    )
}