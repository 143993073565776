import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link, Typography, ListSubheader } from '@material-ui/core';
import { Send, Drafts } from '@material-ui/icons';
import { Home, Shop, ShoppingCart } from '@material-ui/icons';



const useStyles = makeStyles({
  list: {
    width: 300,
  },
  fullList: {
    width: 'auto',
  },
});

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export const MobileMenuBar = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({

    left: false,

  });

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >



      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">

          </ListSubheader>
        }
      // className={classes.root}
      >
        <Link href="/">
          <ListItem button>

            <ListItemText primary="Home" />

          </ListItem>
        </Link>

        <Link href="/shop">
          <ListItem button>

            <ListItemText primary="Shop" />
          </ListItem>
        </Link>


        <Link href="/seller">
          <ListItem button>

            <ListItemText primary="Seller Guide" />
          </ListItem>
        </Link>


        <Link href="/buyer">
          <ListItem button>

            <ListItemText primary="Buyer Guide" />
          </ListItem>

        </Link>


        <Link href="/medicinal-honey">
          <ListItem button>

            <ListItemText primary="Medicinal Honey" />
          </ListItem>
        </Link>


        <Link href="/our-apiaries">
          <ListItem button>

            <ListItemText primary="Our Apiaries" />
          </ListItem>
        </Link>


        {/* <Link href="/invest-with-us">
          <ListItem button>

            <ListItemText primary="Invest With Us" />
          </ListItem>
        </Link> */}

        <Link href="https://birchal.com/company/ahv">
          <ListItem button>

            <ListItemText primary="Invest With Us" />
          </ListItem>
        </Link>



        <Link href="/contact-us">
          <ListItem button>

            <ListItemText primary="Contact Us" />
          </ListItem>
        </Link>



      </List>




      {/* <List> */}


      {/* {['Home', 'Shop', 'Seller Guide', 'Buyer Guide', 'About AHV', 'Contact Us', 'Contact us'].map((text, index) => (
          <ListItem button key={text}>
            
            <ListItemIcon>{index % 2 === 0 ? <Home /> : <Shop />}</ListItemIcon>
            
            <ListItemText primary={text} />
            <Divider />
          </ListItem>
          
        ))} */}
      {/* </List> */}


    </div>
  );

  return (
    <div>
      {(['left'] as Anchor[]).map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton onClick={toggleDrawer(anchor, true)}><MenuIcon /></IconButton>
          {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}