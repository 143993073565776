import React, {FunctionComponent} from "react";
import {DrawerPanel} from "../Components/DrawerPanel";
import {MainBody} from "../MainBody";
import {NewFooter} from "../Components/NewFooter";
import {FooterBottom} from "../Components/FooterBottom";
import {GlobalLogin} from "../Components/GlobalLogin";
import {UpdatedHeader} from "../Components/UpdatedHeader";
import Hidden from '@material-ui/core/Hidden';
import { MobileHeader } from './../Components/MobileHeader';
import { BottomBar } from './../Components/BottomBar';
import { OrderSummary } from './../Dashboard/Order/OrderSummary';
import {useEffect, useState} from 'react';
import {API, getval} from './../Helper';
import { NewHeader } from "../Components/NewHeader";
import { HomeSlider } from './../Components/HomeSlider';
import { ServicesUpdated } from './../Components/ServicesUpdated';
import { FourPoints } from './../Components/FourPoints';
import { LandingLayout } from './../LandingPage/LandingLayout';
import { BackToTop } from './../Components/BackToTop';




export const HomePage: FunctionComponent = () => {
  
  
    return <div className="App" >
      
     
        <Hidden only={['xs', 'sm', 'md', ]}>
        <NewHeader />
        </Hidden>
        <Hidden only={['lg', 'xl']}>
            <MobileHeader />
            </Hidden>
            {/* <HomeSlider /> */}
            {/* <FourPoints /> */}
        {/* <MainBody/> */}
        <LandingLayout />
    
      
       

        <DrawerPanel/>
        <NewFooter/>
        {/* <Hidden only={['lg', 'xl']}>
        <BottomBar/>
        </Hidden> */}
      
        <GlobalLogin/>
      
    </div>
}