import React, { useEffect, useState, FunctionComponent } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import largeproduct from '../../assets/largeproduct.jpg';
import { Grid, IconButton } from '@material-ui/core';
import { Favorite, FavoriteBorder } from '@material-ui/icons';
import { API, Bbc, LoginHelper, AppEvent, Cook } from '../../Helper';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            maxWidth: 768,
            maxHeight: 383,
            alignItems: 'center',
            paddingLeft: '5%',

            [theme.breakpoints.down('sm')]: {
                marginBottom: '41%',
            },

            [theme.breakpoints.down('xs')]: {
                marginBottom: '16%',
            },
        },
        favoriteIcon: {
            [theme.breakpoints.down('sm')]: {
                marginTop: '-1200%',
            },
        },
    }),
);
export const CarouselComponent: FunctionComponent<{ images: any[], primary: string, p: any }> = props => {
    const classes = useStyles();
    const [wishing, setWishing] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [p, setP] = useState(props.p);
    return (
        <div>
            <Grid container  >
                <Grid item lg={11}>
                    <Carousel className={classes.root} selectedItem={props.images.indexOf(props.primary)}>
                        {props.images.map((x, i) => {
                            return <div >
                                <img src={x} alt="nature image" />
                            </div>
                        })}
                    </Carousel>
                </Grid>

                <Grid item lg={1} style={{ marginTop: -15 }}>
                    <IconButton className={classes.favoriteIcon} onClick={async () => {
                        setWishing(true);
                        if (p['wished']) {
                            if (await LoginHelper.isLoggedIn()) {
                                var res = await API.delete('products/wishlist', { productid: p['id'], userid: Cook.getUserData()['user']['id'] })
                                if (res.status == 200) {
                                    setP({ ...p, wished: false })
                                    enqueueSnackbar('Removed from Wishlist')

                                }
                            } else {

                                Bbc.fire(AppEvent.requestLoginDialog);
                            }
                            setWishing(false);

                            return;
                        }


                        if (await LoginHelper.isLoggedIn()) {
                            var res = await API.post('products/wishlist', { product: p, userid: Cook.getUserData()['user']['id'] })
                            if (res.status == 200) {
                                setP({ ...p, wished: true })
                                enqueueSnackbar('Added to Wishlist')

                            }
                        } else {
                            Bbc.fire(AppEvent.requestLoginDialog);
                        }
                        setWishing(false);

                    }}>

                        {p['wished'] ? <Favorite style={{ color: '#f28f16', fontSize: 45, }} /> : <FavoriteBorder style={{ color: '#f28f16', fontSize: 45, }} />}


                    </IconButton>
                </Grid>
            </Grid>
        </div>
    );
}
