import React, {FunctionComponent, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Box, Grid, List, ListItem, Typography, Link} from '@material-ui/core';
import {Facebook, Instagram, LinkedIn, Twitter} from '@material-ui/icons';
import honeylogo from './../assets/honeylogo.png';
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {LoginHelper} from "../Helper";
import {Loader} from './LoaderButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
            backgroundColor: '#ffffff',
        },
        PopupBtnStyle: {
            fontFamily: 'sophia-regular',
            fontWeight: 1000,
            fontSize: 14,
            color: '#ffffff',
            padding: 10,
            width: '100%',
            backgroundColor: '#f28f16',
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#f28f16',
                    color: '#ffffff',
                    boxShadow: 'none',
                    textDecoration: 'none',
                },
            },
        },
        btnStyle: {
            fontFamily: 'sophia-regular',
            fontWeight: 1000,
            fontSize: 14,
            color: '#ffffff',
            padding: 10,
            backgroundColor: '#f28f16',
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#f28f16',
                    color: '#ffffff',
                    boxShadow: 'none',
                    textDecoration: 'none',
                },
            },
        },
        btnCancel: {
            color: '#ffffff',
            backgroundColor: '#737373',
            boxShadow: 'none',
            borderRadius: 4,
            fontFamily: 'sophia',
            fontWeight: 600,
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#938f8f',
                    color: '#ffffff',
                    boxShadow: 'none',
                },
            },
        },
        btnLogin: {
            color: '#ffffff',
            backgroundColor: '#f28f16',
            boxShadow: 'none',
            borderRadius: 4,
            fontFamily: 'sophia',
            fontWeight: 600,
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#fecf51',
                    color: '#ffffff',
                    boxShadow: 'none',
                },
            },
        },
        tabRoot: {
            flexGrow: 1,
            // backgroundColor: theme.palette.background.paper,
            backgroundColor: 'transparent',
            minHeight: '65vh',
            maxHeight: '65vh',
        },
        AppBar: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            color: '#757575',
        },
        dialog: {
            minHeight: '100vh',
            maxHeight: '100vh',
        },
        mainRoot: {
            minWidth: 250,
            maxWidth: 370,
        },
        honeylogo: {
            paddingBottom: '5%',
        },
    }),
);

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const CreateAccount: FunctionComponent<{ onLogin?: (x?: any) => void }> = props => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [value, setValue] = React.useState(1);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };
    return (
        <div className={classes.mainRoot}>
            <Grid container spacing={0}
                  direction="column"
                  alignItems="center"
                  justify="center">
                <Grid item lg={12} xs={12}>
                    <img src={honeylogo} alt="image-logo" className={classes.honeylogo}/>
                </Grid>
            </Grid>
            <div className={classes.tabRoot}>
                <AppBar position="static" className={classes.AppBar}>
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        {/* <Tab label="Register" {...a11yProps(0)} /> */}
                        <Tab label="Sign in" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <Grid item lg={12} xs={12}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="fname"
                            label="Name"
                            type="name"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="phone"
                            label="Phone"
                            type="phone"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="name"
                            label="Email Address"
                            type="email"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="name"
                            label="Password"
                            type="password"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <br/>
                        <Loader  icon={<div></div>} className={classes.PopupBtnStyle} variant="contained">Register</Loader>
                    </Grid>
                    <Grid item lg={12} xs={12} style={{paddingTop: 25, minWidth: 300, maxWidth: 300}}>
                        <Typography variant="caption">By creating an account, you agree to the
                            Australian Honey Ventures, Terms and Privacy Policy</Typography>
                    </Grid>
                    <Grid container spacing={0}
                          direction="column"
                          alignItems="center"
                          justify="center">
                        <Grid item lg={12} xs={12}>
                            <br/>
                            <Typography style={{fontSize: 16,}} variant="subtitle2">Quick Access With</Typography>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <List style={{display: 'flex', flexDirection: 'row'}}>
                                <ListItem>
                                    <Facebook/>
                                </ListItem>
                                <ListItem>
                                    <LinkedIn/>
                                </ListItem>
                                <ListItem>
                                    <Twitter/>
                                </ListItem>
                                <ListItem>
                                    <Instagram/>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <ValidatorForm onSubmit={async () => {
                        setLoading(true);
                        await LoginHelper.login(email, password).then(x => {
                            if (x.length == 0) {
                                if (props.onLogin) {
                                    props.onLogin();
                                }
                            } else {
                            }
                        });
                        setLoading(false);
                    }}>
                        <Grid item lg={12} xs={12}>
                            <TextValidator
                                value={email}
                                onChange={e => setEmail(e.target['value'])}
                                validators={['required', 'isEmail']}
                                errorMessages={['email is required', 'email is in invalid format']}
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                size='small'
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                            />
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <TextValidator
                                value={password}
                                onChange={e => setPassword(e.target['value'])}
                                validators={['required']}
                                errorMessages={['Password is required']}
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                size='small'
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <br/>
                            <Loader  icon={<div></div>} isLoading={loading} fullWidth className={classes.PopupBtnStyle} type={'submit'}
                                    variant="contained">Sign In</Loader>
                        </Grid>
                    </ValidatorForm>
                    <Grid item lg={12} xs={12} style={{paddingTop: 25, minWidth:250, maxWidth: 300}}>
                        {/* <Typography style={{lineHeight: -3.0,}} variant="caption">Please login to your account so you can submit your offer.</Typography> */}
                        <Typography variant="subtitle2" align="left">Don't have an account? <Link href="signup/">Sign-up</Link></Typography>
                    </Grid>
                    {/* <Grid container spacing={0}
                          direction="column"
                          alignItems="center"
                          justify="center">
                        <Grid item lg={12} xs={12}>
                            <br/>
                            <Typography style={{fontSize: 16,}} variant="subtitle2">Quick Access With</Typography>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <List style={{display: 'flex', flexDirection: 'row'}}>
                                <ListItem>
                                    <Facebook/>
                                </ListItem>
                                <ListItem>
                                    <LinkedIn/>
                                </ListItem>
                                <ListItem>
                                    <Twitter/>
                                </ListItem>
                                <ListItem>
                                    <Instagram/>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid> */}
                </TabPanel>
            </div>
        </div>
    );
}