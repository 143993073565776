import React, {useEffect, useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import {Box, Button, Slider, Typography} from '@material-ui/core';
import {API, AppEvent, Bbc} from '../Helper';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        priceroot: {
            width: 250,
            border: '1px solid #efefef',
            padding: '6%',
        },
        headingStyleMain: {
            fontFamily: 'sophia',
            fontSize: 28,
            color: '#3d3a3a',
        },
        slider: {
            color: '#f28f16',
        },
        pricebtnStyle: {
            backgroundColor: '#f28f16',
            color: '#ffffff',
            boxShadow: 'none',
        },
        root: {
            maxWidth: 300,
            marginBottom: '5%',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #efefef',
            '&.MuiListItemText-root': {
                fontSize: 6,
            },
        },
        textStyle: {
            fontSize: 10,
        },
        listItemText: {
            fontSize: '0.8em',
        },
        checkbox: {
            color: '#aaaaaa',
            fontSize: '0.8em',
            '&.Mui-checked': {
                color: '#f28f16',
            },
        },
    }),
);

function valuetext(value: number) {
    return `${value}AUD`;
}

export const AllWidgets = () => {
    const classes = useStyles();
//slider
    const [value, setValue] = React.useState<number[]>([20, 67]);
    const handleChange = (event: any, newValue: number | number[]) => {
        setValue(newValue as number[]);
    };
//slider end
    const [checked, setChecked] = React.useState<number[]>([]);
    const [cats, setCats] = useState([])
    useEffect(() => {
        API.get('categories').then(x => {
            console.log(cats);
            setCats(x.data);
        })
    }, []);
    const handleToggle = (value: number) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };
    return (
        <Box>
            <List className={classes.root}>
                {cats.map((value) => {
                    const labelId = `checkbox-list-label-${value['id']}`;
                    return (
                        <div>
                            <ListItem key={value} role={undefined} dense button onClick={handleToggle(value)}>
                                <ListItemIcon>
                                    <Checkbox
                                        className={classes.checkbox}
                                        edge="start"
                                        checked={checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{'aria-labelledby': labelId}}
                                    />
                                </ListItemIcon>
                                <ListItemText classes={{primary: classes.listItemText}} id={labelId}
                                              primary={`${value['name']}`}/>
                            </ListItem>
                        </div>
                    );
                })}
            </List>
            <Typography align="left" className={classes.headingStyleMain} variant="h6">Price</Typography><br/>
            <div className={classes.priceroot}>
                <Typography variant="caption" id="range-slider" gutterBottom>
                    Price {value[0]} AUD - {value[1]} AUD
                </Typography>
                <br/><br/>
                <Slider
                    max={1000}
                    className={classes.slider}
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    getAriaValueText={valuetext}
                />
                <br/><br/>
                <Button onClick={() => {
                    API.post('products/filter', {
                        categories: checked,
                        priceMin: value[0],
                        priceMax: value[1]
                    }).then(x => {
                        console.log(x.data);
                        Bbc.fire(AppEvent.productsFiltered, x.data);
                    })
                }} className={classes.pricebtnStyle} variant="contained">Filter</Button>
            </div>
        </Box>
    );
}