import React, { FunctionComponent, useEffect, useState } from 'react';
import { Hidden, Box, Link, Breadcrumbs, Container, Typography, Grid, List, ListItem, Divider, Card, CardMedia, MenuItem, Tooltip, Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import productthumn from './../assets/productthumn.jpg';
import { API, AppEvent, Bbc, Cook, LoginHelper, Ways } from "./../Helper";
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Loader } from './../Components/LoaderButton';
import question from './../assets/question.png';
import { cnfMenuList, packagingMenuList, shippingStrategyMenuList, timingMenuList } from './../Constants';
import { createBrowserHistory } from 'history';



const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: '#666666',
        //   color: 'rgba(0, 0, 0, 0.87)',
        color: '#fff',
        maxWidth: 360,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);



const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        root: {

            paddingTop: '2%',
            paddingBottom: '5%',
            backgroundColor: '#fbfbfb',

        },
        productBtn: {
            color: '#ffffff',
            backgroundColor: '#f28f16',
            border: '1px solid #f28f16',
            boxShadow: 'none',
            borderRadius: 4,
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 10,
            paddingBottom: 10,
            fontFamily: 'sophia',
            width: '100%',
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#FCB040',
                    color: '#ffffff',
                    boxShadow: 'none',
                },
            },

        },

        productBtnoutlined: {

            backgroundColor: 'transparent',
            border: '1px solid #303030',
            marginLeft: '19%',
            color: '#303030',
            boxShadow: 'none',
            borderRadius: 4,
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 10,
            paddingBottom: 10,
            fontFamily: 'sophia',
            width: '100%',
            '&.MuiButton-outlined': {
                '&:hover': {
                    backgroundColor: '#fbfbfb',
                    border: '1px solid #a5a4a4',
                    color: '#303030',
                    boxShadow: 'none',
                },
            },


            [theme.breakpoints.down('sm')]: {
                marginLeft: '0%',
            },
        },

        media: {
            maxWith: 95,
            maxHeight: 95,
            marginTop: '5%',
        },

        heading: {
            fontFamily: 'sophia',
        },




        breadcrumbBox: {
            paddingLeft: '5%',
            paddingRight: '5%',
            paddingTop: '1%',
            paddingBottom: '1%',
            backgroundColor: '#efeded',
        },


        tooltipItem: {
            marginLeft: '-2%',
            // marginRight: '-%',
            minWidth: 340,
            [theme.breakpoints.down('sm')]: {
                minWidth: 250,
            },

        },

        mainDiv: {
            [theme.breakpoints.down('sm')]: {
                
                // paddingRight: '18%',
            },


        },

        fieldStyle: {


            [theme.breakpoints.up('sm')]: {
                minWidth: '100%',
            },
            [theme.breakpoints.down('xs')]: {
             maxWidth: 294,
                // maxWidth: 412,
            },

        }




    }),
);




export const QuoteForm: FunctionComponent<{ quote: any }> = props => {
    const classes = useStyles();
    const [product, SetProduct] = useState({})
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('editing');
    const [offer, setOffer] = useState({});

    useEffect(() => {
        Bbc.subscribe(AppEvent.loggedIn, async (data) => {
            setOffer({ ...offer, address: (Cook.getUserData() || {})['user']['address'] ?? {} })
        });
        if (Cook.getUserData() != null) {
            var address = (Cook.getUserData() || {})['user']['address'] ?? {};
            delete address['id']
            setOffer({ ...offer, ...address })
        }
    }, [])

    const set = (e: React.FormEvent) => {
        var n = e.target['name'];
        var v = e.target['value'];
        offer[n] = v;
        setOffer({ ...offer })
        console.log(offer);
    }

    return (
        <Box className={classes.mainDiv}>
        <div>
            <ValidatorForm onSubmit={async () => {
                setLoading(true);
                try {
                    var req = await API.post('offer', {
                        ...offer,
                        product: product,
                        user: Cook.getUserData()['user']
                    }).then(async x => {
                        console.log(x.data);
                        if (offer['saveAddress'] && offer['saveAddress'] == true) {
                            await LoginHelper.updateUserData();
                        }
                        setOffer({});
                        setStatus('done');
                    })
                } catch (x) {
                    setStatus('error')
                }
            }}>
                       { window.location.pathname.includes('checkout/') ? <div></div> : 
                <div>
         
                {props.quote['status'] == 'Quoted' &&
                        <Grid container justify="center" direction="row" spacing={2} style={{ paddingRight: '3%', marginBottom: '2%' }}>

                            <Grid item lg={3}>

                            </Grid>
                            <Grid item lg={4} xs={12}>
                                <br />
                                <Button variant="outlined" onClick={() => {
                                    API.get('singleChat', {
                                        offerid: props.quote['id'],
                                        userid: Cook.getUserData()['user']['id']
                                    }).then(x => {
                                        createBrowserHistory().push(Ways.chatPage + '/' + x.data['id'])
                                        window.location.reload();
                                    })
                                }} className={classes.productBtnoutlined}>
                                    Chat With AHV
                            </Button>
                            </Grid>
                            <Grid item lg={1}></Grid>



                            <Grid item lg={4} xs={12}>
                                <br />
                                <Button variant="contained" className={classes.productBtn} onClick={() => {
                                    createBrowserHistory().push(Ways.checkoutPage + '/' + props.quote['id'])
                                    window.location.reload();
                                }}>
                                    Accept & Place Order
                            </Button>
                            </Grid>
                            <br />



                        </Grid>

                        }
                    
                    </div>
}

                <div className={classes.root}>

               






                    <Grid container style={{ paddingTop: '1%', paddingBottom: '3%', paddingLeft: '3%', paddingRight: '3%', marginTop: '4%', backgroundColor: '#fbfbfb' }}>





                        <Grid item lg={6} xs={12}>

                        { window.location.pathname.includes('checkout/') ? <div></div> : 
                        <div>   
                        <Grid item lg={12} xs={12}>

                        {
                            window.location.pathname.includes('dashboard/orders/order/') ?
                            <Typography variant="h6" className={classes.heading} style={{fontSize: '1.5rem'}}>My Order Requirements</Typography>
                            :

<Typography variant="h6" className={classes.heading} style={{fontSize: '1.5rem'}}>My Quote Requirements</Typography>
}
</Grid><br />
</div>
}

                            <List style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                <ListItem className={classes.tooltipItem}><Typography variant="h6" className={classes.heading}>What’s My Shipping Preference?</Typography></ListItem>
                                {/* <ListItem >
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>

                                                <Typography variant="caption">FOB - AHV arranges and pays for delivery to nearest port and I will handle the rest </Typography><br />
                                                <Typography variant="caption">CIF - AHV arranges and pays for delivery to the my country’s port and I will handle the rest  </Typography><br />
                                                <Typography variant="caption">DDP - AHV arranges and pays for the whole delivery direct to my warehouse  </Typography><br />


                                            </React.Fragment>
                                        }
                                    >
                                        <img src={question} />
                                    </HtmlTooltip>
                                </ListItem> */}
                            </List>


                        </Grid>


                   

                        <Grid style={{ paddingBottom: '2%' }} item lg={12} xs={12} sm={12}>

                            <SelectValidator
                                name='cnf'
                                // fullWidth={true}
                                // className={classes.fieldStyle}
                                fullWidth={true}
                                variant="outlined"
                                validators={['required']}
                                errorMessages={['CNF is required']}
                                onChange={set}
                                disabled
                                value={props.quote['cnf']}
                                label="I am Looking For"
                            >
                                {cnfMenuList}
                            </SelectValidator>
                        </Grid>


                        <Grid item lg={12} xs={12}>

                            <Typography variant="h6" className={classes.heading}>Delivery Schedule</Typography>
                        </Grid>

                        <Grid item lg={12} xs={12} sm={12}>
                            <br />
                            <SelectValidator
                                fullWidth
                                // className={classes.fieldStyle}
                                variant="outlined"
                                name={'shippingStrategy'}
                                onChange={set}
                                disabled
                                validators={['required']}
                                errorMessages={['This field is required']}
                                value={props.quote['shippingStrategy']}
                                label="Over how many deliveries do I need this order split?"
                            >
                                {shippingStrategyMenuList}
                            </SelectValidator>
                        </Grid>



                        <Grid item lg={12} xs={12}>
                            <br />
                            <SelectValidator
                                name={'timing'}
                                value={props.quote['timing']}
                                onChange={set}
                                fullWidth={true}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                variant='outlined'
                                disabled
                                label="How quickly do I need this item shipped ?"
                            >
                                {timingMenuList}
                            </SelectValidator>
                        </Grid>










                        {props.quote['cnf'] != undefined && props.quote['cnf'] == '1' ? <div style={{width: '100%'}}>
                            <Grid container>
                            <Grid item lg={12} xs={12} style={{marginTop: 20}}>
                                        <TextValidator
                                            name={'companyName'}
                                            onChange={set}
                                            value={props.quote['companyName']}
                                            
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            id="amount"
                                            label="Name of Company"
                                            disabled
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12} style={{marginTop: 20}}>
                                    <TextValidator
                                        name={'street1'}
                                        onChange={set}
                                        value={props.quote['street1']}
                                        
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        id="amount"
                                        disabled
                                        label="Line Address 1"
                                        type="amount"
                                        fullWidth
                                        variant="outlined"
                                    />
                                </Grid>

                                    <Grid item lg={12} xs={12} style={{marginTop: 20}}>
                                        <TextValidator
                                            name={'city'}
                                            onChange={set}
                                            value={props.quote['city']}
                                            
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            label="City"
                                            disabled
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
                        <Grid item lg={12} xs={12} style={{marginTop: 20}}>

                                    <TextValidator
                                        name={'state'}
                                        onChange={set}
                                       
                                        value={props.quote['state']}
                                        id="amount"
                                        validators={['required']}
                                        errorMessages={['State is required']}
                                        label="State/Province"
                                        type="amount"
                                        fullWidth
                                        disabled
                                        variant="outlined"
                                    />
                                </Grid>

                                
                                <Grid item lg={12} xs={12} style={{marginTop: 20}}>
                                    <TextValidator
                                        name={'zipcode'}
                                        
                                        value={props.quote['zipcode']}
                                        id="amount"
                                        label="Zip Code"
                                        onChange={set}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        type="amount"
                                        fullWidth
                                        disabled
                                        variant="outlined"
                                    />
                                </Grid>

                                
                                </Grid>

                        </div> : <div style={{ width: '100%' }}>


                        </div>

                        }


                    {props.quote['cnf'] != undefined && props.quote['cnf'] == 2 ?
                            <div style={{ width: '100%' }}>
                                <Grid item lg={12} xs={12}>
                                    <br />
                                    <Typography variant="h6" className={classes.heading}>Here’s my shipping address</Typography>
                                    <br />
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <TextValidator
                                        name={'airport'}
                                        onChange={set}
                                        margin="dense"
                                        disabled
                                        value={props.quote['airport']}
                                        id="port-name"
                                        validators={['required']}
                                        errorMessages={['port is required']}
                                        label="Airport Name"
                                        type="name"
                                        fullWidth
                                        variant="outlined"
                                    />
                                </Grid>


                                <Grid item lg={12} xs={12}>
                                    <TextValidator
                                        name={'state'}
                                        onChange={set}
                                        margin="dense"
                                        value={props.quote['state']}
                                        id="amount"
                                        validators={['required']}
                                        errorMessages={['State is required']}
                                        label="State/Province"
                                        type="amount"
                                        fullWidth
                                        disabled
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <TextValidator
                                        name={'zipcode'}
                                        margin="dense"
                                        value={props.quote['zipcode']}
                                        id="amount"
                                        label="Zip Code"
                                        onChange={set}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        type="amount"
                                        fullWidth
                                        disabled
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <TextValidator
                                        value={props.quote['country']}
                                        onChange={set}
                                        margin="dense"
                                        id="amount"
                                        validators={['required']}
                                        errorMessages={['Country is required']}
                                        label="Country"
                                        type="text"
                                        fullWidth
                                        disabled
                                        variant="outlined"
                                        name={'country'}
                                    />
                                </Grid></div> : <div></div>}








                                {props.quote['cnf'] != undefined && props.quote['cnf'] == 3 ?
                            <div style={{ width: '100%' }}>
                                <Grid item lg={12} xs={12}>
                                    <br />
                                    <Typography variant="h6" className={classes.heading}>Here’s my shipping address</Typography>
                                    <br />
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <TextValidator
                                        name={'portName'}
                                        onChange={set}
                                        margin="dense"
                                        disabled
                                        value={props.quote['portName']}
                                        id="port-name"
                                        validators={['required']}
                                        errorMessages={['port is required']}
                                        label="Port Name"
                                        type="name"
                                        fullWidth
                                        variant="outlined"
                                    />
                                </Grid>


                                <Grid item lg={12} xs={12}>
                                    <TextValidator
                                        name={'state'}
                                        onChange={set}
                                        margin="dense"
                                        value={props.quote['state']}
                                        id="amount"
                                        validators={['required']}
                                        errorMessages={['State is required']}
                                        label="State/Province"
                                        type="amount"
                                        fullWidth
                                        disabled
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <TextValidator
                                        name={'zipcode'}
                                        margin="dense"
                                        value={props.quote['zipcode']}
                                        id="amount"
                                        label="Zip Code"
                                        onChange={set}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        type="amount"
                                        fullWidth
                                        disabled
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <TextValidator
                                        value={props.quote['country']}
                                        onChange={set}
                                        margin="dense"
                                        id="amount"
                                        validators={['required']}
                                        errorMessages={['Country is required']}
                                        label="Country"
                                        type="text"
                                        fullWidth
                                        disabled
                                        variant="outlined"
                                        name={'country'}
                                    />
                                </Grid></div> : <div></div>}






                        {props.quote['cnf'] != undefined && props.quote['cnf'] == 4 || props.quote['cnf'] == 5 || props.quote['cnf'] == 6 || props.quote['cnf'] == 7 ?
                            <div style={{ width: '100%' }}>
                                <Grid item lg={12} xs={12}>
                                    <br />
                                    <Typography variant="h6" className={classes.heading}>Here’s My Shipping Address</Typography>
                                    <br />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'companyName'}
                                            onChange={set}
                                            value={props.quote['companyName']}
                                            margin="dense"
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            id="amount"
                                            disabled
                                            label="Name of Company/Warehouse"
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>

                                <Grid item lg={12} xs={12}>
                                    <TextValidator
                                        name={'street1'}
                                        onChange={set}
                                        value={props.quote['street1']}
                                        margin="dense"
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        id="amount"
                                        disabled
                                        label="Street Address 1"
                                        type="amount"
                                        fullWidth
                                        variant="outlined"
                                    />
                                </Grid>


                                <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'city'}
                                            onChange={set}
                                            value={props.quote['city']}
                                            margin="dense"
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            label="City"
                                            disabled
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>

                                <Grid item lg={12} xs={12}>
                                    <TextValidator
                                        name={'state'}
                                        onChange={set}
                                        margin="dense"
                                        disabled
                                        value={props.quote['state']}
                                        id="amount"
                                        validators={['required']}
                                        errorMessages={['State is required']}
                                        label="State/Province"
                                        type="amount"
                                        fullWidth
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    <TextValidator
                                        name={'zipcode'}
                                        margin="dense"
                                        value={props.quote['zipcode']}
                                        id="amount"
                                        disabled
                                        label="Zip Code"
                                        onChange={set}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        type="amount"
                                        fullWidth
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <TextValidator
                                        value={props.quote['country']}
                                        onChange={set}
                                        margin="dense"
                                        id="amount"
                                        disabled
                                        validators={['required']}
                                        errorMessages={['Country is required']}
                                        label="Country"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        name={'country'}
                                    />
                                </Grid>
                            </div>
                            : <div></div>}


                            




                        <Grid item lg={12} xs={12}>
                            <br />
                            <Typography variant="h6" className={classes.heading}>Other Details</Typography>
                            <br />
                        </Grid>

                        <Grid item lg={12} xs={12}>
                            <TextValidator
                                name={'description'}
                                margin="dense"
                                disabled
                                value={props.quote['description']}
                                id="offer-description"
                                label="Describe Your Offer in Words"
                                type="offer"
                                onChange={set}
                                multiline
                                rows={5}
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>


                    {/* 
                        <Grid item lg={3}>
                        </Grid> */}
                



                </div>

                { window.location.pathname.includes('checkout/') ? <div></div> : 
                <div>

                {props.quote['status'] == 'Quoted' &&
                        <Grid container justify="center" direction="row" spacing={2} style={{ paddingRight: '3%' }}>

                            <Grid item lg={3}>

                            </Grid>
                            <Grid item lg={4} xs={12}>
                                <br />
                                <Button variant="outlined" onClick={() => {
                                    API.get('singleChat', {
                                        offerid: props.quote['id'],
                                        userid: Cook.getUserData()['user']['id']
                                    }).then(x => {
                                        createBrowserHistory().push(Ways.chatPage + '/' + x.data['id'])
                                        window.location.reload();
                                    })
                                }} className={classes.productBtnoutlined}>
                                    Chat With AHV
                            </Button>
                            </Grid>
                            <Grid item lg={1}></Grid>



                            <Grid item lg={4} xs={12}>
                                <br />
                                <Button variant="contained" className={classes.productBtn} onClick={() => {
                                    createBrowserHistory().push(Ways.checkoutPage + '/' + props.quote['id'])
                                    window.location.reload();
                                }}>
                                    Accept & Place Order
                            </Button>
                            </Grid>



                        </Grid>
                    }

</div>
}
            </ValidatorForm>

        </div>
        </Box>
    )
}