import React from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, Hidden,  CardContent, Button, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import { Image, Work, BeachAccess } from '@material-ui/icons';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { MyButton } from './../Styles/MyButton';
import branding2 from './../assets/branding2.png';
import introduction from './../assets/introduction.jpg';
import forest from './../assets/forest.jpg';
import sunrise from './../assets/sunrise.jpg';
import grass from './../assets/grass.jpg';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    listStyle: {
display: 'flex',
flexDirection: 'row',
width: '100%',

[theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },




    },

    actionArea: {

        maxWidth: 450,
        
      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },
    root: {
      
      // boxShadow: 'none',
      // width: 600,
      // height: 584,


      boxShadow: 'none',
    
      width: '100%',
      height: '100%',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundColor: 'transparent',
      backgroundSize: 'cover',

      
    },
    media: {
      
      width: 514,
      height: 409,
      
    },
  
    imgStyle: {
  
      maxWidth: 120,
     
  
    },

    mainHeading: {
      fontFamily: 'sophia',
      color: '#303030',
      fontSize: 22,
  

  },

  
  largeHeading: {
    fontFamily: 'sophia',
    color: '#303030',
    fontSize: 24,
    paddingTop: '6%',
    paddingBottom: 15,

},


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: '1.25rem',
      color: '#726f6f',
      fontWeight: 500,
      

  },
  
  

    vector: {

        width: 600,
        height: 584,

        [theme.breakpoints.down('sm')]: {
            width: 350,
            height: 340,
          },
    }
  
}),
);




export const ImageSections = () => {
    const classes = useStyles();

    return(
        <div style={{paddingTop: '5%', paddingBottom: '5%'}}>
        <Container maxWidth="lg">
        <Grid container
        spacing={10}
         direction="row"
         justify="center"
         alignItems="flex-start"
         style={{marginTop: '1%', padding: 25,  }}
         >

       

    

        

        <Grid item lg={6} sm={12} md={6} xs={12}>
       
           
      


        <Card className={classes.root}>
     
     <CardMedia
       >
           <img src={forest} width="100%" height="100%" alt="services" />
       </CardMedia>

 </Card>
  

     </Grid>



<Hidden only={['sm', 'xs']}>
     <Grid item lg={6} sm={12} xs={12} md={6}>

<Typography className={classes.largeHeading} style={{ fontSize: '1.9rem', paddingLeft: '9%', paddingRight: '9%' }} align="left" gutterBottom variant="h3" component="h2">
SOUTHERN FOREST
</Typography>

<Typography className={classes.descriptionStyle} style={{paddingLeft: '9%', paddingRight: '9%'}}  align="justify" variant="subtitle2" color="textSecondary" component="p">
The Southern Forest region is one of the nine regions of Western Australia and covers an area of nearly 24,000 square kilometres Western Australia's most popular tourist destination.<br /><br />
AHV beekeepers produce world class premium honey from the healthy eucalyptus forests. The premium medicinal honey sourced from this region includes Jarrah and forest Blackbutt.

</Typography>

</Grid>

</Hidden>


<Hidden only={['xl', 'lg', 'md']}>
     <Grid item lg={6} sm={12} xs={12} md={6}>

<Typography className={classes.largeHeading} style={{ fontSize: '1.9rem', }} align="left" gutterBottom variant="h3" component="h2">
SOUTHERN FOREST
</Typography>

<Typography className={classes.descriptionStyle} align="justify" variant="subtitle2" color="textSecondary" component="p">
The Southern Forest region is one of the nine regions of Western Australia and covers an area of nearly 24,000 square kilometres Western Australia's most popular tourist destination.<br /><br />
AHV beekeepers produce world class premium honey from the healthy eucalyptus forests. The premium medicinal honey sourced from this region includes Jarrah and forest Blackbutt.

</Typography>

</Grid>

</Hidden>







<Hidden only={['sm', 'xs']}>
<Grid item lg={6} xs={12} md={6}>
<Typography className={classes.largeHeading} style={{ fontSize: '1.9rem', paddingLeft: '9%', paddingRight: '9%'}} align="left" gutterBottom variant="h3" component="h2">

DARLING SCARPE - PERTH HILLS
</Typography>

<Typography className={classes.descriptionStyle} style={{paddingLeft: '9%', paddingRight: '9%'}}   align="justify" variant="subtitle2" color="textSecondary" component="p">

The Darling Scarp in the Perth Hills region is home to some of Australia’s most spectacular state forests. The large Jarrah and Redgum trees are a monument to the thriving timber industry that existed in the early days of the colony. <br /><br />
The conservation management system well exceeds world standards which allows AHV to produce high quality medical Jarrah and Redgum honey.

</Typography>

</Grid>
</Hidden>



<Hidden only={['xl', 'lg', 'md']}>
<Grid item lg={6} xs={12} md={6}>
<Typography className={classes.largeHeading} style={{ fontSize: '1.9rem',}} align="left" gutterBottom variant="h3" component="h2">

DARLING SCARPE - PERTH HILLS
</Typography>

<Typography className={classes.descriptionStyle}  align="justify" variant="subtitle2" color="textSecondary" component="p">

The Darling Scarp in the Perth Hills region is home to some of Australia’s most spectacular state forests. The large Jarrah and Redgum trees are a monument to the thriving timber industry that existed in the early days of the colony. <br /><br />
The conservation management system well exceeds world standards which allows AHV to produce high quality medical Jarrah and Redgum honey.

</Typography>

</Grid>
</Hidden>








<Grid item lg={6} sm={12} xs={12} md={6}>
       
       <Card className={classes.root}>
    
    <CardMedia
      >
          <img src={sunrise} width="100%" height="100%" alt="services" />
      </CardMedia>

</Card>
 

    </Grid>



    <Grid item lg={6} sm={12} xs={12} md={6}>
       
       <Card className={classes.root}>
    
    <CardMedia
      >
          <img src={grass} width="100%" height="100%"  alt="services" />
      </CardMedia>

</Card>
 

    </Grid>



<Hidden only={['sm', 'xs']}>
    <Grid item lg={6} xs={12} md={6}>
<Typography className={classes.largeHeading} style={{ fontSize: '1.9rem', paddingLeft: '9%', paddingRight: '9%'}} align="left" gutterBottom variant="h3" component="h2">
COASTAL SANDPLAINS
</Typography>

<Typography className={classes.descriptionStyle} style={{paddingLeft: '9%', paddingRight: '9%'}}  align="justify" variant="subtitle2" color="textSecondary" component="p">

The coastal sandplains region covers a vast 6,900 hectares. It is noted as being botanically rich with 15% of the land’s native flora classified rare and protected. <br /><br />

The region is home to the Myrtaceae family of plants which include Eucalyptus (Blackbutt and Red Gum trees) and Leptospermum (Australian Manuka shrubs). The combination of good pollen and nectar-producing plants, and a mild climate makes the Coastal Sandplains one of the best honey-producing regions in the world. 


</Typography>

</Grid>

</Hidden>



<Hidden only={['xl', 'lg', 'md']}>
    <Grid item lg={6} xs={12} md={6}>
<Typography className={classes.largeHeading} style={{ fontSize: '1.9rem',}} align="left" gutterBottom variant="h3" component="h2">
COASTAL SANDPLAINS
</Typography>

<Typography className={classes.descriptionStyle}  align="justify" variant="subtitle2" color="textSecondary" component="p">

The coastal sandplains region covers a vast 6,900 hectares. It is noted as being botanically rich with 15% of the land’s native flora classified rare and protected. <br /><br />

The region is home to the Myrtaceae family of plants which include Eucalyptus (Blackbutt and Red Gum trees) and Leptospermum (Australian Manuka shrubs). The combination of good pollen and nectar-producing plants, and a mild climate makes the Coastal Sandplains one of the best honey-producing regions in the world. 


</Typography>

</Grid>

</Hidden>


        </Grid>

   
        </Container>
        </div>
    )
}