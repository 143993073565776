import React, { FunctionComponent, useEffect } from 'react';
import './App.css';
import {Route, Router} from 'react-router-dom';
import {HomePage} from './Pages/HomePage';
import {createBrowserHistory} from 'history';
import {AllProducts} from './Pages/AllProducts';
import {LoginPage} from './Pages/LoginPage';
import {SignupPage} from './Pages/SignupPage';
import {Product} from './Pages/SingleProduct/Product';
import {BuyerDashboard} from './Dashboard/Dashboard';
import {Ways, Socket, AppEvent, Bbc} from './Helper';
import {Checkout} from './Dashboard/Order/Checkout';
import { ThanksPage } from './Dashboard/Order/ThanksPage';
import { Brands } from './Brands/Brands';
import { Cart } from './Cart';
import { Quote } from './Quote/Quote';
import { LandingLayout } from './LandingPage/LandingLayout';
import { Button, Hidden } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { ConfirmationMsg } from './Components/ConfirmationMsg';

import CssBaseline from "@material-ui/core/CssBaseline";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Zoom from "@material-ui/core/Zoom";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";
import { RegisterYourInterestUpdated } from './Components/RegisterYourInterestUpdated';
import { RegistrationSuccessful } from './Components/RegistrationSuccessful';
import { ForgetPassword } from './Pages/ForgetPassword';
import { PasswordChange } from './Pages/PasswordChange';
import { BottomBarNavigation } from './Components/BottomBarNavigation';
import {InvestWithUs} from './Components/investWithUs'

//scroll to top


const useStyles = makeStyles(theme => ({
    root: {
      position: "fixed",
      bottom: theme.spacing(4),
      right: theme.spacing(4),
      backgroundColor: 'transparent',
      borderRadius: 5,
    },


    scrolltop: {

        [theme.breakpoints.down('sm')]: {
           marginTop: -200,
           borderRadius: 5,
          },
    }
  }));
  
  function ScrollTop(props) {
    const { children } = props;
    const classes = useStyles();
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 100
    });
  
    const handleClick = event => {
      const anchor = (event.target.ownerDocument || document).querySelector(
        "#back-to-top-anchor"
      );
  
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    };
  
    return (
      <Zoom in={trigger}>
        <div onClick={handleClick} role="presentation" className={classes.root}>
          {children}
        </div>
      </Zoom>
    );
  }
  
  ScrollTop.propTypes = {
    children: PropTypes.element.isRequired
  };


  //scroll to top ends














const Version = ()=>{
    return <div>16-2-2022 -- Added invest with us menu</div>;
}

export const  App:FunctionComponent = () => {
const classes = useStyles();
Socket.initialize();

const {enqueueSnackbar} = useSnackbar();

useEffect(()=>{
    Bbc.subscribe(AppEvent.newMessageRecieved , (x)=>{
        console.log(x);
        enqueueSnackbar( 'New message: \n' +x.text , {action :<Button onClick={()=>{
            createBrowserHistory().push(Ways.chatPage + '/' + x.chat);
            window.location.reload();
        }} variant='outlined' style={{color:'white'}}>Go to Chat</Button>})
    })
    Bbc.subscribe(AppEvent.quoteUpdated , (x)=>{
        console.log(x);
        enqueueSnackbar('Quote Updated:\n' + x.description , {action :<Button onClick={()=>{
            createBrowserHistory().push(Ways.singleQuotepage + '/' + x.data);
            window.location.reload();
        }} variant='outlined' style={{color:'white'}}>Go to Quote</Button>})
    })
},[])

    return (
        <Router history={createBrowserHistory()}>
            {/* <div style={{overflowX: 'hidden'}}> */}
            <div>
           
    
      <Toolbar style={{maxHeight: 0, minHeight: 0}} id="back-to-top-anchor" />
         

         
    

                  <div style={{overflowX: 'hidden'}}>
                <Route path="/" exact component={HomePage}/>
                </div>
                {/* <Route path="/" exact component={LandingLayout} /> */}
                <Route path="/" component={Brands}/>
                <Route path="/aftersignup" component={ConfirmationMsg} />
                <Route path="/products" exact component={AllProducts}/>
                <Route path={Ways.singleProduct} component={Product}/>
                <Route path={Ways.cartPage} component={Cart} />
                <Route path={Ways.quotePage} component={Quote} />
                <Route path="/dashboard" component={BuyerDashboard}/>
                <Route path="/login" component={LoginPage}/>
                <Route path="/forgetpassword" component={ForgetPassword} />
                <Route path="/passwordchange" component={PasswordChange} />
                <Route path="/signup" exact component={SignupPage}/>
                <Route path="/registration" exact component={RegisterYourInterestUpdated} />
                <Route path="/checkout" component={Checkout}/>
                <Route path="/thanks" component={ThanksPage}/>
                <Route path="/success" component={RegistrationSuccessful} />
                <Route path="/forgot" exact component={ForgetPassword} />
                <Route path="/reset-password" component={PasswordChange} />
                <Route path="/version" component={Version}/>
                <Route path="/invest-with-us" component={InvestWithUs}/>

           
<Hidden only={['sm', 'xs']}>
            <ScrollTop >
               
        <Fab className={classes.scrolltop} style={{color: '#fff', backgroundColor: '#f28f16', borderRadius: 0}} size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
      </Hidden>
      <Hidden only={['xl', 'lg',]}>
        <BottomBarNavigation />
        </Hidden>
  

</div>
        </Router>
    );
}

export default App;
