import React from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, CardContent, Button, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import { Image, Work, BeachAccess } from '@material-ui/icons';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { MyButton } from './../Styles/MyButton';
import branding2 from './../assets/branding2.png';
import introduction from './../assets/introduction.jpg';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    listStyle: {
display: 'flex',
flexDirection: 'row',
width: '100%',

[theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },




    },

    actionArea: {

        maxWidth: 450,
        
      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },
    root: {
      
      boxShadow: 'none',
      width: 600,
      height: 584,

      
    },
    media: {
      
      width: 514,
      height: 409,
      
    },
  
    imgStyle: {
  
      maxWidth: 120,
     
  
    },

    mainHeading: {
      fontFamily: 'sophia',
      color: '#303030',
      fontSize: 22,
  

  },

  
  largeHeading: {
    fontFamily: 'sophia',
    color: '#303030',
    fontSize: 24,

    paddingBottom: 15,

},


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: '1.25rem',
      color: '#726f6f',
      fontWeight: 500,

  },
  
  

    vector: {

        width: 600,
        height: 584,

        [theme.breakpoints.down('sm')]: {
            width: 350,
            height: 340,
          },
    }
  
}),
);




export const ApiIntroduction = () => {
    const classes = useStyles();

    return(
        <div style={{paddingTop: '4%', paddingBottom: '4%'}}>
        <Container maxWidth="md">
        <Grid container
        
         direction="row"
         justify="center"
         alignItems="center"
         style={{marginTop: '1%', }}
         >

       

        <Grid item lg={12} sm={12} xs={12}>

        <Typography className={classes.largeHeading} style={{ fontSize: '1.9rem',}} align="center" gutterBottom variant="h3" component="h2">
       OUR APIARIES
       </Typography>
        <Typography className={classes.largeHeading} style={{ fontSize: '1.3rem'}} align="center" variant="h3">
       The magic of our honey begins in the apiary
       </Typography>
   
       
       <Typography className={classes.descriptionStyle}  align="center" variant="subtitle2" color="textSecondary" component="p">
       Australian Honey Ventures produces honey in Western Australia on prime land throughout the state’s renowned eucalyptus forests. Our honey suppliers have owned and leased apiary sites for over 100 years.
       </Typography>
       

      

        </Grid>

        

        {/* <Grid item lg={6} sm={12} xs={12}>
       
           
      


        <Card className={classes.root}>
     
     <CardMedia
       >
           <img src={introduction} className={classes.vector} alt="services" />
       </CardMedia>

 </Card>
  

     </Grid> */}

     

        </Grid>



        

        
        </Container>
        </div>
    )
}