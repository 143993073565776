import React, { useEffect, useState, FunctionComponent } from 'react'
import { NewHeader } from './../../Components/NewHeader';
import { Quantity } from './Quantity';
import { SelectValidator, ValidatorForm } from "react-material-ui-form-validator";
import { createBrowserHistory } from 'history';
import { AddCircle } from '@material-ui/icons';

import {
    Hidden,
    Container,
    Divider,
    Grid,
    List,
    ListItem,
    Paper,
    Link, 
    MenuItem, Button, Tooltip, IconButton,  ListItemSecondaryAction,  ListItemAvatar, Avatar, ListItemText,  CircularProgress, ListItemIcon} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import { Visibility } from '@material-ui/icons';
import { CarouselComponent } from './carousel.component';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { NeutritionTable } from './NeutritionTable';
import flask from '../../assets/flask.png';
import gluten from '../../assets/gluten.png';
import tube from '../../assets/tube.png';
import { API, Bbc, LoginHelper, AppEvent, Cook, Ways } from '../../Helper';
import { DrawerPanel } from '../../Components/DrawerPanel';
import { NewFooter } from '../../Components/NewFooter';
import { ShoppingBasket, ShoppingCart } from '@material-ui/icons';
import { MobileHeader } from '../../Components/MobileHeader';
import { BottomBar } from './../../Components/BottomBar';
import { useSnackbar } from 'notistack';
import { AutoLoader } from '../../Components/LoaderButton';
import { GlobalState } from '../../AppState';
import { BreadCrumbsBar } from '../../Components/BreadCrumbs';
import { CenterContent } from '../../Components/CenterContent';
import { GlobalLogin } from '../../Components/GlobalLogin';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: 500,
            boxShadow: 'none',
            overflow: 'hidden',

        },

        iconListStyle: {

            display: 'flex',
            flexDirection: 'row',

            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              flexDirection: 'column',
              },
        },


        reviewlistStyle: {
            display: 'flex',
            flexDirection: 'row',

            [theme.breakpoints.down('xs')]: {
                display: 'flex',
                flexDirection: 'row',
                },

        },

        minimumorderStyle: {

            minWidth: 360,
            
            [theme.breakpoints.down('sm')]: {
               minWidth: 200,
                },
        },

            carousal: {
                border: '1px solid #efefef',
                height: 680,
                borderRadius: 5,
              
                [theme.breakpoints.up('sm')]: {
                  marginBottom: '30%',
                  borderRadius: 5,
                    },

            },

        cartonStyle: {

            minWidth: 170,
            [theme.breakpoints.down('sm')]: {
               minWidth: 150,
                },


        },

        inline: {
            display: 'inline',
            marginTop: 20,

            [theme.breakpoints.down('sm')]: {
              fontSize: '0.8rem',
              },
          },


          emptydiv: {
                height: 3,
            [theme.breakpoints.down('lg')]: {
                height: 0,
                },


          },
          

        callbtnStyle: {
            color: '#fff',
            backgroundColor: '#f28f16',
            fontSize: 15,
            width: '100%',
            border: '2px solid #f28f16',
            boxShadow: 'none',
            borderRadius: 6,
            paddingTop: 14,
            paddingBottom: 14,
            fontFamily: 'sophia',
            fontWeight: 400,
            letterSpacing: 0.8,
            '&.MuiButton-outlined': {
                '&:hover': {
                    color: '#ffffff',
                    backgroundColor: '#f28f16',
                    boxShadow: 'none',
                    border: '2px solid #f28f16',
                },
            },

            [theme.breakpoints.down('sm')]: {
                paddingTop: 10,
                paddingBottom: 10,
                fontSize: 12,

                },


            
        },
        breadcrumbBox: {
            paddingLeft: '5%',
            paddingRight: '5%',
            paddingTop: '1%',
            paddingBottom: '1%',
            backgroundColor: '#efeded',
            // marginTop: '2%',
            // borderTop: '1px solid #efefef',
        },
        listStyle: {
            display: 'flex',
            flexDirection: 'row',
            maxWidth: 250,
            marginLeft: '-8%',
        },
        submitStyle: {
            backgroundColor: '#f28f16',
            color: '#ffffff',
        },
        btnStyle: {
            color: '#ffffff',
            backgroundColor: '#f28f16',
            boxShadow: 'none',
            borderRadius: 4,
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 10,
            paddingBottom: 10,
            fontFamily: 'sophia',
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#D3D3D2',
                    color: '#ffffff',
                    boxShadow: 'none',
                },
            },
        },
        heading: {
            fontSize: 15,
            // flexBasis: '33.33%',
            // flexShrink: 0,
            // align: 'left',
        },
        secondaryHeading: {
            fontSize: 15,
            color: 'secondary',
        },
        expansion: {
            marginBottom: '2%',
            boxShadow: 'none',
            borderRadius: 4,
        },
        iconStyle: {
            marginLeft: '10%',
            color: '#ffffff',
        },
        headingStyle: {
            fontFamily: 'sophia',
        },
        descriptionStyle: {
            fontFamily: 'sophia-regular',
            fontSize: '1.25rem',
            fontWeight: 500,
            padding: 10,
            lineHeight: 2,
            wordWrap: 'break-word',
        },
        mediumStyle: {
            fontFamily: 'sophia-regular',
            fontSize: '1.25rem',
        },
        headingStyleMain: {
            fontFamily: 'sophia',
            fontSize: 28,
            color: '#3d3a3a',

        },
        subHeading: {
            fontFamily: 'sophia',
            fontSize: 16,
            marginBottom: '0.5%',
            color: '#000',
        },

        formControl: {
            margin: theme.spacing(1),
            minWidth: '100%',
        },

    }),
);
export const Product: FunctionComponent = () => {
    const [, setLoading] = useState(false);

    const [products, setProducts] = useState([]);

    const classes = useStyles();
    const [] = useState(false);
    const [p, setP] = useState({});
    const [quantity, setQuantity] = useState(1);
    const { enqueueSnackbar } = useSnackbar();
    const [expanded, setExpanded] = React.useState<string | false>('panel1');
    const [added, setAdded] = useState(false);
    const [selectedSize, setSelectedSize] = useState<any>();

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    }


    let shouldDisable = p['isInCart'] || added;

    useEffect(() => {
        var id = window.location.pathname.split('/')[3];
        var user = {}
        if (LoginHelper.isCookiePresent()) {
            user = { userid: Cook.getUserData()['user']['id'] };
        }
        API.get('product', { productid: id, ...user }).then(c => {
            setQuantity(c.data['minQuantity'])
            setP(c.data)
            console.log(c.data)
            setQuantity(Number(c.data['minQuantity']))
            setSelectedSize(c.data['variations'][0]['id'])
        });
    }, [])

  
    

    if (!p['id']) {
        return  <CenterContent><CircularProgress style={{color: '#f28f16'}}/></CenterContent>
    }

    return (
        <div style={{ borderBottom: '1px solid #efefef', overflowX: 'hidden' }}>
            <Hidden only={['sm', 'md', 'xs']}>
                <NewHeader />
            </Hidden>

            <Hidden only={['lg', 'xl']}>
                <MobileHeader />
            </Hidden>
            <div className={classes.emptydiv} ></div>
            <BreadCrumbsBar />
            <div style={{ paddingTop: '7%', paddingBottom: '4%' }}>
                {/* <HoverMenu /> */}
                <Container maxWidth="lg">
                    <Grid container spacing={4} justify="center">
                        <Grid item lg={5} xs={12} md={12} sm={12} className={classes.carousal}>
                            {p['id'] && <CarouselComponent p={p} images={p['pictures'].map(x => x.image)} primary={p['primaryImage']} />}
                        </Grid>
                        {/* <Grid item lg={1}></Grid> */}
                        
                        <Grid item lg={7} xs={12} sm={12} md={12} style={{paddingLeft: 65, paddingRight: 65}}>
                            {/* List 1 */}
                            <List style={{ display: 'flex', flexDirection: 'column', marginLeft: '-3%', marginBottom: '-3.5%' }}>
                                <ListItem style={{ marginBottom: '-3%' }}>
                                    <Typography className={classes.headingStyleMain} variant="h6"
                                        align="left">{p['productTitle']} </Typography><br />
                                </ListItem>
                             
                                <ListItem>
                                    <ListItemText>
                                    <Rating name="read-only" value={4} readOnly />
                                    </ListItemText>

                                
                                </ListItem>
                                
                            </List>
                            {/* List 2 */}
                            <List style={{ display: 'flex', flexDirection: 'row', maxWidth: '100%', marginLeft: '-3%' }}>

                                <ListItem className={classes.minimumorderStyle}>
                        <Typography variant="body2">Minimum Order Quantity : {p['minQuantity']}&nbsp;cartons/ 1 pallet</Typography>

                                </ListItem>
                                <ListItem>
                                    <Divider orientation="vertical" flexItem />
                                </ListItem>
                                <ListItem className={classes.cartonStyle}>
                                    <Typography align="left" variant="body2">Units Per Carton : {selectedSize ? p['variations'].filter(x => x['id'] == selectedSize)[0]['itemsPerCarton'] : ''}</Typography>
                                </ListItem>
                            </List>
                            {/* List 3
                           */}
                            <List style={{ display: 'flex', marginLeft: '-3%', flexDirection: 'column', marginTop: '-2%' }}>
                                <ListItem>
                                    {/* <Typography className={classes.headingStyle} variant="h6"
                                        align="left">{p['minPrice']} - {p['maxPrice']} AUD</Typography><br /> */}


                                    {/* <Typography className={classes.headingStyle} variant="h6" align="left">
                                        Starting Price :
                                        </Typography> &nbsp;
                                        <Typography className={classes.headingStyle} variant="h6"
                                        align="left">${selectedSize ? p['variations'].filter(x => x['id'] == selectedSize)[0]['price'] : ''} </Typography> */}



                    {
                            (!LoginHelper.isCookiePresent())
                            ?
                            <div>
                            <Typography align="left" className={classes.headingStyle} variant="h6"
                            component="p">RRP: &nbsp;
                            ${p['variations']!=null ? p['variations'][0].rrp : ''} /<Typography style={{ fontSize: 14, color: '#656d6d' }} variant="caption"> Jar</Typography>
                        </Typography>
                        <br />

                        <Button size="small" variant="outlined" style={{pointerEvents: 'none', opacity: '60%', color: '303030', borderColor: '#f28f16'}}>Login to view wholesale price</Button>

                        </div>

                        :
                        <div>

                        <Typography align="left" className={classes.headingStyle} variant="h6"
                        component="p">RRP: &nbsp;
                        ${p['variations']!=null ? p['variations'][0].rrp : ''} /<Typography style={{ fontSize: 14, color: '#656d6d' }} variant="caption"> Jar</Typography>
                    </Typography>

                            
                     


                          <Typography align="left" className={classes.headingStyle} variant="h6"
                            component="p">Wholesale Price:&nbsp;
                            ${p['variations']!=null ? p['variations'][0].price : ''} /<Typography style={{ fontSize: 14, color: '#656d6d' }} variant="caption"> Jar</Typography>
                        </Typography>

                        </div>

                        }






                                </ListItem>
                                <ListItem>
                                    <Typography className={classes.mediumStyle} align="justify"
                                        variant="subtitle1">{p['shortDescription']}</Typography>
                                </ListItem>
                            </List>
                            <Grid container>


                                <Grid item lg={12} xs={12}>
                                    <List>
                                        <Typography variant="subtitle2">Select Size</Typography>
                                    </List>
                                    <List>
                                        <ValidatorForm onSubmit={() => { }}>

                                            <SelectValidator
                                                name='selectedSIze'
                                                style={{ width: '100%', paddingTop: -15, paddingBottom: -15 }}
                                                variant="outlined"
                                                label="Choose a size"
                                                value={selectedSize}
                                                onChange={x => {
                                                    setSelectedSize(String(x.target['value']))
                                                    console.log(x.target['value']);

                                                }}>
                                                {p['variations'] != null && [].concat(p['variations'].map((x, i) => {
                                                    return <MenuItem key={i} selected={x['id'] == selectedSize} value={x['id']}>{x['weight'] + ' grams'}</MenuItem>

                                                }))}
                                            </SelectValidator>
                                        </ValidatorForm>

                                  




                                    </List>
                                </Grid>

                                <Grid item lg={4} xs={12}>
                                    <List><Typography variant="subtitle2">No. of Cartons</Typography></List>
                                    {
                                        p['id'] && <Quantity minValue={p['minQuantity']}
                                            countOnly={true}
                                            onChange={(value) => setQuantity(value)}
                                            disabled={shouldDisable}
                                            cartItem={{ quantity: quantity }} />

                                    }
                                </Grid>
                                <Grid item lg={8} xs={12} style={{ paddingTop: '4%' }}>
                                    <DrawerPanel />

                                    {
                            (!LoginHelper.isCookiePresent())
                            ?

                            <div>  <AutoLoader

                            fullWidth
                            isDisabled={shouldDisable}
                            startIcon={<AddCircle />}
                            onClick={async () => {
                              
                                  
                                    Bbc.fire(AppEvent.requestLoginDialog,
                                    );
                                
                               
                            }} variant="outlined" className={classes.callbtnStyle}>{shouldDisable ? 'Added To quote Basket' : 'Login to view wholesale price'}</AutoLoader></div>

                            :

                            <div>

                                    <AutoLoader
                                        fullWidth
                                        isDisabled={shouldDisable}
                                        startIcon={<ShoppingBasket />}
                                        onClick={async () => {
                                            setLoading(true);
                                            if (await LoginHelper.isLoggedIn()) {
                                                var res = await API.post('cart/additem',
                                                    {
                                                        user: Cook.getUserData()['user']['id'],
                                                        product: p['id'],
                                                        quantity: quantity,
                                                        variation: selectedSize
                                                    })

                                                GlobalState.localCart['items']++;
                                                (GlobalState.localCart['data'] as any[]).push({ product: p, ...res.data });
                                                enqueueSnackbar("Product Added to Basket", {
                                                    action: <React.Fragment>
                                                        <Button variant='contained' style={{ backgroundColor: 'transparent', color: 'white' }} size="small" onClick={() => {
                                                            createBrowserHistory().push(Ways.cartPage);
                                                            window.location.reload();
                                                        }}>
                                                            Go to Basket
                                                      </Button>
                                                      
                                                    </React.Fragment>
                                                }
                                                )
                                                setAdded(true);
                                            } else {
                                                Bbc.fire(AppEvent.requestLoginDialog,
                                                );
                                            }
                                            setLoading(false);
                                        }} variant="outlined" className={classes.callbtnStyle}>{shouldDisable ? 'Added To quote Basket' : 'Add To quote Basket'}</AutoLoader>

                                            </div>

                                            }

                                    {/* </ListItem>
                        
                        </List> */}

                                </Grid>


                            </Grid>



                            <br />


                            <Typography className={classes.headingStyleMain} align="left" variant="h4">Product
                            Overview</Typography>
                            <Paper style={{ boxShadow: 'none', marginTop: '2%', padding: 30, backgroundColor: '#fbfbfb' }}>
                                <Typography className={classes.subHeading} align="left" variant="subtitle2">At A
                                Glance</Typography>
                                <Hidden only={['sm', 'xs']}>
                                <List dense={true} className={classes.iconListStyle}>
                                    <ListItem>
                                        <img src={flask} alt="icon-image" width="50" height="50" />
                                    </ListItem>
                                    <ListItem>
                                        <img src={gluten} alt="icon-image" width="50" height="50" />
                                    </ListItem>
                                    <ListItem>
                                        <img src={tube} alt="icon-image" width="50" height="50" />
                                    </ListItem>
                                    <ListItem>
                                        <img src={flask} alt="icon-image" width="50" height="50" />
                                    </ListItem>
                                    <ListItem>
                                        <img src={gluten} alt="icon-image" width="50" height="50" />
                                    </ListItem>
                                </List>
                                </Hidden>

                                <Hidden only={['lg', 'xl', 'md']}>
                                <Grid item xs={12} sm={12}>
                                    <List style={{display: 'flex', width: '100%', flexDirection: 'row'}}>
                                        <ListItem>
                                            <ListItemIcon>
                                        <img src={flask} alt="icon-image" width="50" height="50" />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="subtitle2">Type 1</Typography>
                                        </ListItemText>
                                        </ListItem>

                                        <ListItem>
                                            <ListItemIcon>
                                        <img src={gluten} alt="icon-image" width="50" height="50" />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="subtitle2">Type 2</Typography>
                                        </ListItemText>
                                        </ListItem>

                                    </List>


                                </Grid>

                                <Grid item xs={12} sm={12}>
                                <List style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                                        <ListItem>
                                            <ListItemIcon>
                                        <img src={flask} alt="icon-image" width="50" height="50" />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="subtitle2">Type 3</Typography>
                                        </ListItemText>
                                        </ListItem>

                                        <ListItem>
                                            <ListItemIcon>
                                        <img src={gluten} alt="icon-image" width="50" height="50" />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="subtitle2">Type 4</Typography>
                                        </ListItemText>
                                        </ListItem>
                                    </List>

                                </Grid>
                                </Hidden>
                            </Paper>
                            <Paper style={{ boxShadow: 'none', marginTop: '2%', backgroundColor: '#fbfbfb', padding: 20, }}>
                                <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}
                                    className={classes.expansion}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography className={classes.subHeading}
                                            variant="subtitle2">Description</Typography>
                                        <Typography className={classes.secondaryHeading}></Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                    
                                        <Typography className={classes.descriptionStyle} variant="h2">
                                            <div>
                                        <p className="longdescription" style={{wordBreak: 'break-all',}}> {p['longDescription']} </p>
                                       
                                        </div>
                                        </Typography>
                                        
                                       
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>

                                <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}
                                    className={classes.expansion}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3bh-content"
                                        id="panel3bh-header"
                                    >
                                        <Typography className={classes.subHeading}
                                            variant="subtitle2">Specifications</Typography>
                                        <Typography className={classes.secondaryHeading}>
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Typography className={classes.descriptionStyle} align="justify" variant="h2">
                                            {/* Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit amet
                                            egestas eros,
                                            vitae egestas augue. Duis vel est augue. */}
                                    </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>

                                <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}
                                    className={classes.expansion}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header"
                                    >
                                        <Typography className={classes.subHeading} variant="subtitle2">Nutritional
                                        Information</Typography>
                                        <Typography className={classes.secondaryHeading}>
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <NeutritionTable />
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>


                                <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}
                                    className={classes.expansion}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4bh-content"
                                        id="panel4bh-header"
                                    >
                                        <Typography className={classes.subHeading}
                                            variant="subtitle2">Reviews</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        &nbsp; &nbsp;No Reviews
                                        <br /><br />


{/*                                         
                                        <List className={classes.reviewlistStyle}>

                                        <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        </ListItemAvatar>
      <ListItemText style={{marginTop: 10}}>
      <React.Fragment>
              <Typography
                component="span"
                variant="subtitle2"
                className={classes.inline}
                color="textPrimary"
              >
                Ali Connors
              </Typography>
           
            </React.Fragment>
      </ListItemText>
       
      </ListItem>

                                            <ListItem>
                                                <Rating name="read-only" value={3} readOnly />
                                            </ListItem>
                                            <Hidden only={['xs']}>
                                            <ListItem>
                                                <Typography className={classes.descriptionStyle}
                                                    variant="caption">24/04/2020</Typography>
                                            </ListItem>
                                            </Hidden>
                                        </List>
                                        <br />
                                    </ExpansionPanelDetails>
                                    <ExpansionPanelDetails>
                                        <List style={{ display: 'flex' }}>
                                            <ListItem style={{marginTop: '-6%'}}>
                                                <Typography className={classes.descriptionStyle} variant="h2"
                                                    align="justify">
                                                    Brighten up your master suite and bring a touch of coastal style to your
                                                    abode with this bedding set! it’s crafted from easy-to-clean polyester
                                                    and filled with a polyfill for comfort.
                                                    As for style, a ruffled border lends a playful touch to this understated
                                                essential.</Typography>
                                            </ListItem>
                                        </List> */}
                                    </ExpansionPanelDetails>
                                    <ExpansionPanelDetails>

{/* 
                                        <List className={classes.reviewlistStyle}>
                                       
         <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        </ListItemAvatar>
      <ListItemText style={{marginTop: 13}}>
      <React.Fragment>
              <Typography
                component="span"
                variant="subtitle2"
                className={classes.inline}
                color="textPrimary"
              >
                Ali Connors
              </Typography>
           
            </React.Fragment>
      </ListItemText>
       
      </ListItem>

      

                                            <ListItem>
                                                <Rating name="read-only" value={5} readOnly />
                                            </ListItem>
                                            <Hidden only={['xs']}>
                                            <ListItem>
                                                <Typography className={classes.descriptionStyle}
                                                    variant="caption">24/03/2020</Typography>
                                            </ListItem>
                                            </Hidden>
                                        </List>
                                        <br />
                                    </ExpansionPanelDetails>
                                    <ExpansionPanelDetails>
                                        <List style={{ display: 'flex' }}>
                                            <ListItem style={{marginTop: '-6%'}}>
                                                <Typography className={classes.descriptionStyle} variant="h2"
                                                    align="justify">
                                                    Brighten up your master suite and bring a touch of coastal style to your
                                                    abode with this bedding set! it’s crafted from easy-to-clean polyester
                                                    and filled with a polyfill for comfort.
                                                    As for style, a ruffled border lends a playful touch to this understated
                                                essential.</Typography>
                                            </ListItem>
                                        </List> */}
                                    </ExpansionPanelDetails>
                                    <ExpansionPanelDetails>
                                       
                                        {/* <List className={classes.reviewlistStyle}>


                                        <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        </ListItemAvatar>
      <ListItemText style={{marginTop: 10}}>
      <React.Fragment>
              <Typography
                component="span"
                variant="subtitle2"
                className={classes.inline}
                color="textPrimary"
              >
                Ali Connors
              </Typography>
           
            </React.Fragment>
      </ListItemText>
       
      </ListItem>


                                            <ListItem>
                                                <Rating name="read-only" value={4} readOnly />
                                            </ListItem>
                                            <Hidden only={['xs']}>
                                            <ListItem>
                                                <Typography className={classes.descriptionStyle}
                                                    variant="caption">26/05/2020</Typography>
                                            </ListItem>
                                            </Hidden>
                                        </List>
                                        <br />
                                    </ExpansionPanelDetails>
                                    <ExpansionPanelDetails>
                                        <List style={{ display: 'flex' }}>
                                            <ListItem style={{marginTop:'-6%'}}>
                                                <Typography className={classes.descriptionStyle} variant="h2"
                                                    align="justify">
                                                    Brighten up your master suite and bring a touch of coastal style to your
                                                    abode with this bedding set! it’s crafted from easy-to-clean polyester
                                                    and filled with a polyfill for comfort.
                                                    As for style, a ruffled border lends a playful touch to this understated
                                                essential.</Typography>
                                            </ListItem>
                                        </List> */}
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Paper>
                        </Grid>

                       
                    </Grid>
                </Container>
            </div>
            <NewFooter />
            {/* <Hidden only={['lg', 'xl']}>
                <BottomBar />
            </Hidden> */}
            <GlobalLogin/>
        </div>
    )
}