import React, { FunctionComponent, useEffect, useState } from 'react';
import { Hidden, Container, Typography, Grid, Tooltip } from '@material-ui/core';
import { NewHeader } from './Components/NewHeader';
import { MobileHeader } from './Components/MobileHeader';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { API, AppEvent, Bbc, Cook, LoginHelper, Ways } from "./Helper";
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Loader } from './Components/LoaderButton';
import { cnfMenuList, shippingStrategyMenuList, timingMenuList } from './Constants';
import { CartList } from './Components/CartList';
import { NewFooter } from './Components/NewFooter';
import { useSnackbar } from 'notistack';
import { createBrowserHistory } from 'history';
import { BreadCrumbsBar } from './Components/BreadCrumbs';
import { ResponsiveTesting } from './Components/ResponsiveTesting';





const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        root: {

            paddingTop: '2%',
            paddingBottom: '5%',
            backgroundColor: '#fff',
            

        },

        media: {
            maxWith: 95,
            maxHeight: 95,
            marginTop: '5%',
        },

        heading: {
            fontFamily: 'sophia',
        },
        priceheading: {
            fontFamily: 'sophia',
            marginTop: '-5%',
        },

        mainheading: {
            fontFamily: 'sophia',
            paddingBottom: '3%',

            [theme.breakpoints.down('sm')]: {
                paddingBottom: '15%'
              },

        },

        breadcrumbBox: {
            paddingLeft: '5%',
            paddingRight: '5%',
            paddingTop: '1%',
            paddingBottom: '1%',
            backgroundColor: '#efeded',
            // marginTop: '2%',
            // borderTop: '1px solid #efefef',
        },

        description: {
            fontFamily: 'sophia-regular',
            fontWeight: 500,
            fontSize: '1.25rem',
            marginBottom: '3%',
        },

        description2: {
            fontFamily: 'sophia-regular',
            fontWeight: 400,
            fontSize: 12,
            marginTop: '-3%',
            color: 'red',
        },

        description3: {
            fontFamily: 'sophia-regular',
            fontWeight: 400,
            fontSize: 12,
            marginTop: '-3%',

        },

        mainContainer: {

            paddingLeft: '3%', paddingRight: '3%', paddingBottom: '5%', backgroundColor: '#fff', border: '1px solid #bcb8b8',
            [theme.breakpoints.down('sm')]: {
                border: '0px solid #fff',
            },

        },
        Card: {
            boxShadow: 'none',
            backgroundColor: '#fbfbfb',
        },


        tooltipItem: {
            marginLeft: '-2%',
            // marginRight: '-%',
            minWidth: 340,
            [theme.breakpoints.down('sm')]: {
                minWidth: 250,
            },

        },

        makeOffer: {
            marginTop: '1%',
            marginRight: '1%',
            color: '#ffffff',
            backgroundColor: '#f28f16',
            boxShadow: 'none',
            borderRadius: 4,
            fontFamily: 'sophia',
            paddingTop: 15,
            paddingBottom: 15,
            fontWeight: 600,
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#FECF51',
                    color: '#ffffff',
                    boxShadow: 'none',
                },
            },
        },



    }),
);




export const Cart: FunctionComponent = () => {
    const classes = useStyles();
    const [product] = useState({})
    const [loading, setLoading] = useState(false);
    const [, setStatus] = useState('editing');
    const [offer, setOffer] = useState<any>({ packagingType: 'carton' });
    const { enqueueSnackbar } = useSnackbar()
    useEffect(() => {
        Bbc.subscribe(AppEvent.loggedIn, async () => {
            setOffer({ ...offer, address: (Cook.getUserData() || {})['user']['address'] ?? {} })
        });
        if (Cook.getUserData() != null) {
            var address = (Cook.getUserData() || {})['user']['address'] ?? {};
            delete address['id']
            setOffer({ ...offer, ...address })
        }
    }, [])

    const set = (e: React.FormEvent) => {
        var n = e.target['name'];
        var v = e.target['value'];
        offer[n] = v;
        setOffer({ ...offer })
        console.log(offer);
    }

    return (
        <div style={{ borderBottom: '1px solid #efefef' }}>




            <Hidden only={['sm', 'md', 'xs']}>
                <NewHeader />
            </Hidden>

            <Hidden only={['lg', 'xl']}>
                <MobileHeader />
            </Hidden>
            <BreadCrumbsBar/>

            <ValidatorForm onSubmit={async () => {
                setLoading(true);
                try {
                    var req = await API.post('cart/offer', {
                        ...offer,
                        product: product,
                        user: Cook.getUserData()['user']
                    }, { userid: Cook.getUserData()['user']['id'] }).then(async x => {
                        console.log(x.data);
                        enqueueSnackbar("Quote Submitted");


                        if (offer['saveAddress'] && offer['saveAddress'] == true) {
                            await LoginHelper.updateUserData();
                            enqueueSnackbar("Address Updated");
                            
                        }
                        createBrowserHistory().push(Ways.quotesPage);
                        window.location.reload();
                        setOffer({});
                        setStatus('done');
                    })
                } catch (x) {
                    setStatus('error')
                    alert("ERror")
                    setLoading(false)
                }
            }}>


                <div className={classes.root}>
                    <Container maxWidth="lg">
                        <Grid container className={classes.mainContainer}>
                            <Grid item lg={12} style={{ paddingTop: '5%',  }}>

                                <Typography variant="h5" className={classes.mainheading}>Quote Basket</Typography>

                            </Grid>
                            {/* <Hidden only={['xs', 'sm']}> */}
                            <Grid item lg={12} md={12} xl={12} sm={12} xs={12}>
                                <CartList />
                               
                            </Grid>

                            {/* </Hidden> */}

                            {/* <Hidden only={['md', 'lg', 'xl']}>
                            <Grid item xs={12} sm={12}>
                                <ResponsiveTesting />
                               
                            </Grid>
                            </Hidden> */}
                        </Grid>
                      




                        <Grid container style={{ paddingTop: '3%', paddingBottom: '3%', paddingLeft: '3%', paddingRight: '3%', marginTop: '4%', backgroundColor: '#fff', border: '1px solid #bcb8b8' }}>
                            <Grid item lg={12}>

                                <Typography variant="h5" className={classes.mainheading}>Let's request a quote for your items</Typography>

                            </Grid>

                            <Grid item lg={8} xs={12} style={{ paddingBottom: '1%' }}>
                                {/* <Typography className={classes.description} align="justify" variant="caption">Lorem ipsum dolor sit amet,
                                consectetur adipiscing elit. Etiam eget rutrum libero,
                                sed maximus magna. Aenean vel elit in est sagittis accumsan,Lorem ipsum dolor sit amet,
                   consectetur adipiscing elit.</Typography> */}
                            </Grid>

                            {/* <Grid item lg={12} xs={12}>

                                <Typography variant="h6" className={classes.heading}>How Many Do I Want ?</Typography>

                            </Grid>

                            <Grid style={{ paddingRight: '0.5%' }} item lg={8} xs={12}>
                                <br />
                                <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    value={offer['items']}
                                    validators={['required']}
                                    errorMessages={['Quantity is required']}
                                    label="Quantity"
                                    onChange={set}
                                    name={'items'}
                                />

                            </Grid>

                            <Grid item lg={4} xs={12}>
                                <br />
                                <SelectValidator
                                    variant="outlined"
                                    fullWidth
                                    value={offer['packageType']}
                                    validators={['required']}
                                    errorMessages={['Quantity is required']}
                                    // label="Per Carton/Per Pallete"
                                    onChange={set}
                                    defaultValue={'carton'}
                                    name={'packageType'}
                                    shrink={false}

                                >


                                    <MenuItem selected value={'carton'}>Carton</MenuItem>
                                    <MenuItem value={'pallet'}>Pallet</MenuItem>

                                </SelectValidator>
                            </Grid> */}





                            <Grid item lg={6} xs={12}>

                                {/* <List style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                    <ListItem className={classes.tooltipItem}><Typography variant="h6" className={classes.heading}>What’s My Shipping Preference?</Typography></ListItem>
                                    <ListItem >
                                        <HtmlTooltip
                                            title={
                                                <React.Fragment>
                                                    <Typography variant="caption">FOB - AHV arranges and pays for delivery to nearest port and I will handle the rest </Typography><br />
                                                    <Typography variant="caption">CIF - AHV arranges and pays for delivery to the my country’s port and I will handle the rest  </Typography><br />
                                                    <Typography variant="caption">DDP - AHV arranges and pays for the whole delivery direct to my warehouse  </Typography><br />
                                                </React.Fragment>
                                            }
                                        >
                                            <img src={question} />
                                        </HtmlTooltip>
                                    </ListItem>
                                </List> */}
                            </Grid>
                            <Grid style={{ paddingBottom: '2%' }} item lg={12} xs={12}>
                                <SelectValidator
                                    name='cnf'
                                    fullWidth={true}
                                    variant="outlined"
                                    validators={['required']}
                                    errorMessages={['CNF is required']}
                                    onChange={set}
                                    value={offer['cnf']}
                                    label="I am looking for"
                                    className="textAlign"
                                >
                                    {cnfMenuList}
                                </SelectValidator>
                            </Grid>


                            <Grid item lg={12} xs={12}>

                                <Typography variant="h6" className={classes.heading}>Delivery Schedule</Typography>
                            </Grid>

                            <Grid item lg={12} xs={12} sm={12} md={12}>
                                <br />
                                <SelectValidator
                                     fullWidth={true}
                                    variant="outlined"
                                  
                                
                                    name={'shippingStrategy'}
                                    onChange={set}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    value={offer['shippingStrategy']}
                                    label="Over how many deliveries do I need this order split?"
                                >
                                    {shippingStrategyMenuList}
                                </SelectValidator>
                            </Grid>



                            <Grid item lg={12} xs={12}>
                                <br />
                                <SelectValidator
                                    name={'timing'}
                                    value={offer['timing']}
                                    onChange={set}
                                    fullWidth={true}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    variant='outlined'
                                    label="How quickly do I need this item shipped ?"
                                >
                                    {timingMenuList}
                                </SelectValidator>
                            </Grid>


                            {offer['cnf'] != undefined && offer['cnf'] == '1' ? 
                               <div style={{ width: '100%' }}>
<Grid container >
                            <Grid item lg={12} xs={12}>
                                        <br />
                                        <Typography variant="h6" className={classes.heading}>Here’s my shipping address</Typography>
                                        <br />
                                    </Grid>

                                    {/* <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'port'}
                                            onChange={set}
                                            
                                            value={offer['port']}
                                            id="port-name"
                                            validators={['required']}
                                            errorMessages={['port is required']}
                                            label="Port Name"
                                            type="name"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid> */}

                                    <Grid item lg={12} xs={12} style={{marginBottom: 20}}>
                                        <TextValidator
                                            name={'companyName'}
                                            onChange={set}
                                            value={offer['companyName']}
                                            
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            id="amount"
                                            label="Company Name"
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    
                                    </Grid>

                                    <Grid item lg={12} xs={12} style={{marginBottom: 20}}>
                                        <TextValidator
                                            name={'street1'}
                                            onChange={set}
                                            value={offer['street1']}
                                            
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            id="amount"
                                            label="Address Line 1"
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12} style={{marginBottom: 20}}>
                                        <TextValidator
                                            name={'city'}
                                            onChange={set}
                                            value={offer['city']}
                                            
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            label="City"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12} style={{marginBottom: 20}}>
                                        <TextValidator
                                            name={'state'}
                                            onChange={set}
                                            
                                            value={offer['state']}
                                            id="amount"
                                            validators={['required']}
                                            errorMessages={['State is required']}
                                            label="State/Province"
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12} style={{marginBottom: 20}}>
                                        <TextValidator
                                            name={'zipcode'}
                                            
                                            value={offer['zipcode']}
                                            id="amount"
                                            label="Post Code"
                                            onChange={set}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>


                                    </Grid>
                                    
                            </div> : <div style={{ width: '100%' }}>


                            </div>

                            }


                            {offer['cnf'] != undefined && offer['cnf'] == '2' ?
                                <div style={{ width: '100%' }}>
                                    <Grid item lg={12} xs={12}>
                                        <br />
                                        <Typography variant="h6" className={classes.heading}>Here’s my shipping address</Typography>
                                        <br />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'airport'}
                                            onChange={set}
                                            margin="dense"
                                            value={offer['airport']}
                                            id="port-name"
                                            validators={['required']}
                                            errorMessages={['port is required']}
                                            label="Airport Name"
                                            type="name"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>


                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'state'}
                                            onChange={set}
                                            margin="dense"
                                            value={offer['state']}
                                            id="amount"
                                            validators={['required']}
                                            errorMessages={['State is required']}
                                            label="State/Province"
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'zipcode'}
                                            margin="dense"
                                            value={offer['zipcode']}
                                            id="amount"
                                            label="Zip Code"
                                            onChange={set}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            value={offer['country']}
                                            onChange={set}
                                            margin="dense"
                                            id="amount"
                                            validators={['required']}
                                            errorMessages={['Country is required']}
                                            label="Country"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            name={'country'}
                                        />
                                    </Grid></div> : <div></div>}


                            {/* {offer['cnf'] != undefined && offer['cnf'] == '3' ?
                                <div style={{ width: '100%' }}>
                                    <Grid item lg={12} xs={12}>
                                        <br />
                                        <Typography variant="h6" className={classes.heading}>Here’s My Shipping Address</Typography>
                                        <br />
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'house'}
                                            onChange={set}
                                            value={offer['house']}
                                            margin="dense"
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            id="amount"
                                            label="Name of Company/Warehouse"
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'street1'}
                                            onChange={set}
                                            value={offer['street1']}
                                            margin="dense"
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            id="amount"
                                            label="Street Address 1"
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'suburb'}
                                            onChange={set}
                                            value={offer['suburb']}
                                            margin="dense"
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            label="Suburb"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'city'}
                                            onChange={set}
                                            value={offer['city']}
                                            margin="dense"
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            label="City"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>



                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'street2'}
                                            margin="dense"
                                            value={offer['street2']}
                                            onChange={set}
                                            id="amount"
                                            validators={['required']}
                                            errorMessages={['This field is required is required']}
                                            label="Street Address 2"
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'state'}
                                            onChange={set}
                                            margin="dense"
                                            value={offer['state']}
                                            id="amount"
                                            validators={['required']}
                                            errorMessages={['State is required']}
                                            label="State/Province"
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'zipcode'}
                                            margin="dense"
                                            value={offer['zipcode']}
                                            id="amount"
                                            label="Zip Code"
                                            onChange={set}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            value={offer['country']}
                                            onChange={set}
                                            margin="dense"
                                            id="amount"
                                            validators={['required']}
                                            errorMessages={['Country is required']}
                                            label="Country"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            name={'country'}
                                        />
                                    </Grid>
                                </div>
                                : <div></div>} */}





                                        {/* For DDP Both */}


                                        {offer['cnf'] != undefined && offer['cnf'] == '4' || offer['cnf'] == '5' || offer['cnf'] == '6' || offer['cnf'] == '7' ?
                                <div style={{ width: '100%' }}>
                                    <Grid item lg={12} xs={12}>
                                        <br />
                                        <Typography variant="h6" className={classes.heading}>Here’s my shipping address</Typography>
                                        <br />
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'companyName'}
                                            onChange={set}
                                            value={offer['companyName']}
                                            margin="dense"
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            id="amount"
                                            label="Name of Company/Warehouse"
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'street1'}
                                            onChange={set}
                                            value={offer['street1']}
                                            margin="dense"
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            id="amount"
                                            label="Street Address 1"
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
{/* 
                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'suburb'}
                                            onChange={set}
                                            value={offer['suburb']}
                                            margin="dense"
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            label="Suburb"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid> */}

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'city'}
                                            onChange={set}
                                            value={offer['city']}
                                            margin="dense"
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            label="City"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>



                                    {/* <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'street2'}
                                            margin="dense"
                                            value={offer['street2']}
                                            onChange={set}
                                            id="amount"
                                            validators={['required']}
                                            errorMessages={['This field is required is required']}
                                            label="Street Address 2"
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid> */}

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'state'}
                                            onChange={set}
                                            margin="dense"
                                            value={offer['state']}
                                            id="amount"
                                            validators={['required']}
                                            errorMessages={['State is required']}
                                            label="State/Province"
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'zipcode'}
                                            margin="dense"
                                            value={offer['zipcode']}
                                            id="amount"
                                            label="Zip Code"
                                            onChange={set}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            value={offer['country']}
                                            onChange={set}
                                            margin="dense"
                                            id="amount"
                                            validators={['required']}
                                            errorMessages={['Country is required']}
                                            label="Country"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            name={'country'}
                                        />
                                    </Grid>
                                </div>
                                : <div></div>}







{/* DDP ends here */}







{/* CIF SEA */}







{offer['cnf'] != undefined && offer['cnf'] == '3' ?
                                <div style={{ width: '100%' }}>
                                    <Grid item lg={12} xs={12}>
                                        <br />
                                        <Typography variant="h6" className={classes.heading}>Here’s my shipping address</Typography>
                                        <br />
                                    </Grid>
                                 

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'portName'}
                                            onChange={set}
                                            margin="dense"
                                            value={offer['portName']}
                                            id="port-name"
                                            validators={['required']}
                                            errorMessages={['port is required']}
                                            label="Port Name"
                                            type="name"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>





                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'state'}
                                            onChange={set}
                                            margin="dense"
                                            value={offer['state']}
                                            id="amount"
                                            validators={['required']}
                                            errorMessages={['State is required']}
                                            label="State/Province"
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            name={'zipcode'}
                                            margin="dense"
                                            value={offer['zipcode']}
                                            id="amount"
                                            label="Zip Code"
                                            onChange={set}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            type="amount"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <TextValidator
                                            value={offer['country']}
                                            onChange={set}
                                            margin="dense"
                                            id="amount"
                                            validators={['required']}
                                            errorMessages={['Country is required']}
                                            label="Country"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            name={'country'}
                                        />
                                    </Grid>
                                </div>
                                : <div></div>}













                                {/* CIF SEA ends */}









                            <Grid item lg={12} xs={12}>
                                <br />
                                <Typography variant="h6" className={classes.heading}>Other Details</Typography>
                                <br />
                            </Grid>
                                        <Hidden only={['sm', 'xs']}>
                            <Grid item lg={12} xs={12} sm={12}>
                                <TextValidator
                                    name={'description'}
                                    margin="dense"
                                    style={{  whiteSpace: 'nowrap',
                                    paddingRight: 10,
                                        wordBreak: 'break-all',
                                        minWidth: 294,
                                        overflow: 'hidden',
                                        // textOverflow: '---',
                                        }}
                                    value={offer['description']}
                                    id="offer-description"
                                    label="Use this space to list any additional information that may be useful in this quote"
                                    type="offer"
                                    onChange={set}
                                    multiline
                                    rows={5}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            </Hidden>

<Hidden only={['lg', 'md', 'xl']}>
                            <Grid item lg={12} xs={12} sm={12}>
                                <TextValidator
                                    name={'description'}
                                    margin="dense"
                                 
                                    value={offer['description']}
                                    id="offer-description"
                                    label="Use this space to list any additional..."
                                    type="offer"
                                    onChange={set}
                                    multiline
                                    rows={5}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            </Hidden>



                            <br />
                          
                        </Grid>

                        <Grid container direction="row">
                <Grid item lg={8}>
                            </Grid>
                            <Grid item lg={4} xs={12}>
                                <br /><br />
                                <Loader isLoading={loading} icon={<div></div>} variant="contained" type='submit' className={classes.makeOffer}>
                                    Submit Quote Request
                            </Loader>
                            </Grid>
                            </Grid>

                    </Container>
                </div>
              
            </ValidatorForm>
            <NewFooter />
        </div>
    )
}