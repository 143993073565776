import React from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, CardContent, CardActions, Button,  List, ListItem } from '@material-ui/core';
import { fade, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import mainimage from './../assets/mainimage.png';
import homepagedesign from './../assets/homepagedesign.jpg';
import beekeeper from './../assets/beekeeper.jpg';
// import { MyButton } from './../Styles/MyButton';




const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    actionArea: {
        
      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },

    mainHeading: {

      fontFamily: 'sophia',
      color: '#fff',
      marginTop: '-27%',
      marginLeft: '17%',
      fontSize: '3.5rem',
      textShadow: '2 2 4 #000000',

      [theme.breakpoints.down('md')]: {
        fontSize: 20,
       marginTop: '-43%',
      },


    },

    slideHeading: {

      
      color: '#fff',

      [theme.breakpoints.up('xl')]: {
       
      },

    },
     
    root: {
      
    
      maxHeight: 560,
      height: 'auto',
       maxWidth: '100%',
       margin: "auto",
      boxShadow : 'none',
      position: 'relative',

      
    },
    media: {
        objectFit: 'cover',
      
    },
  
    imgStyle: {
  
      maxWidth: 120,
     
  
    },

    btnStyle: {
      padding: 15,
      fontFamily: 'sophia',
      boxShadow: 'none',
    },

    mainImage: {
      width: '100%',
      height: '100%',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      paddingBottom: '3%',
      backgroundSize: 'cover',
    },
  
  
  }),
  );
  




export const MainImage = () => {
    const classes = useStyles();

    return(
        <div style={{marginTop: '-1.2%'}}>
        
        <Grid container
         
         direction="row"
         justify="center"
         alignItems="center"
         
         >

        <Grid item lg={12} sm={12} xs={12}>
        <Card className={classes.root}>
      <CardActionArea>
        <CardMedia className={classes.media}>
            <img src={beekeeper} alt="bakcground" className={classes.mainImage} />
        </CardMedia>
        {/* <CardContent className={classes.slideHeading} >
          <Typography className={classes.mainHeading}  align="left" gutterBottom variant="h4" component="h2">
          FINDING<br />EXCITING MARKETS<br />FOR AUSTRALIAN HONEY
          </Typography>

        </CardContent> */}
      </CardActionArea>
      <CardActions>
      
      
      </CardActions>
    </Card>
       

        </Grid>

    
        </Grid>


        
    
        </div>
    )
}