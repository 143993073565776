import React, { useEffect, useState } from 'react';
import { Ways, API, Cook,} from './../Helper';
import { createBrowserHistory } from 'history';
import { Hidden } from '@material-ui/core';
import { MobileHeader } from './../Components/MobileHeader';
import { BuyerServices } from './../Buyer/BuyerServices';
import { Strategy } from './../Buyer/Strategy';
import { Fees} from './../Buyer/Fees';
import { MainSlide } from './../Buyer/mainSlide';
import { Success } from './../Buyer/Success';
import { HoneyInformation } from './../Buyer/HoneyInformation';
import { HoneySalePlus } from './../Buyer/HoneySalePlus';
import { FlagshipBrands } from './../Buyer/FlagshipBrands';
import { FAQ } from './FAQ';
import { PaymentPolicy } from './PaymentPolicy';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { GlobalLogin } from "./../Components/GlobalLogin";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
root: {
   overflowX: 'hidden',
    marginTop: -16,

    [theme.breakpoints.down('sm')]: {
      marginTop: -7,
    },

},
   
  }),
);



export const BuyerPage = () => {
    const classes = useStyles();
  

    return(

        <div  className={classes.root}>

            <Hidden only={['lg', 'xl']}>
            <MobileHeader />
            </Hidden>
            <MainSlide />
            <BuyerServices />
            <Strategy />
            <Fees />
            <HoneyInformation />
            <Success />
            <PaymentPolicy />
            <HoneySalePlus />
            <FlagshipBrands />
            <FAQ />
            <GlobalLogin />
        </div>
    )
}