import React from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, Link,  CardContent, List, Hidden, ListItem, ListItemAvatar, Avatar, Button,  ListItemText } from '@material-ui/core';
import { Image, Work, BeachAccess } from '@material-ui/icons';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { MyButton } from './../Styles/MyButton';
import branding1 from './../assets/branding1.png';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    listStyle: {
display: 'flex',
flexDirection: 'row',
width: '100%',

[theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },




    },

    actionArea: {

        maxWidth: 450,
        
      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },
    root: {
      
      boxShadow: 'none',
      width: 530,
      height: 409,

      
    },
    media: {
      
      width: 514,
      height: 409,
      
    },
  
    imgStyle: {
  
      maxWidth: 120,
     
  
    },

    mainHeading: {
      fontFamily: 'sophia',
      color: '#303030',
      fontSize: 22,
  

  },


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: '1rem',
      color: '#726f6f',
      fontWeight: 500,

  },

  mainHeading2: {
    fontFamily: 'sophia',
    color: '#303030',
    fontSize: 22,
    marginTop: 50,

    paddingBottom: 15,

},


descriptionStyle2: {

    fontFamily: 'sophia-regular',
    fontSize: '1.25rem',
    color: '#726f6f',
    fontWeight: 500,

},

btnStyle: {

  padding: 15,
  fontFamily: 'sophia',
  width: 230,
  
},

avatarStyle: {

  marginTop: -25,
},

link: {

  '&.MuiLink-underlineHover': {
    color: '#f28f16 !important',
  },

},
  

    vector: {

        width: 514,
        height: 409,

        [theme.breakpoints.down('sm')]: {
            width: 350,
            height: 340,
          },
    }
  
}),
);




export const Strategy = () => {
    const classes = useStyles();

    return(
        <div style={{paddingTop: '6%', paddingBottom: '8%'}}>
        <Container maxWidth="xl">
        <Grid container
        
         direction="row"
         justify="center"
         alignItems="center"
         
         >

        <Grid item lg={4} sm={12} xs={12}>
       
           
           
          <Typography className={classes.mainHeading} style={{fontSize: '1.9rem'}} align="left" gutterBottom variant="h5" component="h2">
          Where do we distribute?
          </Typography>
          
          <Typography className={classes.descriptionStyle2} align="justify" variant="body2">
          We produce a range of honey and bee product foods and supplements to suit various premium export markets and industries. We sell on a wholesale B2B basis.
          This means we don’t sell direct to the public. 
          </Typography>
        
          <ListItemText>
          <Typography className={classes.mainHeading2} variant="body2">China</Typography>
          <Typography className={classes.descriptionStyle2} align="justify" variant="body2">We have an exclusive distributor for the whole of the China mainland region.
          If you are looking to buy AHV products destined for China, please contact our distributor. Details can be found on the <Link className={classes.link} href="/stockist">Stockists</Link> page. </Typography>
        </ListItemText>
      
        <ListItemText>
          <Typography className={classes.mainHeading2}  variant="body2">Rest of world</Typography>
          <Typography className={classes.descriptionStyle2} align="justify" variant="body2">AHV is currently handling all stockist enquiries relating to products destined for non-China mainland regions.
           This is likely to change as we negotiate exclusive sales agreements in various export regions. </Typography>
        </ListItemText>
     

        </Grid>

        <Grid item lg={1}></Grid>

        <Grid item lg={4} sm={12} xs={12}>

          <Hidden only={['sm', 'xs']}>
        <Card className={classes.root}>
     
        <CardMedia
          >
              <img src={branding1} className={classes.vector} alt="services" width="464" height="359" />
          </CardMedia>

    </Card>
    </Hidden>

    <Hidden only={['md', 'lg', 'xl']}>
        <Card style={{boxShadow: 'none',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: 'transparent',}}>
     
        <CardMedia
          >
              <img src={branding1} className={classes.vector} alt="services" width="464" height="359" />
          </CardMedia>

    </Card>
    </Hidden>
        </Grid>

     

        </Grid>

        <Grid container justify="center">
        <Grid item lg={3} xs={12} sm={12} md={12}>
        
                  <Typography align="center">
                    <br /><br /><br />
                    <Link href="/stockist">
            <Button className={classes.btnStyle} variant="contained">
                Find a stockist
            </Button>
            </Link>
            </Typography>
           
        </Grid>

        </Grid>



        {/* <Grid container
        
         
        direction="row"
        justify="center"
        alignItems="flex-start"
         style={{marginTop: '5%'}}
         >
             <Grid item lg={12}>

             <List className={classes.listStyle}>
      <ListItem>
        <ListItemAvatar className={classes.avatarStyle}>
          <Avatar>
            <Image />
          </Avatar>
        </ListItemAvatar>
        <ListItemText>
          <Typography className={classes.mainHeading2} variant="body2">China</Typography>
          <Typography className={classes.descriptionStyle2} variant="body2">We have an exclusive distributor for the whole of the China mainland region.
          If you are looking to buy AHV products destined for China, please contact our distributor. Details can be found on the Stockists page. </Typography>
        </ListItemText>
       
      </ListItem>
      <ListItem>
        <ListItemAvatar className={classes.avatarStyle}>
          <Avatar>
            <Work />
          </Avatar>
        </ListItemAvatar>
        <ListItemText>
          <Typography className={classes.mainHeading2} variant="body2">Rest of world</Typography>
          <Typography className={classes.descriptionStyle2} variant="body2">AHV is currently handling all stockist enquiries relating to products destined for non-China mainland regions.
           This is likely to change as we negotiate exclusive sales agreements in various export regions. </Typography>
        </ListItemText>
      
      </ListItem>
  
    </List>

             </Grid>

            
         </Grid> */}


        

        
        </Container>
        </div>
    )
}