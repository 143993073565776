import React, { useEffect, useState } from 'react';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, Grid, Typography, CircularProgress, ListItem, ListItemText, IconButton, Divider,  ListItemSecondaryAction, Hidden, List, Link } from '@material-ui/core';
import { Quantity } from './../Pages/SingleProduct/Quantity';
import { Delete } from '@material-ui/icons';
import productthumn from './../assets/productthumn.jpg';
import { API, LoginHelper, Ways } from '../Helper';
import { useSnackbar } from 'notistack';
import { Loader, AutoLoader } from './LoaderButton';
import { GlobalState } from '../AppState';


const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
  table: {
     minWidth: 320,


  },

  bottomListStyle: {

    display: 'flex',
    flexDirection: 'column',
    width: '100%',

  },

  mainheading: {
    fontFamily: 'sophia',
    paddingBottom: '5%',

  },

  labelStyle: {

    [theme.breakpoints.up('sm')]: {
      display: 'none',
      
    },
  },


  responsiveDivider: {
    margingTop: 70,
    marginBottom: 70,
    paddingTop: 0.1,
    paddingBottom: 0.1,
    backgroundColor: '#fff',
    color: '#000',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
      
    },

  },


}),
);





export const CartList = () => {
  const classes = useStyles();
  const [cart, setCart] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const getCartItems = async () => {
    var req = await API.get('cart/items', { user: LoginHelper.userData()['user']['id'] });
    if (req.status == 200) {
      setLoading(false);
      setCart(req.data['data']);
      console.log(req.data);
    } else {
      enqueueSnackbar("Error getting Cart Data");
    }
  }
  useEffect(() => {
    getCartItems();
  }, [])

  if (loading) {
    return <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '30vh' }}
    >

      <Grid item xs={3}>
        < CircularProgress style={{color: '#f28f16'}} />
      </Grid>
    </Grid>
  }

  if (!loading && cart.length == 0) {
    return <Grid
      container
      spacing={0}
      
      alignItems="center"
      justify="center"
      style={{ minHeight: '30vh' }}
    >

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography align="center">
       NOTHING IS IN THE CART
       </Typography>
    </Grid>
    <Grid item lg={2}>
                    <Link href="/shop"><Button style={{color: '#fff', backgroundColor: '#f28f16', marginBottom: '8%'}} fullWidth variant="contained">Go To Shop</Button></Link>
                       
                        </Grid>
    </Grid>
  }

  return (
    <div>
     



      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right">Product ID</TableCell>
              <TableCell align="right">Product Description</TableCell>
              <TableCell align="right">Image</TableCell>
              <TableCell align="right">No. Of Cartons</TableCell>
              <TableCell align="right">Size(g)</TableCell>
            
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          {
            cart.map((x, i) => {
              return <TableBody className="rowgroup" style={{ paddingTop: -15, paddingBottom: -15, }}>
             
                <TableCell className="cartdata" align="right">HON-PRO-{x['product']['id']}</TableCell>
                <TableCell className="cartdata" align="right"><a href={Ways.singleProduct + x['product']['id']}>{x['product']['productTitle']}</a></TableCell>
                <TableCell className="cartdata" align="right">
                  
                  <img src={x['product']['primaryImage']} width="35" height="35" /></TableCell>
               
                <TableCell className="cartdata"  align="center"><Quantity minValue={x['product']['minQuantity']} cartItem={x} onChange={(x)=>{
                  // getCartItems();
                  var c = cart;
                  c[i]['quantity'] = x;
                  setCart([...c])
                  // console.log(x);
                }} /></TableCell>
                <TableCell className="cartdata" align="right">{x['variation']['weight']}</TableCell>
           
                <TableCell className="cartdata" align="right"><IconButton
               
                  onClick={async () => {
                    await API.post('cart/removeitem', { item: x['id'] }).then(res => {
                      if (res.status == 200) {
                        enqueueSnackbar('Item Removed');
                        var nc = [...cart.filter(z => z['id'] != x['id'])];
                        GlobalState.localCart = { items: nc.length, data: nc }
                        console.log(nc);
                        setCart([...nc])
                      }
                    })
                  }}
                  >
                  <Delete /></IconButton></TableCell>
                  <Divider className={classes.responsiveDivider} />
              </TableBody>
            })
          }







        </Table>
      </TableContainer>




    </div>
  )
}