import React from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, CardContent, List, ListItem, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import beewelllogo from './../assets/beewelllogo.png';
import honeyhitlogo from './../assets/honeyhitlogo.png';
import honeyyummieslogo from './../assets/honeyyummieslogo.png';
import beegenixlogo from './../assets/beegenixlogo.png';

// import { MyButton } from './../Styles/MyButton';



const useStyles = makeStyles({

    actionArea: {
        
      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },

    btnStyle: {

      padding: 15,
    },


    root: {
      maxWidth: 350,
      boxShadow: 'none',
      backgroundColor: 'transparent',

      
    },
    media: {
      height: 200,
      
    },
  
    imgStyle: {
  
      maxWidth: 200,
     
  
    },

    mainHeading: {
      fontFamily: 'sophia',
      color: '#303030',
      fontSize: 22,
  
      paddingBottom: 15,

  },


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: 16,
      color: '#726f6f',

  },
  
  
  });
  




export const FlagshipBrands = () => {
    const classes = useStyles();

    return(
        <div style={{backgroundColor: '#fff'}}>
        <Container maxWidth="xl">
        <Grid container
         spacing={2}
         direction="row"
         justify="center"
         alignItems="center"
         style={{paddingBottom: '4%', paddingTop: '4%'}}
         >

        <Grid item lg={3} sm={6} xs={12} md={6}>
        <Card className={classes.root}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          className={classes.media}>
              <img src={beewelllogo} alt="services" className={classes.imgStyle} />
          </CardMedia>
        <CardContent>
           
           
          <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
          Beewell
          </Typography>
         
        </CardContent>
      </CardActionArea>
    </Card>

        </Grid>

        <Grid item lg={3} sm={6} xs={12} md={6}>
        <Card className={classes.root}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          className={classes.media}>
              <img src={honeyhitlogo} alt="services" className={classes.imgStyle} />
          </CardMedia>
        <CardContent>
    
          <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
          Honey Hiit
          </Typography>
        
        </CardContent>
      </CardActionArea>
    </Card>
        </Grid>

        <Grid item sm={6} lg={3} xs={12} md={6}>
        <Card className={classes.root}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          className={classes.media}>
              <img src={honeyyummieslogo} alt="services" className={classes.imgStyle} />
          </CardMedia>
        <CardContent>
        
          <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
        Honey Yummies
          </Typography>
        
        </CardContent>
      </CardActionArea>
    </Card>
        </Grid>

        <Grid item sm={6} lg={3} xs={12} md={6}>
        <Card className={classes.root}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          className={classes.media}>
              <img src={beegenixlogo} alt="services" className={classes.imgStyle} />
          </CardMedia>
        <CardContent>
        
          <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
        Bee Genix
          </Typography>
        
        </CardContent>
      </CardActionArea>
    </Card>
        </Grid>
     

        </Grid>

      
        </Container>
        </div>
    )
}