import * as axios from "axios";
import { AxiosResponse } from "axios";
import { EventEmitter } from "events";
import { Cookies } from 'react-cookie';
import { createBrowserHistory } from 'history';
import socketIOClient from "socket.io-client";
import { GlobalState } from "./AppState";

export const getval = (object, path = '', even?) => {
    let res = path.split('.').reduce((o, x) => o == undefined ? o : o[x], object);
    if (!res) {
        res = even
    }
    return Math.round(res).toFixed(2);
}





export class Cook {
    static saveUserData = (obj: any) => {

        new Cookies().set('userData', obj);
    }

    static getUserData = () => {
        return new Cookies().get('userData');
    }

    static deleteUserData = () => {
        return new Cookies().remove('userData' , {path: '/'});
    }
}


export const getOfferPrice = (offer: any) => {
    if (offer['counterPrice']) {
        return Number(offer['counterPrice'])
    } else {
        return Number(offer['price'])
    }
}

export class LoginHelper {


    static userid = () => Cook.getUserData()['user']['id'];

    static userData = () => Cook.getUserData();

    static updateUserData = async () => {
        var c = Cook.getUserData();
        console.log(c);
        if (!c) return;
        var newData = await API.get('userdata', { userEmail: c['user']['email'] });
        console.log(newData);
        Cook.saveUserData({ session: c['session'], user: newData.data });
    }

    static login = async (email: string, password: string): Promise<string> => {
        var res = await API.post('login', { email: email, password: password })
        if (res.status == 200) {
            Cook.saveUserData(res.data);
            var data = Cook.getUserData();
            Socket.emit('login', { email: data['user']['email'] })
            Bbc.fire(AppEvent.loggedIn);
            console.log(Cook.getUserData());
            window.location.reload();
            return '';
        } else {
            return res.data;
        }
    }
    static logout = () => {
        var c = Cook.getUserData()['session'];
        Cook.deleteUserData()
        if (c)
            API.post('logout', { session: c })
        createBrowserHistory().push('/')
        window.location.reload();
    }

    

    static isCookiePresent = (): boolean => Cook.getUserData() != null
    static isLoggedIn = async (): Promise<boolean> => {
        let userData = Cook.getUserData();
        return userData != null && (await (await API.get('sessionstatus', { session: userData['session'] })).data == true);
    }
}

export enum AppEvent {
    requestOfferDrawerOpen,
     requestOpenedWithLogin,
      requestOfferDrawerClose, 
      loggedIn, productsFiltered,
       requestLoginDialog, 
       newChatSelected, newMessageRecieved,
        messageAllSeen,quoteUpdated

}

export class Bbc {
    static _emittor = new EventEmitter();
    static subscribe = (type: AppEvent, func: (data?: any) => void) => {
        Bbc._emittor.on(type.toString(), func);
    }

    static fire = (type: AppEvent, data?: any) => {
        Bbc._emittor.emit(type.toString(), data);
    }

    static removeLitseners = (event: AppEvent) => {
        Bbc._emittor.removeAllListeners(event.toString());
    }
}

export class API {
    static network = 'https://34.71.74.232:8080/';
    static networkDev = 'https://34.71.74.232:7070/';
    static local = 'http://localhost:3002/';
    static url = 'https://nothingrealjustchecking.tk/'
    static testURL = 'https://anotherdomaintotestahv.ml/'
static officialUrl = 'https://bknd.australianhoneyventures.com.au/'

    static _apiHost = API.officialUrl;

    static post = async (endpoint: string, body?: any, headers?: any): Promise<AxiosResponse> => {
        return await axios.default.post(API._apiHost + endpoint, body, { headers: headers, withCredentials: true })
    }

    static put = async (endpoint: string, body?: any, headers?: any): Promise<AxiosResponse> => {
        return await axios.default.put(API._apiHost + endpoint, body, { headers: headers, withCredentials: true })
    }

    static get = async (endpoint: string, headers?: any): Promise<AxiosResponse> => {
        return await axios.default.get(API._apiHost + endpoint, { headers: headers, withCredentials: true });
    }

    static delete = async (endpoint: string, headers?: any): Promise<AxiosResponse> => {
        return await axios.default.delete(API._apiHost + endpoint, { headers: headers, withCredentials: true });
    }
}

export const createUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export class Socket {
    static socket: SocketIOClient.Socket = socketIOClient(API._apiHost, {
        // reconnection: true,
        // transports: ['polling', 'websocket'],
        // upgrade: false,
        // multiplex: true,
         forceNew: true 
    });
    static initialize = () => {

        if (LoginHelper.isCookiePresent()) {
            Socket.socket.emit('cookieFound', Cook.getUserData());
        }

        Socket.socket.on('quoteUpdated' , (x)=>{
            console.log(x)
            Bbc.fire(AppEvent.quoteUpdated , x);
            GlobalState.notifications = [...GlobalState.notifications , x];
        })

        Socket.socket.on('message', (x) => {
            Bbc.fire(AppEvent.newMessageRecieved, x);
        
            GlobalState.unreadMessages = [...GlobalState.unreadMessages.map(c => c), x];
        })

        Socket.socket.on('messageSent', message => {
            console.log(message);
            GlobalState.messages[GlobalState.messages.findIndex(x => x['id'] == message['id'])] = message;
        })

        Socket.socket.on('messageAllSeen', message => {
            console.log("Seen");
            console.log(message);
            Bbc.fire(AppEvent.messageAllSeen, message);
        })

        Socket.socket.on('messageSeen', (message) => {

            var index = GlobalState.messages.findIndex(x => {
                return x['id'] == message['id'];
            });

            GlobalState.messages[index] = message;
        })
    }

    static emit = (event: string, data: any) => {
        Socket.socket.emit(event, data);
    }
}

export class Ways {

    static go = (str :string)=>{
        createBrowserHistory().push(str);
        window.location.reload();
    }

    static home: string = '/';
    static singleProduct: string = Ways.home + 'shop/product/';
    static login: string = Ways.home + 'login/';
    static forgetpassword: string = Ways.home + 'forgetpassword/';
    static signup: string = Ways.home + 'signup/';
    // static registrationSuccessfull: string = Ways.home + 'success/';
    static registration: string = Ways.home + 'registration/'
    static dashboard: string = Ways.home + 'dashboard/'
    static quotesPage: string = Ways.dashboard + 'quotes/';
    // static singleOfferpage: string = Ways.offersPage + 'offer';
    static singleQuotepage: string = Ways.quotesPage + 'quote';
    // static brands: string = 'brands/';
    static brands: string = Ways.home + 'brands/';
    static beewellpage: string =  '/beewell/';
    static beegenixpage: string =  '/beegenix/';
    static honeyhitpage: string = '/honeyhiit/';
    static honeyyummiespage: string = '/honey-yummies/';
    static shoppage: string =  '/shop';
    static termspage : string = '/terms';
    static landingpage: string = '/landingpage';
    static apiariespage: string = '/our-apiaries';
    static medicinalpage: string = '/medicinal-honey';
    static contactpage: string = '/contact-us';
    static stockistpage: string = '/stockist';
    static backtotop: string = '/backtotop';
   
    // static shopPage: string = Ways.brands + 'shop/';
    static sellerpage: string =  '/seller';
    static buyerpage: string = '/buyer';
    static ordersPage: string = Ways.dashboard + 'orders';
    static chatPage: string = Ways.dashboard + 'chats';
    static wishlistPage: string = Ways.dashboard + 'wishlist';
    static settingsPage: string = Ways.dashboard + 'settings';
    static singleOrderPage: string = Ways.ordersPage + '/order';
    static checkoutPage: string = '/checkout';
    static cartPage: string = Ways.home + 'quote-basket/';
    static quotePage: string = Ways.home + 'quote/';
}


export const containerNumbers = (s:string) : boolean =>{
        return  /\d/.test(s)
}