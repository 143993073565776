import React, { FunctionComponent, useEffect, useState } from 'react';
import Timer from 'react-compound-timer';
import { List, ListItem, Grid, Typography, Link, Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Loader } from './../Components/LoaderButton';
import { HourglassEmpty, Info } from '@material-ui/icons';
import loader from './../assets/loader.png';
import { createBrowserHistory } from 'history';
import { Ways, Cook, API } from '../Helper';



const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        listItemStyle: {

            // backgroundColor: '#f28f16',
            color: '#303030',
            borderRadius: 6,
            border: '1px solid #303030',
            paddingLeft: 9,
            paddingRight: 9,
            margin: 0,
        },

        typography: {
            margin: 2,
            paddingBottom: 10,
        },

        heading: {
            fontFamily: 'sophia',
        },

listStyle: 
{
            display: 'flex', flexDirection: 'row', width: '44%', marginTop: '-2%', marginBottom: '-2%',

            [theme.breakpoints.down('sm')]: {
                width: '30%',
                
              },
},
        makeOffer: {
            marginTop: '1%',
            marginRight: '1%',
            color: '#ffffff',
            backgroundColor: '#f28f16',
            boxShadow: 'none',
            borderRadius: 4,
            fontFamily: 'sophia',
            fontWeight: 600,
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#FECF51',
                    color: '#ffffff',
                    boxShadow: 'none',
                },
            },
        },


    }),
);

export const TimerComponent: FunctionComponent<{ quote: any }> = props => {
    const [loading, setLoading] = useState(false);
    const classes = useStyles();


    if (props.quote['status'] != 'Quoted') {
        return <div>

          
        </div>
    }

    return (
        <div>
           
            <Timer
                initialTime={Date.parse(props.quote['allowedDate']) - Date.now()}
                direction="backward"
            >
                {() => (
                    <React.Fragment>
                        <List style={{marginTop: '-3%'}}>
                             <Typography className={classes.typography} variant="subtitle2"><b>Quote expires in:</b></Typography>
                            
                        </List>
                        <List className={classes.listStyle} dense>
                        
                               
                           
                            
                            <ListItem className={classes.listItemStyle}>
                                <Timer.Days /> </ListItem>
                                <ListItem>
                                    Days
                                </ListItem>


                            <ListItem className={classes.listItemStyle}>
                                <Timer.Hours /></ListItem>
                                <ListItem>
                                    Hours
                                </ListItem>

                            <ListItem className={classes.listItemStyle}>
                                <Timer.Minutes /> </ListItem>
                                <ListItem>
                                    Mins
                                </ListItem>
                                <ListItem>
                                    </ListItem>

                                    {/* <ListItem>
                                    </ListItem>

                                    <ListItem>
                                    </ListItem>

                                    <ListItem>
                                    </ListItem>

                                    <ListItem>
                                    </ListItem>
                                    <ListItem>
                                    </ListItem> */}

                                    


            {/* <ListItem className={classes.listItemStyle}>
                                <Timer.Seconds /> Seconds

            </ListItem> */}

                            {/* <ListItem className={classes.listItemStyle}>
            <Timer.Seconds /> Seconds

            </ListItem> */}
                        </List>







                    </React.Fragment>
                )}
            </Timer>
            <br />
            {/* <Grid item lg={12} xs={12}>
                <Loader isLoading={loading} icon={<div></div>} variant="contained" onClick={() => {
                    createBrowserHistory().push(Ways.checkoutPage + '/' + props.quote['id'])
                    window.location.reload();
                }} className={classes.makeOffer}>
                    Accept & Place Order
                            </Loader>
                <br />
                <Typography variant="body2" onClick={() => {
                    API.get('singleChat', {
                        offerid: props.quote['id'],
                        userid: Cook.getUserData()['user']['id']
                    }).then(x => {
                        createBrowserHistory().push(Ways.chatPage + '/' + x.data['id'])
                        window.location.reload();
                    })
                }} align="center">Or</Typography>  <Button variant="outlined" fullWidth onClick={() => {
                    API.get('singleChat', {
                        offerid: props.quote['id'],
                        userid: Cook.getUserData()['user']['id']
                    }).then(x => {
                        createBrowserHistory().push(Ways.chatPage + '/' + x.data['id'])
                        window.location.reload();
                    })
                }}><br /><Typography align="center" variant="body2">chat with admin</Typography></Button>
            </Grid> */}

        </div>
    )
}