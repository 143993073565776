import React, {useEffect, useState} from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import product from './../assets/product.jpg';
import Rating from '@material-ui/lab/Rating';
import {Grid, List, ListItem} from '@material-ui/core';
import {API, AppEvent, Bbc} from '../Helper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        maxWidth: 300,
        boxShadow: 'none',
        borderRadius: 8,
        paddingBottom: '4%',
        '&.MuiCard-root': {
            '&:hover': {
                border: '1px solid #ffffff',
                marginTop: -15,
            },
        },

    },
    media: {
        height: 120,
    },
    imgStyle: {
        maxWidth: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    headingStyleMain: {
        fontFamily: 'sophia',
        fontSize: 42,
        color: '#3d3a3a',
    },
    headingStyleMain2: {
        fontFamily: 'sophia',
        fontSize: 22,
        color: '#3d3a3a',
    },
    headingStyle1: {
        fontFamily: 'sophia',
        fontSize: 22,
        color: '#3d3a3a',
    },
    headingStyle2: {
        fontFamily: 'sophia-regular',
        fontSize: 14,
        marginLeft: '6%',
        fontWeight: 600,
        marginTop: '-4%',
    },
    headingStyle: {
        fontFamily: 'sophia-regualr',
        fontSize: 15,
        marginLeft: '6%',
        fontWeight: 600,
    },
    priceStyle: {
        fontFamily: 'sophia-regualr',
        fontSize: 16,
        marginLeft: '6%',
        fontWeight: 600,
        color: '#303030',
    },
    descriptionStyle: {
        fontFamily: 'sophia-light',
    },
    mediumStyle: {
        fontFamily: 'sophia-regular',
    },
    shopButton: {
        marginBottom: '6%',
        color: '#ffffff',
        backgroundColor: '#f28f16',
        borderRadius: 20,
        boxShadow: 'none',
        fontFamily: 'sophia',
    },
    productBtn: {
        color: '#ffffff',
        backgroundColor: '#f28f16',
        boxShadow: 'none',
        borderRadius: 4,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 10,
        paddingBottom: 10,
        fontFamily: 'sophia',
        width: '100%',
        '&.MuiButton-contained': {
            '&:hover': {
                backgroundColor: '#D3D3D2',
                color: '#ffffff',
                boxShadow: 'none',
            },
        },
    },
    productSection: {},
    mainStyle: {
// marginTop: '6%',
        // marginBottom: '6%',
        // paddingTop: '6%',
        paddingBottom: '12%',
        backgroundColor: '#fbfbfb',
    },
}),
);
export const ProductsRow = () => {
    const classes = useStyles();
    const [products, setProducts] = useState([]);
    useEffect(() => {
        API.get('products').then(x => {
            console.log(x.data);
            setProducts(x.data['products'])
        })
        Bbc.subscribe(AppEvent.productsFiltered, (x) => {
            setProducts(x);
        })
    }, [])
    return (
        <div className={classes.mainStyle}>
            <Grid container justify="center" alignItems="center" spacing={4} className={classes.productSection}>
                {products.slice(0, 9).map((x, i) => {
                    return <Grid item lg={3} md={2}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia
                                    className={classes.media}>
                                    <img src={product} alt="services" className={classes.imgStyle}/>
                                </CardMedia>
                                <CardContent>
                                    <br/> <br/> <br/>
                                    <br/> <br/> <br/>
                                    <List style={{display: 'flex', flexDirection: 'row'}}>
                                        <ListItem>
                                            <Typography align="left" className={classes.headingStyle1}
                                                        variant="subtitle1" color="textSecondary" component="p">
                                                {x['productTitle']}
                                            </Typography>
                                        </ListItem>
                                        <ListItem>
                                            <Typography align="right">
                                                <Rating name="read-only" style={{marginTop: '1%'}} readOnly/>
                                            </Typography>
                                        </ListItem>
                                    </List>
                                    <Typography align="left" className={classes.headingStyle2} variant="subtitle2"
                                                color="textSecondary" component="p">
                                        TA {x['ta']} +
                                    </Typography>
                                    <Typography align="left" className={classes.priceStyle} variant="subtitle2"
                                                color="textSecondary" component="p">
                                        {x['minPrice']} - {x['maxPrice']} AUD
                                    </Typography>
                                    <br/>
                                    <Button size="medium" variant="contained" onClick={(e) => {
                                        Bbc.fire(AppEvent.requestOfferDrawerOpen, x);
                                        e.preventDefault();
                                    }} className={classes.productBtn}>
                                        Request Quote
                                    </Button>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                })}
            </Grid>
        </div>
    );
}