import React from 'react';
import { Container, Grid, Box, Breadcrumbs, Link, Typography } from '@material-ui/core';
import comming from './../assets/comming.png';
import { ProductDesign } from './../Components/ProductDesign';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import { DrawerPanel } from './../Components/DrawerPanel';
import { BreadCrumbsBar } from '../Components/BreadCrumbs';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
   


breadcrumbBox: {
    paddingLeft: '5%',
    paddingRight: '5%',
    paddingTop: '1%',
    paddingBottom: '1%',
    backgroundColor: '#f4f4f4',
    // marginTop: '2%',
    // borderTop: '1px solid #efefef',
},

headingStyleMain: {
    fontFamily: 'sophia',
    fontSize: 42,
    color: '#3d3a3a',
},
headingStyleMain2: {
    fontFamily: 'sophia',
    fontSize: 22,
    color: '#3d3a3a',
},

}));


export const HoneyHit = () => {
    const classes = useStyles();
    return(
            <div style={{backgroundColor: '#fff', marginTop: '-1%'}}>
                 
               <BreadCrumbsBar/>
                <Container>
                    <Grid container justify="center" alignItems="center" style={{padding: '8%'}} >


                    <Grid item xs={12}>
                    <Typography style={{color: '#FCB040', fontFamily: 'sophia'}} align="center" variant="h2">Coming Soon</Typography>
                      
                       
                    </Grid>

                    </Grid>
                </Container>
 
        </div>
    )
}