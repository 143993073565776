import React, {useState} from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, CardContent, Link, List, ListItem, Hidden, Button } from '@material-ui/core';
import sectionimg from './../assets/sectionimg.png';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
// import { MyButton } from './../Styles/MyButton';\
import fav1 from './../assets/fav1.png';
import { Favorite } from '@material-ui/icons';
import segment from './../assets/segment.png';
import potential from './../assets/potential.png';
import network from './../assets/network.png';
import { API, AppEvent, Bbc, LoginHelper, Ways, Cook } from './../Helper';
import volume from './../assets/volume.png';
import { RegisterYourInterest } from './../Components/RegisterYourInterest';



const useStyles = makeStyles((theme: Theme) =>
createStyles({

    actionArea: {
      paddingTop: '3%',

      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },
    root: {
      minWidth: 260,
      height: 300,
      padding: '5%',
      backgroundColor: 'transparent',
      boxShadow: 'none',

      [theme.breakpoints.down('sm')]: {
      marginTop: '1%',
      marginBottom: '-22%',
       },

       [theme.breakpoints.down('xs')]: {
        marginTop: '1%',
        marginBottom: '-22%',
         },

      


    },
    media: {
      height: 100,



    },


    sectionMedia: {

      width: '100%',
      height: 'auto',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      
  },

  card: {
    maxHeight: 920,
    height: 'auto',
     maxWidth: '100%',
     margin: "auto",
    boxShadow : 'none',
    position: 'relative',

   
},

    imgStyle: {

      maxWidth: 120,


    },

    iconStyle: {

        fontSize: 70,
        color: '#fff',
        backgrounColor: '#fff',
    },

    mobHeading: {

      fontFamily: 'sophia',
      // color: '#303030',
      color: '#fff',
      
      paddingTop: 25,
      paddingBottom: 15,
      fontSize: '1.9rem',

      [theme.breakpoints.down('sm')]: {
        fontSize: '0.8rem',
        color: '#fff',
       },

    }, 


    mobDescription: {

      fontFamily: 'sophia-regular',
      fontSize: '1.25rem',
      fontWeight: 500,
      // color: '#726f6f',
      color: '#fff',

      [theme.breakpoints.down('sm')]: {
        fontSize: '0.8rem',
        color: '#fff',
       },
    },


    mainHeading: {
      fontFamily: 'sophia',
      // color: '#303030',
      color: '#fff',
      fontSize: '1.9rem',
      paddingTop: 25,
      paddingBottom: 15,

      [theme.breakpoints.down('sm')]: {
        fontSize: '1.4rem',
        color: '#303030',
        marginTop: '-13%',
       },

  },


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: '1.25rem',
      fontWeight: 500,
      // color: '#726f6f',
      color: '#fff',

      [theme.breakpoints.down('sm')]: {
        fontSize: '1.2rem',
        color: '#303030',
        
       },

      

  },

  btnStyle: {
    backgroundColor: '#fff',
    marginTop: '3%',
    marginBottom: '2%',
    color: '#303030',
    width: 230,
    fontFamily: 'sophia',
    padding: 15,

      '&.MuiButton-contained': {
        '&:hover': {
          backgroundColor: '#fff',
        },
      },

  },
  

}),
);






export const Success = () => {
    const classes = useStyles();

    const [data, setData] = useState<any[]>([]);

    return(
        <div style={{backgroundColor: 'transparent', paddingTop: '8%', paddingBottom: '0%'}}>
            <Card  className={classes.card}>
                            <CardMedia >
                                <img className={classes.sectionMedia} src={sectionimg} alt="image" />
                            </CardMedia>
                        </Card>
        <Container fixed>
        <Grid container
         spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
         style={{marginTop: '-64%', position: 'relative'}}

         >

             <Grid item xs={10}>
                 <Typography className={classes.mobHeading}  align="center" variant="h5">
                 Become an exclusive distributor
                 </Typography>

                 <Typography className={classes.mobDescription} align="center" variant="body2">
                 If you have a distinct market or customer segment that is well suited to our innovative products, an exclusive distribution arrangement may be possible. If you are interested in exclusivity over a region, submit an exclusivity enquiry telling us about the following: 
                 </Typography>
             </Grid>
             {/* <Hidden only={['lg', 'md', 'xl']}>
             <Grid item lg={12} style={{height: 300}}></Grid>
             </Hidden> */}

        <Grid item lg={3} sm={6} xs={12}>

        <Card className={classes.root}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          className={classes.media}>

             {/* <Favorite className={classes.iconStyle} /> */}
             <img src={segment} width="140" height="140" />
          </CardMedia>
        <CardContent>


          <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
          Segment
          </Typography>
        
          <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
          What is your market segment and geographic territory ?
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>

        </Grid>

        <Grid item lg={3} sm={6} xs={12}>
        <Card className={classes.root}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          className={classes.media}>
           {/* <Favorite className={classes.iconStyle} /> */}
           <img src={potential} width="140" height="140" />
          </CardMedia>
        <CardContent>

          <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
          Potential
          </Typography>
        
          <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
          What is your market potential in terms of size and growth rates ?

          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
        </Grid>

        <Grid item lg={3} sm={6}  xs={12}>
        <Card className={classes.root}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          className={classes.media}>
             <img src={network} width="140" height="140" />
             {/* <Favorite className={classes.iconStyle} /> */}
          </CardMedia>
        <CardContent>

          <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
          Network
          </Typography>
        
          <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
          How large is your distribution<br />network ?
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
        </Grid>


        <Grid item lg={3} sm={6}  xs={12}>
        <Card className={classes.root}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          className={classes.media}>
             <img src={volume} width="140" height="140" />
             {/* <Favorite className={classes.iconStyle} /> */}
          </CardMedia>
        <CardContent>

          <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
          Volume
          </Typography>
        
          <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
          What volume/quota will you commit to purchasing ?
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
        </Grid>


        </Grid>

        <Grid container justify="center" style={{paddingTop: '3%'}}>
        <Grid item lg={12} xs={12} sm={12}>
           
                  <Typography align="center">
          

            {/* <RegisterYourInterest currentBeekeeper={data} /> */}
            {
            (!LoginHelper.isCookiePresent())
                            ?

                            <div>       <Button className={classes.btnStyle}
                           
                         
                            variant="contained"
    
                        
                            fullWidth
                         
                            onClick={async () => {
                              
                                    Bbc.fire(AppEvent.requestLoginDialog,
                                    );
                              
                               
                            }}>
                                Register Your Interest
                           </Button></div>
                          
                        

                        :
<div>
            <Link href="/registration">
            <Button className={classes.btnStyle} variant="contained"
        >
        Register Your Interest
      </Button>
      </Link>

      </div>
}
            </Typography>
          
        </Grid>

        </Grid>
        </Container>
        </div>
    )
}