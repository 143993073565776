import React, {useState} from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, CardContent, Button, List, Box, Hidden,  Paper,  ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import { Image, Work, BeachAccess } from '@material-ui/icons';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { MyButton } from './../Styles/MyButton';
import branding2 from './../assets/branding2.png';
import introduction from './../assets/introduction.jpg';
import { Money } from '@material-ui/icons';
import asset2 from './../assets/asset2.svg';
import { green } from '@material-ui/core/colors';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    listStyle: {
display: 'flex',
flexDirection: 'row',
width: '100%',

[theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },




    },

    actionArea: {

        maxWidth: 435,
         padding: 25,
        
      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

     

    },


    paper: {
        // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',

    },
    root: {
      
       
      width: 400,
      height: 331,
     
     
      backgroundColor: '#fbfbfb',


      
    },
    media: {
      
      width: 314,
      height: 209,
      
    },
  
    imgStyle: {
  
      maxWidth: 120,
     
  
    },

    mainHeading: {
      fontFamily: 'sophia',
      color: '#303030',
      fontSize: 22,


    //   '&:hover': {
    //     fontFamily: 'sophia',
    //     color: '#f28f16',
    //     fontSize: 22,

    //   },
  

  },

  
  largeHeading: {
    fontFamily: 'sophia',
    color: '#303030',
    fontSize: 24,

    paddingBottom: 55,

},


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: '1.25rem',
      color: '#726f6f',
      fontWeight: 600,
      

  },
  
  

 
  
}),
);




export const LandingServices = (props) => {

  const [isShown, setIsShown] = useState(false);
  const [isSeen, setIsSeen] = useState(false);
  const [isSaw, setIsSaw] = useState(false);
    const classes = useStyles();

    return(
        <div style={{paddingTop: '2%', paddingBottom: '3%'}}>
        <Container maxWidth="lg">
        <Grid container
        
         direction="row"
         justify="center"
         alignItems="center"
         spacing={4}
         style={{marginTop: '1%', }}
         >




{/* For Larger Screens */}

 <Hidden only={['sm', 'xs', 'md']}>
       <Grid item lg={12} xs={12} md={12} xl={12}>
       <Typography className={classes.largeHeading} style={{ fontSize: '1.9rem',}} align="left" gutterBottom variant="h3" component="h2">
    SMALL CHANGES,<span style={{color: '#f28f16', fontSize: '3rem', paddingLeft: '1%'}}>BIG IMPACT</span>
       </Typography>

       </Grid>
       </Hidden>

       {/* For smaller screens */}



       
 <Hidden only={['xl', 'lg',]}>
       <Grid item lg={12} md={12} xs={12} sm={12}>
       <Typography className={classes.largeHeading} style={{ fontSize: '1.9rem',}} align="center" gutterBottom variant="h3" component="h2">
    SMALL CHANGES,<span style={{color: '#f28f16', fontSize: '3rem', paddingLeft: '1%'}}>BIG IMPACT</span>
       </Typography>

       </Grid>
       </Hidden>






      
   <Grid item lg={4} sm={6} xs={12} md={4} className="cardgrid" onMouseEnter={() => setIsShown(true)}  onMouseLeave={() => setIsShown(false)}>
          

{ !isShown &&

<Card className="cardservice">
<CardActionArea className={classes.actionArea}>
<Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
    RETURNING MORE TO BEEKEEPERS
    </Typography>
    <br />
  
  <CardMedia>
   
  <Typography>
  <svg style={{width: 100, height: 100, }} viewBox="0 0 250 250" {...props}>
<defs>
  <style>{".prefix__cls-2{fill:#fcb03f}" }</style>
</defs>
<title>{"Asset 1A"}</title>
<g id="prefix__Layer_2" data-name="Layer 2">
  <g id="prefix__Layer_1-2" data-name="Layer 1">
    <path fill="none" d="M0 0h250v250H0z" />
    <path
      className="prefix__cls-2"
      d="M40.29 83.38v130.73a20.6 20.6 0 0020.58 20.58h129a20.6 20.6 0 0020.58-20.58V83.38A20.61 20.61 0 00192.74 63v-9.7h1.65a10.18 10.18 0 0010.17-10.16V24.28a10.18 10.18 0 00-10.17-10.16H56.31a10.18 10.18 0 00-10.17 10.16v18.86A10.18 10.18 0 0056.31 53.3H58V63a20.6 20.6 0 00-17.71 20.38zm161 10.39V199H49.39V93.77z"
    />
    <path
      className="prefix__cls-2"
      
      d="M97.83 170.47a12.44 12.44 0 008.24 3.57 10.16 10.16 0 006.84-2.7 16.47 16.47 0 009.25 6.54 3.26 3.26 0 006.39 0 16.48 16.48 0 009.13-6.6 10.17 10.17 0 006.95 2.8 12.44 12.44 0 008.24-3.57c5.09-4.52 6.59-10.43 4.24-16.63-2-5.31-6.87-10.91-14.41-16.64a94.206 94.206 0 00-9.38-6.28 9.34 9.34 0 00.84-3.85 9.18 9.18 0 00-1.69-5.32 19.67 19.67 0 013.34-3 3.26 3.26 0 00-3.9-5.22 24.24 24.24 0 00-4.92 4.7 8.48 8.48 0 00-3.28 0 24.78 24.78 0 00-4.92-4.7 3.26 3.26 0 10-3.91 5.22 19.29 19.29 0 013.34 3 9.25 9.25 0 00-.84 9.17c-.58.33-1.27.75-2.12 1.28a94.032 94.032 0 00-7.27 5c-7.54 5.73-12.38 11.33-14.4 16.64-2.36 6.12-.85 12.12 4.24 16.59zm40.93-28.09c6.46 4.92 10.7 9.68 12.25 13.78 1.39 3.64.58 6.72-2.47 9.43a6.63 6.63 0 01-3.91 1.93c-1.59 0-5.71-1.57-9.56-16.12l-.09-.32a131 131 0 01-2.62-13.08c1.64 1 3.92 2.5 6.4 4.38zM121.39 155c1.15.1 2.52.16 4 .16s2.81-.06 4-.16q.83 2.9 1.76 5.34a39.16 39.16 0 01-11.44 0c.54-1.6 1.12-3.34 1.68-5.34zm4 12.28a34.71 34.71 0 007.63-.75c-2.07 3.28-4.84 5.17-7.72 5.17s-5.67-1.91-7.74-5.22a34.18 34.18 0 007.79.83zm-25.71-11.12c1.56-4.1 5.8-8.86 12.26-13.78 2.48-1.88 4.79-3.41 6.4-4.42a128.59 128.59 0 01-2.63 13.12 3 3 0 01-.08.32c-3.85 14.55-8 16.12-9.56 16.12a6.6 6.6 0 01-3.91-1.93c-3.05-2.71-3.86-5.79-2.48-9.43z"
    />
  </g>
</g>
</svg>
    </Typography>
    </CardMedia>
  <CardContent>
     
     
 
    <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
    Brokering model directs profits back to Beekeepers
    </Typography>
  </CardContent>
</CardActionArea>
</Card>

}


    {isShown && (

      

         <Card className="cardservice">
      <CardActionArea className={classes.actionArea}>
      <Typography className={classes.mainHeading} style={{color: '#f28f16'}} align="center" gutterBottom variant="h5" component="h2">
          RETURNING MORE TO BEEKEEPERS
          </Typography>
          <br />
        
        <CardMedia>
         
        <Typography>
          
        <svg style={{width: 100, height: 100, }} viewBox="0 0 250 250" {...props}>
      <defs>
        
      </defs>
      <title>{"Asset 1A"}</title>
      <g id="prefix__Layer_3" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path fill="none" d="M0 0h250v250H0z" />
          <path
            className="prefix__cls-3"
            d="M40.29 83.38v130.73a20.6 20.6 0 0020.58 20.58h129a20.6 20.6 0 0020.58-20.58V83.38A20.61 20.61 0 00192.74 63v-9.7h1.65a10.18 10.18 0 0010.17-10.16V24.28a10.18 10.18 0 00-10.17-10.16H56.31a10.18 10.18 0 00-10.17 10.16v18.86A10.18 10.18 0 0056.31 53.3H58V63a20.6 20.6 0 00-17.71 20.38zm161 10.39V199H49.39V93.77z"
          />
          <path
            className="prefix__cls-3"
            
            d="M97.83 170.47a12.44 12.44 0 008.24 3.57 10.16 10.16 0 006.84-2.7 16.47 16.47 0 009.25 6.54 3.26 3.26 0 006.39 0 16.48 16.48 0 009.13-6.6 10.17 10.17 0 006.95 2.8 12.44 12.44 0 008.24-3.57c5.09-4.52 6.59-10.43 4.24-16.63-2-5.31-6.87-10.91-14.41-16.64a94.206 94.206 0 00-9.38-6.28 9.34 9.34 0 00.84-3.85 9.18 9.18 0 00-1.69-5.32 19.67 19.67 0 013.34-3 3.26 3.26 0 00-3.9-5.22 24.24 24.24 0 00-4.92 4.7 8.48 8.48 0 00-3.28 0 24.78 24.78 0 00-4.92-4.7 3.26 3.26 0 10-3.91 5.22 19.29 19.29 0 013.34 3 9.25 9.25 0 00-.84 9.17c-.58.33-1.27.75-2.12 1.28a94.032 94.032 0 00-7.27 5c-7.54 5.73-12.38 11.33-14.4 16.64-2.36 6.12-.85 12.12 4.24 16.59zm40.93-28.09c6.46 4.92 10.7 9.68 12.25 13.78 1.39 3.64.58 6.72-2.47 9.43a6.63 6.63 0 01-3.91 1.93c-1.59 0-5.71-1.57-9.56-16.12l-.09-.32a131 131 0 01-2.62-13.08c1.64 1 3.92 2.5 6.4 4.38zM121.39 155c1.15.1 2.52.16 4 .16s2.81-.06 4-.16q.83 2.9 1.76 5.34a39.16 39.16 0 01-11.44 0c.54-1.6 1.12-3.34 1.68-5.34zm4 12.28a34.71 34.71 0 007.63-.75c-2.07 3.28-4.84 5.17-7.72 5.17s-5.67-1.91-7.74-5.22a34.18 34.18 0 007.79.83zm-25.71-11.12c1.56-4.1 5.8-8.86 12.26-13.78 2.48-1.88 4.79-3.41 6.4-4.42a128.59 128.59 0 01-2.63 13.12 3 3 0 01-.08.32c-3.85 14.55-8 16.12-9.56 16.12a6.6 6.6 0 01-3.91-1.93c-3.05-2.71-3.86-5.79-2.48-9.43z"
          />
        </g>
      </g>
    </svg>
          </Typography>
          </CardMedia>
        <CardContent>
           
           
       
          <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
          Brokering model directs profits back to Beekeepers
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>



      )}











  
  
     </Grid>


     <Grid item lg={4} sm={6} xs={12} md={4} className="cardgrid" onMouseEnter={() => setIsSeen(true)}  onMouseLeave={() => setIsSeen(false)}>

       
    { !isSeen && 
     <Card className="cardservice">
 <CardActionArea className={classes.actionArea}>
 <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
 PRODUCTS TAILORED FOR EXPORT
     </Typography>
     <br />
   
   <CardMedia>
    
   <Typography>



   <svg style={{width: 100, height: 100}} viewBox="0 0 250.35 250" {...props}>
      <defs>
        <style>{".prefix__cls-2{fill:#fcb03f}"}</style>
      </defs>
      <title>{"Asset 2"}</title>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path fill="none" d="M0 0h250v250H0z" />
          <g id="prefix__Layer_1-2-2" data-name="Layer 1-2">
            <path
              className="prefix__cls-2"
              d="M234.72 14.12H16A15.62 15.62 0 00.35 29.74V149A15.62 15.62 0 0016 164.64h218.72A15.63 15.63 0 00250.35 149V29.74a15.62 15.62 0 00-15.63-15.62zm0 108.85a26 26 0 00-26 26H42A26 26 0 0016 123V55.78a26 26 0 0026-26.04h166.68a26 26 0 0026 26z"
            />
            <path
              className="prefix__cls-2"
              d="M128.47 93.28v12.5a8 8 0 005.21-2.08 4.75 4.75 0 002.09-4.17 6 6 0 00-1.57-4.16 22.37 22.37 0 00-5.73-2.09zM117 77.14a4.72 4.72 0 001.56 3.64 15.66 15.66 0 005.21 2.61v-12a9.31 9.31 0 00-5.21 2.08 4.05 4.05 0 00-1.56 3.67z"
            />
            <path
              className="prefix__cls-2"
              d="M125.35 49.53a39.59 39.59 0 1039.58 39.59 39.58 39.58 0 00-39.58-39.59zm14.58 59.38a17.13 17.13 0 01-11.46 4.16v5.21h-4.68v-5.21a28.81 28.81 0 01-17.19-7.81l5.21-6.26a26.6 26.6 0 0012 6.25V91.72a23.4 23.4 0 01-11.46-5.21 11.15 11.15 0 01-3.65-9.37 13.32 13.32 0 014.17-9.9 15.65 15.65 0 0110.94-4.17V60h4.68v3.6a30.84 30.84 0 0114.59 5.2l-4.69 6.25a23.75 23.75 0 00-9.9-4.16v13a28.37 28.37 0 0112 5.21 11.13 11.13 0 013.65 9.37 12.94 12.94 0 01-4.21 10.44z"
            />
            <circle
              className="prefix__cls-2"
              cx={199.31}
              cy={89.12}
              r={20.83}
            />
            <circle className="prefix__cls-2" cx={51.39} cy={89.12} r={20.83} />
            <path
              className="prefix__cls-2"
              d="M.35 172.35h250v15.62H.35zM.35 195.73h250v15.63H.35zM.35 219.06h250v15.63H.35z"
            />
          </g>
        </g>
      </g>
    </svg>



     </Typography>
     </CardMedia>
   <CardContent>
      
      
  
     <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
     Distinct differentiation against competing brands
     </Typography>
   </CardContent>
 </CardActionArea>
</Card>
}   


{isSeen && (





<Card className="cardservice">
<CardActionArea className={classes.actionArea}>
<Typography className={classes.mainHeading} style={{color: '#f28f16'}} align="center" gutterBottom variant="h5" component="h2">
PRODUCTS TAILORED FOR EXPORT
    </Typography>
    <br />
  
  <CardMedia>
   
  <Typography>
    
  <svg style={{width: 100, height: 100, }} viewBox="0 0 250 250" {...props}>
<defs>
  
</defs>
<title>{"Asset 1A"}</title>
<g id="prefix__Layer_3" data-name="Layer 2">
  <g id="prefix__Layer_1-2" data-name="Layer 1">
  <path prefix__cls-3 d="M234.7,14.1H16c-8.6,0-15.6,7-15.6,15.6c0,0,0,0,0,0V149c0,8.6,7,15.6,15.6,15.6c0,0,0,0,0.1,0h218.7
				c8.6,0,15.6-7,15.6-15.6c0,0,0,0,0,0V29.7C250.4,21.1,243.4,14.1,234.7,14.1C234.7,14.1,234.7,14.1,234.7,14.1z M234.7,123
				c-14.4,0-26,11.6-26,26H42c0-14.3-11.7-26-26-26V55.8c14.4,0,26-11.6,26-26c0,0,0,0,0,0h166.7c0,14.4,11.6,26,26,26L234.7,123z"
				/>
			<path prefix__cls-3 d="M128.5,93.3v12.5c1.9,0,3.8-0.8,5.2-2.1c1.4-0.9,2.2-2.5,2.1-4.2c0-1.5-0.5-3-1.6-4.2
				C132.4,94.4,130.5,93.7,128.5,93.3z"/>
			<path prefix__cls-3 d="M117,77.1c0,1.4,0.5,2.7,1.6,3.6c1.6,1.2,3.3,2.1,5.2,2.6v-12c-1.9,0.1-3.7,0.9-5.2,2.1
				C117.4,74.3,116.8,75.7,117,77.1z"/>
			<path prefix__cls-3 d="M125.3,49.5c-21.9,0-39.6,17.7-39.6,39.6s17.7,39.6,39.6,39.6c21.9,0,39.6-17.7,39.6-39.6c0,0,0,0,0,0
				C164.9,67.3,147.2,49.5,125.3,49.5C125.4,49.5,125.4,49.5,125.3,49.5z M139.9,108.9c-3.2,2.7-7.3,4.2-11.5,4.2v5.2h-4.7v-5.2
				c-6.4-0.6-12.5-3.3-17.2-7.8l5.2-6.3c3.4,3.1,7.5,5.2,12,6.3V91.7c-4.2-0.7-8.2-2.5-11.5-5.2c-2.6-2.4-4-5.8-3.7-9.4
				c-0.1-3.7,1.5-7.3,4.2-9.9c3-2.8,6.9-4.2,10.9-4.2V60h4.7v3.6c5.2,0.5,10.2,2.3,14.6,5.2l-4.7,6.3c-2.9-2.1-6.3-3.5-9.9-4.2v13
				l0,0c4.4,0.8,8.5,2.6,12,5.2c2.6,2.4,4,5.8,3.6,9.4C144.4,102.4,142.9,106.3,139.9,108.9z"/>
			<circle prefix__cls-3 cx="199.3" cy="89.1" r="20.8"/>
			<circle prefix__cls-3 cx="51.4" cy="89.1" r="20.8"/>
			<rect x="0.3" y="172.4" prefix__cls-3 width="250" height="15.6"/>
			<rect x="0.3" y="195.7" prefix__cls-3 width="250" height="15.6"/>
			<rect x="0.3" y="219.1" prefix__cls-3 width="250" height="15.6"/>
  </g>
</g>
</svg>
    </Typography>
    </CardMedia>
  <CardContent>
     
     
 
    <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
    Distinct differentiation against competing brands
    </Typography>
  </CardContent>
</CardActionArea>
</Card>





)}


</Grid>


<Grid item lg={4} sm={6} xs={12} md={4} className="cardgrid" onMouseEnter={() => setIsSaw(true)}  onMouseLeave={() => setIsSaw(false)}>

       
       
{ !isSaw && 

<Card className="cardservice">
 <CardActionArea className={classes.actionArea}>
 <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
 ACCESS TO MARKETING COLLATERAL
     </Typography>
     <br />
   
   <CardMedia>
    
   <Typography>
        


   <svg style={{width: 100, height: 100, color: green}} viewBox="0 0 250 250" {...props}>
      <defs>
        <style>{".prefix__cls-2{fill:#fcb03f}"}</style>
      </defs>
      <title>{"Asset 3"}</title>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path fill="none" d="M0 0h250v250H0z" />
          <path
            className="prefix__cls-2"
            d="M233.4 208.14L209 232.55a7.41 7.41 0 01-10.44 0l-19.19-19.27-19.26 19.27a7.76 7.76 0 01-6.4 2.06 7.5 7.5 0 01-5.37-4.12L81.11 90.12A7.41 7.41 0 0191 80.27l140.37 67.2a7.33 7.33 0 012.06 11.84l-19.3 19.19 19.27 19.2a7.42 7.42 0 010 10.44z"
          />
          <path
            className="prefix__cls-2"
            d="M110.82 176.37a7.43 7.43 0 01-7 7.72c-1.32 0-2.64.08-4 .08a85 85 0 01-60-24.86c-32.43-32.5-33-85.81-1.32-119l2.5-2.54C74.35 6 127.66 6.59 160.09 39a84.87 84.87 0 0124.85 64 7.36 7.36 0 01-14.71-.74 70.43 70.43 0 00-20.51-52.87c-26.84-26.84-71-27.35-98.39-1.1L49 50.63C23 77.91 23.39 122 50.23 148.87a70.44 70.44 0 0052.87 20.52 7.38 7.38 0 017.72 6.98z"
          />
          <path
            className="prefix__cls-2"
            d="M66.92 85.34a36 36 0 007.72 39.12 35.47 35.47 0 0014.49 8.82 7.34 7.34 0 01-2.21 14.34 7.57 7.57 0 01-2.2-.29 50.67 50.67 0 01-31.11-68.24 2.76 2.76 0 01.3-.66 50.72 50.72 0 0125.36-25.37c.23-.07.45-.22.67-.29a50.67 50.67 0 0168.23 31.1 7.36 7.36 0 01-14 4.41 35.82 35.82 0 00-47.87-22.2 4.65 4.65 0 00-.59.29 34.68 34.68 0 00-18.49 18.46c-.08.17-.22.36-.3.51z"
          />
        </g>
      </g>
    </svg>



     </Typography>
     </CardMedia>
   <CardContent>
      
      
  
     <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
     Create irresistible best sellers for retailers
     </Typography>
   </CardContent>
 </CardActionArea>
</Card>}




{isSaw && (





<Card className="cardservice">
<CardActionArea className={classes.actionArea}>
<Typography className={classes.mainHeading} style={{color: '#f28f16'}} align="center" gutterBottom variant="h5" component="h2">
ACCESS TO MARKETING COLLATERAL
    </Typography>
    <br />
  
  <CardMedia>
   
  <Typography>
    
  <svg style={{width: 100, height: 100, }} viewBox="0 0 250 250" {...props}>
<defs>
  
</defs>
<title>{"Asset 1A"}</title>
<g id="prefix__Layer_3" data-name="Layer 2">
  <g id="prefix__Layer_1-2" data-name="Layer 1">
	<path prefix__cls-3 d="M233.4,208.1L209,232.6c-2.9,2.9-7.6,2.9-10.4,0l-19.2-19.3l-19.3,19.3c-1.7,1.6-4.1,2.4-6.4,2.1
			c-2.3-0.4-4.3-2-5.4-4.1L81.1,90.1c-1.7-3.7-0.2-8.1,3.5-9.9c2-0.9,4.3-0.9,6.3,0l140.4,67.2c3.7,1.7,5.2,6.1,3.5,9.7
			c-0.4,0.8-0.9,1.5-1.5,2.1l-19.3,19.2l19.3,19.2C236.1,200.6,236,205.2,233.4,208.1z"/>
		<path prefix__cls-3 d="M110.8,176.4c0.2,4.1-3,7.5-7,7.7c-1.3,0-2.6,0.1-4,0.1c-22.5,0-44.1-9-60-24.9c-32.4-32.5-33-85.8-1.3-119
			l2.5-2.5C74.3,6,127.7,6.6,160.1,39c16.9,16.9,26,40.1,24.9,64c-0.2,4.1-3.7,7.2-7.7,7c-4.1-0.2-7.2-3.7-7-7.7
			c0.9-19.7-6.6-38.9-20.5-52.9c-26.8-26.8-71-27.4-98.4-1.1L49,50.6c-26,27.3-25.6,71.4,1.2,98.2c14,13.9,33.1,21.4,52.9,20.5
			C107.2,169.2,110.6,172.3,110.8,176.4z"/>
		<path prefix__cls-3 d="M66.9,85.3c-5.5,13.4-2.5,28.8,7.7,39.1c4,4.1,9,7.1,14.5,8.8c3.9,1.2,6,5.3,4.8,9.2c-1,3.1-3.8,5.1-7,5.1
			c-0.7,0-1.5-0.1-2.2-0.3c-26.7-8.5-41.3-37.1-32.8-63.7c0.5-1.5,1.1-3,1.7-4.5c0.1-0.2,0.2-0.5,0.3-0.7C59,67.2,68,58.1,79.3,53.1
			c0.2-0.1,0.4-0.2,0.7-0.3c25.7-11,55.5,0.9,66.5,26.6c0.6,1.5,1.2,3,1.7,4.5c0.9,4-1.5,7.9-5.5,8.8c-3.5,0.8-7.2-1.1-8.5-4.4
			c-6-18.8-26.2-29.3-45-23.2c-1,0.3-1.9,0.7-2.9,1c-0.2,0.1-0.4,0.2-0.6,0.3c-8.3,3.5-15,10.1-18.5,18.5C67.1,85,67,85.2,66.9,85.3
			z"/>
  </g>
</g>
</svg>
    </Typography>
    </CardMedia>
  <CardContent>
     
     
 
    <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
    Create irresistible best sellers for retailers
    </Typography>
  </CardContent>
</CardActionArea>
</Card>





)}





</Grid>

    
        </Grid>



        

        
        </Container>
        </div>
    )
}