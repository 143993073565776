import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import { Grid } from '@material-ui/core';
import { PlayCircleFilled } from '@material-ui/icons';


class Video extends Component {
  render () {
    return<div>
                <Grid container justify="center" direction="row" alignItems="center">
                   
                      <Grid item lg={4}></Grid>
                    <Grid item lg={6}>  <ReactPlayer className="react-player"   PlayCircleFilled={<button></button>} url='https://www.youtube.com/watch?v=pt0r4bf21S8&feature=emb_title&ab_channel=JayCurtin' />
                  
</Grid>
                    <Grid item lg={2}></Grid>

                   
                </Grid>
        
       </div>
  }
}



export default Video;