import React, {useEffect, useState} from 'react';
import {NewFooter} from '../Components/NewFooter';
import {FooterBottom} from '../Components/FooterBottom';
import {Box, Breadcrumbs, CircularProgress, Container, Grid, Link, Typography} from '@material-ui/core';
import {AllQuotesTable} from './Offer/AllQuotesTable';
import {AllQuotesTableForMobile} from './Offer/AllQuotesTableForMobile';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
// import { Ways, API } from './../Helper2';
import {Menus} from './Menus';
import {Cook, Ways} from '../Helper';
// import { Loader } from './../Components/Loader';
import {createBrowserHistory} from 'history'
import {ChatLayout} from './Chat/ChatLayout';
import {UpdatedHeader} from '../Components/UpdatedHeader';
import {OfferLayout} from './SingleOffer/OfferLayout';
import {OrderLayout} from './Order/OrderLayout';
import {AllOrdersTable} from './Order/AllOrdersTable';
import {AllOrdersTableForMobile} from './Order/AllOrdersTableForMobile';
import Hidden from '@material-ui/core/Hidden';
import { MobileHeader } from './../Components/MobileHeader';
import { ExpansionMenu } from './ExpansionMenu';
import { ProfileSettings } from './ProfileSettings';
import { BottomBar } from './../Components/BottomBar';
import { NewHeader } from './../Components/NewHeader';
import { WishlistTable } from './../Components/WishlistTable';
import { WishlistTableForMobile } from './../Components/WishlistTableForMobile';
import { Quote } from './../Quote/Quote';
import { BreadCrumbsBar } from '../Components/BreadCrumbs';
import { BuyerServices } from './../Buyer/BuyerServices';
import { MainContent } from './MainContent';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        breadcrumbBox: {
            paddingLeft: '5%',
            paddingRight: '5%',
            paddingTop: '1%',
            paddingBottom: '1%',
            backgroundColor: '#f4f4f4',
           
        },

        emptyDiv: {

            marginBottom: -2,

            [theme.breakpoints.up('xl')]: {
                marginBottom: 0,
                
              },
        },
        
        mainHeading: {
            fontFamily: 'sophia',
        },
    }),
);
export const BuyerDashboard = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        var d = Cook.getUserData();
        if (d != null) {
            setLoading(false)
        } else {
            createBrowserHistory().push('/login?redirect=' + window.location.pathname);
            window.location.reload();
        }
    })
    if (loading) {
        return <div style={{height: '100vh', width: '100vh'}}>
            <CircularProgress style={{marginLeft: '90%', marginTop: '45%'}}/>
        </div>
    }
    let path = window.location.pathname;
    let content = <div style={{marginTop: -9}}><MainContent /></div>
    // if (path.includes(Ways.dashboard)) {
    //     content = <div><MainContent /></div>

    // }else
    if (path.includes(Ways.singleQuotepage)) {
        content = <Quote />
    } else if (path == Ways.quotesPage) {
        content = <div><AllQuotesTable /></div>
        // <div><Hidden only={['sm', 'xs',]}><AllQuotesTable/></Hidden><Hidden only={['md', 'lg', 'xl']}><AllQuotesTableForMobile/></Hidden></div>
    } else if (path.includes(Ways.singleOrderPage)) {
        content = <OrderLayout/>
    } else if (path.includes(Ways.ordersPage)) {
        content = <div><AllOrdersTable /></div>
        // <div><Hidden only={['sm', 'xs',]}><AllOrdersTable/></Hidden><Hidden only={['md', 'lg', 'xl']}><AllOrdersTableForMobile/></Hidden></div>
    } else if (path.includes(Ways.settingsPage)) {
        content = <ProfileSettings />
    }
    if (path.includes(Ways.chatPage)) {
        content = <ChatLayout/>
    } else if (path.includes(Ways.wishlistPage)) {
        content = <div><WishlistTable /></div>
        //  <div><Hidden only={['sm', 'xs',]}><WishlistTable/></Hidden><Hidden only={['md', 'lg', 'xl']}><WishlistTableForMobile/></Hidden></div>
    }
    
    return (
        <div>
            <Hidden only={['xs', 'sm', 'md']}>
            <NewHeader />
            </Hidden>

            <Hidden only={['lg', 'xl']}>
                <MobileHeader />
            </Hidden>
            {/* <Hidden only={['sm', 'md', 'lg']}> */}
            <div className={classes.emptyDiv} ></div>
            {/* </Hidden> */}
            <BreadCrumbsBar/>
            
            <Container maxWidth="lg" style={{
                paddingBottom: '5%',
                borderTop: '1px solid #efefef',
                paddingTop: '3%',
                borderBottom: '1px solid #efefef',
                overflow: 'hidden',
                
            }}>
                
                <Grid container spacing={8}>
                    <Grid item lg={3} xs={12}>
                        <Hidden only={['xs', 'sm', 'md']}>
                        <Menus/>
                        </Hidden>

                        <Hidden only={['lg', 'xl']}>
                            <ExpansionMenu />
                        </Hidden>
                    </Grid>
                    <Grid item lg={9} xs={12} sm={12} md={12}>
                        {content}
                    </Grid>
                </Grid>
            </Container>
            <NewFooter/>

            {/* <Hidden only={['lg', 'xl', ]}>
            <BottomBar />
            </Hidden> */}
        </div>
    )
}