import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { MainSlide } from './MainSlide';
import { MedicinalIntroduction } from './MedicinalIntroduction';
import VideoBox from './VideoBox';
import { ProductSection } from './ProductSection';
import { PictureSections } from './PictureSections';
import { MobileHeader } from './../Components/MobileHeader';
import { Hidden } from '@material-ui/core';





const useStyles = makeStyles((theme: Theme) =>
  createStyles({
root: {
// marginTop: -16,
overflowX: 'hidden',
overflowY: 'hidden',

[theme.breakpoints.up('xl')]: {
  marginTop: -16,
},


},
   
  }),
);



export const MedicinalHoneyLayout = () => {
    const classes = useStyles();

    return(

        <div className={classes.root}>
          <Hidden only={['xl', 'lg', 'md']}>
  <MobileHeader />
</Hidden>
     <MainSlide />
     <MedicinalIntroduction />
     <VideoBox />
     <ProductSection />
     <PictureSections />
     
     
        </div>
    )
}