import React from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, CardContent, Link, ListItem, Button } from '@material-ui/core';
import { fade, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import honeyservice1 from './../assets/honeyservice1.png';
import honeyservice2 from './../assets/honeyservice2.png';
import honeyservice3 from './../assets/honeyservice3.png';
// import { MyButton } from './../Styles/MyButton';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    actionArea: {
        
      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },

    btnStyle: {

      padding: 15,
      [theme.breakpoints.up('lg')]: {
       marginLeft: '-3%',
      },
    },


    root: {
      maxWidth: 350,
      boxShadow: 'none',

      
    },
    media: {
      height: 120,
      
    },
  
    imgStyle: {
  
      maxWidth: 120,
     
  
    },

    mainHeading: {
      fontFamily: 'sophia',
      color: '#303030',
      fontSize: 22,
  
      paddingBottom: 15,

  },


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: '1.25rem',
      fontWeight: 500,
      color: '#726f6f',

  },
  
  
}),
);

  




export const SellerServices = () => {
    const classes = useStyles();

    return(
        <div>
        <Container>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        
         style={{marginBottom: '7%', marginTop: '12%'}}
         >

        <Grid item lg={4} sm={6} xs={12}>
        <Card className={classes.root}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          className={classes.media}>
              <img src={honeyservice1} alt="services" className={classes.imgStyle} />
          </CardMedia>
        <CardContent>
           
           
          <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
          High Prices
          </Typography>
          <br />
          <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
          We find the world's best premium export markets for your honey and share the profits with you.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>

        </Grid>

        <Grid item lg={4} sm={6} xs={12}>
        <Card className={classes.root}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          className={classes.media}>
              <img src={honeyservice2} alt="services" className={classes.imgStyle} />
          </CardMedia>
        <CardContent>
    
          <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
          Upfront Payments
          </Typography>
          <br />
          <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
          Beekeepers deliver their honey and receive an upfront payment, greater than the market average packing rate.  
          An additional bonus is issued once their honey is sold. 
          

          </Typography>

          
        </CardContent>
      </CardActionArea>
    </Card>
        </Grid>
        
        <Grid item sm={12} lg={4} xs={12}>
        <Card className={classes.root}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          className={classes.media}>
              <img src={honeyservice3} alt="services" className={classes.imgStyle} />
          </CardMedia>
        <CardContent>
        
          <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
          Beekeepers App
          </Typography>
          <br />
          <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
          Beekeepers get access to an app which puts honey data in the palm of their hand.
        Track delivery dates, honey volumes and characteristics, and sale information with ease.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
        </Grid>

        <Grid item lg={8} sm={8}></Grid>
     

        </Grid>

        <Grid container alignItems="center" justify="center" direction="row">
      
         
        <Grid item lg={3} xs={8} sm={12}>
            {/* <List>
                <ListItem> */}
                <Typography align="center">
                  {/* <Link href="/signup"> */}
            <Button className={classes.btnStyle} variant="contained">
                Register Your Interest
            </Button>
            {/* </Link> */}
            </Typography>
            {/* </ListItem>
            </List> */}
        </Grid>

        

      

        </Grid>
        </Container>
        </div>
    )
}