import React, { FunctionComponent, useEffect, useState } from 'react';
import { Divider, Grid, List, ListItem, ListItemSecondaryAction, Typography, ListItemText, CircularProgress, ListItemIcon, Container } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Lock } from '@material-ui/icons';
import { API, getval, getOfferPrice, Ways } from '../../Helper';
import { cnf, packaging, shippingStrategy, timing } from '../../Constants';
import { Loader } from '../../Components/LoaderButton';
import { PaymentDialog } from './PaymentDialog';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { AllQuotesTable } from './../Offer/AllQuotesTable';
import { CartListForQuote } from '../../Components/CartListForQuote';
import { GlobalState } from '../../AppState';
import { QuoteForm } from './../../Quote/QuoteForm';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listStyle: {
            maxWidth: 280,
        },

        root: {

            backgroundColor: '#ffffff', paddingTop: '4%', paddingBottom: '4%', paddingLeft: '2%', paddingRight: '2%', marginTop: '-5%', minHeight: '75vh', overflowX: 'hidden',

            [theme.breakpoints.down('sm')]: {
              paddingBottom: '16%',
                
              },


        },
        btnStyle: {
            color: '#fff',
            backgroundColor: '#f28f16',
            width: '100%',
            boxShadow: 'none',
        },
    }),
);
export const OrderSummary: FunctionComponent<{ offer: any, }> = props => {
    const stripeLivePublicKey = 'pk_live_51H4oTADeMgQmVxTcW1ZiT7P3HLQwhouG2oAjkmSnRw4vqCD4CGmzcZ6hnswdECoapgojF16kqi99DMyoH8ZzpbhC00eM8rtCR8';

    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const stripePromise = loadStripe(stripeLivePublicKey);


 
    const [co, setCo] = useState({});

    const [quote , setQuote] = useState({});

    const getData = async() => {

        // var id = window.location.pathname.split('/')[4];

        // var off = await API.get('offer', { offerid: id });
        // console.log(off.data);
        // setQuote(off.data);
    }

 


    useEffect(() => {

        getData();
    }, [])

    if (props.offer['status'] != 'Quoted') {
        // return <div>You Can't Create an order yet</div>
        return<Container maxWidth="sm"><Grid container alignItems="center" justify="center"><CircularProgress style={{color: '#f28f16'}} /></Grid></Container>
    }

  

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item lg={12}>
                    <Typography variant="h6">Order Summary</Typography>
                </Grid>
                {/* <Grid item lg={9}>

                    <div><Typography variant="subtitle2">Click to see details &nbsp;<a href={Ways.singleQuotepage + '/' + props.offer['id']}>HON-QUO-{props.offer['rid']}</a></Typography>
                    </div>
                    </Grid> */}
                    <Grid item lg={3}>
                    {/* <List className={classes.listStyle}>
                        <ListItem style={{marginLeft: '-4%'}}>
                            <Typography variant="subtitle2">Total Cost:</Typography>
                            <ListItemSecondaryAction><Typography
                                variant="subtitle2">{props.offer['totalBill']} AUD</Typography></ListItemSecondaryAction>
                        </ListItem>
                        <ListItem >

                            
                      
                        </ListItem>
                    </List> */}
                    {/* <List style={{ display: 'flex', flexDirection: 'row' }}>
                        <ListItem style={{ maxWidth: 40, marginTop: '-13%' }}>
                            <Lock />
                        </ListItem>
                        <ListItem style={{ maxWidth: 480 }}>
                            <Typography variant="subtitle2">Security & Privacy<br />
                                Every transaction on ahv is secure. Any personal information you give us will be
                                handled according to our Privacy Policy.</Typography>
                        </ListItem>
                    </List> */}
                </Grid>
                

                <Grid item lg={12} xs={12} sm={12} md={12} style={{overflow: 'auto', marginTop: '-2%'}}>
                          
                          
            {props.offer['id'] && <CartListForQuote quote={props.offer}/>}
                   
                    </Grid>

                    <Grid item lg={9}>

                    </Grid>
                    <Grid item lg={3} xs={12} sm={12} style={{marginTop: '2%'}}>
                               <Elements stripe={stripePromise}>
                                <PaymentDialog offer={props.offer} amount={getOfferPrice(props.offer) + Number(props.offer['totalBill'])} customer={getval(props.offer, 'user.firstname') + ' ' + getval(props.offer, 'user.lastName')} />
                            </Elements> 
                    </Grid>

                    
                    <Grid item lg={1}></Grid>
                    <Grid item lg={12} xs={12} sm={12}>
                    {props.offer['id'] && <QuoteForm quote={props.offer}/>}
                    </Grid>

                    <Grid item lg={8} style={{paddingTop: '4%'}}>
               <List>
                      
                        <ListItem>
                            <ListItemIcon>
                                <Lock style={{color: '#303030'}} />

                            </ListItemIcon>
                            <ListItemText>
                            <Typography variant="subtitle2">Security & Privacy<br />
                            Every transaction on the AHV website is secure. Any personal information you give us will be handled in accordance with our Privacy Policy.</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                    </Grid>

            
            </Grid>
        </div>
    )
}