import React from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, CardContent, Button, List, ListItem, ListItemAvatar, Avatar, Hidden,  ListItemText } from '@material-ui/core';
import { Image, Work, BeachAccess } from '@material-ui/icons';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { MyButton } from './../Styles/MyButton';
import branding2 from './../assets/branding2.png';
import introduction from './../assets/introduction.jpg';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    listStyle: {
display: 'flex',
flexDirection: 'row',
width: '100%',

[theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },




    },

    actionArea: {

        maxWidth: 450,
        
      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },
    root: {
      
      boxShadow: 'none',
    
      width: '100%',
      height: '100%',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    
      backgroundSize: 'cover',

      // [theme.breakpoints.down('sm')]: {
      //  width: 400,
      //  height: 384,
      // },

      

      
    },
    media: {
      
      width: 514,
      height: 409,
      
    },
  
    imgStyle: {
  
      maxWidth: 120,
     
  
    },

    mainHeading: {
      fontFamily: 'sophia',
      color: '#303030',
      fontSize: 22,
  

  },

  
  largeHeading: {
    fontFamily: 'sophia',
    color: '#303030',
    fontSize: 24,

    paddingBottom: 15,

},


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: '1.25rem',
      color: '#726f6f',
      fontWeight: 500,

  },
  
  

    vector: {

      width: '100%',
      height: '100%',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
     
      backgroundSize: 'cover',

        // width: 600,
        // height: 584,

        // [theme.breakpoints.down('sm')]: {
        //     width: 330,
        //     height: 320,
        //   },
    }
  
}),
);




export const Introduction = () => {
    const classes = useStyles();

    return(
        <div style={{paddingTop: '2%',}}>
        <Container maxWidth="lg">
        <Grid container
        
         direction="row"
         justify="center"
         alignItems="center"
         style={{marginTop: '1%', }}
         >

       

{/* For Larger Screens */}

<Hidden only={['sm', 'md', 'xs']}>
        <Grid item lg={6} sm={12} xs={12} md={6}>

        <Typography className={classes.largeHeading} style={{ fontSize: '1.9rem',}} align="left" gutterBottom variant="h3" component="h2">
        INTRODUCING AHV FOR<br />
BEEKEEPERS & DISTRIBUTORS
       </Typography>
   
       
       <Typography className={classes.descriptionStyle} style={{paddingRight: '16%'}} align="justify" variant="subtitle2" color="textSecondary" component="p">
       AHV is a 100% Australian privately owned aggregator of Australian honey with impressive honey inventory levels capable of supplying packaged honey products to export markets.<br /><br />

We have operations extending along the value chain from receival and storage, marketing, packaging and sale into the export and domestic markets.<br /><br />

Collaborative relationships with Beekeepers and distributors, and product innovation in the medicinal,
 endurance racing and well-being markets, has us on a trajectory of brokering at least one third of Australia’s total commercial honey production by 2027.
       </Typography>
      
        </Grid>

        </Hidden>


        {/* For Smaller Screens */}



        <Hidden only={['xl', 'lg',]}>
        <Grid item lg={6} sm={12} xs={12} md={6}>

        <Typography className={classes.largeHeading} style={{ fontSize: '1.9rem',}} align="center" gutterBottom variant="h3" component="h2">
        INTRODUCING AHV FOR<br />
BEEKEEPERS & DISTRIBUTORS
       </Typography>
   
       
       <Typography className={classes.descriptionStyle} align="justify" variant="subtitle2" color="textSecondary" component="p">
       AHV is a 100% Australian privately owned aggregator of Australian honey with impressive honey inventory levels capable of supplying packaged honey products to export markets.<br /><br />

We have operations extending along the value chain from receival and storage, marketing, packaging and sale into the export and domestic markets.<br /><br />

Collaborative relationships with Beekeepers and distributors, and product innovation in the medicinal,
 endurance racing and well-being markets, has us on a trajectory of brokering at least one third of Australia’s total commercial honey production by 2027.
       </Typography>
      
        </Grid>

        </Hidden>




        

        <Grid item lg={6} sm={12} xs={12} md={6}>
       
        <Card className={classes.root}>
     
     <CardMedia
       >
           <img src={introduction} className={classes.vector} alt="services" />
       </CardMedia>

 </Card>
  

     </Grid>




     

        </Grid>



        

        
        </Container>
        </div>
    )
}