import React, {} from 'react';
import { NewHeader } from './../Components/NewHeader';
import { NewFooter } from './../Components/NewFooter';
import { BottomBar } from './../Components/BottomBar';
import { Beegenix } from './Beegenix';
import { BuyerPage } from './../Buyer/BuyerPage';
import { DrawerPanel } from './../Components/DrawerPanel';
import { MobileHeader } from './../Components/MobileHeader';
import {

    Hidden,

} from '@material-ui/core';
import {Ways} from '../Helper';

import Typography from '@material-ui/core/Typography';
import { Beewell } from './Beewell';
import { ProductDesign } from '../Components/ProductDesign';
import { CommingSoon } from './CommingSoon';
import { SellerPage } from './../Seller/SellerPage';
import { Terms } from '../Components/Terms';
import { HoneyHit } from './HoneyHit';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { BreadCrumbsBar } from '../Components/BreadCrumbs';
import { Container, Grid } from '@material-ui/core';
import { LandingLayout } from './../LandingPage/LandingLayout';
import { ApiariesLayout } from './../OurApiaries/ApiariesLayout';
import { MedicinalHoneyLayout } from './../MedicinalHoney/MedicinalHoneyLayout';
import { FourPoints } from '../Components/FourPoints';
import { ConfirmationMsg } from '../Components/ConfirmationMsg';
import { ContactLayout } from './../Contact/ContactLayout';
import { BackToTop } from './../Components/BackToTop';






// import { Beewell } from './Beewell';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    headingStyleMain5: {
        fontFamily: 'sophia',
        fontSize: '1.9rem',
        color: '#303030',
  
    },

    root: {

        [theme.breakpoints.down('lg')]: {
            marginTop: -2,
          },
    },

    shopRoot: {
        marginTop: -17,

        [theme.breakpoints.down('sm')]: {
            marginTop: -5,
          },
    }
 
 
}),
);





export const Brands = () => {
    const classes = useStyles();

    

    let path = window.location.pathname;
    console.log(path);
    if(path == Ways.shoppage|| path == Ways.beegenixpage || path== Ways.stockistpage || path==Ways.backtotop || path == Ways.contactpage || path == Ways.beewellpage || path == Ways.apiariespage || path == Ways.medicinalpage || path == Ways.buyerpage || path == Ways.honeyhitpage || path == Ways.honeyyummiespage || path == Ways.landingpage || path == Ways.termspage  || path == Ways.sellerpage){

    }else{
        return <div></div>
    }
    
    let content = <CommingSoon />
    if (path.includes(Ways.beewellpage)) {
        content = <Beewell />
    
    } else if (path == Ways.beegenixpage) {
        content = <Beegenix />
    
    } else if (path.includes(Ways.honeyhitpage)) {
        content = <HoneyHit />
    } else if ( path.includes(Ways.honeyyummiespage)) {
        content = <HoneyHit />
        
    } else if (path.includes(Ways.backtotop)) {
        content = <BackToTop />
    }
    
    else if ( path.includes(Ways.shoppage)) {
        content =   <div className={classes.shopRoot}>
                          <Hidden only={['lg', 'xl']}>
            <MobileHeader />
            </Hidden>
                <BreadCrumbsBar />
                <Container>
                    <Grid container justify="center" alignItems="center">


                    <Grid item xs={12} lg={12}>
  
                        <Typography className={classes.headingStyleMain5} style={{paddingTop: '8%', paddingBottom: '-2%'}} align="center" variant="h3">How to purchase at AHV</Typography><br/>
                       <FourPoints />
                        <ProductDesign />
                    </Grid>
               
                    </Grid>
                </Container>
            </div>
   
    
    } else if ( path.includes(Ways.sellerpage)) {
        content = <SellerPage />
    }
    else if ( path.includes(Ways.buyerpage)) {
        content = <BuyerPage />
    }
  
    else if (path.includes(Ways.termspage)){
        content =<Terms/>
    }

    else if (path.includes(Ways.landingpage)){
        content = <LandingLayout />
    }

    else if ( path.includes(Ways.apiariespage)) {
        content = <ApiariesLayout />
    }

    else if ( path.includes(Ways.medicinalpage)) {
        content = <MedicinalHoneyLayout />
        // content = <ConfirmationMsg />
    }

    else if ( path.includes(Ways.contactpage)) {
        content = <ContactLayout />
    }

    else if ( path.includes(Ways.stockistpage)) {
        content = <div style={{marginTop: '1%', paddingTop: '5%', paddingBottom: '5%'}}><Container maxWidth="md"><Grid container justify="center" alignItems="center"><h1>Find a Stockist</h1></Grid></Container></div>
    }

   

   
 
    return (
        <div style={{borderBottom: '1px solid #efefef',}}>
            <Hidden only={['sm', 'md', 'xs']}>
            <NewHeader />
            </Hidden>

            {/* <Hidden only={['lg', 'xl']}>
            <NewHeader />
            </Hidden> */}
          
            <div style={{paddingTop: '1%',}}></div>
           
                        {content}
                
            <NewFooter/>
            {/* <Hidden only={['lg', 'xl']}>
            <BottomBar/>
            </Hidden> */}
            <DrawerPanel />
        </div>
    )
}