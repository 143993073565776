import React, { FunctionComponent, useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Link from '@material-ui/core/Link';
import { Container, Grid, List, ListItem, Box, Button, ListItemText, ListItemSecondaryAction, Hidden, Tooltip, IconButton } from '@material-ui/core';
import { API, AppEvent, Bbc, LoginHelper, Ways, Cook } from '../Helper';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import { Loader } from './LoaderButton';
import { AddCircle } from '@material-ui/icons';
import { GlobalState } from '../AppState';
import { useSnackbar } from 'notistack';
import {createBrowserHistory} from 'history';
import { GlobalLogin } from "./GlobalLogin";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        maxWidth: 385,
        boxShadow: '0 19px 38px rgb(251, 251, 251), 0 15px 12px rgb(239, 239, 239)',
        border: '1px solid #efefef',
        padding: 15,
        backgroundColor: '#fff',



        borderRadius: 8,
        '&.MuiCard-root': {
            '&:hover': {
                border: '1px solid #f28f16',
                // marginTop: -15,
                backgroundColor: '#fff',
                color: '#fff',


            },
        },
    },


    actionarea: {
        
        '&.MuiCardActionArea-root': {
  
          textAlign: 'center',
  
  
        },
  
      },

    cardContent: {

        marginTop: '20%',
        backgroundColor: '#fff',
        
    },
    media: {
        height: 120,

    },
    imgStyle: {
        // maxWidth: '100%',
        width: 290,
        height: 340,
        

        [theme.breakpoints.down('sm')]: {
            width: 290,
            height: 340,
           
        
            
          },
    },
    headingStyleMain: {
        fontFamily: 'sophia',
        fontSize: 42,
        color: '#3d3a3a',
    },
    headingStyleMain2: {
        fontFamily: 'sophia',
        fontSize: 22,
        color: '#3d3a3a',
    },
    headingStyle1: {
        fontFamily: 'sophia',
        fontSize: '1.4rem',
        color: '#3d3a3a',
        marginTop: '-6%',
        marginBottom: '-2%',
        marginLeft: '-6%',
        lineHeight:  1.3,
    },
    textStyle: {
        fontFamily: 'sophia-regular',
        fontSize: 16,
        // marginLeft: '6%',
        fontWeight: 600,
        marginTop: '-3.4%',
    },
    headingStyle: {
        fontFamily: 'sophia-regualr',
        fontSize: 15,
        marginLeft: '6%',
        fontWeight: 600,
    },
    priceStyle: {
        fontFamily: 'sophia',
        fontSize: 16,
        // marginLeft: '6%',
        fontWeight: 400,
        color: '#303030',
        marginTop: '3%',
        
    },
    descriptionStyle: {
        fontFamily: 'sophia-light',
    },
    mediumStyle: {
        fontFamily: 'sophia-regular',
    },
    shopButton: {
        marginBottom: '6%',
        color: '#ffffff',
        backgroundColor: '#f28f16',
        borderRadius: 20,
        boxShadow: 'none',
        fontFamily: 'sophia',
    },
    productBtn: {
        color: '#ffffff',
        backgroundColor: '#f28f16',
        boxShadow: 'none',
        borderRadius: 4,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 10,
        paddingBottom: 10,
        fontFamily: 'sophia',
        width: '100%',
        '&.MuiButton-contained': {
            '&:hover': {
                backgroundColor: '#FCB040',
                color: '#ffffff',
                boxShadow: 'none',
            },
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: 12,
           
        
            
          },


    },
    productSection: {
        paddingTop: '5%',
    },
    cardStyle: {
        boxShadow: 'none'
    },
    mainStyle: {
        marginTop: '0.5%',
        paddingBottom: '1%',
        backgroundColor: '#fff',
    },
}),
);
export const ProductDesign = () => {
    const [RRP, setRRP] = useState();
    const [value, setValue] = React.useState<number | null>(2);
    const classes = useStyles();
    const [products, setProducts] = useState([]);
    useEffect(() => {
        var head = {};
        if (LoginHelper.isCookiePresent()) {
            try {
                head['user'] = Cook.getUserData()['user']['id'];
            } catch (x) { }
        }
        API.get('products', head).then(x => {
            console.log(x.data);
            setProducts(x.data['products'])
        })
    }, [])
    return (
        <div className={classes.mainStyle}>


            <Container maxWidth="lg">
                <Grid container justify="flex-start" alignItems="center" direction="row" spacing={2} className={classes.productSection}>
                <GlobalLogin />
                    {products.slice(0, 4).map((x, i) => {
                        return <SingleProductCard product={x} />
                    })}
                </Grid>
            </Container>
           
        </div>
    );
}
export const SingleProductCard: FunctionComponent<{ product: any }> = props => {

 
    const [RRP, setRRP] = useState();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const x = props.product;
    const { enqueueSnackbar } = useSnackbar();
    var isDisabled = GlobalState.selectedItems.filter(x => x.id == props.product.id).length > 0 || props.product['isInCart'];

    console.log(props.product)
    return <Grid item lg={4} md={6} sm={6} xs={12} style={{ marginBottom: '4%' }}>
         
        <Card className={classes.root}>
            <CardActionArea className={classes.actionarea}>
                <CardMedia className={classes.media}>
                    <Link href={Ways.singleProduct + x['id']}>
                        <img src={props.product['primaryImage']} alt="services" className={classes.imgStyle} />
                    </Link>
                </CardMedia>
                <br /><br />
                <CardContent className={classes.cardContent}>
                    <br /> <br /> <br />
                    <br /> <br /> <br />
                    <div style={{height: 120}}>
                    <List style={{ display: 'flex', flexDirection: 'column' }}>
                        <ListItem>
                            <ListItemText>
                            <Typography align="left" component="legend" style={{marginLeft: '-6%'}}>
                                <Rating
                                    name="customized-empty"
                                    defaultValue={5}
                                    precision={0.5}
                                    emptyIcon={<StarBorderIcon fontSize="inherit" />}
                                />
                            </Typography>
                            {/* </Box> */}
                            </ListItemText>

                            {/* {
                            (!LoginHelper.isCookiePresent())
                            ?
                            <ListItemSecondaryAction>
                                    <Tooltip className="tooltip" interactive title="Login to view wholesale buy prices">
                                    <IconButton onClick={()=>{
                                          Bbc.fire(AppEvent.requestLoginDialog,
                                          );
                                        }} color="primary" aria-label="upload picture" component="span">
                                    <VisibilityOff style={{color: '#f28f16'}}>Wholesale Price</VisibilityOff>
                                    </IconButton>
                                    </Tooltip>
                                    </ListItemSecondaryAction>

                                    : 
                                    <ListItemSecondaryAction>
                                    <Tooltip interactive title="">
                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                    <Visibility style={{color: '#f28f16'}} />
                                    </IconButton>
                                    </Tooltip>
                                    </ListItemSecondaryAction>

                            } */}


                        </ListItem>
                        
                        <ListItem>
                            <Typography align="left" className={classes.headingStyle1} variant="subtitle1"
                                color="textSecondary" component="p">
                                {x['productTitle']}
                            </Typography>
                        </ListItem>

                    </List>
                    
                    <Typography align="left" className={classes.textStyle} variant="subtitle2" color="textSecondary"
                        component="p">
                        {/* TA {x['ta']} + */}
                    </Typography>
                        {/* <Link href="#">
                    <Typography style={{color: '#303030'}} align="left" variant="body2">Login to view wholesale buy prices</Typography>
                    </Link> */}
                    </div>

                        {
                            (!LoginHelper.isCookiePresent())
                            ?

                            
                            <Typography align="left" className={classes.priceStyle} variant="subtitle1" color="textSecondary"
                            component="p">RRP:&nbsp;
                            ${x.variations!=null ? x.variations[0].rrp : ''} /<Typography style={{ fontSize: 14, color: '#656d6d' }} variant="caption"> Jar</Typography>
                           
                        </Typography>
                        

                        :

<ListItem style={{marginLeft: -15, marginTop: -15}}>
    <ListItemText>
                          
                        <Typography align="left" className={classes.priceStyle} variant="subtitle1" color="textSecondary"
                        component="p">RRP:&nbsp;
                        ${x.variations!=null ? x.variations[0].rrp : ''} /<Typography style={{ fontSize: 14, color: '#656d6d' }} variant="caption"> Jar </Typography>
                        
                    </Typography> 
                    </ListItemText>

                    <ListItemSecondaryAction>



                          <Typography style={{marginBottom: -7}} align="left" className={classes.priceStyle} variant="subtitle1" color="textSecondary"
                            component="p">WSP:&nbsp;
                            ${x.variations!=null ? x.variations[0].price : ''} /<Typography style={{ fontSize: 14, color: '#656d6d' }} variant="caption"> Jar</Typography>
                        </Typography>

                        </ListItemSecondaryAction>
                       

                        </ListItem>

                        }
                  


                    <br />

                               

                    {
                            (!LoginHelper.isCookiePresent())
                            ?

                           

                            <div>   
                                
                                    <Loader icon={<AddCircle />}
                            isDisabled={GlobalState.selectedItems.filter(x => x.id == props.product.id).length > 0 || props.product['isInCart']}
                            isLoading={loading}
                            variant="contained"
                           
                            startIcon={props}
                            
                            fullWidth
                            className={classes.productBtn}
                            onClick={async () => {
                              
                                    Bbc.fire(AppEvent.requestLoginDialog,
                                    );
                              
                               
                            }}>
                                
                           {isDisabled ? 'Added To quote BASKET' : 'Login to view wholesale price'}</Loader>
                           </div>
                         




                          
                        

                        :

                        <div>

                        

                            
                              <Loader
                        isDisabled={GlobalState.selectedItems.filter(x => x.id == props.product.id).length > 0 || props.product['isInCart']}
                        isLoading={loading}
                        variant="contained"

                        startIcon={props}
                        fullWidth
                        className={classes.productBtn}
                        onClick={async () => {
                            setLoading(true);
                            if (await LoginHelper.isLoggedIn()) {
                                GlobalState.selectedItems.push(props.product);
                                var data = LoginHelper.userData();
                                console.log(data);
                                var res = await API.post('cart/additem', { user: data['user']['id'], product: props.product['id'], quantity: props.product['minQuantity']  });
                                GlobalState.localCart['data'].push({product : props.product , ...res.data});
                                GlobalState.localCart['items']++;
                                if (res.status == 200) {
                                    enqueueSnackbar("Product Added to Basket" , {action: <React.Fragment>
                                         
                                        <Button variant='contained' style={{backgroundColor :'transparent' , color:'white'}} size="small" onClick={()=>{
                                          createBrowserHistory().push(Ways.cartPage);
                                          window.location.reload();
                                        }}>
                                          Go to Basket
                                        </Button>
                                        {/* <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                                          <CloseIcon fontSize="small" />
                                        </IconButton> */}
                                      </React.Fragment>
                                      }
                                   )                                }
                            } else {
                                Bbc.fire(AppEvent.requestLoginDialog,
                                );
                            }
                            setLoading(false);
                        }}>
                            
                       {isDisabled ? 'Added To quote BASKET' : 'ADD TO Quote BASKET'}</Loader></div>
                    }
                    
                  
                      

                </CardContent>
               
            </CardActionArea>
            
           
        </Card>
       
        
    </Grid>;
    
}
