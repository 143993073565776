import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Link, Typography, List, ListItemText, ListItemSecondaryAction, ListItem } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import honeylogo from './../assets/honeylogo.png';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { API, LoginHelper } from '../Helper';
import { createBrowserHistory } from 'history';
import { Loader } from '../Components/LoaderButton';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
            backgroundColor: '#ffffff',
        },
        listStyle: {
            display: 'flex',
            flexDirection: 'row',
        },
        box: {
            backgroundColor: '#fbfbfb',
            paddingTop: '2%',
            paddingBottom: '2%',
            marginTop: '14%',
        },
        root: {
            marginTop: '5%',
            height: '10vh',
            marginBottom: '20%',
        },
        PopupBtnStyle: {
            fontFamily: 'sophia-regular',
            fontWeight: 1000,
            fontSize: 14,
            color: '#ffffff',
            padding: 10,
            width: '100%',
            backgroundColor: '#f28f16',
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#f28f16',
                    color: '#ffffff',
                    boxShadow: 'none',
                    textDecoration: 'none',
                },
            },
        },
        btnStyle: {
            fontFamily: 'sophia-regular',
            fontWeight: 1000,
            fontSize: 14,
            marginTop: 25,
            color: '#ffffff',
            padding: 10,
            backgroundColor: '#f28f16',
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#f28f16',
                    color: '#ffffff',
                    boxShadow: 'none',
                    textDecoration: 'none',
                },
            },
        },
        topContainer: {
            // borderBottom: '1px solid #efefef',
            marginBottom: '5%',
            paddingBottom: '2%',
            width: '100%',
        },
    }),
);
export const PasswordChange = () => {
    const [confirm, setConfirm] = useState();
    const [password, setPassword] = useState();
    const [loading, setLoading] = useState(false);
    const [enabled, setEnabled] = useState(false);
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();


    useEffect(() => {

        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            console.log({
                pass : password ,
                val : value
            })
            if (value !== password) {
                return false;
            }
            return true;
        });
    },   [password ])



    return (
        <div className={classes.root}>
            <Container maxWidth="md" className={classes.topContainer}>
                <Grid container direction="row"
                    justify="center"
                    alignItems="center"
                >

                    <Grid item lg={3}>
                        <Link href="/">
                            <img src={honeylogo} alt="image" />
                        </Link>
                    </Grid>
                    <Grid item lg={6} xs={2}></Grid>
                    <Grid item lg={1}>
                        <Lock />
                    </Grid>
                    <Grid item lg={2}>
                        <Typography align="left" variant="subtitle2">Change Password</Typography>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="xs">
                <Grid container direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item lg={12} style={{ paddingBottom: 10 }}>
                        <Typography variant='h6'>Password Recovery</Typography>
                        <Typography style={{ minWidth: 435, }} variant="caption">You are required to enter your new password.</Typography>
                    </Grid>
                    <ValidatorForm onSubmit={async () => {
                        setLoading(true);
                        var recoveryId = window.location.pathname.split('/')[2];
                        var res = await API.post('resetPassword', { recoveryid: recoveryId, newpassword: password })

                        enqueueSnackbar(res.data['message']);
                        if(res.data['code'] == 1) {
                            createBrowserHistory().push('/login')
                            window.location.reload();
                        }
                        setLoading(false);





                    }} className={classes.topContainer} noValidate>
                        <Grid item lg={12}>
                            <TextValidator
                                value={password}
                                onChange={e => setPassword(e.target['value'])}
                                validators={['required','minStringLength:8']}
                                errorMessages={['Password is required','Password must be atleast 8 characters']}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                type='password'
                                size='small'
                                name="password"
                                id="firstPass"
                                label="Enter new password"
                                autoFocus
                            // style={{minWidth : 400}}
                            />

                            <TextValidator
                                value={confirm}
                                onChange={e => setConfirm(e.target['value'])}
                                validators={['required', 'isPasswordMatch']}
                                errorMessages={['Password is required', 'Passwords do not match']}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                type='password'

                                name="confirm"
                                size='small'
                                id="secondPass"
                                label="Confirm password"

                            // style={{minWidth : 400}}
                            />
                            <Loader
                                type="submit"
                                fullWidth
                                icon={<div></div>}
                                variant="contained"
                                color="primary"
                                isLoading={loading}
                                className={classes.btnStyle}
                            >
                                Update Password
                        </Loader>
                        </Grid>
                        {/* <TextValidator
                            value={password}
                            onChange={e => setPassword(e.target['value'])}
                            validators={['required']}
                            errorMessages={['Password is required']}
                            variant="outlined"
                            margin="normal"
                            size='small'
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        /> */}

                    </ValidatorForm>
                    <Grid item lg={4} sm={3} xs={3}>
                    </Grid>
                    <Grid item lg={8} sm={9} xs={9}>
                        <List>
                            <ListItem>
                                <ListItemText>
                                    <Typography variant="subtitle2" align="left">Do you you want to login ?</Typography>

                                </ListItemText>

                                <ListItemSecondaryAction style={{ paddingLeft: 20 }}>
                                    <Link href="/login">
                                        <Typography align="center" variant="subtitle2" >click here</Typography>
                                    </Link>
                                </ListItemSecondaryAction>



                            </ListItem>
                            <ListItem>

                            </ListItem>
                        </List>
                        {/* <Link style={{color: 'blue',}} href="/signup">Don't have an accout ?</Link> */}


                        {/* <Link style={{color: 'blue'}} href="/signup">Singup</Link> */}
                    </Grid>
                    <Grid item lg={12} xs={12} style={{ paddingTop: 25, }}>

                    </Grid>
                </Grid>
            </Container>
            <Box className={classes.box}>
                <Grid container>
                    <Grid item lg={3}></Grid>
                    <Grid item lg={2}>
                        <Typography variant="subtitle2">© 2020, Australian Honey Ventures</Typography>
                    </Grid>
                    <Grid item lg={3} xs={3}>
                    </Grid>
                    <Grid item lg={2} xs={3}>
                        <Typography variant="subtitle2">Privacy Policy</Typography>
                    </Grid>
                    <Grid item lg={2}></Grid>
                </Grid>
            </Box>
        </div>
    )
}