import React, {FunctionComponent, useEffect, useState} from "react";
import {Dialog, DialogContent} from "@material-ui/core";
import {CreateAccount} from "./CreateAccount";
import {AppEvent, Bbc} from "../Helper";

export const GlobalLogin: FunctionComponent = props => {
    const [open, setOpen] = useState(false)
    const [onLogin, setOnLogin] = useState<() => void>();
    useEffect(() => {
        Bbc.subscribe(AppEvent.requestLoginDialog, (func) => {
            setOpen(true);
            setOnLogin(func);
        })
    }, [])
    return <Dialog open={open} onClose={() => {
        setOpen(false)
    }}>
        <DialogContent>
            <CreateAccount onLogin={() => {
                setOpen((false));
                if (onLogin) {
                    onLogin();
                }
            }}/>
        </DialogContent>
    </Dialog>
}