import React from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, CardContent, Button, List, Hidden,  ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import { Image, Work, BeachAccess } from '@material-ui/icons';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { MyButton } from './../Styles/MyButton';
import branding2 from './../assets/branding2.png';
import distributer from './../assets/distributer.jpg';
import jay from './../assets/jay.jpg';
import stephen from './../assets/stephen.jpg';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    listStyle: {
display: 'flex',
flexDirection: 'row',
width: '100%',

[theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },




    },

    teamroot: {

      [theme.breakpoints.down('sm')]: {
        marginTop: '2%',
      },


    },

    actionArea: {

        maxWidth: 450,
        
      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },
    root: {
      
      // boxShadow: 'none',
      // width: 600,
      // height: 584,
      boxShadow: 'none',
    
      width: '80%',
      height: '80%',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    
      backgroundSize: 'cover',

      
    },
    media: {
      
      width: 514,
      height: 409,
      
    },
  
    imgStyle: {
  
      maxWidth: 120,
     
  
    },

    mainHeading: {
      fontFamily: 'sophia',
      color: '#303030',
      fontSize: 22,
  

  },

  
  largeHeading: {
    fontFamily: 'sophia',
    color: '#303030',
    fontSize: 24,

    paddingBottom: 15,

},


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: '1.25rem',
      color: '#726f6f',
      fontWeight: 500,

  },
  
  

    vector: {

      boxShadow: 'none',
    
      width: '100%',
      height: '100%',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    
      backgroundSize: 'cover',

     
    }
  
}),
);




export const Team = () => {
    const classes = useStyles();

    return(
        <div style={{ paddingTop: '2%',}} className={classes.teamroot}>
        <Container maxWidth="xl">
        <Grid container
        
         direction="row"
         justify="flex-start"
         alignItems="flex-start"
         spacing={2}
         style={{paddingLeft: '5%', paddingRight: '5%'}}
        
         >

       

        <Grid item lg={3} sm={6} xs={12} md={6} style={{marginTop: '3%'}}>

       

        <Card className={classes.root}>
     
     <CardMedia
       >
           <img src={jay} className={classes.vector} alt="services" />
       </CardMedia>

 </Card>
      

        </Grid>

        

        <Hidden only={['sm', 'xs']}>

        <Grid item lg={3} sm={6} xs={12} md={6} style={{paddingTop: '4%', paddingRight: '4%'}}>

 <Typography className={classes.largeHeading} style={{ fontSize: '1.9rem',}} align="left" gutterBottom variant="h3" component="h2">
 DISTRIBUTORS CONTACT
       </Typography>
   
       
       <Typography className={classes.descriptionStyle}  align="left" variant="subtitle2" color="textSecondary" component="p">
       If you are an export distributor looking for product information and price guides, please contact Jay Curtin to arrange an introductory meeting at:<br /><br />
       </Typography>


      

       <Typography className={classes.descriptionStyle} style={{ fontSize: '1.5rem', color: '#f28f16'}} align="justify" variant="subtitle2" color="textSecondary" component="p">
       <b>+61 478 637 630</b>
     
       
       </Typography>

       <Typography variant="subtitle2" align="left" style={{fontSize: '1.1rem',}}>jayc@australianhoneyventures.com.au</Typography>

     </Grid>
     </Hidden>



     
     <Hidden only={['xl', 'lg', 'md']}>

<Grid item lg={6} sm={6} xs={12} md={6} style={{paddingTop: '4%'}}>

<Typography className={classes.largeHeading} style={{ fontSize: '1.9rem',}} align="center" gutterBottom variant="h3" component="h2">
DISTRIBUTORS CONTACT
</Typography>


<Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
If you are an export distributor looking for product information and price guides, please contact Jay Curtin to arrange an introductory meeting at:<br /><br />
</Typography>




<Typography className={classes.descriptionStyle} style={{ fontSize: '1.5rem', color: '#f28f16'}} align="center" variant="subtitle2" color="textSecondary" component="p">
<b>+61 478 637 630</b>


</Typography>

<Typography variant="subtitle2" align="center" style={{ fontSize: '1rem',}}>jayc@australianhoneyventures.com.au</Typography>

</Grid>
</Hidden>







     <Grid item lg={3} sm={6} xs={12} md={6} style={{marginTop: '3%'}}>

       

<Card className={classes.root}>

<CardMedia
>
   <img src={stephen} className={classes.vector} alt="services" />
</CardMedia>

</Card>


</Grid>




<Hidden only={['sm', 'xs']}>
<Grid item lg={3} sm={6} xs={12} md={6} style={{paddingTop: '4%', paddingRight: '4%'}}>

<Typography className={classes.largeHeading} style={{ fontSize: '1.9rem',}} align="left" gutterBottom variant="h3" component="h2">
BEEKEEPERS CONTACT
</Typography>


<Typography className={classes.descriptionStyle} align="left" variant="subtitle2" color="textSecondary" component="p">
If you’re a Beekeeper looking to supply honey to AHV, please contact Stephen Fewster at:<br /><br />
</Typography>


<Typography className={classes.descriptionStyle} style={{ fontSize: '1.5rem', color: '#f28f16'}} align="justify" variant="subtitle2" color="textSecondary" component="p">
       <b>+61 407 920 954</b>
     
       
       </Typography>

       <Typography variant="subtitle2" align="left" style={{ fontSize: '1.1rem',}}>stephenf@australianhoneyventures.com.au</Typography>

</Grid>
</Hidden>



<Hidden only={['xl', 'md', 'lg']}>
<Grid item lg={6} sm={6} xs={12} md={6} style={{paddingTop: '4%'}}>

<Typography className={classes.largeHeading} style={{ fontSize: '1.9rem',}} align="center" gutterBottom variant="h3" component="h2">
BEEKEEPERS CONTACT
</Typography>


<Typography className={classes.descriptionStyle}  align="center" variant="subtitle2" color="textSecondary" component="p">
If you’re a Beekeeper looking to supply honey to AHV, please contact Stephen Fewster at:<br /><br />
</Typography>


<Typography className={classes.descriptionStyle} style={{fontSize: '1.5rem', color: '#f28f16'}} align="center" variant="subtitle2" color="textSecondary" component="p">
       <b>+61 407 920 954</b>
     
       
       </Typography>

       <Typography variant="subtitle2" align="center" style={{ fontSize: '1rem',}}>stephenf@australianhoneyventures.com.au</Typography>

</Grid>
</Hidden>


     

        </Grid>



        

        
        </Container>
        </div>
    )
}