import React, { useState , FunctionComponent } from 'react';
import { Box, Container, Grid, Link, Typography, List, Card, CardMedia, Button, IconButton, ListItem, Hidden,  CircularProgress } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import honeylogo from './../assets/honeylogo.png';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { API } from '../Helper';
import { Loader } from '../Components/LoaderButton';
import { useSnackbar } from 'notistack';
import { createBrowserHistory } from 'history';
import { Add } from '@material-ui/icons';
import { Ways } from './../Helper';
import imgplaceholder from './../assets/imgplaceholder.png';
import { Cancel } from '@material-ui/icons';
import firebase from 'firebase';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
            backgroundColor: '#ffffff',
        },
        listStyle: {
            display: 'flex',
            flexDirection: 'row',
        },
        box: {
            backgroundColor: '#fbfbfb',
            padding: '2%',
            
            marginTop: '8%',

            [theme.breakpoints.down('sm')]: {
                marginBottom: '18%',
            },

          

        },
        root: {
            marginTop: '5%',
            height: '10vh',
            marginBottom: '20%',
       
        },
        PopupBtnStyle: {
            fontFamily: 'sophia-regular',
            fontWeight: 1000,

            fontSize: 14,
            marginTop: 25,
            color: '#ffffff',
            padding: 10,
            width: '100%',
            backgroundColor: '#f28f16',
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#f28f16',
                    color: '#ffffff',
                    boxShadow: 'none',
                    textDecoration: 'none',
                },
            },
        },
        btnStyle: {
            fontFamily: 'sophia-regular',
            fontWeight: 1000,
            fontSize: 14,
            color: '#ffffff',

            padding: 10,
            backgroundColor: '#f28f16',
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#f28f16',
                    color: '#ffffff',
                    boxShadow: 'none',
                    textDecoration: 'none',
                },
            },
        },
        topContainer: {
            borderBottom: '1px solid #efefef',
            marginBottom: '5%',
            paddingBottom: '2%',
        },
    }),
);

export const ImageCard:FunctionComponent<{ url :any , onDelete:()=>void}>  = props => {
    return <Card style={{ width: '100%', height: 300, backgroundColor: '#fbfbfb' }}>,
    <IconButton onClick={async ()=>{
        var ref = firebase.app().storage().refFromURL(props.url);
        await ref.delete();
        props.onDelete();
   }}>
            <Cancel style={{ paddingLeft: 5, }} />
        </IconButton>
        <CardMedia>
        <img src={props.url!=null ? props.url : imgplaceholder} style={{height: '100%', width: '100%', objectFit: 'cover'}}/>
        </CardMedia>


    </Card>
}

export const SignupPage = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [obj , setObj] = useState({});

    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [completeCert, setCompleteCert] = useState(false);
    const [completeLicense, setCompleteLicense] = useState(false);
    const [uploadingCert, setUploadingCert] = useState(false);
    const [uploadingLicense, setUploadingLicense] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [certificate , setCertificate] = useState();
    const [license , setLicense] = useState();


    const set = (event:any)=>{
        var c = obj;
        c[event['target']['name']] = event['target']['value']
        setObj({...c , ...obj})
    }

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Container maxWidth="md" className={classes.topContainer}>
                <Grid container direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item lg={3}>
                        <Link href="/">
                            <img src={honeylogo} alt="image" />
                        </Link>
                    </Grid>
                    <Grid item lg={6} xs={2}></Grid>
                    <Grid item lg={1}>
                        <Lock />
                    </Grid>
                    <Grid item lg={2}>
                        <Typography align="left" variant="subtitle2">Secure Login</Typography>
                    </Grid>
                </Grid>
            </Container>
            <ValidatorForm onSubmit={async () => {
                //  alert('Starting')
                setLoading(true);
                var val = {
                ...obj , licenseURL : license , certificateURL : certificate
                };

            console.log(val);

              if (license == null || certificate == null) {
                  enqueueSnackbar("Fields are missing")
              } else{
                await API.post('signup', val).then(x => {
                    console.log(x);
                        if (x.status == 200) {
                    enqueueSnackbar("SignUp Successfull.")
                    createBrowserHistory().push("/aftersignup");
                        } else if (x.status == 203) {
                            enqueueSnackbar("Email already exists")
                        } else if (x.status >= 400 && x.status <500 ) {
                            enqueueSnackbar("Client side error")
                        }




                        
                        // else {
                        //     enqueueSnackbar("SignUp unsuccessfull.")
                        // }
                    //    createBrowserHistory().push("/aftersignup");
                    //    window.location.reload();
                    
                    // if (x.status == 200) {
                    //     enqueueSnackbar("SignUp Successfull.")
                    //     createBrowserHistory().push("/aftersignup");
                    //     window.location.reload();
                    // } 
                    // else {
                    //     enqueueSnackbar("Fields are missing")
                    // }
                })
            }
                setLoading(false)
            }}>
                <Container maxWidth="md">
                    <Grid container direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={1}
                    >
                      <Grid item lg={12} style={{ paddingBottom: 10 }}>
                            <Typography variant='h4'><b>Welcome to Australian Honey Ventures</b></Typography>
                            <br />
                            <Typography variant="body2">To view our wholesale buy prices for our products, you will need to register for an account with us.
                             In addition to personal details, you will be required to upload evidence of your business registration, a photo of you holding your driver's license,
                             and disclosure of the role you play within your stated company.</Typography>
                            <br /><br />
                        </Grid>

                        <Grid item lg={12} xs={12} style={{ paddingBottom: 10 }}>
                            <Typography variant='h6' align="left">Contact Details</Typography>
                        </Grid>
                        <Grid item lg={12} xs={12}  spacing={2} >
                            <TextValidator
                                value={obj['companyName']}
                                onChange={set}
                                fullWidth
                                margin="dense"
                                id="name"
                                label="Name of Company/Business"
                                validators={['required', 'minStringLength:2']}
                                errorMessages={['Company Name is required', 'Company Name should be more than 2 characters']}
                                type="name"
                                name='companyName'
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item lg={6} xs={12}  spacing={2} style={{ paddingRight: 5 }}>
                            <TextValidator
                                value={obj['firstName']}
                                onChange={set}
                                fullWidth
                                margin="dense"
                                id="name"
                                label="First Name"
                                validators={['required', 'minStringLength:2']}
                                errorMessages={['First Name is required', 'First Name should be more than 2 characters']}
                                type="name"
                                name='firstName'
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item lg={6} xs={12} spacing={2}>
                            <TextValidator
                                value={obj['lastName']}
                                onChange={set}
                                fullWidth
                                margin="dense"
                                id="name"
                                label="Last Name"
                                validators={['required']}
                                errorMessages={['Last Name is required',]}
                                type="name"
                                name='lastName'
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item lg={6} xs={12} style={{ paddingRight: 5 }}>
                            <TextValidator
                                fullWidth
                                value={obj['phone']}
                                onChange={set}
                                margin="dense"
                                id="phone"
                                label="Phone"
                                validators={['required', 'isNumber']}
                                errorMessages={['Phone is required', 'Phone number is invalid']}
                                type="phone"
                                name='phone'
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <TextValidator
                                fullWidth
                                margin="dense"
                                value={obj['email']}
                                onChange={set}
                                id="name"
                                name='email'
                                validators={['required']}
                                errorMessages={['Email is required',]}
                                label="Email Address"
                                type="email"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <TextValidator
                                name='password'
                                onChange={set}
                                fullWidth
                                validators={['required', 'minStringLength:8']}
                                errorMessages={['Password is required', 'Password must be at least 8 characters long']}
                                margin="dense"
                                value={obj['password']}
                                id="name"
                                label="Password"
                                type="password"
                                variant="outlined"
                                autoComplete="new-password"
                            />
                        </Grid>
                        <Grid item lg={12} xs={12} style={{ paddingBottom: 25, paddingTop: 50 }}>
                            <Typography variant='h5'><b>Proof Of Identity</b></Typography>
                            <br />
                            <Typography variant='h6'>Upload Business Certificate</Typography>
                            {/* <Typography style={{ minWidth: 435, }} variant="caption">By creating an account, you agree to
                                the Australian Honey Ventures, Terms and Privacy Policy</Typography> */}
                        </Grid>

                        {/* Certificate Upload / Show Image Logic */}
                        <Grid item lg={5} xs={12}>
                            {(uploadingCert || !completeCert) && <Card style={{ width: '100%', height: 300, backgroundColor: '#fbfbfb',
      
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      
      backgroundSize: 'cover', }}>,
                                 <CardMedia style={{ paddingLeft: '44%', paddingTop: '20%', }} >
                                    <IconButton

                                        component="label"
                                    >
                                        {!uploadingCert ? <Add /> : <CircularProgress/>}
                                        <input
                                            type="file"
                                            // required
                                            style={{ display: "none", }}
                                           
                                            
                                            onChange={async e => {
                                                // Null Checking of selected files
                                                console.log(e);

                                                if (e.target.files) {
                                                    // this will get the first of the selected files
                                                    let file = e.target.files[0];
                                                    console.log(file);
                                                    // check for the file size here
                                                    // the file size is represented in bytes

                                                    if (file.size > 2097152) {
                                                        console.log(
                                                            "File size " +
                                                            file.size +
                                                            " is larger than 1 MB.\nIt will not be uploaded"
                                                        );
                                                        return;
                                                    }
                                                    setUploadingCert(true);
                                                    // This method will upload the file and return a proper url
                                                    var ref = firebase.app().storage().ref("Images/" + Date.now());
                                                    var task = ref.put(file);
                                                    task.on(firebase.storage.TaskEvent.STATE_CHANGED, function (snapshot) {
                                                        var percent = snapshot.bytesTransferred / snapshot.totalBytes * 100;
                                                        console.log(percent + "% done");
                                                        setUploadProgress(percent);
                                                    });
                                                    task.on(firebase.storage.TaskEvent.STATE_CHANGED, {
                                                        'complete': async function () {
                                                            console.log('upload complete!');
                                                            // task.on('')
                                                            let url = await ref.getDownloadURL();
                                                            setUploadingCert(false);
                                                            setCompleteCert(true);
                                                            setCertificate(url)
                                                            console.log(url);
                                                            // below is just housekeeping stuff
                                                        }
                                                    });

                                                }
                                            }}
                                        />
                                    </IconButton>
                                    <Typography variant="subtitle2">Upload</Typography>

                                </CardMedia>
                            </Card>
                            }
                            {completeCert && <ImageCard onDelete={()=>{
                                setCompleteCert(false);
                            }} url={certificate}/>}
                        </Grid>

                        <Grid item lg={7} xs={12}></Grid>

                        {/* End Certificate Upload / Show Logic */}

                        <Grid item lg={12} xs={12} style={{ paddingBottom: 25, paddingTop: 50 }}>
                            <Typography variant='h6'>Upload a photo of you holding your Drivers License</Typography>
                            {/* <Typography style={{ minWidth: 435, }} variant="caption">By creating an account, you agree to
                                the Australian Honey Ventures, Terms and Privacy Policy</Typography> */}
                        </Grid>

                        <Grid item lg={5} xs={12}>
                          {(uploadingLicense || !completeLicense) &&   <Card style={{ width: '100%', height: 300, backgroundColor: '#fbfbfb' }}>,
                            <CardMedia style={{ paddingLeft: '44%', paddingTop: '20%' }} >

                                    <IconButton

                                        component="label"
                                    >
                                        {!uploadingLicense ? <Add /> : <CircularProgress/>}
                                        <input
                                            type="file"
                                            // required
                                            style={{ display: "none" }}
                                            onChange={async e => {
                                                // Null Checking of selected files

                                                if (e.target.files) {
                                                    // this will get the first of the selected files
                                                    let file = e.target.files[0];
                                                    // check for the file size here
                                                    // the file size is represented in bytes

                                                    if (file.size > 2097152) {
                                                        console.log(
                                                            "File size " +
                                                            file.size +
                                                            " is larger than 1 MB.\nIt will not be uploaded"
                                                        );
                                                        return;
                                                    }
                                                    setUploadingLicense(true);
                                                    // This method will upload the file and return a proper url
                                                    var ref = firebase.app().storage().ref("Images/" + Date.now());
                                                    var task = ref.put(file);
                                                    task.on(firebase.storage.TaskEvent.STATE_CHANGED, function (snapshot) {
                                                        var percent = snapshot.bytesTransferred / snapshot.totalBytes * 100;
                                                        console.log(percent + "% done");
                                                        setUploadProgress(percent);
                                                    });
                                                    task.on(firebase.storage.TaskEvent.STATE_CHANGED, {
                                                        'complete': async function () {
                                                            console.log('upload complete!');
                                                            // task.on('')
                                                            let url = await ref.getDownloadURL();
                                                            setUploadingLicense(false);
                                                            setCompleteLicense(true);
                                                            setLicense(url)
                                                            console.log(url);
                                                            // below is just housekeeping stuff
                                                        }
                                                    });

                                                }
                                            }}
                                        />
                                    </IconButton>
                                    <Typography variant="subtitle2">Upload</Typography>

                                </CardMedia>


                            </Card>}

                            {completeLicense && <ImageCard url={license} onDelete={()=>{
                                setCompleteLicense(false);
                            }}/>}

                        </Grid>

                        <Grid item lg={7}></Grid>
                      

                        <Grid item lg={12} xs={12} style={{ paddingBottom: 10, paddingTop: 50 }}>
                            <Typography variant='h6'>State your role in your business</Typography>

                        </Grid>

                        <Grid item lg={12} xs={12} spacing={2} style={{ paddingRight: 5 }}>
                            <TextValidator
                                value={obj['role']}
                                onChange={set}
                                fullWidth
                                margin="dense"
                                id="name"
                                label="Your Role"
                                validators={['required', 'minStringLength:2']}
                                errorMessages={['Role is required']}
                                type="name"
                                name='role'
                                variant="outlined"
                            />
                        </Grid>



                        <Grid item lg={12} xs={12} style={{ paddingBottom: 10 , paddingTop: 50 }}>
                            <Typography variant='h6'>Country of Origin</Typography>

                        </Grid>

                        <Grid item lg={12} xs={12} spacing={2} style={{ paddingRight: 5 }}>
                            <TextValidator
                                value={obj['country']}
                                onChange={set}
                                fullWidth
                                margin="dense"
                                id="name"
                                label="Country of origin"
                                validators={['required', 'minStringLength:2']}
                                errorMessages={['Country is required']}
                                type="name"
                                name='country'
                                variant="outlined"
                            />
                        </Grid>











                        <Grid item lg={12} xs={12}>
                            <Loader className={classes.PopupBtnStyle} icon={<div></div>} fullWidth type='submit' variant="contained"
                                isLoading={loading}>Sign up</Loader>
                        </Grid>
                        <Grid item lg={9}>
                        </Grid>
                        <Grid item lg={3} xs={12} style={{ paddingTop: 10 }}>

                            <Hidden only={['sm', 'xs']}>
                            <List>
                                <ListItem>
                                    <Typography variant="subtitle2">Already have account ?</Typography>
                                    <Link href="/login">
                                        <Typography align="right" style={{ marginLeft: 5 }} variant="subtitle2">Login</Typography>
                                    </Link>
                                </ListItem>
                                <ListItem>

                                </ListItem>
                            </List>
                            </Hidden>


                            
                            <Hidden only={['md', 'lg', 'xl']}>
                            <List>
                                <ListItem style={{marginLeft: -5}}>
                                    <Typography variant="subtitle2">Already have account ?</Typography>
                                    <Link href="/login">
                                        <Typography align="right" style={{ marginLeft: 5 }} variant="subtitle2">Login</Typography>
                                    </Link>
                                </ListItem>
                                <ListItem>

                                </ListItem>
                            </List>
                            </Hidden>
                        </Grid>
                        <Hidden only={['sm', 'xs']}>
                        <Grid item lg={12} xs={12} style={{ marginTop: -40, }}>
                            <Typography style={{ maxWidth: 250, }} variant="caption">By creating an account, you agree to
                                the Australian Honey Ventures,<br /> <Link style={{color: '#f28f16'}} href="/terms"><b>Terms</b></Link> and Privacy Policy</Typography>
                        </Grid>
                        </Hidden>

                        <Hidden only={['md', 'lg', 'xl']}>
                        <Grid item lg={12} xs={12} style={{ marginTop: -40, }}>
                            <br />
                            <Typography style={{ maxWidth: 250, paddingBottom: '5%' }} variant="caption">By creating an account, you agree to
                                the Australian Honey Ventures, <Link style={{color: '#f28f16'}} href="/terms"><b>Terms</b></Link> and Privacy Policy</Typography>
                        </Grid>
                        </Hidden>
                    </Grid>
                </Container>
            </ValidatorForm>
            <Box className={classes.box}>
                <Container maxWidth="md">
                <Grid container>
                   
                    <Grid item lg={7} xs={12} sm={12}>
                        <Typography align="left" variant="subtitle2">© 2020, Australian Honey Ventures</Typography>
                    </Grid>
                    <Grid item lg={2} xs={2}>
                    </Grid>
                    <Grid item lg={3} xs={3}>
                        {/* <Typography variant="subtitle2" align="right">Privacy Policy</Typography> */}
                    </Grid>
                   
                </Grid>
                </Container>
            </Box>
        </div>
    )
}