import React, { useState , FunctionComponent } from 'react';
import { Box, Container, Grid, Link, Typography, List, Card, CardMedia, Button, IconButton, ListItem, CircularProgress } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import honeylogo from './../assets/honeylogo.png';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
            backgroundColor: '#ffffff',
        },
        listStyle: {
            display: 'flex',
            flexDirection: 'row',
        },
        box: {
            backgroundColor: '#fbfbfb',
            paddingTop: '2%',
            paddingBottom: '2%',
            marginTop: '8%',
        },
        root: {
            marginTop: '5%',
            height: '10vh',
            marginBottom: '20%',
        },
        PopupBtnStyle: {
            fontFamily: 'sophia-regular',
            fontWeight: 1000,

            fontSize: 14,
            marginTop: 25,
            color: '#ffffff',
            padding: 10,
            width: '100%',
            backgroundColor: '#f28f16',
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#f28f16',
                    color: '#ffffff',
                    boxShadow: 'none',
                    textDecoration: 'none',
                },
            },
        },
        btnStyle: {
            fontFamily: 'sophia-regular',
            fontWeight: 1000,
            fontSize: 14,
            color: '#ffffff',

            padding: 10,
            backgroundColor: '#f28f16',
            '&.MuiButton-contained': {
                '&:hover': {
                    backgroundColor: '#f28f16',
                    color: '#ffffff',
                    boxShadow: 'none',
                    textDecoration: 'none',
                },
            },
        },
        topContainer: {
            borderBottom: '1px solid #efefef',
            marginBottom: '5%',
            paddingBottom: '2%',
        },
    }),
);



export const ConfirmationMsg = () => {
  

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Container maxWidth="md" className={classes.topContainer}>
                <Grid container direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item lg={3}>
                        <Link href="/">
                            <img src={honeylogo} alt="image" />
                        </Link>
                    </Grid>
                    <Grid item lg={6} xs={2}></Grid>
                    <Grid item lg={1}>
                        <Lock />
                    </Grid>
                    <Grid item lg={2}>
                        <Typography align="left" variant="subtitle2">Secure Login</Typography>
                    </Grid>
                </Grid>
            </Container>


        
                <Container maxWidth="md">
                    <Grid container direction="row"
                        justify="center"
                        alignItems="center"
                        style={{paddingBottom: '8%'}}
                    >
                        <Grid item lg={12} style={{ paddingTop: '8%' }}>
                            <Typography variant='h5'><b>Thankyou for signing up with AHV. Our team is reveiwing your account details.
You will receive an email when your account is approved.</b></Typography>
                            <br /><br />
                        </Grid>
                        <Grid item lg={3}><Link href="/"><Button style={{color: '#fff', backgroundColor: '#f28f16', marginBottom: '8%'}} variant="contained">Browse Website</Button></Link></Grid>
                        <Grid item lg={9}></Grid>

                        </Grid>
                        <Grid item lg={12}></Grid>

                        </Container>

                
        </div>
    )
}