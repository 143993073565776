import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Slider } from './Slider';
import { Introduction } from './Introduction';
import { LandingServices } from './LandingServices';
import { MobileHeader } from '../Components/MobileHeader';
import { Hidden, Typography } from '@material-ui/core';
import { DistributorProducts } from './DistributorProducts';
import { PricesKeepers } from './PricesKeepers';
import { Test } from './Test';
import { Team } from './Team';
import { NewHeader } from '../Components/NewHeader';
import { NewFooter } from '../Components/NewFooter';
import { ThanksPage } from '../Dashboard/Order/ThanksPage';
import { ResponsiveTesting } from './../Components/ResponsiveTesting';
import { ResponsiveTesting2 } from './../Components/ResponsiveTesting2';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
root: {
// marginTop: 7,
marginTop: -3,

[theme.breakpoints.down('lg')]: {
  marginTop: -4,
},
},
   
  }),
);



export const LandingLayout = () => {
    const classes = useStyles();

    return(

        <div className={classes.root}>
          {/* <NewHeader /> */}
       <Slider />
       <Introduction />
       <LandingServices />
       <DistributorProducts />
       <PricesKeepers />
       {/* <ResponsiveTesting />
       <ResponsiveTesting2 /> */}
       
     
  
        </div>
    )
}