import React from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, CardContent, ListItemText, ListItemAvatar, Avatar,Button,  ListItemIcon, Divider, List, ListItem, ListItemSecondaryAction } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Done } from '@material-ui/icons';
import { Image, Work, BeachAccess, Lock } from '@material-ui/icons';
import password from './../assets/password.png';
import box from './../assets/box.png';
import securepayment from './../assets/securepayment.png';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    actionArea: {

        maxWidth: 450,
        
      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },
    root: {
      
      boxShadow: 'none',
      width: 514,
      height: 409,

      
    },
    media: {
      
      width: 514,
      height: 409,
      
    },
  
    imgStyle: {
  
      maxWidth: 120,
     
  
    },

    
    mainHeading: {
      fontFamily: 'sophia',
      // color: '#303030',
      color: '#fff',
      fontSize: 22,
  

  },


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: '1.25rem',
      paddingLeft: '6%',
      fontWeight: 500,
      // color: '#726f6f',
      color: '#fff',

  },
  

  btnStyle: {

    fontFamily: 'sophia',
    padding: 15,
    width: 230,
    backgroundColor: '#fff',
    marginTop: '5%',
  },
  secondList: {

    [theme.breakpoints.down('sm')]: {
      marginTop: '-11%',
      },

  },
  
  
}),
);
  




export const Fees = () => {
    const classes = useStyles();

    return(
        <div style={{backgroundColor: '#FCB040', paddingTop: '8%', paddingBottom: '8%',}}>
        <Container maxWidth="xl">
        <Grid container
         spacing={2}
         direction="row"
         justify="center"
         alignItems="center"
         style={{paddingBottom: '3%'}}
         
         
         >

        <Grid item lg={4} sm={12} xs={12}>
    
           
           
          <Typography className={classes.mainHeading} style={{fontSize: '1.9rem'}} align="center" gutterBottom variant="h5" component="h2">
          Who can become a stockist?
          </Typography>
          <Typography className={classes.descriptionStyle} style={{color: '#fff'}} align="center" variant="subtitle2">To be eligible you must:</Typography>

        </Grid>

        </Grid>

      
        <Grid container
         spacing={2}
         direction="row"
         justify="center"
         alignItems="center"
         style={{ paddingLeft: '7%', paddingRight: '7%', borderBottom: '2px solid #fff', borderTop: '2px solid #fff'}}
         >
            
             <Grid item lg={5} >
                 <List style={{maxWidth: 450, pointerEvents: 'none'}}>
                 <ListItem button>
          <ListItemIcon>
            <Done style={{color: '#fff'}} />
          </ListItemIcon>
          <ListItemText>
            <Typography className={classes.descriptionStyle} variant="body2">Be a registered business owner</Typography>
          </ListItemText>
       
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <Done style={{color: '#fff'}} />
          </ListItemIcon>
          <ListItemText>
            <Typography className={classes.descriptionStyle} variant="body2">Have an active ABN</Typography>
          </ListItemText>
         
        </ListItem>
                 </List>

             </Grid>

             <Grid item lg={5}>

             <List style={{width: '100%', pointerEvents: 'none'}}>
                     <ListItem className={classes.secondList}>
                     <ListItemIcon>
                         <Done style={{color: '#fff'}} />

                     </ListItemIcon>
                      <ListItemText><Typography className={classes.descriptionStyle} variant="body2">Purchase a minimum of $150 each order</Typography></ListItemText>
                  
                     </ListItem>

                     <ListItem button>
          <ListItemIcon>
            <Done style={{color: '#fff'}} />
          </ListItemIcon>
          <ListItemText>
            <Typography className={classes.descriptionStyle} variant="body2">Have a store that is actively trading</Typography>
          </ListItemText>
    
        </ListItem>
                 </List>

                 
                 
                 </Grid>

                
             </Grid>

            <Grid container justify="center">
             <Grid item lg={9} xs={12}>
                 <List style={{maxWidth: '100%', paddingTop: '7%', display: 'flex', paddingLeft: '17%', flexDirection: 'row'}}>
      <ListItem>
        <ListItemAvatar>
         <img src={box} width="70" height="70" />
        </ListItemAvatar>
        <ListItemText>
          <Typography className={classes.descriptionStyle} variant="body2">Applicants unable to satisfy any criteria listed above will not be considered </Typography>
        </ListItemText>
      </ListItem>
   
    
    </List>

   


                 </Grid>

                 
                 </Grid>


        </Container>


        </div>
    )
}