import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import { ShoppingBasket, Done } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Grid, PropTypes, IconButton, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',

            '&.MuiButton-text': {
                paddingTop: 10,
                paddingBottom: 10,
            },

        },
        wrapper: {
            margin: theme.spacing(0),
            padding: theme.spacing(0),



            position: 'relative',
        },
        buttonSuccess: {







        },
        buttonProgress: {
            //   color: green[500],
            color: '#ffffff',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -5,
            marginLeft: -12,



            '&.MuiButton-text': {
                paddingTop: 10,
                paddingBottom: 10,
            },

        },
    }),
);
export const Loader: FunctionComponent<{
    onClick?: () => void,
    type?: 'submit' | 'reset' | 'button',
    fullWidth?: boolean,
    name?: string,
    variant?: 'text' | 'outlined' | 'contained',
    color?: PropTypes.Color
    className?: string,
    startIcon?: any,
    isLoading?: boolean;
    isDisabled?:boolean,
    disabledContent?:any,
    disabledIcon?:any,
    icon? : any,

}> = props => {
    const classes = useStyles();
    const [success, setSuccess] = React.useState(false);
    const [loading, setLoading] = React.useState(props.isLoading)
    const timer = React.useRef<number>();
    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });
    React.useEffect(() => {
        console.log(props.isLoading);
        if (props.isLoading) {
            setLoading(!props.isLoading);
        }
        return () => {
            clearTimeout(timer.current);
        };
    }, []);
    const handleButtonClick = () => {
        if (props.onClick) {
            props.onClick();
        }
    };
    return (
        <div className={classes.wrapper}>
            <Grid item lg={12} xs={12}>
                <Button
                    startIcon={props.isDisabled ? props.disabledIcon ? props.disabledIcon : <Done /> : props.icon ? props.icon : <ShoppingBasket /> }
                    
                    variant={props.variant}
                  
                    type={props.type}
                    fullWidth={props.fullWidth}
                    color={props.color}
                    style={{ backgroundColor: props.isDisabled ? 'grey' : '#f28f16' , color: '#fff', minWidth: '100%', maxWidth: '100%' }}
                    className={props.className}
                    disabled={props.isLoading || props.isDisabled}
                    onClick={handleButtonClick}
                    name={props.name}
                >
                    {props.children}
                </Button>
                {props.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Grid>
        </div>
    );
}

export const AutoLoader: FunctionComponent<{
    onClick?: () => void,
    type?: 'submit' | 'reset' | 'button',
    fullWidth?: boolean,
    name?: string,
    variant?: 'text' | 'outlined' | 'contained' ,
    color?: PropTypes.Color
    className?: string,
    startIcon?: any,
    isDisabled?:boolean,
    disabledContent?:any

}> = props => {
    const classes = useStyles();
    const [success, setSuccess] = React.useState(false);
    const [loading, setLoading] = React.useState(false)
    const timer = React.useRef<number>();
    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });
    React.useEffect(() => {
        
        return () => {
            clearTimeout(timer.current);
        };
    }, []);
    const handleButtonClick = async () => {
        if (props.onClick) {
            setLoading(true);
            await props.onClick();
            setLoading(false);
        }
    };
    return (
        <div className={classes.wrapper}>
            <Grid item lg={12} xs={12}>
                <Button
                    startIcon={props.startIcon}
                    variant={props.variant}
                    
                    type={props.type}
                    fullWidth={props.fullWidth}
                    color={props.color}
                    style={{ backgroundColor: props.isDisabled ? 'grey' : '#f28f16' , color: '#fff', minWidth: '100%', maxWidth: '100%' }}
                    className={props.className}
                    disabled={loading || props.isDisabled}
                    onClick={handleButtonClick}
                    name={props.name}
                >
                    {props.children}
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Grid>
        </div>
    );
}



export const AutoLoaderIcon: FunctionComponent<{
    onClick?: () => void,
    type?: 'submit' | 'reset' | 'button',
    name?: string,
    color?: PropTypes.Color
    className?: string,
    isDisabled?:boolean,
    disabledContent?:any

}> = props => {
    const classes = useStyles();
    const [success, setSuccess] = React.useState(false);
    const [loading, setLoading] = React.useState(false)
    const timer = React.useRef<number>();
    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });
    React.useEffect(() => {
        
        return () => {
            clearTimeout(timer.current);
        };
    }, []);
    const handleButtonClick = async () => {
        if (props.onClick) {
            setLoading(true);
            await props.onClick();
            setLoading(false);
        }
    };
    return (
        <div className={classes.wrapper}>
                <IconButton
                    // style={{ backgroundColor: props.isDisabled ? 'grey' : '#f28f16' , color: '#fff', }}
                    disabled={loading || props.isDisabled}
                    onClick={handleButtonClick}
                    name={props.name}
                >
                    {props.children}
                </IconButton>
                {loading && <CircularProgress size={20} className={classes.buttonProgress} />}
        </div>
    );
}