import React from 'react';
import { Grid , Container, Typography, Card, CardActionArea, CardMedia, CardContent, List, ListItem, Button } from '@material-ui/core';
import sectionimg from './../assets/sectionimg.png';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { MyButton } from './../Styles/MyButton';\
import fav1 from './../assets/fav1.png';
import highprice from './../assets/highprice.png';
import competition from './../assets/competition.png';
import community from './../assets/community.png';
import { Favorite } from '@material-ui/icons';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    main: {
      marginTop: '-15%',
      [theme.breakpoints.down('md')]: {
        marginTop: '35%',
        
      },

    },

    actionArea: {
      paddingTop: '3%',
      paddingBottom: '3%',

      '&.MuiCardActionArea-root': {

        textAlign: 'center',


      },

    },
    root: {
      width: 350,
      height: 340,
      backgroundColor: 'transparent',
      boxShadow: 'none',
      


    },
    media: {
      height: 100,



    },


    sectionMedia: {

      width: '100%',
      height: '100%',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      paddingBottom: '3%',
      backgroundSize: 'cover',

      [theme.breakpoints.down('md')]: {
        display: 'none',
        
      },

      
  },

  card: {
    maxHeight: 880,
    height: 'auto',
     maxWidth: '100%',
     margin: "auto",
    boxShadow : 'none',
    position: 'relative',

   
},

    imgStyle: {

      maxWidth: 120,


    },

    iconStyle: {

        fontSize: 70,
        color: '#fff',
        backgrounColor: '#fff',
    },


    mainHeading: {
      fontFamily: 'sophia',
      // color: '#303030',
      color: '#fff',
      fontSize: 22,
      paddingTop: 25,
      paddingBottom: 15,

      [theme.breakpoints.down('md')]: {
        color: '#303030',
      },

  },


  descriptionStyle: {

      fontFamily: 'sophia-regular',
      fontSize: '1.25rem',
      fontWeight: 500,
      // color: '#726f6f',
      color: '#fff',
      [theme.breakpoints.down('md')]: {
        color: '#303030',
      },

  },

  btnStyle: {
    backgroundColor: '#fff',
    marginTop: '5%',
    marginLeft: '-2%',
    color: '#303030',
    padding: 15,

      '&.MuiButton-contained': {
        '&:hover': {
          backgroundColor: '#fff',
        },
      },

      [theme.breakpoints.down('sm')]: {
        marginLeft: '0%',
      },

  },
  

}),
);






export const Success = () => {
    const classes = useStyles();

    return(
        <div className={classes.main} style={{backgroundColor: 'transparent', paddingTop: '8%', paddingBottom: '8%'}}>
            <Card  className={classes.card}>
                            <CardMedia >
                                <img className={classes.sectionMedia} src={sectionimg} alt="image" />
                            </CardMedia>
                        </Card>
        <Container>
        <Grid container
         spacing={4}
         direction="row"
         justify="center"
         alignItems="center"
         style={{marginTop: '-66%', paddingTop: '3%', position: 'relative',}}

         >

             <Grid item xs={10}>
                 <Typography className={classes.mainHeading} style={{ fontSize: '1.9rem',}} align="center" variant="h5">
                 Small changes, big impact
                 </Typography>

                 <Typography className={classes.descriptionStyle} align="center" variant="body2">
             A new-age honey packing company returning more to Beekeepers and creating a better tomorrow for everyone.
                 </Typography>
             </Grid>

        <Grid item lg={4} sm={6} xs={12}>
        <Card className={classes.root}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          className={classes.media}>

             {/* <Favorite className={classes.iconStyle} /> */}
             <img src={highprice} width="140" height="140" />
          </CardMedia>
        <CardContent>


          <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
          High Prices
          </Typography>
          <br />
          <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
          The concept of sharing sale profits helps to create better outcomes for beekeepers
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>

        </Grid>

        <Grid item lg={4} sm={6} xs={12}>
        <Card className={classes.root}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          className={classes.media}>
           {/* <Favorite className={classes.iconStyle} /> */}
           <img src={competition} width="140" height="140" />
          </CardMedia>
        <CardContent>

          <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
          Increased competitiveness
          </Typography>
          <br />
          <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
          Facilitating more meaningful collaboration with industry will position Australia as a nation of premium honey

          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
        </Grid>

        <Grid item sm={6} lg={4} xs={12}>
        <Card className={classes.root}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          className={classes.media}>
             <img src={community} width="140" height="140" />
             {/* <Favorite className={classes.iconStyle} /> */}
          </CardMedia>
        <CardContent>

          <Typography className={classes.mainHeading} align="center" gutterBottom variant="h5" component="h2">
          Engaged communities
          </Typography>
          <br />
          <Typography className={classes.descriptionStyle} align="center" variant="subtitle2" color="textSecondary" component="p">
          Building shared industry understanding with communities will have material positive effects on society<br />
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
        </Grid>


        </Grid>

        <Grid container justify="center" alignItems="center" style={{paddingTop: '4%'}}>
     
        <Grid item lg={3} xs={12} sm={12}>
            <Typography align="center">
            <Button fullWidth className={classes.btnStyle} variant="contained">
                Register Your Interest
            </Button>
            </Typography>
           
        

        </Grid>
        </Grid>
        </Container>
        </div>
    )
}