import React, { useEffect, useState } from 'react';
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Settings, Lock, Restore, Storage, Email, Favorite } from '@material-ui/icons';
import { Link, ListItemIcon, ListItemText,  List, Badge, ListItemSecondaryAction, Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';
import { Ways, LoginHelper, API, Cook } from './../Helper';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { GlobalState } from '../AppState';
import { observer } from 'mobx-react';
import  {createBrowserHistory} from 'history';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginBottom: "6%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      // fontWeight: theme.typography.fontWeightRegular,
      
    },

    mainStyle: {
        width: '100%',
        // border: '1px solid #efefef',
        borderRadius: 8,
    
      },
    
      menuHeading: {
    
        paddingLeft: '5%',
        paddingTop: '5%',
        fontFamily: 'sophia',
      },
    
      customBadge: {
        backgroundColor: "#ffffff",
        color: "#f28f16"
      }
    
  })
);

const StyledMenuItem = withStyles((theme) => ({
    root: {
  
      // width: 300,
      border: '1px solid #efefef',
  
  
  
    },
  
  }))(MenuItem);
  
  export const UnreadCount = observer((props: { count }) => <ListItemSecondaryAction>{GlobalState.unreadMessages.length}</ListItemSecondaryAction>);

export const ExpansionMenu = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [messageCount, setMessageCount] = useState(0);


  useEffect(() => {
    API.get('unreadMessages', { userid: Cook.getUserData()['user']['id'] }).then(x => {
      console.log(x.data);

      GlobalState.unreadMessages = x.data;
    })
  }, [])

  const menuItems: { path?: string, title: string, icon: JSX.Element, onClick?: () => void }[] = [
    { path: Ways.quotesPage, title: 'Quotes', icon: <Restore fontSize="small" style={{ fontSize: 21, }} /> },
    { path: Ways.ordersPage, title: 'Orders', icon: <Storage fontSize="small" style={{ fontSize: 21, }} /> },
    { path: Ways.chatPage, title: 'Messages', icon: <Email fontSize="small" style={{ fontSize: 21, }} /> },
    { path: Ways.wishlistPage, title: 'Wishlist', icon: <Favorite fontSize="small" style={{ fontSize: 21 }} /> },
    // { path: Ways.settingsPage, title: 'Profile Settings', icon: <Settings fontSize="small" style={{ fontSize: 21, }} /> },
    {
      path: Ways.settingsPage,
      title: 'Profile Settings',
      icon: <Settings fontSize="small" style={{ fontSize: 21, }} />
  },
  {
      title: 'Logout', icon: <Lock fontSize="small" style={{ fontSize: 21, }} />, onClick: () => {
          setOpen(true)
      }
  }

    // {
    //   title: 'Logout', icon: <Lock fontSize="small" style={{ fontSize: 21, }} />, onClick: () => {
    //     LoginHelper.logout();
    //   }
    // }

  ];




  return (
    <div className={classes.root}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{backgroundColor: '#fbfbfb'}}
        >
          <Typography className={classes.heading}>Menu</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
        <div className={classes.mainStyle}>
      {/* <Typography className={classes.menuHeading} variant="h6">Menu</Typography> */}
      <br />
      {menuItems.map((x, i) => {
        let isSelected = window.location.pathname == (x.path);
        return <Link key={i} href={x.path} style={{ textDecoration: 'none', color: '#4c4a4a', }}>
          <StyledMenuItem onClick={e => {
            if (x.onClick != null) {
              x.onClick();
            }
          }} style={{ fontSize: 25, paddingTop: 15, paddingBottom: 15, backgroundColor: isSelected ? '#f28f16' : '4c4a4a', color: isSelected ? 'white' : '#4c4a4a' }}>
            <ListItemIcon style={{ marginLeft: 5, color: isSelected ? 'white' : '#4c4a4a' }}>
              {x.icon}
            </ListItemIcon>
            <ListItemText primary={x.title} />
            {
              (x.path == Ways.chatPage) &&  <Badge badgeContent={GlobalState.unreadMessages.length} classes={{ badge: classes.customBadge }} ></Badge>
            }
          </StyledMenuItem>
        </Link>
      })}

    </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogContent style={{ padding: 20, width: 350 }}>
                    Are you sure you want to logout ?
                </DialogContent>
                <DialogActions style={{ paddingBottom: 15 }}>
                    <Button variant="contained" style={{ backgroundColor: '#f28f16', color: '#fff', boxShadow: 'none' }} onClick={() => {
                        if (LoginHelper.isCookiePresent()) {
                            LoginHelper.logout()
                        } else {
                            createBrowserHistory().push('/login')
                            window.location.reload();
                        }
                    }}>Logout</Button>
                    <Button variant="outlined" onClick={() => {
                        setOpen(false)
                    }}>Cancel</Button>
                </DialogActions>
            </Dialog>
    
    
    </div>
  );
}
